import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Unidade from "react-1app/lib/View";
import Edit from "./Edit";
import moment from "moment";
import * as Producao from "../../worker/producao";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      load: true,
      totalLista: 0,
      sugestEmpresa:[],
      sugestUsuarios:[],
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
    this.sugest();
    this.sugest2()
  }

  sugest() {
    Producao.listarSugestEmpresa({},(lista) => {
      this.setState({
        sugestEmpresa: lista,
        load: false
      });
    });
  }
  sugest2() {
    Producao.listarSugestUsuario({},(lista) => {
      this.setState({
        sugestUsuarios: lista,
        load: false
      });
    });
  }
  buscar() {
    Producao.listarRelatorio({buscar:this.state.pesquisa},({lista,total},error) => {
      this.setState({
        list: lista,
        totalLista: total,
        load: false
      });
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={4} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader>{"Relatorios Produção"}</ListSubheader>
                  <TextInput
                    label="Filtrar"
                    style={styles.input}
                    placeholder="Pesquisa por nome."
                    onChange={pesquisa => {
                      this.state.parametros.page = 0;
                      this.setState({ pesquisa },()=>{
                        this.buscar()
                      });
                    }}
                    value={this.state.pesquisa}
                    inputNative
                  />
                  {this.state.usuario.adm ? (
                    <TouchableOpacity
                      children={"Novo"}
                      variant="outlined"
                      onPress={() => {
                        this.setState({ relatorio: {id_user:this.state.usuario.id} });
                      }}
                    />
                  ) : null}
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    button
                    selected={this.state.id === item.id}
                    onClick={event => {
                      this.setState({ relatorio: item, id: item.id });
                      window.focusElementById("edit");
                    }}
                  >
                    <Avatar src={item.logo}>
                      <Icon name={"build"} />
                    </Avatar>
                    <ListItemText
                      primary={item.os}
                      secondary={
                        item.empresa+" | " +(item.descricao || "") +
                        " | " +
                        moment(item.data).format("DD/MM/YY")
                      }
                    />
                    <Icon
                      color={"primary"}
                      name={"created"}
                      style={styles.icon}
                    />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
              tablePagination
              count={this.state.totalLista}
              load={this.state.load}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, { page })
                });
              }}
              handleChangeRowsPerPage={event => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  })
                });
              }}
            />
          </View>
        </View>
        <View id={"edit"} style={styles.view3} xs={12} md={8} item>
          {this.state.relatorio ? (
            <Edit
              {...this.props}
              relatorio={this.state.relatorio}
              activity={this}
              sugestEmpresa={this.state.sugestEmpresa}
              sugestUsuarios={this.state.sugestUsuarios}
              atualizar={(relatorio) => {
                this.setState({ relatorio, id: (relatorio||{}).id });

                this.buscar();
              }}
            />
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 150
  },
  image: {
    height: 30,
    width: 30
  },
  icon: {
    cursor: "pointer"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
