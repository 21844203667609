import React, { Component } from "react";
import Progress from "react-1app/lib/Progress";

import View from "react-1app/lib/View";
import TextInput from "../../components/Input";
import Alert from "react-1app/lib/Alert";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Table from "react-1app/lib/Table";
import { NavLink } from "react-router-dom";
import { Util, Dados } from "../../infra";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { OpenSnack, OpenDialogOption,OpenDialog } from "react-1app";
import UpdateArquivo from "react-1app/lib/View";
import Avatar from "@material-ui/core/Avatar";
import * as Inspecoes from "../../worker/inspecoes";
import { PgModal } from "react-1app/lib/PgModal";
import InspecoesPg from "../inspecoes";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DatePicker from "../../components/DatePicker";
import * as Log from "../../worker/log";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      usuario: this.props.screenProps.store.getState().user,
      anchorEl: [],
      fim: null,
      inicio: null,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      }
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    let { parametros, text } = this.state;
    this.setState({ load: true });
    Inspecoes.statusOSAberto(
      {
        ...parametros,
        ...this.props.parametros,
        pesquisa: text,
        isNotEnviado: true,
        isNotRevizado: true
      },
      (lista = [], error) => {
        this.setState({
          totalLista: lista.length,
          list: lista,
          load: false
        });
      }
    );
  }

  componentWillUnmount() {}

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();

    let res = list.filter(
      a =>
        (!this.state.inicio || a.inicio >= this.state.inicio) &&
        (!this.state.fim || a.inicio <= this.state.fim)
    );
    if (escapedValue === "" || !escapedValue) {
      return res;
    }

    // const regex = new RegExp("^" + escapedValue+"$", "i");
    function valid(text){
      let t=text.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();
      return t.includes(escapedValue);
    }
    // return res.filter(item => regex.test(item.codigo.toLowerCase()));
    return res.filter(item => valid(item.codigo));
  }

  getTotal(){
    let list=this.getSuggestions();
    let total=0
    for (var i = 0; i < list.length; i++) {
      total=total+Number(list[i].total)
    }
    return total;
  }

  getStyle(item) {
    let dias = moment().diff(moment(item.inicio), "days");
    if (dias <= 10) return { backgroundColor: "green", color: "#fff" };
    if (dias <= 30) return { backgroundColor: "#0000FF", color: "#fff" };
    return { backgroundColor: "red", color: "#fff" };
  }

  open_comentario(inspecoes,index, base) {
    OpenDialog({
      title: "Comentário da inspeção",
      // description:
      //   "Comentário e feito ",
      action: [
        {
          label: "CANCELAR",
          onClick: () => this.setState({ comentario: "" })
        },
        {
          label: "Enviar para engenharia",
          onClick: () => {
            let data = {
              id_inspecoes:inspecoes.id,
              objectId: inspecoes.objectId,
              comentario: this.state.comentario
            };
            Log.enviarEngenharia(data,re => {
              base.buscar();
              this.setState({ comentario: "" });
            });
          },
          color: "secondary"
        }
      ],
      input: {
        secureTextEntry: true,
        type: "comentario",
        value: inspecoes.comentario,
        onChange: value => this.setState({ comentario: value }),
        label: "Comentario"
      }
    });
  }

  openInspecoes(item) {
    if (this.Modal && this.Modal.openModal)
      this.Modal.openModal(
        props => [
          <AppBar>
            <Toolbar>
              <div variant="h6" color="inherit">
                {item.empresa_nome + " - " + item.codigo}
              </div>

              <TouchableOpacity
                icon
                style={{
                  marginLeft: "auto"
                }}
                color="inherit"
                aria-label="Close"
                onPress={() => {
                  props.closeModal();
                }}
              >
                <Icon name={"close"} />
              </TouchableOpacity>
            </Toolbar>
          </AppBar>,
          <View
            style={{ width: "100%", marginTop: 80, paddingLeft: 15 }}
            container
            spacing={16}
          >
            <View xs={12} item>
              <InspecoesPg
                {...props}
                parametros={{
                  codigo: item.codigo,
                  isNotRevizado: true,
                  isNotEnviado: true
                }}
                headAddColumn={["Comentario de devolução","Enviar"]}
                renderAddColumn={(inspecoes,index,base) => {
                  return [
                    inspecoes.comentario,
                    <div>
                      <TouchableOpacity
                        variant="outlined"
                        children={"Engenharia"}
                        onClick={() => {
                          this.open_comentario(inspecoes,index,base);
                        }}
                      />
                    </div>
                  ];
                }}
              />
            </View>
          </View>
        ],
        {
          ...this.props
        }
      );
  }

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={"12"} item>
          <Table
            tableHead={["Dias", "OS", "Empresa", "Inspeções", "Inicio", "Ação"]}
            tableData={this.getList().map((item, index) => [
              <Avatar style={this.getStyle(item)}>
                {moment().diff(moment(item.inicio), "days")}
              </Avatar>,

              item.codigo,
              item.empresa_nome,
              item.total,
              moment(item.inicio)
                .locale("pt-br")
                .fromNow(),
              <TouchableOpacity
                key={index}
                variant="outlined"
                children={"Conferir"}
                onPress={() => this.openInspecoes(item)}
              ></TouchableOpacity>
            ])}
            title={"Lista de relatórios para aprovação | inspeções: "+this.getTotal()}
            tablePagination
            count={Number(this.getSuggestions().length)}
            load={this.state.load}
            page={this.state.parametros.page}
            rowsPerPage={this.state.parametros.limit}
            handleChangePage={(event, page) => {
              this.setState({
                parametros: Object.assign(this.state.parametros, { page })
              });
            }}
            handleChangeRowsPerPage={event => {
              this.setState({
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                })
              });
            }}
            actionBar={
              <View>
                <DatePicker
                  value={this.state.inicio}
                  type={"date"}
                  onChange={value => {
                    console.log(value);
                    this.state.inicio = value;
                    this.setState({
                      inicio: value
                    });
                  }}
                  label={"Data Inicio"}
                />

                <DatePicker
                  type={"date"}
                  value={this.state.fim}
                  onChange={value => {
                    this.state.fim = value;
                    this.setState({
                      item: this.state.item
                    });
                  }}
                  label={"Data Fim"}
                />
                <TextInput
                  label="Filtrar empresas"
                  type="search"
                  id="search"
                  style={styles.input}
                  placeholder={"Buscar por OS"}
                  onChange={pesquisa => this.setState({ pesquisa })}
                  value={this.state.pesquisa}
                  inputNative
                />
                <TouchableOpacity
                  variant="outlined"
                  onPress={() => {
                    this.buscar();
                  }}
                  children={"Atualizar"}
                />
              </View>
            }
          />
        </View>
        <PgModal {...this.props} ref={f => (this.Modal = f)} />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 250
  },
  image: {
    height: 30,
    width: 30
  },
  icon10: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  numero: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,150,1)",
    alignSelf: "stretch",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10
  },
  view3: {}
});
