import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud } from "../../infra";
import styleGlobal from "../../styleGlobal";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false
    };
  }

  post_bottom2() {
    this.setState({
      save: true
    });
    var canvas = document.getElementsByTagName("canvas");
    var img = canvas&&canvas[0]&&canvas[0].toDataURL("image/png");
    if (img) {
      this.setState({
        load: true
      });
      Cloud.post(
        "imageMake",
        {
          data: img,
          name: ".png"
        },
        (re, error) => {
          console.log(re,error);
          if (!error && re.url_img) {
            this.state.inspecoes.qrcode = re.url_img;
            this.state.inspecoes.setMetodoApi("salvarWeb", "POST");
            this.state.inspecoes.salvarNuven(console.log);
            this.props.closeModal();
          } else {
            this.setState({
              save: false
            });
            alert("QRCode em Manuteção");
          }
        }
      );
    } else {
      this.setState({
        save: false
      });
      alert("QRCode em Manuteção");
    }
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"QRCode do Equipamento"}</Text>
            <View style={styles.view}>
              <QRCode
                style={styles.view2}
                value={"ID:" + this.state.inspecoes.key_equipamento}
                size={200}
                bgColor="#000"
                fgColor={"#fff"}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.post_bottom2()}
                children={"Salvar"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    width:"none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    margin: 20,
    borderRadius:10,
    backgroundColor:"#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
