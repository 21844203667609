import { Cloud } from "../infra";
import cookie from "react-cookies";
import md5 from "md5";

export function login(login, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("login/dash/email",  login , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function loginToken(input={}, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("login/token",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}


export function loadUser(callback) {
  try {
    var data = cookie.load("token");
    console.log(data);
    Cloud.setTokenUser(data);
    // Cloud.setKeyUser(data.objectId);
    // Cloud.setTokenProjeto(data.token_projeto);
    return data;
  } catch (e) {
    // if (callback) callback(null);
    return "";
  }
}
export function cacheUser(user) {
  try {
    cookie.save("token", user&&user.token?user.token:'', {
      path: "/"
    });
  } catch (e) {}
}
