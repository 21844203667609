import { Util } from "../../infra";
import moment from "moment";
export function saveOcorencias(state, callback) {
  if (
    state.inspecoes.key_user == state.user_local.objectId ||
    state.user_local.adm
  ) {
    if (!state.ocorrencias.lista) return;
    if (state.inspecoes.objectId) {
      state.ocorrencias.key_inspecoes = state.inspecoes.objectId;
    } else {
      state.ocorrencias.key_inspecoes = state.inspecoes._id;
      state.inspecoes.id_local = state.inspecoes._idLocal;
    }
    state.ocorrencias.key_local_inspecoes = state.inspecoes._idLocal;
    semNet(state.ocorrencias, callback, state);
  } else {
    alert("Vc não foi o criador da inspeções e não tem permissão de edicão");
  }
}
function semNet(ocorrencias, callback, state) {
  ocorrencias.salvarLocal(re => {
    if (state.activity.state.activity.mensagem) {
      state.activity.state.activity.mensagem("Salvo Local");
    }
    if (callback) {
      callback();
    }
  });
}

function saveWeb(state, callback) {
  state.ocorrencias.key_local_inspecoes = state.inspecoes._idLocal;
  state.ocorrencias.sLocal = false;
  state.ocorrencias.setMetodoApi("salvar", "POST");
  state.ocorrencias.salvarNuven(re => {
    // console.log(state.ocorrencias);

    if (state.activity.state.activity.mensagem) {
      state.activity.state.activity.mensagem("Salvo Web");
    }
    if (callback) {
      callback();
    }
  });
}

export function caluclar(item, state) {
  var formula = item.code.lay.calculo; //  {a}+{b}
  for (var i = 0; i < state.item.code.lay.lista.length; i++) {
    var foco = state.item.code.lay.lista[i];
    if (foco.code.lay.tag && state.dados[foco.objectId]) {
      formula = Util.replaceAll(
        formula,
        "{" + foco.code.lay.tag + "}",
        state.dados[foco.objectId]
      ); // {a} por 10
    }
  }
  try {
    state.dados[item.objectId] = eval(formula);
  } catch (e) {
  } finally {
  }
}
export function test(item, state) {
  var test = item.code.lay.test; //  {a}+{b}
  for (var i = 0; i < state.item.code.lay.lista.length; i++) {
    var foco = state.item.code.lay.lista[i];
    if (foco.code.lay.tag && state.dados[foco.objectId]) {
      test = Util.replaceAll(
        test,
        "{" + foco.code.lay.tag + "}",
        state.dados[foco.objectId]
      ); // {a} por 10
    }
  }
  try {
    item.code.test = eval(test);
  } catch (e) {
  } finally {
  }
  // console.log(state.dados[item.objectId]);
  /// 10+20
}
export function test2(item, foco, state) {
  var test = item.code.lay.test; //  {a}+{b}
  for (var i = 0; i < foco.code.lay.lista.length; i++) {
    var foco2 = foco.code.lay.lista[i];
    // console.log(foco2.code.lay.tag , state.inspecoes.dados[foco2.objectId]);
    if (
      (foco2.code.lay.tag && state.dados[foco2.objectId]) ||
      (foco2.code.lay.tag && !state.dados[foco2.objectId])
    ) {
      // console.log(state.inspecoes.dados[foco2.objectId]);
      test = Util.replaceAll(
        test,
        "{" + foco2.code.lay.tag + "}",
        state.dados[foco2.objectId]
      ); // {a} por 10
    }
    // console.log(test,2);
  }
  try {
    // console.log(test,2);

    item.code.test = eval(test);
  } catch (e) {
  } finally {
  }
}
