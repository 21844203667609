import React, { Component } from "react";

import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Icon from "react-1app/lib/Icon";

const Modal = props => [
  <AppBar>
    <Toolbar>
      <div variant="h6" color="inherit">
        {props.title}
      </div>

      <TouchableOpacity
        icon
        style={{
          marginLeft: "auto"
        }}
        color="inherit"
        aria-label="Close"
        onPress={() => {
          props.closeModal();
        }}
      >
        <Icon name={"close"} />
      </TouchableOpacity>
    </Toolbar>
  </AppBar>,
  <iframe
    id="fred"
    style={{ marginTop: 60 }}
    title="PDF megasteam"
    src={props.src}
    frameborder="1"
    scrolling="auto"
    height="100%"
    width="100%"
  ></iframe>
];


export default Modal
