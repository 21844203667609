import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import DatePicker from "react-1app/lib/DatePicker";
import ImageUpload from "react-1app/lib/ImageUpload";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Formularios from "../formularios";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment";
import Icon from "react-1app/lib/Icon";
import EditOrdem from "./EditOrdem";
import * as Producao from "../../worker/producao";
import AutoComplete from "../../components/Auto";
import SelectInput from "react-1app/lib/SelectInput";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatorio: this.props.relatorio,
      load: false,
      value: 0,
      list: [],
      sugest: []
    };
  }

  componentDidMount() {
    this.buscar();
    if (this.state.relatorio.id_empresa) {
      this.sugest();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.relatorio != this.props.relatorio &&
      nextProps.relatorio.id != this.props.relatorio.id
    ) {
      this.setState(
        {
          relatorio: nextProps.relatorio,
          list: [],
          value: 0
        },
        () => {
          this.buscar();
          if (this.state.relatorio.id_empresa) {
            this.sugest();
          }
        }
      );
    }
    return true;
  }

  sugest() {
    Producao.listarTipo(
      { id_empresa: this.state.relatorio.id_empresa },
      lista => {
        this.setState({
          sugest: lista,
          totalLista: lista.length,
          load: false
        });
      }
    );
  }

  deletar(item) {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.setState({
            load2: true
          });
          Producao.deletarRelacao(item, (res, error) => {
            if (error) alert(error.msg);
            this.buscar();
            this.setState({
              load2: false
            });
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  buscar() {
    Producao.listarRelacao({ id_relatorio: this.state.relatorio.id }, lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }
  open_ordem(item) {
    this.props.navigator.openModal({
      component: EditOrdem,
      props: {
        heranca: this.state,
        activity: this,
        item,
        sugest: this.state.sugest,
        atualizar: () => this.buscar()
      }
    });
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <View style={{ marginBottom: 10, padding: 5 }}>
          <Text style={styles.label}>{"Relação"}</Text>
          <TouchableOpacity
            children={"Novo"}
            style={{ marginLeft: "auto" }}
            variant="outlined"
            onPress={() => {
              this.open_ordem({ id_relatorio: this.state.relatorio.id });
            }}
          />
        </View>
        <ListView
          nativo
          renderItem={({ item, index }) => {
            return (
              <ListItem key={index} style={{ width: "100%" }}>
                <Avatar src={item.logo} style={{ marginRight: 10 }}>
                  <Icon name={"person"} />
                </Avatar>
                <ListItemText
                  primary={item.tag}
                  secondary={
                    (item.tipo || "") +
                    " | " +
                    (item.tamanho || "") +
                    " | " +
                    moment(item.data).format("DD/MM/YY")
                  }
                />
                <ListItemSecondaryAction>
                  <TouchableOpacity
                    icon
                    color={"secondary"}
                    onClick={() => {
                      this.deletar(item);
                    }}
                  >
                    <Icon name={"delete"} style={styles.icon} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    icon
                    onClick={() => {
                      this.open_ordem(item);
                    }}
                  >
                    <Icon name={"created"} />
                  </TouchableOpacity>
                </ListItemSecondaryAction>
              </ListItem>
            );
          }}
          separator
          style={{ overflow: "none" }}
          data={this.state.list}
        />
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
    // marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginTop: 10
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  icon: {
    // margin: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  },
  image: {
    width: 250,
    height: 250,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderColor: "rgba(150,150,145,1)",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 3
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    fontSize: 13,
    marginTop: 20
  }
});
