import React, { Component } from "react";
import Progress from "react-1app/lib/Progress";

import View from "react-1app/lib/View";
import TextInput from "../../components/Input";
import Alert from "react-1app/lib/Alert";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Table from "react-1app/lib/Table";
import { NavLink } from "react-router-dom";
import { Util, Dados } from "../../infra";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { OpenSnack, OpenDialogOption,OpenDialog } from "react-1app";
import UpdateArquivo from "react-1app/lib/View";
import Avatar from "@material-ui/core/Avatar";
import * as Inspecoes from "../../worker/inspecoes";
import DadoRemessa from "./DadoRemessa";
import { PgModal } from "react-1app/lib/PgModal";
import * as RelatorioPdf from "../../worker/relatorio_pdf";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DatePicker from "../../components/DatePicker";
import * as Log from "../../worker/log";
import ModalIframe from "../../components/ModalIframe";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      usuario: this.props.screenProps.store.getState().user,
      anchorEl: [],
      fim: null,
      inicio: null,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      }
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    let { parametros, text } = this.state;
    this.setState({ load: true });
    RelatorioPdf.protocoloEnvioLista(
      {
        ...parametros
      },
      (lista = [], error) => {
        // console.log(lista, error);
        this.setState({
          totalLista: lista.length,
          list: lista,
          load: false
        });
      }
    );
  }

  open(item) {
    this.setState({
      load: true
    });
    RelatorioPdf.montarProtocolo({id_protocolo:item.id}, (data, error) => {
      this.setState({
        load: false
      });
      if (error) return alert((error && error.msg) || "Error a gerar arquivo");
      try {
        this.verPdf(data.base64);
      } catch (e) {
        console.log(e);
      }
    });
  }

  verPdf(src) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src
      }
    });
  }

  getTotal(){
    let list=this.getSuggestions();
    let total=0
    for (var i = 0; i < list.length; i++) {
      total=total+Number((list[i].lista||[]).length)
    }
    return total;
  }

  componentWillUnmount() {}

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();

    let res = list.filter(
      a =>
        (!this.state.inicio || a.criado >= this.state.inicio) &&
        (!this.state.fim || a.criado <= this.state.fim)
    );
    if (escapedValue === "" || !escapedValue) {
      return res;
    }

    // const regex = new RegExp("^" + escapedValue+"$", "i");
    function valid(text){
      let t=text.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();
      return t.includes(escapedValue);
    }
    // return res.filter(item => regex.test(item.codigo.toLowerCase()));
    return res.filter(item => valid(item.codigo));
  }


  render() {
    return [
      // <AppBar>
      //   <Toolbar>
      //     <div variant="h6" color="inherit">
      //       {"Historico de protocolo"}
      //     </div>
      //     <TouchableOpacity
      //       icon
      //       style={{
      //         marginLeft: "auto"
      //       }}
      //       color="inherit"
      //       aria-label="Close"
      //       onPress={() => {
      //         this.props.closeModal();
      //       }}
      //     >
      //       <Icon name={"close"} />
      //     </TouchableOpacity>
      //   </Toolbar>
      // </AppBar>,
      <View
        // style={{ width: "100%", marginTop: 80, paddingLeft: 15 }}
        container
        spacing={16}
      >
        <View style={styles.view3} xs={"12"} item>
          <Table
            tableHead={[ "OS", "Empresa", "Inspeções","data",  "Ação"]}
            tableData={this.getList().map((item, index) => [
              item.codigo,
              item.empresa_nome,
              (item.lista||[]).length,
              moment(item.criado).format("DD/MM/YYYY"),
              <TouchableOpacity
                key={index}
                variant="outlined"
                children={"Visualizar"}
                onPress={() => this.open(item)}
              ></TouchableOpacity>
            ])}
            title={"Lista de protocolo | inspeções: " +
            this.getTotal()}
            tablePagination
            count={Number(this.getSuggestions().length)}
            load={this.state.load}
            page={this.state.parametros.page}
            rowsPerPage={this.state.parametros.limit}
            handleChangePage={(event, page) => {
              this.setState({
                parametros: Object.assign(this.state.parametros, { page })
              });
            }}
            handleChangeRowsPerPage={event => {
              this.setState({
                parametros: Object.assign(this.state.parametros, {
                  limit: event.target.value,
                  page: 0
                })
              });
            }}
            actionBar={
              <View>
                <DatePicker
                  value={this.state.inicio}
                  type={"date"}
                  onChange={value => {
                    console.log(value);
                    this.state.inicio = value;
                    this.setState({
                      inicio: value
                    });
                  }}
                  label={"Data Inicio"}
                />

                <DatePicker
                  type={"date"}
                  value={this.state.fim}
                  onChange={value => {
                    this.state.fim = value;
                    this.setState({
                      item: this.state.item
                    });
                  }}
                  label={"Data Fim"}
                />
                <TextInput
                  label="Filtrar empresas"
                  type="search"
                  id="search"
                  style={styles.input}
                  placeholder={"Buscar por OS"}
                  onChange={pesquisa => this.setState({ pesquisa })}
                  value={this.state.pesquisa}
                  inputNative
                />
                <TouchableOpacity
                  variant="outlined"
                  onPress={() => {
                    this.buscar();
                  }}
                  children={"Atualizar"}
                />
              </View>
            }
          />
        </View>
        <PgModal {...this.props} ref={f => (this.Modal = f)} />
      </View>
    ];
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 250
  },
  image: {
    height: 30,
    width: 30
  },
  icon10: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  numero: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,150,1)",
    alignSelf: "stretch",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10
  },
  view3: {}
});
