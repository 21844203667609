import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import DatePicker from "react-1app/lib/DatePicker";
import ImageUpload from "react-1app/lib/ImageUpload";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Formularios from "../formularios";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment";
import Icon from "react-1app/lib/Icon";
import EditOrdem from "./EditOrdem";
import * as Producao from "../../worker/producao";
import AutoComplete from "../../components/Auto";
import SelectInput from "react-1app/lib/SelectInput";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Relacao from "./Relacao";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatorio: this.props.relatorio,
      load: false,
      value: 0,
      sugestOS: [],
      loadOS: false
    };
  }
  sugestOS() {
    this.setState({loadOS:true,sugestOS:[]})
    Producao.listarSugestOS(
      { id_empresa: this.state.relatorio.id_empresa },
      lista => {
        this.setState({
          sugestOS: lista,
          loadOS: false
        });
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.relatorio != this.props.relatorio) {
      this.setState({
        relatorio: nextProps.relatorio,
        value: 0
      });
    }
    return true;
  }

  salvar() {
    let { relatorio } = this.state;
    let res = (dados, error) => {
      this.setState({
        load: false,
        relatorio: dados
      });
      if (this.props.atualizar) this.props.atualizar(dados);
    };
    this.setState({
      load: true
    });
    if (relatorio.id) {
      Producao.atualizarRelatorio(relatorio, res);
    } else {
      Producao.salvarRelatorio(relatorio, res);
    }
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.setState({
            load2: true
          });
          Producao.deletarRelatorio(this.state.relatorio, (res, error) => {
            if (error) alert(error.msg);
            if (this.props.atualizar) this.props.atualizar({});
            this.setState({
              load2: false
            });
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  open_ordem(item) {
    this.props.navigator.openModal({
      component: EditOrdem,
      props: {
        heranca: this.state,
        activity: this,
        item
      }
    });
  }

  deletarColaborador(index) {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.relatorio.colaboradores.splice(index, 1);
          this.setState({ relatorio: this.state.relatorio });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  deletarHorarios(index) {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.relatorio.horarios.splice(index, 1);
          this.setState({ relatorio: this.state.relatorio });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container spacing={16}>
        <View style={styleGlobal.Grid} xs={12} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View style={{ marginBottom: 10, padding: 5 }}>
              <Text style={styles.label}>{"Relatorio"}</Text>
              <Tabs
                value={this.state.value}
                onChange={(event, value) => {
                  this.setState({ value });
                }}
              >
                <Tab label="Info" />
                <Tab label="Colaboradores" />
              </Tabs>
            </View>
            {this.state.value == 0 ? (
              <View container spacing={16} style={{ padding: 20 }}>
                <View xs={12} item>
                  <AutoComplete
                    list={this.props.sugestUsuarios}
                    keyLabel={"nome"}
                    keyValue={"id"}
                    label={"Usuário"}
                    value={this.state.relatorio.id_user}
                    disabled={this.state.relatorio.id}
                    onChange={value => {
                      this.state.relatorio.id_user = value.id;
                      this.setState({ relatorio: this.state.relatorio });
                    }}
                  />
                </View>
                <View xs={12} item>
                  <AutoComplete
                    list={this.props.sugestEmpresa}
                    keyLabel={"nome"}
                    keyValue={"id"}
                    disabled={this.state.relatorio.id}
                    label="Empresa"
                    value={this.state.relatorio.id_empresa || null}
                    onChange={value => {
                      this.state.relatorio.id_empresa = value.id;
                      this.setState({ relatorio: this.state.relatorio });
                      this.sugestOS();
                    }}
                  />
                </View>
                {this.state.relatorio.id_empresa ? (
                  <>
                    <View xs={6} item>
                      {!this.state.relatorio.id?(
                        <AutoComplete
                          list={this.state.sugestOS}
                          keyLabel={"codigo"}
                          keyValue={"codigo"}
                          label="OS"
                          load={this.state.loadOS}
                          value={this.state.relatorio.os || null}
                          onChange={value => {
                            this.state.relatorio.os = value.codigo;
                            this.setState({ relatorio: this.state.relatorio });
                          }}
                        />
                    ):(
                      <TextInput
                        style={[styles.nome, { marginTop: 20 }]}
                        value={this.state.relatorio.os}
                        disabled={true}
                        onChange={value => {
                          this.state.relatorio.os = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        label={"OS"}
                      />
                    )}
                    </View>
                    <View xs={12} md={6} item>
                      <DatePicker
                        type={"date"}
                        value={this.state.relatorio.data || null}
                        onChange={value => {
                          this.state.relatorio.data = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        label={"Data"}
                      />
                    </View>
                    <View sx={12} md={6} item>
                      <AutoComplete
                        list={[{ nome: "São José dos Campos" }]}
                        keyLabel={"nome"}
                        keyValue={"nome"}
                        label="Cidade"
                        value={this.state.relatorio.cidade || ""}
                        onChange={value => {
                          this.state.relatorio.cidade = value.nome;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                      />
                    </View>
                    <View xs={12} md={6} item>
                      <TextInput
                        style={[styles.nome, { marginTop: 20 }]}
                        value={this.state.relatorio.veiculo}
                        onChange={value => {
                          this.state.relatorio.veiculo = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        placeholder={"tipo e place"}
                        label={"Veiculo"}
                      />
                    </View>
                    <View xs={12} md={6} item>
                      <TextInput
                        style={[styles.nome, { marginTop: 20 }]}
                        value={this.state.relatorio.responsavel}
                        onChange={value => {
                          this.state.relatorio.responsavel = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        label={"Responsavel"}
                      />
                    </View>
                    <View xs={12} md={6} item>
                      <TextInput
                        style={[styles.nome, { marginTop: 20 }]}
                        value={this.state.relatorio.setor}
                        onChange={value => {
                          this.state.relatorio.setor = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        label={"Setor"}
                      />
                    </View>
                    <View xs={12} item>
                      <TextInput
                        style={styles.nome}
                        value={this.state.relatorio.obs}
                        multiline
                        onChange={value => {
                          this.state.relatorio.obs = value;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                        keyboardType={"default"}
                        label={"OBSERVAÇÃO"}
                      />
                    </View>
                    <View xs={12} md={12} item>
                      <View>
                        <Text style={styles.label}>
                          {"Deslocamento | Cliente"}
                        </Text>
                        <TouchableOpacity
                          children={"Novo"}
                          variant="outlined"
                          onPress={() => {
                            if (!this.state.relatorio.horarios)
                              this.state.relatorio.horarios = [];
                            this.state.relatorio.horarios.push({});
                            this.setState({ relatorio: this.state.relatorio });
                          }}
                        />
                      </View>
                    </View>
                    <View xs={12} md={12} item>
                      <View style={styleGlobal.Grid} container spacing={16}>
                        {this.state.relatorio.horarios &&
                          this.state.relatorio.horarios.map((h, i) => [
                            <View xs={12} sm={4} item>
                              <SelectInput
                                style={{ ...styles.nome, marginTop: 19 }}
                                value={h.tipe}
                                key_label={"label"}
                                onChange={value => {
                                  h.tipe = value;
                                  this.setState({
                                    relatorio: this.state.relatorio
                                  });
                                }}
                                list={[
                                  {
                                    label: "Deslocamento",
                                    value: "delocamento"
                                  },
                                  { label: "No cliente", value: "cliente" }
                                ]}
                                label={"Tipo"}
                              />
                            </View>,
                            <View xs={12} sm={3} item>
                              <DatePicker
                                style={[styles.nome, { marginTop: 20 }]}
                                value={h.inicio}
                                onChange={value => {
                                  h.inicio = value;
                                  this.setState({
                                    relatorio: this.state.relatorio
                                  });
                                }}
                                ref={v => (this.nome = v)}
                                keyboardType={"default"}
                                label={"Inicio"}
                                type={"time"}
                              />
                            </View>,
                            <View xs={12} sm={3} item>
                              <DatePicker
                                style={[styles.nome, { marginTop: 20 }]}
                                value={h.fim}
                                onChange={value => {
                                  h.fim = value;
                                  this.setState({
                                    relatorio: this.state.relatorio
                                  });
                                }}
                                ref={v => (this.nome = v)}
                                keyboardType={"default"}
                                label={"Fim"}
                                type={"time"}
                              />
                            </View>,
                            <View xs={12} sm={2} item>
                              <TouchableOpacity
                                icon
                                style={{ marginTop: 15 }}
                                color={"secondary"}
                                onClick={() => {
                                  this.deletarHorarios(i);
                                }}
                              >
                                <Icon name={"delete"} />
                              </TouchableOpacity>
                            </View>
                          ])}
                      </View>
                    </View>
                  </>
                ) : null}
              </View>
            ) : null}
            {this.state.value == 1 ? (
              <ListView
                ListHeaderComponent={() => (
                  <View style={styles.head}>
                    <ListSubheader component="div">
                      {"COLABORADORES MEGASTEAM"}
                    </ListSubheader>
                    <TouchableOpacity
                      children={"Novo"}
                      style={{ marginLeft: "auto" }}
                      variant="outlined"
                      onPress={() => {
                        if (!this.state.relatorio.colaboradores)
                          this.state.relatorio.colaboradores = [];
                        this.state.relatorio.colaboradores.push({});
                        this.setState({ relatorio: this.state.relatorio });
                      }}
                    />
                  </View>
                )}
                nativo
                renderItem={({ item, index }) => {
                  return (
                    <ListItem key={index} style={{ width: "100%" }}>
                      <Avatar src={item.logo} style={{ marginRight: 10 }}>
                        <Icon name={"person"} />
                      </Avatar>
                      <AutoComplete
                        list={this.props.sugestUsuarios}
                        keyLabel={"nome"}
                        keyValue={"id"}
                        label={"Usuario"}
                        value={item.id_user}
                        onChange={value => {
                          item.id_user = value.id;
                          this.setState({ relatorio: this.state.relatorio });
                        }}
                      />
                      <ListItemSecondaryAction>
                        <TouchableOpacity
                          icon
                          color={"secondary"}
                          onClick={() => {
                            this.deletarColaborador(index);
                          }}
                        >
                          <Icon name={"delete"} />
                        </TouchableOpacity>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
                separator
                style={{ overflow: "none" }}
                data={this.state.relatorio.colaboradores}
              />
            ) : null}
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.deletar()}
                children={"Delete"}
                color={"secondary"}
                variant="outlined"
                disabled={!this.state.relatorio.id}
                load={this.state.load2}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styleGlobal.Grid} xs={12} lg={6} item>
          {this.state.relatorio && this.state.relatorio.id ? (
            <Relacao {...this.props} relatorio={this.props.relatorio} />
          ) : null}
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
    // marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginTop: 10
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  icon: {
    // margin: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  },
  image: {
    width: 250,
    height: 250,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderColor: "rgba(150,150,145,1)",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 3
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    fontSize: 13,
    marginTop: 20
  }
});
