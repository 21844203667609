import React, { Component, useState } from "react";
import Progress from "react-1app/lib/Progress";

import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import styleGlobal from "../../styleGlobal";
import * as Inspecoes from "../../worker/inspecoes";
import * as RelatorioPdf from "../../worker/relatorio_pdf";
import { OpenDialog } from "react-1app/lib/DialogAlert";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import moment from "moment";
import Icon from "react-1app/lib/Icon";
import ListSubheader from "@material-ui/core/ListSubheader";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Switch from "react-1app/lib/Switch";
import ModalIframe from "../../components/ModalIframe";
import Input from "../../components/Input";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaID: [],
      load: true
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    this.setState({ load: true });
    Inspecoes.basic(
      {
        codigo: this.props.item.codigo,
        key_empresa: this.props.item.key_empresa,
        isNotEnviado: true,
        revizado: true
      },
      (lista = [], error) => {
        // console.log(lista, error);
        this.setState({
          totalLista: lista.length,
          listaID: lista.map(item => ({ id: item.id })),
          list: lista,
          load: false
        });
      }
    );
  }

  validarEnvio() {
    if (!this.state.listaID[0])
      return alert("Adicione uma inspeção para continuar");
    let assinaturas={}
    let Component = () => {
      let [ass, setAss] = useState({});
      assinaturas=ass;
      return (
        <View container spacing={16}>
          <View xs={6} item>
            <Text style={{marginTop:25}}>{"Assinatura 1"}</Text>
            <TextInput
              label={"Cargo"}
              fullWidth
              value={ass.cargo1}
              onChange={value => {
                setAss({ ...ass, cargo1: value });
              }}
            />
            <TextInput
              label={"Nome"}
              fullWidth
              value={ass.nome1}
              onChange={value => {
                setAss({ ...ass, nome1: value });
              }}
            />
          </View>
          <View xs={6} item>
            <Text style={{marginTop:25}}>{"Assinatura 2"}</Text>
            <TextInput
              label={"Cargo"}
              fullWidth
              value={ass.cargo2}
              onChange={value => {
                setAss({ ...ass, cargo2: value });
              }}
            />
            <TextInput
              label={"Nome"}
              fullWidth
              value={ass.nome2}
              onChange={value => {
                setAss({ ...ass, nome2: value });
              }}
            />
          </View>
        </View>
      );
    };
    OpenDialog({
      title: "Você confirma o envio",
      description:
        "Você vai receber um PDF com protocolo de envio e a lista de inspeções enviadas",
      action: [
        {
          label: "CONFIRMAR",
          onClick: () => {
            this.enviar({
              ...this.props.item,
              obs: this.state.obs,
              listaID: this.state.listaID,
              assinaturas
            });
          },
          color: "secondary"
        },
        { label: "CANCELAR" }
      ],
      Component,
      input: {
        secureTextEntry: true,
        type: "obs",
        onChange: value => this.setState({ obs: value }),
        label: "Observação"
      }
    });
  }

  enviar(item) {
    this.setState({
      load: true
    });
    RelatorioPdf.protocoloEnvio(item, (data, error) => {
      this.setState({
        load: false
      });
      if (error) return alert((error && error.msg) || "Error a gerar arquivo");
      try {
        this.verPdf(data.base64);
      } catch (e) {
        console.log(e);
      }
      this.props.activity.buscar();
    });
  }

  verPdf(src) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src
      }
    });
  }

  getList() {
    return this.getSuggestions();
    // let { limit, page } = this.state.parametros;
    // let off = page * limit;
    // off = off < 0 ? 0 : off;
    // return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return list.filter(item =>
      regex.test(item[this.props.key_label || "nome"])
    );
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={12} lg={10} item>
          <View style={styles.tela} type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <Text style={styles.head}>{"Inspecoes para envio"}</Text>
              )}
              renderItem={({ item, index }) => {
                let usar = (this.state.listaID || []).find(
                  ({ id }) => item.id === id
                );
                return (
                  <ListItem key={index} style={{ width: "100%" }}>
                    <Avatar src={item.logo}>
                      <Icon name={"image"} />
                    </Avatar>
                    <ListItemText
                      primary={item.numero}
                      secondary={item.codigo + " - " + item.relatorio}
                    />
                    <ListItemSecondaryAction>
                      {usar
                        ? [
                            <Input
                              style={styles.input}
                              value={usar.ds}
                              onChange={value => {
                                usar.ds = value;
                                this.setState({ alterado: true });
                              }}
                              placeholder={"Dispositivo de Segurança"}
                            />,
                            <Input
                              style={styles.input}
                              value={usar.ip}
                              onChange={value => {
                                usar.ip = value;
                                this.setState({ alterado: true });
                              }}
                              placeholder={"Indicador de Pressão"}
                            />,
                            <Input
                              style={styles.input}
                              value={usar.outros}
                              onChange={value => {
                                usar.outros = value;
                                this.setState({ alterado: true });
                              }}
                              placeholder={"Outros"}
                            />
                          ]
                        : null}
                      <Switch
                        style={styles.adm}
                        value={usar}
                        onChange={value => {
                          if (!this.state.listaID) this.state.listaID = [];
                          if (value) {
                            this.state.listaID.push({ id: item.id });
                          } else {
                            this.state.listaID = this.state.listaID.filter(
                              ({ id }) => item.id !== id
                            );
                          }
                          this.setState({ listaID: this.state.listaID });
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }}
              separator
              data={this.getList()}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                children={"Cancelar"}
                color={"secondary"}
                variant="outlined"
                style={styles.delete}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.validarEnvio()}
                children={"Concluir"}
                variant="outlined"
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  head: {
    marginBottom: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  },
  input: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    padding: 5,
    margin: 5
  }
});
