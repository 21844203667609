import React, { Component, useState } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import ImageUpload from "react-1app/lib/ImageUpload";
import Progress from "react-1app/lib/Progress";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Icon from "react-1app/lib/Icon";
import ListSubheader from "@material-ui/core/ListSubheader";
import Edit from "../usuarios/Edit";
import { OpenDialog, Switch } from "react-1app";
import List from "../comercial/List";
import * as Producao from "../../worker/producao";
import { Util } from "../../infra";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresas: new Dados("empresas").parse(this.props.empresas),
      load: true,
      list: [],
      value: 0,
      preco: null
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    Producao.listarTipo({ id_empresa: this.state.empresas.id }, lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  salvar() {
    let { preco } = this.state;
    let res = (dados, error) => {
      this.setState({
        load: false,
        preco: null
      });
      this.buscar(dados);
    };
    this.setState({
      load: true
    });
    if (preco.id) {
      Producao.atualizarTipo(preco, res);
    } else {
      Producao.salvarTipo(preco, res);
    }
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          Producao.deletarTipo(this.state.preco, (res, error) => {
            if (error) alert(error.msg);
            this.buscar({});
            this.setState({
              load2: false,
              preco: null
            });
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container spacing={16}>
        <View style={styleGlobal.Grid} xs={6} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Tabela"}</ListSubheader>
                  <TouchableOpacity
                    style={{ marginLeft: "auto" }}
                    children={"Adicionar"}
                    onPress={() => {
                      this.setState({
                        preco: { id_empresa: this.state.empresas.id },
                        id: null
                      });
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.id === item.id}
                    onClick={event => {
                      if (this.state.id !== item.id) {
                        this.setState({
                          preco: {...item},
                          id: item.id
                        });
                      }
                    }}
                  >
                    <Avatar src={item.logo}>
                      <Icon
                        style={styles.icon}
                        name={"build"}
                      />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={Util.parseMoney(item.valor)}
                    />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
              load={this.state.load}
            />
          </View>
        </View>
        <View style={{ marginTop: 10 }} xs={6} item>
          {this.state.preco ? (
            <View type={"Paper"}>
              <View style={{ padding: 20 }} container spacing={16}>
                <View xs={12} item>
                  <TextInput
                    style={styles.nome}
                    value={this.state.preco.nome}
                    onChange={value => {
                      this.state.preco.nome = value;
                      this.setState({ preco: this.state.preco });
                    }}
                    keyboardType={"default"}
                    label={"Nome"}
                  />
                </View>
                <View xs={12} item>
                  <TextInput
                    style={styles.nome}
                    value={this.state.preco.valor}
                    onChange={value => {
                      this.state.preco.valor = value;
                      this.setState({ preco: this.state.preco });
                    }}
                    type={"number"}
                    label={"Valor"}
                  />
                </View>
                <View xs={12} item style={styles.action}>
                  <TouchableOpacity
                    onPress={() => this.deletar()}
                    children={"Delete"}
                    color={"secondary"}
                    variant="outlined"
                    style={styles.delete}
                    disabled={!this.state.empresas.objectId}
                    load={this.state.load2}
                  ></TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.salvar()}
                    children={"Salvar"}
                    variant="outlined"
                    style={styles.delete}
                    load={this.state.load}
                  ></TouchableOpacity>
                </View>
              </View>
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
