import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import TextInput from "react-1app/lib/TextInput";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import * as Inspecoes from "../../worker/inspecoes";
import InputAdornment from "@material-ui/core/InputAdornment";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: {},
      usuario: this.props.screenProps.store.getState().user,
      load: true,
      list: [],
      relatorio: {},
      parametros:{}
    };
  }

  componentDidMount() {
     this.buscar();
  }

  buscarDados() {
    var query = new Query("relatorio");

    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }


  buscar() {
    let { parametros, pesquisa } = this.state;
    let par = { ...parametros, ...this.props.parametros, pesquisa2: pesquisa };
    this.setState({ load: true });
    Inspecoes.buscarInspecoesDash(par, ({ lista = [], total = 0 }, error) => {
      console.log(lista);
      this.setState({
        totalLista: total,
        list: lista,
        load: false
      });
    });
  }

  mover(inspecoes) {
    this.setState({
      load: true
    });
let insp=new Dados("inspecoes").parse({objectId:inspecoes.objectId,id:inspecoes.id});
insp.key_unidade = this.props.unidades.objectId;
insp.key_empresa = this.props.empresas.objectId;
insp.key_user = this.state.usuario.objectId;
insp.key_local = this.props.locais.objectId;
insp.local = this.props.locais;
insp.empresa = this.props.empresas;
insp.unidade = this.props.unidades;
insp.user = this.state.usuario;
insp.key_equipamento = this.props.equipamento.objectId;
insp.equipamento = this.props.equipamento;
 // console.log(insp);
    insp.setMetodoApi("salvar", "POST");
    insp.salvarNuven(re => {
      if (re.objectId || re.id) {
        OpenSnack({ message: "Salvo", type: "success" });
        this.props.closeModal();
        if(this.props.atualizar)this.props.atualizar();
      }
      this.setState({
        load: false
      });
    });
  }
  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Mover Inspeção"}</Text>
            <TextInput
              style={styles.codigo}
              value={this.state.pesquisa}
              onChange={value => {
                this.setState({ pesquisa: value });
              }}
              keyboardType={"default"}
              label={"Codigo (OS)"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  <TouchableOpacity
                  variant="outlined"
                  onPress={() => {
                    this.buscar();
                  }}
                  children={"Buscar"}
                  />
                  </InputAdornment>
                ),
              }}
            />
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                  >
                    <ListItemText
                      primary={item.codigo_equipamento+" | "+item.codigo+" | "+item.numero}
                      secondary={item.unidade_nome+" | "+item.local_nome}
                    />
                    <TouchableOpacity
                      variant="outlined"
                      onPress={() => {
                        Alert.alert("Mover", "Voce realmente deseja mover este item?", [
                          { text: "Ok", onPress: () => this.mover(item) },
                          { text: "Cancelar", onPress: () => console.log() }
                        ]);
                      }}
                      children={"Mover"}
                    />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
