import * as types from "./actionTypes";
import { Cloud } from "../infra";
import * as login from "../worker/login";

export function init(argument) {
  // body...
}

export function logout() {
  login.cacheUser("");
  Cloud.setTokenUser("");
  Cloud.setKeyUser("");
  return {
    type: types.LOGOUT
  };
}
export function logar(user, store) {
  login.cacheUser(user);
  Cloud.setTokenUser(user.token);
  Cloud.setKeyUser(user.objectId);
  return {
    type: types.LOGIN,
    user: user
  };
}
