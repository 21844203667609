let Dados = {};

Dados.classe_lista = [
  {
    text: "A",
    value: "A",
    data: {}
  },
  {
    text: "B",
    value: "B",
    data: {}
  },
  {
    text: "C",
    value: "C",
    data: {}
  },
  {
    text: "D",
    value: "D",
    data: {}
  }
];
Dados.grupo_lista = [
  {
    text: "1",
    value: "1",
    data: {}
  },
  {
    text: "2",
    value: "2",
    data: {}
  },
  {
    text: "3",
    value: "3",
    data: {}
  },
  {
    text: "4",
    value: "4",
    data: {}
  },
  {
    text: "5",
    value: "5",
    data: {}
  }
];
Dados.categoria_lista = [
  {
    text: "I",
    value: "I",
    data: {}
  },
  {
    text: "II",
    value: "II",
    data: {}
  },
  {
    text: "III",
    value: "III",
    data: {}
  },
  {
    text: "IV",
    value: "IV",
    data: {}
  },
  {
    text: "V",
    value: "V",
    data: {}
  }
];
Dados.regime_trabalho_lista = [
  {
    text: "Contínuo",
    value: "Contínuo",
    data: {}
  },
  {
    text: "Intermitente",
    value: "Intermitente",
    data: {}
  },
  {
    text: "Batelada",
    value: "Batelada",
    data: {}
  }
];
Dados.selectinput4_lista = [
  {
    text: "A",
    value: "A",
    data: {}
  },
  {
    text: "B",
    value: "B",
    data: {}
  },
  {
    text: "C",
    value: "C",
    data: {}
  }
];
Dados.selectinput5_lista = [
  {
    text: "A",
    value: "A",
    data: {}
  },
  {
    text: "B",
    value: "B",
    data: {}
  },
  {
    text: "C",
    value: "C",
    data: {}
  },
  {
    text: "D",
    value: "D",
    data: {}
  }
];
Dados.selectinput6_lista = [
  {
    text: "1",
    value: "1",
    data: {}
  },
  {
    text: "2",
    value: "2",
    data: {}
  },
  {
    text: "3",
    value: "3",
    data: {}
  },
  {
    text: "4",
    value: "4",
    data: {}
  },
  {
    text: "5",
    value: "5",
    data: {}
  }
];
Dados.selectinput7_lista = [
  {
    text: "I",
    value: "I",
    data: {}
  },
  {
    text: "II",
    value: "II",
    data: {}
  },
  {
    text: "III",
    value: "III",
    data: {}
  },
  {
    text: "IV",
    value: "IV",
    data: {}
  },
  {
    text: "V",
    value: "V",
    data: {}
  }
];
Dados.selectinput8_lista = [
  {
    text: "Contínuo",
    value: "Contínuo",
    data: {}
  },
  {
    text: "Intermitente",
    value: "Intermitente",
    data: {}
  },
  {
    text: "Batelada",
    value: "Batelada",
    data: {}
  }
];

Dados.descricao_lista = {
  1: [
    { text: "PMTA", value: "PMTA", data: {} },
    { text: "PTH", value: "PTH", data: {} },
    { text: "Pressão de Operação", value: "Pressão de Operação", data: {} },
    { text: "PMTA - Casco", value: "PMTA - Casco", data: {} },
    { text: "PTH - Casco", value: "PTH - Casco", data: {} },
    {
      text: "Pressão de Operação - Casco",
      value: "Pressão de Operação - Casco",
      data: {}
    },
    { text: "PMTA - Camisa", value: "PMTA - Camisa", data: {} },
    { text: "PTH - Camisa", value: "PTH - Camisa", data: {} },
    {
      text: "Pressão de Operação - Camisa",
      value: "Pressão de Operação - Camisa",
      data: {}
    }
  ],
  2: [
    {
      text: "PMTA",
      value: "PMTA",
      data: {}
    },
    {
      text: "Pressão de Teste Hidrostático",
      value: "Pressão de Teste Hidrostático",
      data: {}
    },
    {
      text: "Pressão de Trabalho",
      value: "Pressão de Trabalho",
      data: {}
    }
  ],
  3: [
    {
      text: "PMTA - Casco",
      value: "PMTA - Casco",
      data: {}
    },
    {
      text: "PTH - Casco",
      value: "PTH - Casco",
      data: {}
    },
    {
      text: "Pressão de Operação - Casco",
      value: "Pressão de Operação - Casco",
      data: {}
    },
    {
      text: "PMTA - Tubos",
      value: "PMTA - Tubos",
      data: {}
    },
    {
      text: "PTH - Tubos",
      value: "PTH - Tubos",
      data: {}
    },
    {
      text: "Pressão de Operação - Tubos",
      value: "Pressão de Operação - Tubos",
      data: {}
    },
    {
      text: "PMTA - Calandra",
      value: "PMTA - Calandra",
      data: {}
    },
    {
      text: "PTH - Calandra",
      value: "PTH - Calandra",
      data: {}
    },
    {
      text: "Pressão de Operação - Calandra",
      value: "Pressão de Operação - Calandra",
      data: {}
    }
  ]
};

export default Dados;
