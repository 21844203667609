import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import { Util, Dados, Query, Cloud } from "../../infra";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Text from "react-1app/lib/Text";
import * as Inspecoes from "../../worker/inspecoes";
import * as RelatorioPdf from "../../worker/relatorio_pdf";

import ListaOcorrencias from "../inspecoes/PgOcorrencias";
import Table from "react-1app/lib/Table";
import IconButton from "@material-ui/core/IconButton";
import ModalIframe from "../../components/ModalIframe";
import { OpenSnack, OpenDialogOption } from "react-1app";
import Select from "../../components/SelectInput";
import DatePicker from "../../components/DatePicker";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      usuario: this.props.screenProps.store.getState().user,
      totalLista: 0,
      inicio: null,
      termino: null,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true,
        sql_ordem: '  "createdAt" DESC nulls last'
      },
      list: [],
      options: [
        {
          value: "ven_externo",
          text: "Vencimento exame Externo"
        },
        {
          value: "ven_interno",
          text: "Vencimento exame Interno"
        },
        {
          value: "ven_alternativo",
          text: "Vencimento teste Alternativo"
        },
        { value: "createdAt", text: "Criado" }
      ]
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    let { parametros, text, inicio, termino, tag_busca } = this.state;
    let where = "";
    if (inicio) {
      if (!tag_busca) {
        alert("Adicione um tag para busca");
        return;
      }
      if (!termino) {
        termino = moment().toJSON();
      }
      where = `("${tag_busca}" >= '${inicio}' and "${tag_busca}" <= '${termino}' )`;
    }
    this.setState({ load: true });
    Inspecoes.buscarInspecoesDash(
      { ...parametros, ...this.props.parametros, pesquisa2: text, where },
      ({ lista = [], total = 0 }, error) => {
        this.setState({
          totalLista: total,
          list: lista,
          load: false
        });
      }
    );
  }

  open_modal_bottom1(inspecoes) {
    this.props.navigator.openModal({
      component: ListaOcorrencias,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes
      }
    });
  }

  verPdf(pdf) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src: pdf
      }
    });
  }

  actionInspecao(inspecoes) {
    if (!inspecoes.lista || !inspecoes.lista[0])
      return alert("Ainda nao tem arquivos");
    let listOpcoes = inspecoes.lista.map(item => ({
      label: item.descricao||item.arquivo,
      onPress: () => Util.web(item.arquivo)
    }));
    listOpcoes.push({
      label: "Fechar",
      onPress: () => console.log(),
      separa: "-"
    });
    OpenDialogOption({
      title: "Arquivos da inspeção",
      description: "Clique para visualizar",
      action: listOpcoes
    });
  }

  componentWillUnmount() {}

  excelComerial() {
    this.setState({
      gerar3: true
    });

    RelatorioPdf.excelComerial(
      {
        listaID: this.state.list
          .filter(x => !x.naoedit)
          .map(item => item.objectId),
        key_user: this.state.usuario.objectId,
        limit: this.state.parametros.limit
      },
      (data, error) => {
        console.log(data, error);
        this.setState({
          gerar3: false
        });
        if (error) return alert("Erro ao gerar excel");

        Util.web("https://megasteam.1app.com.br/files/" + data.nome);
      }
    );
  }

  pdfValvulas() {
    this.setState({
      gerar3: true
    });

    RelatorioPdf.pdfValvulas(
      {
        listaID: this.state.list
          .filter(x => !x.naoedit)
          .map(item => item.objectId),
        key_user: this.state.usuario.objectId,
        isBase64: true
      },
      (caminho, error) => {
        console.log(caminho, error);
        // this.open_web_bottom5();
        this.setState({
          gerar3: false
        });
        if (error)
          return alert((error && error.msg) || "Error a gerar arquivo");
        // console.log(caminho);
        this.verPdf(caminho.base64);
        // this.verPdf(caminho.nome);
      }
    );
  }

  open_web_bottom5() {
    this.verPdf(
      "https://megasteam.1app.com.br/files/pdfEmpresa" +
        this.state.usuario.empresas +
        ".pdf"
    );
  }
  pdfEmpresarial() {
    this.setState({ gerar: true });

    RelatorioPdf.pdfComerialNR13(
      {
        listaID: this.state.list
          .filter(x => !x.naoedit)
          .map(item => item.objectId),
        limit: this.state.parametros.limit,
        key_user: this.state.usuario.objectId,
        empresa: this.state.usuario.empresas,
        isBase64: true
      },
      (data, error) => {
        // this.open_web_bottom5();
        this.setState({ gerar: false });
        if (error)
          return alert((error && error.msg) || "Error a gerar arquivo");
        try {
          this.verPdf(data.base64);
        } catch (e) {
          console.log(e);
        }
      }
    );
  }

  actionRelatorio() {
    let listOpcoes = [
      {
        label: "Gerar PDF relatório dos dispositivos de controle e segurança",
        onPress: () => this.pdfValvulas(),
        separa: "Opções PDF"
      },
      {
        label: "Gerar PDF relatório dos equipamentos",
        onPress: () => this.pdfEmpresarial()
      },
      { label: "Cancelar", onPress: () => console.log(), separa: "Cancelar" }
    ];
    OpenDialogOption({
      title: "Gerar relatório",
      description:
        "Os relatório sera gerado com base nós dados na tabela abaixo!",
      action: listOpcoes
    });
  }
  render() {
    return (
      <div className={this.props.classes.root}>
        <View style={styles.view2} container spacing={16}>
          <AppBar>
            <Toolbar>
              <Text variant="h6" color="inherit">
                Equipamento / Inspeções
              </Text>

              <TouchableOpacity
                icon
                style={styles.add}
                color="inherit"
                aria-label="Close"
                onPress={() => {
                  this.props.closeModal();
                }}
              >
                <Icon name={"close"} />
              </TouchableOpacity>
            </Toolbar>
          </AppBar>
          <View style={styles.view3} xs={12} item>
            <View type={"Paper"}>
              <Table
                tableHead={[
                  "Unidade / Locais",
                  "TAG / OS",
                  "Inspetor",
                  "Criado",
                  "Concluído",
                  "S. Pend",
                  "Arquivos",
                  "Ocorrencias"
                ]}
                tableData={this.state.list.map((inspecoes, index) => [
                  <div>
                    {inspecoes.unidade_nome}
                    <br />
                    {Util.subString(inspecoes.local_nome, 25)}
                  </div>,
                  <div>
                    {inspecoes.codigo_equipamento} <br /> {inspecoes.codigo}
                  </div>,
                  <div>
                    {inspecoes.user_nome.split(" ")[0]}
                    <br />
                    {inspecoes.nome_relatorio}
                  </div>,
                  moment(inspecoes.createdAt)
                    .locale("pt-br")
                    .fromNow(),
                  <Icon
                    style={[styles.icon10, { marginLeft: 10 }]}
                    fromFontFamily={"Material Icons"}
                    icon={
                      !inspecoes.concluido
                        ? "radio_button_unchecked"
                        : "check_circle"
                    }
                  />,
                  <Icon
                    style={[styles.icon10, { marginLeft: 10 }]}
                    fromFontFamily={"Material Icons"}
                    icon={
                      !inspecoes.sPendecias
                        ? "radio_button_unchecked"
                        : "check_circle"
                    }
                  />,
                  <IconButton
                    icon
                    onClick={event => {
                      if (inspecoes.naoedit) {
                        this.actionInspecao(inspecoes);
                      } else {
                        this.verPdf(inspecoes.url);
                      }
                    }}
                    disabled={!inspecoes.url &&!inspecoes.naoedit|| inspecoes.naoedit&&!inspecoes.lista}
                  >
                    <Icon
                      style={styles.icon10}
                      fromFontFamily={"Material Icons"}
                      icon={inspecoes.naoedit ? "file_copy" : "picture_as_pdf"}
                    />
                  </IconButton>,
                  <IconButton
                    icon
                    onClick={event => {
                      this.open_modal_bottom1(inspecoes);
                    }}
                  >
                    <Icon
                      style={styles.icon10}
                      fromFontFamily={"Material Icons"}
                      icon={"warning"}
                    />
                  </IconButton>
                ])}
                title={"Lista de Inspeções"}
                tablePagination
                count={Number(this.state.totalLista)}
                load={this.state.load}
                page={this.state.parametros.page}
                rowsPerPage={this.state.parametros.limit}
                handleChangePage={(event, page) => {
                  this.setState(
                    {
                      parametros: Object.assign(this.state.parametros, {
                        page
                      }),
                      load: true
                    },
                    this.buscar()
                  );
                }}
                handleChangeRowsPerPage={event => {
                  this.setState(
                    {
                      parametros: Object.assign(this.state.parametros, {
                        limit: event.target.value,
                        page: 0
                      }),
                      load: true
                    },
                    this.buscar()
                  );
                }}
                actionBar={
                  <View>
                    <View style={styles.border}>
                      <Select
                        style={styles.selectinput}
                        value={this.state.tag_busca}
                        onChange={(value, data, index) => {
                          this.state.tag_busca = value;
                          this.setState({
                            item: this.state.item
                          });
                        }}
                        ref={v => (this.selectinput = v)}
                        key_value={"value"}
                        key_label={"text"}
                        label={"Tag para busca"}
                        list={this.state.options}
                      />
                      <DatePicker
                        style={styles.datepicker}
                        value={this.state.inicio}
                        onChange={value => {
                          this.state.inicio = value;
                          this.setState({
                            item: this.state.item
                          });
                        }}
                        ref={v => (this.datepicker = v)}
                        label={"Data inicio"}
                      />
                      <DatePicker
                        style={styles.datepicker}
                        value={this.state.termino}
                        onChange={value => {
                          this.state.termino = value;
                          this.setState({
                            item: this.state.item
                          });
                        }}
                        ref={v => (this.datepicker1 = v)}
                        label={"Data de fim"}
                      />
                    </View>
                    <TextInput
                      label="Filtrar empresas"
                      style={styles.input}
                      placeholder={"Buscar por OS, TAG, N° do Relatório"}
                      onChange={pesquisa => this.setState({ pesquisa })}
                      value={this.state.pesquisa}
                      inputNative
                    />
                    <TouchableOpacity
                      variant="outlined"
                      onPress={() => {
                        this.buscar();
                      }}
                      children={"Buscar"}
                    />
                    <TouchableOpacity
                      variant="outlined"
                      color={"default"}
                      style={{ marginLeft: 10 }}
                      onPress={() => {
                        this.actionRelatorio();
                      }}
                      children={"Relatórios"}
                    />
                  </View>
                }
              />
            </View>
          </View>
        </View>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  border: {
    borderColor: "rgba(0,0,0,0.1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginRight: 5,
    padding: 4
  },
  datepicker: {
    marginLeft: 3
  },
  selectinput: {
    marginRight: 4,
    width: 200
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {
    marginTop: 70
  }
});

const styles2 = theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 2
  }
});
export default withStyles(styles2)(Home);
