"use strict";
import React,{Component} from "react";

import {
  View,
  ScrollView,
  TitleBar,
  TouchableOpacity,
  Icon,
  Text,
  OpenSnack,
  StyleSheet
} from "react-1app";

import { Cloud, Query } from "../../infra";
import AutoComplete from "../../components/AutoComplete";
import styleGlobal from "../../styleGlobal";

//nao atualizar
export default class MoverEquipamentoInspecao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipamentos: this.props.equipamentos,
      inspecoes:this.props.inspecoes,
    };
    this.state.itens_unidades = null;
    this.state.itens_locais = null;
  }

  close_modal_bottom() {
      this.props.closeModal();

  }
  componentDidMount() {
    this.get_data_empresas();
    //{onDidMount}
  }
  get_data_empresas() {
    var query = new Query("empresas");
    query.addAscendingOrder("nome");
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_empresas: lista,
        load_empresas: false
      });
    });
  }
  get_data_unidade(objectId) {
    var query = new Query("unidades");
    query.addAscendingOrder("nome");
    query.whereEqualTo("empresas", objectId);
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_unidades: lista,
        load_empresas: false
      });
    });
  }
  get_data_Locais(objectId) {
    var query = new Query("locais");
    query.addAscendingOrder("nome");
    query.whereEqualTo("unidades", objectId);
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_locais: lista,
        load_empresas: false
      });
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }

  mover() {
    let {
      key_empresa,
      key_unidade,
      key_local,
      equipamentos,
      inspecoes
    } = this.state;
    // return console.log(inspecoes);
    let key_equipamento = this.state.equipamentos.objectId;
    if (!key_equipamento || !key_empresa || !key_unidade || !key_local)
      return OpenSnack({ message: "Erro verifique as opções", type: "error" });
    Cloud.post(
      "equipamentos/mover",
      {
        key_equipamento,
        key_empresa,
        key_unidade,
        key_local
      },
      (res, error) => {
        if (error)
          return OpenSnack({
            message: "Erro em realizar ação error: " + error.msg,
            type: "error"
          });
        this.concluirMovimentacaoDeEquipamento(res.novo, inspecoes, () => {
          // console.log(res);
          this.close_modal_bottom();
          this.props.salvarInspecao(inspecoes);
          OpenSnack({ message: "Equipamento alterado", type: "success" });
        });
        // console.log(res,error);
      }
    );
  }

  concluirMovimentacaoDeEquipamento(equipamento, inspecoes, callback) {
    // console.log(24);
    try {
      inspecoes.equipamento = equipamento;
      inspecoes.key_equipamento = equipamento.objectId;
      inspecoes.key_empresa = equipamento.key_empresa;
      inspecoes.key_unidade = equipamento.key_unidade;
      inspecoes.key_local = equipamento.key_local;
      this.getEmpresas(inspecoes.key_empresa, empresa => {
        this.getUnidade(inspecoes.key_unidade, unidade => {
          this.getLocais(inspecoes.key_local, local => {
            inspecoes.empresa = empresa;
            inspecoes.unidade = unidade;
            inspecoes.local = local;
            // console.log(inspecoes);
            callback();
          });
        });
      });
    } catch (e) {
      console.log(e);
    }
  }
  getEmpresas(objectId, callback) {
    var query = new Query("empresas");
    query.whereEqualTo("objectId", objectId);
    query.first(data => {
      callback(data);
    });
  }
  getUnidade(objectId, callback) {
    var query = new Query("unidades");
    query.whereEqualTo("objectId", objectId);
    query.first(data => {
      callback(data);
    });
  }
  getLocais(objectId, callback) {
    var query = new Query("locais");
    query.whereEqualTo("objectId", objectId);
    query.first(data => {
      callback(data);
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{  "Mover o equipamento: " + this.state.equipamentos.denominacao}</Text>

            <Text style={styles.label2} text={"Selecione as opções a baixo"} />
            <AutoComplete
              list={this.state.itens_empresas}
              keyLabel={"nome"}
              label="Empresa"
              placeholder="Empresa"
              onChange={data => {
                // console.log(data);
                this.setState({
                  key_empresa: data.objectId,
                  itens_unidades: null,
                  itens_locais: null
                });
                this.get_data_unidade(data.objectId);
              }}
            />
            {this.state.itens_unidades && (
              <AutoComplete
                list={this.state.itens_unidades}
                keyLabel={"nome"}
                label="Unidade"
                placeholder="Unidade"
                onChange={data => {
                  // console.log(data);
                  this.setState({
                    key_unidade: data.objectId,
                    itens_locais: null
                  });
                  this.get_data_Locais(data.objectId);
                }}
              />
            )}
            {this.state.itens_locais && (
              <AutoComplete
                list={this.state.itens_locais}
                keyLabel={"nome"}
                label="Local"
                placeholder="Local"
                onChange={data => {
                  // console.log(data);
                  this.setState({ key_local: data.objectId });
                }}
              />
            )}
            <View style={styles.action}>
              <TouchableOpacity
                style={styles.delete}
                onPress={() => this.mover()}
                children={"Mover"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  label2:{marginBottom:20}
});
