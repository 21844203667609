import { Cloud } from "../infra";

export function salvarRelatorio(input, callback) {
  Cloud.post("producao/relatorio", input, (dados, error) => {
    callback(dados, error);
  });
}
export function atualizarRelatorio(input, callback) {
  Cloud.put("producao/relatorio", input, (dados, error) => {
    callback(dados, error);
  });
}
export function listarRelatorio(input, callback) {
  Cloud.get("producao/relatorio", input, (dados, error) => {
    callback(dados, error);
  });
}
export function deletarRelatorio(input, callback) {
  Cloud.delete("producao/relatorio", input, (dados, error) => {
    callback(dados, error);
  });
}
//============================================================
export function salvarRelacao(input, callback) {
  Cloud.post("producao/relacao", input, (dados, error) => {
    callback(dados, error);
  });
}
export function atualizarRelacao(input, callback) {
  Cloud.put("producao/relacao", input, (dados, error) => {
    callback(dados, error);
  });
}
export function listarRelacao(input, callback) {
  Cloud.get("producao/relacao", input, (dados, error) => {
    callback(dados, error);
  });
}
export function deletarRelacao(input, callback) {
  Cloud.delete("producao/relacao", input, (dados, error) => {
    callback(dados, error);
  });
}
//============================================================

export function listarSugestEmpresa(input, callback) {
  Cloud.get("producao/relatorio/sujest/empresa", input, (dados, error) => {
    callback(dados, error);
  });
}

export function listarSugestUsuario(input, callback) {
  Cloud.get("producao/relatorio/sujest/usuario", input, (dados, error) => {
    callback(dados, error);
  });
}
export function listarSugestOS(input, callback) {
  Cloud.get("producao/relatorio/sujest/os", input, (dados, error) => {
    callback(dados, error);
  });
}
//============================================================
export function salvarTipo(input, callback) {
  Cloud.post("producao/tipo", input, (dados, error) => {
    callback(dados, error);
  });
}
export function atualizarTipo(input, callback) {
  Cloud.put("producao/tipo", input, (dados, error) => {
    callback(dados, error);
  });
}
export function listarTipo(input, callback) {
  Cloud.get("producao/tipo", input, (dados, error) => {
    callback(dados, error);
  });
}
export function deletarTipo(input, callback) {
  Cloud.delete("producao/tipo", input, (dados, error) => {
    callback(dados, error);
  });
}
//============================================================
