import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Input from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import TextPadrao from "./TextPadrao";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: [],
      load: true,
      item: {}
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("textopadroes");

    query.addAscendingOrder("createdAt");
    query.setLimit(1000);
    query.select(lista => {
      console.log(lista);
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  delete(item) {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.setState({
            save: true
          });
          item.status = 3;
          item.setMetodoApi("salvar", "POST");
          item.salvarNuven(re => {
            this.setState({
              save: false
            });
            this.buscarDados();
            OpenSnack({ message: "Deletado" });
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  salvar_bottom2(item) {
    this.setState({
      save: true
    });
    item.setMetodoApi("salvar", "POST");
    item.salvarNuven(re => {
      this.setState({
        save: false
      });
      this.buscarDados();
      OpenSnack({ message: "Salvo", type: "success" });
    });
  }

  novo() {
    this.state.list.unshift(
      new Dados("textopadroes").parse({ nome: "", lista: [] })
    );
    this.setState({
      list: this.state.list,
      load: false
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          {!this.state.item.id ? (
            <View style={styles.tela} type={"Paper"}>
              <View>
                <Text style={styles.label}>{"Opções de textos"}</Text>
                <TouchableOpacity
                  style={{ marginLeft: "auto" }}
                  onPress={() => this.novo()}
                  icon
                >
                  <Icon name={"add"} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.props.closeModal()}
                  style={{ marginLeft: 10 }}
                  children={"Fechar"}
                  color={"default"}
                  variant="outlined"
                ></TouchableOpacity>
              </View>
              <ListView
                renderItem={({ item, index }) => {
                  return (
                    <ListItem key={index} style={{ width: "100%" }}>
                      <Avatar style={{ marginRight: 20 }}>
                        <Icon
                          style={styles.icon2}
                          fromFontFamily={"Material Icons"}
                          icon={"format_list_numbered"}
                        />
                      </Avatar>
                      <Input
                        style={{ width: "100%", fontSize: 20 }}
                        value={Util.subString(item.nome, 250)}
                        onChange={value => {
                          item.nome = value;
                          this.setState({ item: {} });
                        }}
                        placeholder={"Nome da categoria"}
                      />
                      <TouchableOpacity
                        onClick={() => {
                          this.salvar_bottom2(item);
                        }}
                        color={"primary"}
                        icon
                      >
                        <Icon name={"save"} />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onClick={() => {
                          this.delete(item);
                        }}
                        color={"secondary"}
                        icon
                        disabled={!item.objectId}
                      >
                        <Icon name={"delete"} />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onClick={() => {
                          this.setState({ item });
                        }}
                        color={"default"}
                        icon
                        disabled={!item.objectId}
                      >
                        <Icon
                          fromFontFamily={"Material Design Icons"}
                          name={"chevron-right"}
                        />
                      </TouchableOpacity>
                    </ListItem>
                  );
                }}
                separator
                nativo
                data={this.state.list}
                load={this.state.load}
              />
            </View>
          ) : null}
          {this.state.item.id ? (
            <TextPadrao
              {...this.props}
              textopadroes={this.state.item}
              closeModal={() => {
                this.setState({ item: {} });
              }}
            />
          ) : null}
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
