import React, { Component } from "react";
import Progress from "react-1app/lib/Progress";

import View from "react-1app/lib/View";
import TextInput from "../../components/Input";
import Alert from "react-1app/lib/Alert";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Table from "react-1app/lib/Table";
import { NavLink } from "react-router-dom";
import { Util, Dados } from "../../infra";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { OpenSnack, OpenDialogOption } from "react-1app";
import PgQrcode from "../equipamento/PgQrCode";
import ListaOcorrencias from "./PgOcorrencias";
import UploadArquivos from "../equipamento/UploadArquivos";
import ListaGeoLocalizacao from "./PgGeoLocalizacao";
import ModalIframe from "../../components/ModalIframe";

import * as RelatorioPdf from "../../worker/relatorio_pdf";
import * as Inspecoes from "../../worker/inspecoes";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/SelectInput";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      usuario: this.props.screenProps.store.getState().user,
      anchorEl: [],
      inicio: null,
      termino: null,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true,
        where: "( naoedit is NULL  or naoedit=false )",
        sql_ordem:
          'inspecoes."sPendecias" asc nulls first,  inspecoes."createdAt" DESC nulls last  '
      },
      options: [
        {
          value: "ven_externo",
          text: "Vencimento exame Externo"
        },
        {
          value: "ven_interno",
          text: "Vencimento exame Interno"
        },
        {
          value: "ven_alternativo",
          text: "Vencimento teste Alternativo"
        },
        { value: "createdAt", text: "Criado" }
      ]
    };
  }

  componentDidMount() {
    this.buscar();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.parametros != this.props.parametros) {
      setTimeout(() => {
        this.buscar();
      }, 10);
    }
    return true;
  }

  buscar() {
    let { parametros, pesquisa, inicio, termino, tag_busca } = this.state;
    let par = { ...parametros, ...this.props.parametros, pesquisa2: pesquisa };
    this.setState({ load: true });
    if (inicio) {
      if (!tag_busca) return alert("Adicione um tag para busca");
      if (!termino) termino = moment().toJSON();
      if (!par.where) par.where = "";
      else par.where += " and ";
      par.where += `("${tag_busca}" >= '${inicio}' and "${tag_busca}" <= '${termino}' )`;
    }
    // console.log(this.props.parametros);
    Inspecoes.buscarInspecoesDash(par, ({ lista = [], total = 0 }, error) => {
      console.log(lista);
      this.setState({
        totalLista: total,
        lista: lista,
        load: false
      });
    });
  }

  componentWillUnmount() {}

  salvar_bottom3(inspecoes) {
    inspecoes.status = 3;
    this.setState({
      save: true
    });

    inspecoes = new Dados("inspecoes").parse(inspecoes);
    inspecoes.salvarNuven(re => {
      OpenSnack({ message: "Inspeção deletada", type: "success" });
      this.setState({
        save: false,
        anchorEl: []
      });
      this.buscar();
    });
  }
  //
  // verPdf(pdf){
  //   var wnd = window.open(pdf);
  // }

  verPdf(pdf) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src: pdf
      }
    });
  }

  open_modal_bottom1(inspecoes) {
    this.props.navigator.openModal({
      component: ListaOcorrencias,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes
      }
    });
  }
  open_modal_bottom14(inspecoes) {
    this.props.navigator.openModal({
      component: PgQrcode,
      props: {
        oldState: this.state,
        activity: this,
        inspecoes,
        heranca: this.state
      }
    });
  }
  open_modal_bottom12(inspecoes) {
    // return alert("Em breve...");
    this.props.navigator.openModal({
      component: UploadArquivos,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes,
        atualizar: inp => this.buscar()
      }
    });
  }
  open_modal_bottom13(inspecoes) {
    this.props.navigator.openModal({
      component: ListaGeoLocalizacao,
      props: { heranca: this.state, activity: this, inspecoes }
    });
  }

  actionPdf(inspecoes) {
    if (inspecoes.url) {
      let listOpcoes = [
        {
          label: "Gerar pdf timbrado",
          onPress: () => this.gerarPdf(inspecoes),
          separa: "Opções PDF"
        },
        { label: "Ver PDF atual", onPress: () => this.verPdf(inspecoes.url) }
      ];
      if (this.state.usuario.adm && inspecoes.url) {
        listOpcoes.push({
          label: inspecoes.link_rel
            ? "Assinar Eletronicamente Novamente"
            : "Assinar Eletronicamente",
          onPress: () => this.gerarPdfAssinado(inspecoes),
          separa: "Opções PDF assinado ( AssinaWeb )"
        });
      }
      if (inspecoes.link_rel)
        listOpcoes.push({
          label: "Ver PDF assinado Eletronicamente",
          onPress: () => this.verPdf(inspecoes.link_rel)
        });
      listOpcoes.push({
        label: "Cancelar",
        onPress: () => console.log(),
        separa: "Cancelar"
      });
      OpenDialogOption({
        title: "Gerar PDF",
        description: "Você realmente deseja gerar um novo PDF?",
        action: listOpcoes
      });
    } else {
      this.gerarPdf(inspecoes);
    }
  }

  gerarPdf(inspecoes, cell) {
    this.setState({ gerando: inspecoes.objectId });
    RelatorioPdf.gerarPdfInspesao(
      {
        key_inspecoes: inspecoes.objectId,
        key_user: this.state.usuario.objectId
      },
      (data, error) => {
        if (data) {
          if (data.erro) {
            OpenSnack({ message: "Pdf em manutenção", type: "error" });
            this.setState({ gerando: false });
          } else {
            OpenSnack({ message: "PDF gerando", type: "success" });
            inspecoes.url = data.url;
            this.setState({ gerando: false });
            var wnd = window.open(data.url);
            wnd.print();
          }
        } else {
          this.setState({ gerando: false });
        }
        this.buscar();
      }
    );
  }

  gerarPdfAssinado(inspecoes) {
    this.setState({ gerando: inspecoes.objectId });
    RelatorioPdf.gerarPdfAssinado(
      {
        key_inspecoes: inspecoes.objectId,
        key_user: this.state.usuario.objectId
      },
      (data, error) => {
        if (error) {
          OpenSnack({ message: error.msg, type: "error" });
          this.setState({ gerando: false });
        } else {
          OpenSnack({ message: "PDF gerando", type: "success" });
          inspecoes.link_rel = data.link_rel;
          this.setState({ gerando: false });
          var wnd = window.open(data.link_rel);
          wnd.print();
        }
        this.buscar();
      }
    );
  }

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={"12"} item>
          <Table
            tableHead={[
              ...((this.props.parametros || {}).isNotRevizado ||
              (this.props.parametros || {}).revizado ||
              this.props.norCEmpresa
                ? []
                : ["Empresa"]),
              "Unidade / Locais",
              "TAG / OS",
              "Inspetor",
              "Criado",
              "Concluído",
              "S. Pend",
              "PDF",
              ...(this.props.headAddColumn ? this.props.headAddColumn : []),
              "Ações"
            ]}
            tableData={this.state.lista.map((inspecoes, index) => [
              ...((this.props.parametros || {}).isNotRevizado ||
              (this.props.parametros || {}).revizado ||
              this.props.norCEmpresa
                ? []
                : [Util.subString(inspecoes.empresa_nome, 25)]),
              <div>
                {inspecoes.unidade_nome}
                <br />
                {Util.subString(inspecoes.local_nome, 25)}
              </div>,
              <div>
                {inspecoes.codigo_equipamento} <br /> {inspecoes.codigo}
              </div>,
              <div>
                {inspecoes.user_nome.split(" ")[0]}
                <br />
                {inspecoes.nome_relatorio}
              </div>,
              moment(inspecoes.createdAt)
                .locale("pt-br")
                .fromNow(),
              (inspecoes.naoedit && <div />) || (
                <Icon
                  style={[styles.icon10, { marginLeft: 10 }]}
                  fromFontFamily={"Material Icons"}
                  icon={
                    !inspecoes.concluido
                      ? "radio_button_unchecked"
                      : "check_circle"
                  }
                />
              ),
              (inspecoes.naoedit && <div />) || (
                <Icon
                  style={[styles.icon10, { marginLeft: 10 }]}
                  fromFontFamily={"Material Icons"}
                  icon={
                    !inspecoes.sPendecias
                      ? "radio_button_unchecked"
                      : "check_circle"
                  }
                />
              ),
              (inspecoes.naoedit && (
                <IconButton
                  icon
                  onClick={event => {
                    this.open_modal_bottom12(inspecoes);
                  }}
                >
                  <Icon
                    style={styles.icon10}
                    fromFontFamily={"Material Design Icons"}
                    icon={"file-multiple"}
                  />
                </IconButton>
              )) ||
                (this.state.gerando != inspecoes.objectId && (
                  <IconButton
                    icon
                    onClick={event => {
                      this.actionPdf(inspecoes);
                    }}
                  >
                    <Icon
                      style={styles.icon10}
                      fromFontFamily={"Material Icons"}
                      icon={inspecoes.url ? "picture_as_pdf" : "cached"}
                    />
                  </IconButton>
                )) || <Progress style={styles.progresso1} />,
              ...(this.props.renderAddColumn
                ? this.props.renderAddColumn(inspecoes, index, this)
                : []),
              this.renderActions(inspecoes, index)
            ])}
            title={this.props.title||"Lista de Inspeções"}
            tablePagination
            count={Number(this.state.totalLista)}
            load={this.state.load}
            page={this.state.parametros.page}
            rowsPerPage={this.state.parametros.limit}
            handleChangePage={(event, page) => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, { page }),
                  load: true
                },
                this.buscar()
              );
            }}
            handleChangeRowsPerPage={event => {
              this.setState(
                {
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  }),
                  load: true
                },
                this.buscar()
              );
            }}
            actionBar={
              <View>
                <View style={styles.border}>
                  <Select
                    style={styles.selectinput}
                    value={this.state.tag_busca}
                    onChange={(value, data, index) => {
                      this.state.tag_busca = value;
                      this.setState({
                        item: this.state.item
                      });
                    }}
                    ref={v => (this.selectinput = v)}
                    key_value={"value"}
                    key_label={"text"}
                    label={"Tag para busca"}
                    list={this.state.options}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.inicio}
                    onChange={value => {
                      this.state.inicio = value;
                      this.setState({
                        item: this.state.item
                      });
                    }}
                    ref={v => (this.datepicker = v)}
                    label={"Data inicio"}
                  />
                  <DatePicker
                    style={styles.datepicker}
                    value={this.state.termino}
                    onChange={value => {
                      this.state.termino = value;
                      this.setState({
                        item: this.state.item
                      });
                    }}
                    ref={v => (this.datepicker1 = v)}
                    label={"Data de fim"}
                  />
                </View>
                <TextInput
                  label="Filtrar empresas"
                  style={styles.input}
                  placeholder={"Buscar por OS, TAG, N° do Relatório"}
                  onChange={pesquisa => this.setState({ pesquisa })}
                  value={this.state.pesquisa}
                  inputNative
                  onSubmitEditing={() => {
                    this.buscar();
                  }}
                />
                <TouchableOpacity
                  variant="outlined"
                  onPress={() => {
                    this.buscar();
                  }}
                  children={"Buscar"}
                />
              </View>
            }
          />
        </View>
      </View>
    );
  }

  renderActions(inspecoes, index) {
    return (
      <div key={"action" + index}>
        <IconButton
          aria-label="More"
          aria-owns={this.state.anchorEl[index] ? "long-menu" : null}
          aria-haspopup="true"
          onClick={event => {
            let anchorEl = this.state.anchorEl;
            anchorEl[index] = event.currentTarget;
            this.setState({
              anchorEl
            });
          }}
        >
          <Icon
            style={styles.icon10}
            fromFontFamily={"Material Icons"}
            icon={"more_vert"}
          />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl[index]}
          open={Boolean(this.state.anchorEl[index])}
          onClose={() => {
            let anchorEl = this.state.anchorEl;
            anchorEl[index] = null;
            this.setState({ anchorEl });
          }}
        >
          <Text
            style={styles.numero}
            text={inspecoes.nome_relatorio || "Arquivos"}
          />
          {!inspecoes.naoedit && (
          <a//NavLink
              // to={"/editar/" + inspecoes.id}
              // activeClassName="active"
              // key={"key_1"}
              href={"/editar/" + inspecoes.id}
               target="_blank"
            >
              <MenuItem
                key={"editar"}
                onClick={() => {
                  this.setState({ anchorEl: [] });
                }}
              >
                Editar
              </MenuItem>
            </a>
          )}
          {inspecoes.comentario ? (
            <MenuItem
              key={"comentario"}
              onClick={() => {
                this.open_comentario(inspecoes);
                this.setState({ anchorEl: [] });
              }}
            >
              Comentário
            </MenuItem>
          ) : null}
          {!inspecoes.naoedit && (
            <MenuItem
              key={"geo"}
              onClick={() => {
                this.open_modal_bottom13(inspecoes);
                this.setState({ anchorEl: [] });
              }}
            >
              Geolocalização
            </MenuItem>
          )}
          {!inspecoes.naoedit && (
            <MenuItem
              key={"qrcode"}
              onClick={() => {
                this.open_modal_bottom14(inspecoes);
                this.setState({ anchorEl: [] });
              }}
            >
              QRcode
            </MenuItem>
          )}
          {!inspecoes.naoedit && (
            <MenuItem
              key={"editar3"}
              onClick={() => {
                this.open_modal_bottom1(inspecoes);
                this.setState({ anchorEl: [] });
              }}
            >
              Ocorrencias
            </MenuItem>
          )}
          {inspecoes.naoedit && (
            <MenuItem
              key={"editar4"}
              onClick={() => {
                this.open_modal_bottom12(inspecoes);
                this.setState({ anchorEl: [] });
              }}
            >
              Arquivos
            </MenuItem>
          )}
          <MenuItem
            key={"deletar"}
            onClick={() => {
              Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
                { text: "Ok", onPress: () => this.salvar_bottom3(inspecoes) },
                { text: "Cancelar", onPress: () => console.log() }
              ]);
              this.setState({ anchorEl: [] });
            }}
          >
            Deletar
          </MenuItem>
          <Text style={styles.numero} text={inspecoes.numero} />
        </Menu>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 250
  },
  image: {
    height: 30,
    width: 30
  },
  icon10: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  numero: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,150,1)",
    alignSelf: "stretch",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10
  },
  view3: {},
  border: {
    borderColor: "rgba(0,0,0,0.1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginRight: 5,
    padding: 4,
    maxWidth: 500
  },
  datepicker: {
    marginLeft: 3,
    maxWidth: 150
  },
  selectinput: {
    marginRight: 4,
    width: 180
  }
});
