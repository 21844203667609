import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
// import TextInput from "react-1app/lib/TextInput";

import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import Unidade from "../unidade";
// import styleGlobal from "../../styleGlobal";
import moment from "moment";
import { Query } from "../../infra";

export default class Comercial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.parametros != this.props.parametros
    ) {
      setTimeout(() => {
        this.buscar();
      }, 10);
    }
    return true;
  }

  buscar(pesquisa) {
    let {parametros={}}=this.props;
    this.setState({load:true});
    var query = new Query(parametros.entidade||"empresas");
    query.addAscendingOrder(parametros.order||"nome");
    (parametros.where||[]).map(a=>{query.whereEqualTo(...a);});
    (parametros.setWhere||[]).map(a=>{query.setWhereServer(a);});

    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false,
        id: null
      });
    });
  }

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return list.filter(item => regex.test(item[this.props.key_label||"nome"]));
  }

  componentWillUnmount() {}

  render() {
    return (
      <View type={"Paper"}>
        <ListView
          ListHeaderComponent={() => (
            <View style={styles.head}>
              {this.props.head ? (
                this.props.head()
              ) : (
                <ListSubheader component="div">
                  {this.props.title}
                </ListSubheader>
              )}
              <TextInput
                style={styles.input}
                placeholder="Pesquisa."
                onChange={pesquisa => this.setState({ pesquisa })}
                value={this.state.pesquisa}
                inputNative
              />
              {this.props.action && this.props.action()}
            </View>
          )}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                key={index}
                button
                style={{ width: "100%" }}
                selected={this.props.id === item.id}
                onClick={event => {
                  this.props.onChange(item);
                }}
              >
                <Avatar src={item.logo}>
                  <Icon name={"image"} />
                </Avatar>
                <ListItemText
                  primary={item[this.props.key_label||"nome"]}
                  secondary={moment(item.createdAt).format("DD/MM/YY")}
                />

                {this.props.open ? (
                  <ListItemSecondaryAction>
                    <TouchableOpacity
                      icon
                      onClick={() => {
                        this.props.open(item);
                      }}
                    >
                      <Icon name={"insert_drive_file"} />
                    </TouchableOpacity>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            );
          }}
          separator
          data={this.getList()}
          tablePagination
          count={Number(this.getSuggestions().length)}
          load={this.state.load}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState({
              parametros: Object.assign(this.state.parametros, { page })
            });
          }}
          handleChangeRowsPerPage={event => {
            this.setState({
              parametros: Object.assign(this.state.parametros, {
                limit: event.target.value,
                page: 0
              })
            });
          }}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  icon: {
    cursor: "pointer"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
