"use strict";
import React, { Component } from "react";

import {
  ImageUpload,
  StyleSheet,
  Icon,
  DatePicker,
  TextInput,
  Alert,
  Switch,
  Text,
  TouchableOpacity,
  View
} from "react-1app";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
export default class CadastroUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {},
      user: new Dados("user"),
      load_tela: true,
      user_local: new Dados("user_local").parse(this.props.user_local)
    };
    //{onConstructor}
  }

  alterar(test) {
    if (test == 1) {
      this.state.user_local.adm = true;
    } else {
      this.state.user_local.adm = false;
    }
    if (test == 2) {
      this.state.user_local.market = true;
      this.state.user_local.habilitado = false;
    } else {
      this.state.user_local.market = false;
    }
    if (test == 3) {
      this.state.user_local.campo = true;
    } else {
      this.state.user_local.campo = false;
    }
    this.setState({
      aterado: true
    });
  }

  deletar() {
    Alert.alert("Deletar", "Deseja deletar este registro", [
      {
        text: "Não",
        onPress: null
      },
      {
        text: "Sim",
        onPress: () => {
          this.setState({
            save: true
          });
          this.state.user_local.status = 3;
          this.state.user_local.setMetodoApi("salvar", "POST");
          this.state.user_local.salvarNuven(re => {
            this.props.closeModal();
            this.setState({
              save: false
            });

            if (this.props.activity.get_data_user)
              this.props.activity.get_data_user();
          });
        }
      }
    ]);
  }
  salvar() {
    this.setState({
      save: true
    });
    this.state.user_local.setMetodoApi("salvar", "POST");
    this.state.user_local.salvarNuven(re => {
      this.setState({
        save: false
      });
        if (this.props.onChange) this.props.onChange(this.state.user_local);
      if (this.props.activity.get_data_user)
        this.props.activity.get_data_user();
    });
  }
  componentDidMount() {
    // this.get_data_tela();
    //{onDidMount}
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <Text style={styles.label} text={"Cadastro Usuario"} />
        <TextInput
          style={styles.nome}
          value={this.state.user_local.nome}
          onChange={value => {
            this.state.user_local.nome = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.nome = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Nome"}
        />
        <TextInput
          style={styles.login}
          value={this.state.user_local.login}
          onChange={value => {
            this.state.user_local.login = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.login = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Login"}
        />
        <TextInput
          style={styles.senha}
          value={this.state.user_local.senha}
          onChange={value => {
            this.state.user_local.senha = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.senha = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Senha"}
        />
        <TextInput
          style={styles.telefone}
          value={this.state.user_local.telefone}
          onChange={value => {
            this.state.user_local.telefone = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.telefone = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Felefone"}
        />
        <TextInput
          style={styles.celular}
          value={this.state.user_local.celular}
          onChange={value => {
            this.state.user_local.celular = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.celular = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Celular"}
        />
        <TextInput
          style={styles.funcao}
          value={this.state.user_local.funcao}
          onChange={value => {
            this.state.user_local.funcao = value;
            this.setState({
              user_local: this.state.user_local
            });
          }}
          ref={v => (this.funcao = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Função"}
        />
        {!this.state.user_local.user ? (
          <TextInput
            style={styles.crea}
            value={this.state.user_local.crea}
            onChange={value => {
              this.state.user_local.crea = value;
              this.setState({
                user_local: this.state.user_local
              });
            }}
            ref={v => (this.crea = v)}
            keyboardType={"default"}
            secureTextEntry={false}
            multiline={false}
            label={"CREA"}
          />
        ) : null}
        {!this.state.user_local.user ? (
          <View style={styles.view2}>
            <View style={styles.view3}>
              <Switch
                style={styles.adm}
                value={this.state.user_local.adm}
                onChange={value => {
                  this.state.user_local.adm = value;
                  this.setState({
                    user_local: this.state.user_local
                  });
                  this.alterar(1);
                }}
                ref={v => (this.adm = v)}
              />
              <Text style={styles.label1} text={"Administrador"} />
            </View>
            <View style={styles.view4}>
              <Switch
                style={styles.market}
                value={this.state.user_local.market}
                onChange={value => {
                  this.state.user_local.market = value;
                  this.setState({
                    user_local: this.state.user_local
                  });
                  this.alterar(2);
                }}
                ref={v => (this.market = v)}
              />
              <Text style={styles.label2} text={"Market"} />
            </View>
            <View style={styles.view5}>
              <Switch
                style={styles.campo}
                value={this.state.user_local.campo}
                onChange={value => {
                  this.state.user_local.campo = value;
                  this.setState({
                    user_local: this.state.user_local
                  });
                  this.alterar(3);
                }}
                ref={v => (this.campo = v)}
              />
              <Text style={styles.label3} text={"Inspetor"} />
            </View>
          </View>
        ) : null}
        {!this.state.user_local.user ? (
          <View style={styles.view7}>
            <Text style={styles.label5} text={"Assinatura para pdf"} />
            <ImageUpload
              style={styles.image}
              value={this.state.user_local.image}
              onChange={value => {
                this.state.user_local.image = value;
                this.setState({
                  user_local: this.state.user_local
                });
              }}
              ref={v => (this.image = v)}
            />
          </View>
        ) : null}
        <View style={styles.action}>
          {!this.state.user_local.user ? (
            <TouchableOpacity
              onPress={() => this.props.closeModal()}
              children={"Fechar"}
              color={"default"}
              variant="outlined"
            ></TouchableOpacity>
          ) : null}
          {!this.state.user_local.user ? (
            <TouchableOpacity
              onPress={() => this.deletar()}
              children={"Delete"}
              color={"secondary"}
              variant="outlined"
              style={styles.delete}
              disabled={!this.state.user_local.objectId}
              load={this.state.load2}
            ></TouchableOpacity>
          ) : null}
          <TouchableOpacity
            onPress={() => this.salvar()}
            children={"Salvar"}
            variant="outlined"
            style={styles.delete}
            load={this.state.load}
          ></TouchableOpacity>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    width: 480
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    flex: 1,
    fontSize: 20
  },
  view6: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  label4: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 14
  },
  habilitado: {
    alignSelf: "auto",
    width: 60
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    marginRight: 10,
    marginLeft: 10,
    position: "relative"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(7,81,120,1)"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    position: "relative"
  },
  icon: {
    fontSize: 25,
    color: "rgba(7,81,120,1)"
  },
  view1: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  nome: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 30,
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 5,
    textAlign: "left"
  },
  login: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  senha: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  telefone: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  celular: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  funcao: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  crea: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  view2: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    marginTop: 20
  },
  view3: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  adm: {
    alignSelf: "auto"
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view4: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  market: {
    alignSelf: "auto"
  },
  label2: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view5: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  campo: {
    alignSelf: "auto"
  },
  label3: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view7: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 75
  },
  label5: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    fontSize: 13,
    marginBottom: 5
  },
  image: {
    width: 350,
    height: 50,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    backgroundColor: "rgba(237,239,245,1)"
  }
});
