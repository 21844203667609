import React, { Component } from "react";
import View from "react-1app/lib/View";
import Image from "react-1app/lib/Image";
import Text from "react-1app/lib/Text";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import Hidden from "@material-ui/core/Hidden";
import * as actions from "../../redux/actions";
import styleGlobal from "../../styleGlobal";
import Avatar from "@material-ui/core/Avatar";
import ModalValidarPdf from "../inspecoes/ModalValidarPdf";
import Perfil from "../usuarios/CardInfo";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      user: this.props.screenProps.store.getState().user,
      install: false
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var { user } = this.props.screenProps.store.getState();
      if (user !== this.state.user) this.setState({ user });
    });
    if (window.onInstaller)
      this.pwa = window.onInstaller(install => {
        this.setState({ install });
      });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.pwa) this.pwa();
  }

  sair() {
    this.props.screenProps.store.dispatch(actions.logout());
    this.props.navigation.navigate("/login");
  }

  abrirPerfil() {}

  validarPdf() {
    this.props.navigator.openModal({
      pageName: "ModalValidarPdf",
      component: ModalValidarPdf,
      props: {
        navigator: this.props.navigator,
        activity: this
      }
    });
  }

  perfil() {
    this.props.navigator.openModal({
      pageName: "ModalValidarPdf",
      component: Perfil,
      props: {
        navigator: this.props.navigator,
        activity: this
      }
    });
  }

  render() {
    return (
      <View style={styles.content}>
        <TouchableOpacity
          style={styleGlobal.buttonIcon}
          tooltip={"Início"}
          color="inherit"
          onPress={() => {
            this.props.navigation.navigate("/");
          }}
        >
          <Icon name={"home"} />
          <Hidden smDown key={"key_maior"}>
            <Text color="inherit" style={styles.text}>
              {"Dashbord"}
            </Text>
          </Hidden>
        </TouchableOpacity>
        <View style={styles.view4} />
        {this.state.install ? (
          <TouchableOpacity
            style={{ ...styleGlobal.buttonIcon, margin: 5 }}
            variant="outlined"
            onPress={() => {
              this.state.install();
            }}
            color="inherit"
          >
            <Avatar style={{ color: "white", background: "rgba(0,0,0,0)" }}>
              <Icon
                fromFontFamily={"Material Design Icons"}
                name={"cellphone-arrow-down"}
                color="inherit"
              />
            </Avatar>
            <Hidden smDown key={"key_maior"}>
              {"Instalar app"}
            </Hidden>
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          icon
          style={styleGlobal.buttonIcon}
          onPress={() => {
            this.validarPdf();
          }}
          tooltip={"Validar Pdf"}
          color="inherit"
        >
          <Icon icon={"file-check"} fromFontFamily={"Material Design Icons"} />
        </TouchableOpacity>
        <TouchableOpacity
          icon
          style={styleGlobal.buttonIcon}
          tooltip={"Perfil"}
          onPress={() => {
            this.perfil();
          }}
          color="inherit"
        >
          {this.state.user.image ? (
            <Image
              style={styles.image}
              source={{ uri: this.state.user.image }}
            />
          ) : null}
          {!this.state.user.image ? <Icon name={"group"} /> : null}
        </TouchableOpacity>

        <TouchableOpacity
          icon
          style={styleGlobal.buttonIcon}
          onPress={() => {
            this.sair();
          }}
          tooltip={"Sair"}
          color="inherit"
        >
          <Icon icon={"exit_to_app"} />
        </TouchableOpacity>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: null,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flex: 1
  },
  text: {
    textAlign: "left",
    fontWeight: "700",
    fontSize: 17,
    marginLeft: 20
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 50,
    paddingBottom: 2
  }
});
