import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import StyleSheet from "react-1app/lib/StyleSheet";
import ListView from "react-1app/lib/ListView";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Input from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: this.props.list,
      usar: []
    };
  }

  componentDidMount() {}

  deletList(item) {
    var lista = this.props.textopadroes.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      if (foco.objectId == item.objectId) {
        // console.log(i,to);
        this.props.textopadroes.lista.splice(i, 1);
        break;
      }
    }
    this.setState({ textopadroes: this.props.textopadroes });
  }

  salvar_bottom2() {
    this.setState({
      save: true
    });
    this.props.textopadroes.setMetodoApi("salvar", "POST");
    this.props.textopadroes.salvarNuven(re => {
      this.setState({
        save: false
      });
      OpenSnack({ message: "Salvo", type: "success" });
    });
  }

  novo() {
    this.props.textopadroes.lista.unshift({
        text: "",
        status: 1,
        objectId: new Date().getTime() + "key"
      }
    );
    this.setState({
      list: this.state.list,
      load: false
    });
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <View>
          <TouchableOpacity
            onPress={() => this.props.closeModal()}
            style={{ marginRight: 10 }}
            icon
            color={"default"}
            variant="outlined"
          >
            <Icon
              fromFontFamily={"Material Design Icons"}
              name={"chevron-left"}
            />
          </TouchableOpacity>
          <Text style={styles.label}>{"Textos"}</Text>
          <TouchableOpacity
            style={{ marginLeft: "auto" }}
            onPress={() => this.novo()}
            icon
          >
            <Icon name={"add"} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginLeft: 10 }}
            onPress={() => this.salvar_bottom2()}
            children={"Salvar"}
            variant="outlined"
            load={this.state.load}
          ></TouchableOpacity>
        </View>
        <ListView
          renderItem={({ item, index }) => {
            return (
              <ListItem key={index} style={{ width: "100%" }}>
                <Avatar style={{ marginRight: 20 }}>
                  <Icon
                    style={styles.icon2}
                    fromFontFamily={"Material Icons"}
                    icon={"format_list_numbered"}
                  />
                </Avatar>
                <Input
                  style={{ width: "100%" }}
                  multiline
                  value={item.text}
                  onChange={value => {
                    item.text = value;
                    this.setState({ item: {} });
                  }}
                  placeholder={"Qual textopadrao"}
                />
                <TouchableOpacity
                  onClick={() => {
                    this.deletList(item);
                  }}
                  color={"secondary"}
                  icon
                >
                  <Icon name={"delete"} />
                </TouchableOpacity>
              </ListItem>
            );
          }}
          separator
          nativo
          data={this.props.textopadroes.lista}
          load={this.state.load}
        />
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    alignItems: "center"
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
