
import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import StyleSheet from "react-1app/lib/StyleSheet";
import View from "react-1app/lib/View";
import moment from "moment"
const DatePicker =(props)=>(
  <View  style={StyleSheet.flatten([styles,props.style,{alignItems:"row"}])}>
    <TextField
      id="date"
      label={props.label}
      type="date"
      margin="normal"
      fullWidth
      {...props}
      style={{}}
      style={{marginTop:0,marginBottom:0}}
      value={moment(props.value).format("YYYY-MM-DD")}
      onChange={(e,ee) => {
        var value = e.target.value?moment(e.target.value).toJSON():"";
        if (props.onChange) props.onChange(value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{style:{height: 28}}}
      inputProps={{style:{marginBottom: 0,  height:23,  border: "none",borderBottom: "none",boxShadow: "none"}}}
        />
    </View>
  );

  var styles = { cursor: "pointer",display:"flex",height:40,flex:1,width:"100%"}
  export default DatePicker;
