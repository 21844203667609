import {
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

import View from "react-1app/lib/View";
import Login from "./views/acesso";
import Cadastro from "./views/acesso";
import Empresa from "./views/empresa";
import Inspecoes from "./views/inspecoes";
import Elaboracao from "./views/elaboracao";
import Dashboard from "./views/dashboard";
import Formulario from "./views/inspecoes_edit";
import Info from "./views/info";
import Comercial from "./views/comercial";
import StatusOs from "./views/status_os";
import Usuarios from "./views/usuarios";
import Cliente from "./views/cliente";
import Engenharia from "./views/engenharia";
import Relatorios from "./views/relatorio";
import RelatoriosInspetor from "./views/relatorio_inspetor";
import Almoxarifado from "./views/almoxarifado";
import RelatorioProducao from "./views/relatorio_producao";

import Historico from "./views/engenharia/Historico";


export const pages=[
  { path: "/dashboard", component:withRouter(connect()(Dashboard)),icon:"dashboard" ,name:'Dashboard',menu:true,permissao:'adm'},
  { path: "/inspecoes", component: withRouter(connect()(Inspecoes)),icon:"folder_shared" ,name:'Todas Inspeções',menu:true,permissao:'adm'},
  { path: "/elaboracao", component: withRouter(connect()(Elaboracao)),icon:"build" ,name:'Relatórios em elaboração',menu:true,permissao:'adm'},
  { path: "/status", component: withRouter(connect()(StatusOs)),icon:"drag_indicator" ,name:'Relatórios para aprovação',menu:true,permissao:'adm'},
  { path: "/engenharia", component: withRouter(connect()(Engenharia)),icon:"verified" ,name:'Relatórios aprovados',menu:true,permissao:'adm'},
  { path: "/historico", component: withRouter(connect()(Historico)),icon:"attach_email" ,name:'Protocolos Enviados',menu:true,permissao:'adm'},
  { path: "/editar/:id_inspecao", component: withRouter(connect()(Formulario)),permissao:"campo"},
  { path: "/empresa", component: withRouter(connect()(Empresa)),icon:"business" ,name:'Empresa',menu:true,permissao:"campo"},
  { path: "/info", component: withRouter(connect()(Info)),icon:"info" ,name:'Informações',menu:true,permissao:'adm'},
  { path: "/comercial", component: withRouter(connect()(Comercial)),icon:"local_mall" ,name:'Comercial',menu:true,permissao:"market"},
  { path: "/usuarios", component: withRouter(connect()(Usuarios)),icon:"account_circle" ,name:'Usuários',menu:true,permissao:'adm'},
  { path: "/cliente", component: withRouter(connect()(Cliente)),icon:"account_box" ,name:'Cliente',menu:true,permissao:'user'},
  { path: "/relatorio/inspetores", component: withRouter(connect()(RelatoriosInspetor)),icon:"group" ,name:'Relatorio de Inspetores',menu:true,permissao:'adm'},
  { path: "/almoxarifado", component: withRouter(connect()(Almoxarifado)),icon:"assignment" ,name:'Almoxarifado',menu:true,permissao:'almoxarife'},
  { path: "/relatorio/producao", component: withRouter(connect()(RelatorioProducao)),icon:"event_available" ,name:'Relatorio de produção',menu:true,permissao:'adm'},
  { path: "/modelo/relatorios", component: withRouter(connect()(Relatorios)),icon:"settings_ethernet" ,name:'Modelos de Relatorio',menu:true,permissao:'adm'},
  { path: "/login", component:withRouter(connect()(Login))},
]
