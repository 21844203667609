
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => {

    return(
      <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: -30.002865, lng: -51.2053106 }}
       center={{ lat: props.lat, lng: props.lng }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
      >
      <MarkerClusterer
      onClick={()=>{}}
      averageCenter
      enableRetinaIcons
      gridSize={60}
      >
      {props.lista&&props.lista[0]&&props.lista.map(({latitude,longitude},key)=>(
        <Marker key={"poit"+key} position={{ lat:latitude, lng:longitude}} />
      ))}
      </MarkerClusterer>
      </GoogleMap>
    )})
  );

  function Maps({lista=[],latitude=0,longitude=0}) {
    // console.log(props);
    return (
      <CustomSkinMap
      lista={lista}
      lat={latitude}
      lng={longitude}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtJ-XoEwYoFJ6n0msME_24chxgI-ay3gs"
      loadingElement={<div style={{ height: 500,width:`100%` }} />}
      containerElement={<div style={{ height: 500,width:`100%` }} />}
      mapElement={<div style={{ height: `100%`,width:`100%` }} />}
      />
    );
  }

  export default Maps;
