import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import TextInput from "react-1app/lib/TextInput";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import CadastorEquipamento from "../equipamento/Edit";
import MoverEquipamentoInspecao from "../equipamento/MoverEquipamentoInspecao";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: !Boolean((this.props.inspecoes || {}).relatorio),
      list: (this.props.inspecoes || {}).relatorio&&[(this.props.inspecoes || {}).relatorio]||[],
      relatorio: this.props.inspecoes&&this.props.inspecoes.relatorio||{}
    };
  }

  componentDidMount() {
    if (this.state.load) this.buscarDados();
  }

  buscarDados() {
    var query = new Query("relatorio");

    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  salvar() {
    this.setState({
      load: true
    });
    if (!this.state.inspecoes.objectId) {
      this.state.inspecoes.key_relatorio = this.state.relatorio.objectId;
      this.state.inspecoes.relatorio = this.state.relatorio;
    }
    this.state.inspecoes.setMetodoApi("salvarWeb", "POST");
    this.state.inspecoes.salvarNuven(re => {
      if (re.objectId || re.id) {
        OpenSnack({ message: "Salvo", type: "success" });
        this.props.closeModal();
        if(this.props.atualizar)this.props.atualizar();
      }
      this.setState({
        load: false
      });
    });
  }
  open_pg_bottom3() {
    this.props.navigator.openModal({
      component: CadastorEquipamento,
      props: {
        testEq: true,
        heranca: this.state,
        activity: this,
        equipamentos: this.state.inspecoes.equipamento
      }
    });
  }
  open_pg_bottom4() {
    this.props.navigator.openModal({
      component: MoverEquipamentoInspecao,
      props: {
        testEq: true,
        equipamentos: this.state.inspecoes.equipamento,
        inspecoes: this.state.inspecoes,
        salvarInspecao: this.props.salvarInspecao
      }
    });
  }
  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Dados para Inspeção"+(this.state.inspecoes.origem?" (CLONADO)":"")}</Text>
            <TextInput
              style={styles.codigo}
              value={this.state.inspecoes.codigo}
              onChange={value => {
                this.state.inspecoes.codigo = value;
                this.setState({ inspecoes: this.state.inspecoes });
              }}
              keyboardType={"default"}
              label={"Codigo (OS)"}
            />
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    button
                    disabled={this.state.inspecoes.id}
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.setState({ alterado: true, relatorio: item });
                    }}
                  >
                    <ListItemText
                      primary={item.nome}
                      secondary={item.descricao}
                    />
                    <Icon
                      color={"default"}
                      name={
                        this.state.relatorio.id == item.id
                          ? "check_circle"
                          : "radio_button_unchecked"
                      }
                    />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
            <View style={styles.action}>
              {this.state.inspecoes.objectId ? (
                <TouchableOpacity
                  onPress={() => this.open_pg_bottom3()}
                  children={"Informações do equipamento"}
                  variant="outlined"
                  disabled={this.state.load}
                ></TouchableOpacity>
              ) : null}
              {this.state.inspecoes.objectId ? (
                <TouchableOpacity
                  style={styles.delete}
                  onPress={() => this.open_pg_bottom4()}
                  children={"Mover equipamento"}
                  variant="outlined"
                  disabled={this.state.load}
                ></TouchableOpacity>
              ) : null}

              <TouchableOpacity
                style={styles.delete}
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
