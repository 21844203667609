import React from "react";
import ChartistGraph from "react-chartist";
import moment from "moment";
import StatsCard from "./Cards/StatsCard.js";
import ChartCard from "./Cards/ChartCard.js";
import { Util } from "../../infra";
import Grid from "react-1app/lib/View";
import GraficoPie from "../../components/GraficoPie";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "./Cards/charts";

import * as Relatorios from "../../worker/relatorio";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      empresa: {},
      loadEstatisca: false,
      formatEstatistica: [],
      code: "",
      cupom: {},
      dadosPeriodo: {},
      dadosStatus: {},
      dadosSemanais: [],
      dadosMensais: [],
      empresa: []
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  componentWillUnmount() {}

  buscarDados() {
    Relatorios.totalizadores({}, (dadosPeriodo = {}, error) => {
      this.setState({ dadosPeriodo });
      Relatorios.totalizadoresDiasSemana({}, (dadosSemanais = [], error) => {
        this.setState({ dadosSemanais});
        Relatorios.totalizadoresMes({}, (dadosMensais = [], error) => {
          this.setState({ dadosMensais });
          Relatorios.totalizadoresEmpresas({}, (empresa = [], error) => {
            let list=[]
            for (var i = 0; i < empresa.length; i++) {
              if (i < 15) {
                empresa[i].name=Util.subString(empresa[i].name,15)
                list.push(empresa[i])
              }else if (i==15) {
                list.push({name:"Outros",value:empresa[15].value})
              }else {
                list[15].value+=empresa[i].value
              }
            }
            this.setState({ empresa:list });
          });
        });
      });
    });
  }

  getHeight(list, name, add) {
    add = add ? add : 30;
    let init = 0;
    if (list && list[0]) {
      let newL = list.map(d => ({ iTotal: d[name] }));
      for (var i = 0; i < newL.length; i++) {
        let t = Number(newL[i].iTotal);
        // console.log(newL[i]);
        if (t > init) init = t;
      }
    }
    return init + init * 0.05;
  }

  retornarPorcentagem(atual = 0, anterior = 0) {
    let total = ((atual - anterior) / anterior) * 100;
    return Number(total.toFixed(2)) || 0;
  }

  retornarPorcentagemConversao(atual = 0, anterior = 0) {
    let total = (atual / anterior) * 100;
    return total.toFixed(2);
  }

  calculaDiferencaConversao() {
    let { concluido_atual, concluido_anterior, total_atual, total_anterior } =
      this.state.dadosPeriodo.conversao || {};
    return (
      Number(
        this.retornarPorcentagem(
          this.retornarPorcentagemConversao(concluido_atual, total_atual),
          this.retornarPorcentagemConversao(concluido_anterior, total_anterior)
        )
      ) || 0
    );
  }

  calculaValorAtualConversao() {
    let { concluido_atual, total_atual } =
      this.state.dadosPeriodo.conversao || {};
    return (
      Number(this.retornarPorcentagemConversao(concluido_atual, total_atual)) ||
      0
    );
  }

  invertePorcentagemNegativa(porcentagem) {
    return Number((porcentagem < 0 && porcentagem * -1) || porcentagem) || 0;
  }

  tratarListSemanal(lista = []) {
    let data = { labels: [], series: [] };
    data.labels = lista.map(item =>
      moment(item.mes, "YYYY-MM-DD").set("hour",12).format("ddd")
    );
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = [lista.map(item => item.total)];

    return data;
  }

  tratarList(lista = []) {
    let data = { labels: [], series: [] };
    data.labels = lista.map(item =>
      moment(item.mes + "/02", "YYYY-MM-DD").format("MMM")
    );
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = [lista.map(item => item.total)];

    return data;
  }

  tratarDadosCias(lista) {
    let data = { labels: [], series: [] };
    let newLista = lista.filter(i => i.venda);
    data.labels = newLista.map((x, i) => i + 1 + "");
    data.dados = newLista.map(item => ({ name: item.name, value: item.venda }));
    // console.log(lista.map(item => "17/" + item.mes));
    data.series = newLista.map(item => item.venda || 0);

    return data;
  }

  render() {
    console.log(this.tratarListSemanal(this.state.dadosSemanais));
    return (
      <Grid container style={{ marginTop: -30 }}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"business"}
              iconColor="darkgray"
              title={"Empresa cadastrados"}
              description={(this.state.dadosPeriodo.empresa || {}).total || 0}
              statText={
                ((this.state.dadosPeriodo.empresa || {}).total_semana || 0) +
                " Última semana"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"folder_shared"}
              iconColor="darkgray"
              title={"Inspeções"}
              description={(this.state.dadosPeriodo.inspecoes || {}).total || 0}
              statText={
                ((this.state.dadosPeriodo.inspecoes || {}).total_semana || 0) +
                " Última semana"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"settings_applications"}
              iconColor="darkgray"
              title={"Equipamentos"}
              description={
                (this.state.dadosPeriodo.equipamento || {}).total || 0
              }
              statText={
                ((this.state.dadosPeriodo.equipamento || {}).total_semana ||
                  0) + " Última semana"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              icon={"people"}
              iconColor="darkgray"
              title={"Usuarios cadastrados"}
              description={(this.state.dadosPeriodo.usuarios || {}).total || 0}
              statText={
                ((this.state.dadosPeriodo.usuarios || {}).total_semana || 0) +
                " Última semana"
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart"
                  data={this.tratarListSemanal(this.state.dadosSemanais)}
                  type="Line"
                  options={{
                    ...dailySalesChart.options,
                    high: this.getHeight(this.state.dadosSemanais, "total"),
                    height: 300
                  }}
                  listener={dailySalesChart.animation}
                />
              }
              chartColor="darkgray"
              title={"Inspeções por dia"}
              text={"Baseado na última semana"}
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <ChartistGraph
                  className="ct-chart"
                  data={this.tratarList(this.state.dadosMensais)}
                  // data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={{
                    ...emailsSubscriptionChart.options,
                    high: this.getHeight(this.state.dadosMensais, "total"),
                    height: 300
                  }}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              }
              chartColor="darkgray"
              title={"Inspeções por mês"}
              text="Baseado nos últimos 12 meses"
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ChartCard
              chart={
                <GraficoPie
                  className="ct-chart"
                  data={this.state.empresa}
                  fill={
                    this.props.screenProps.store.getState().theme != "light"
                      ? "#001e60"
                      : "rgb(205,155,56)"
                  }
                />
              }
              chartColor="darkgray"
              title="Representabilidade"
              text="Baseado nos últimos 12 meses"
              statIcon={"access_time"}
              statIconColor="darkgray"
              statText={
                "Atualizado em " +
                moment().format("DD") +
                " de " +
                moment().format("MMMM") +
                " de " +
                moment().format("YYYY")
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
