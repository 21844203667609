import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import DadosListas from "./Dados";
import Table from "react-1app/lib/Table";
import Select from "../../components/SelectInput";
import SelectInput from "react-1app/lib/SelectInput";
import Icon from "react-1app/lib/Icon";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipamentos: new Dados("equipamentos").parse(this.props.equipamentos),
      load: false,
      value: this.props.equipamentos.objectId ? 1 : 0
    };
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.equipamentos.salvarNuven(re => {
      this.setState({
        load: false,
        equipamentos: re
      });
      if (this.props.atualizar) this.props.atualizar(this.state.equipamentos);
      if (this.props.equipamentos) {
        for (var i in re) {
          this.props.equipamentos[i] = re[i];
        }
      }
      this.props.closeModal();
    });
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.equipamentos.status = 3;
          this.setState({
            load2: true
          });
          this.state.equipamentos.salvarNuven(re => {
            this.setState({
              load2: false
            });
            this.props.closeModal();
            if (this.props.atualizar) this.props.atualizar();
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={7} item>
          <View style={styles.tela} type={"Paper"}>
            <View>
              <Text style={styles.label}>{"Equipamento"}</Text>
              <Tabs
                value={this.state.value}
                onChange={(ii, value) => this.setState({ value })}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Tipo" disabled={this.state.equipamentos.objectId} />
                <Tab label="Geral" />
                <Tab label="Classe" />
                <Tab label="Pressões" />
              </Tabs>
            </View>

            {this.state.value == 0 ? (
              <Etapa1 equipamentos={this.state.equipamentos} />
            ) : null}
            {this.state.value == 1 ? (
              <Etapa2 equipamentos={this.state.equipamentos} />
            ) : null}
            {this.state.value == 2 ? (
              <Etapa3 equipamentos={this.state.equipamentos} />
            ) : null}
            {this.state.value == 3 ? (
              <Etapa4 equipamentos={this.state.equipamentos} />
            ) : null}
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.setState({ value: this.state.value - 1 })}
                disabled={
                  this.state.value == 0 ||
                  (this.state.equipamentos.objectId && this.state.value == 1)
                }
              >
                <Icon name={"keyboard_arrow_left"} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.deletar()}
                children={"Delete"}
                color={"secondary"}
                variant="outlined"
                style={styles.delete}
                disabled={!this.state.equipamentos.objectId}
                load={this.state.load2}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                disabled={
                  !this.state.equipamentos.objectId && this.state.value < 3
                }
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.setState({ value: this.state.value + 1 })}
                disabled={this.state.value == 3}
                style={styles.delete}
              >
                <Icon name={"keyboard_arrow_right"} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

class Etapa1 extends Component {
  state = {
    list: [
      { value: 1, label: "Vasos de Pressão" },
      { value: 2, label: "Caldeira" },
      { value: 3, label: "Trocador de Calor" },
      { value: 4, label: "Tubulação" }
    ]
  };

  render() {
    return (
      <View style={styles.options}>
        {this.state.list.map((item, index) => (
          <TouchableOpacity
            style={styles.option}
            onPress={() => {
              this.props.equipamentos.equipamento = item.value;
              this.setState({ value: item.value });
            }}
            children={item.label}
            variant={
              item.value == this.props.equipamentos.equipamento
                ? "contained"
                : "outlined"
            }
            fullWidth
          ></TouchableOpacity>
        ))}
      </View>
    );
  }
}

class Etapa2 extends Component {
  onChange = tag => value => {
    this.props.equipamentos[tag] = value;
    this.setState({ tag });
  };
  state = {};

  render() {
    let inputs = [
      {
        label: "Denominação",
        value: this.props.equipamentos.denominacao,
        onChange: this.onChange("denominacao")
      },
      {
        label: "Código identificação",
        onChange: this.onChange("codigo"),
        value: this.props.equipamentos.codigo
      },
      {
        label: "Fabricante",
        onChange: this.onChange("fabricante"),
        value: this.props.equipamentos.fabricante
      },
      {
        label: "Nº de Série",
        onChange: this.onChange("nserie"),
        value: this.props.equipamentos.nserie
      },
      {
        label: "Ano de fabricação",
        onChange: this.onChange("anofabric"),
        value: this.props.equipamentos.anofabric
      },
      {
        label: "Código de Projeto",
        onChange: this.onChange("cod_fabricacao"),
        value: this.props.equipamentos.cod_fabricacao
      },
      {
        label: "Ano de Edição",
        onChange: this.onChange("anoedicao"),
        value: this.props.equipamentos.anoedicao
      },
      {
        label: "Comentários - Identificação do Equipamento",
        onChange: this.onChange("comentario"),
        value: this.props.equipamentos.comentario
      }
    ];
    let inputsTubulacao = [
      {
        label: "Denominação",
        value: this.props.equipamentos.denominacao,
        onChange: this.onChange("denominacao")
      },
      {
        label: "Tag",
        onChange: this.onChange("codigo"),
        value: this.props.equipamentos.codigo
      },
      {
        label: "Fabricante",
        onChange: this.onChange("fabricante"),
        value: this.props.equipamentos.fabricante
      },
      {
        label: "Ano de fabricação",
        onChange: this.onChange("anofabric"),
        value: this.props.equipamentos.anofabric
      },
      {
        label: "Código de Projeto",
        onChange: this.onChange("cod_fabricacao"),
        value: this.props.equipamentos.cod_fabricacao
      }
    ];
    return (
      <View style={styles.options}>
        {(
          (this.props.equipamentos.equipamento == 4 && inputsTubulacao) ||
          inputs
        ).map((props, index) => (
          <TextInput key={index + "input"} {...props} />
        ))}
      </View>
    );
  }
}

class Etapa3 extends Component {
  onChange = tag => value => {
    this.props.equipamentos[tag] = value;
    this.setState({ tag });
  };
  state = {};

  render() {
    let inputs = {
      1: [
        {
          label: "Finalidade do Equipamento",
          value: this.props.equipamentos.finalidade,
          onChange: this.onChange("finalidade")
        },
        {
          label: "Fluido",
          onChange: this.onChange("fluido"),
          value: this.props.equipamentos.fluido
        },
        {
          label: "Classe do Fluido",
          onChange: this.onChange("classe"),
          value: this.props.equipamentos.classe,
          list: DadosListas.classe_lista
        },
        {
          label: "Grupo Potencial de Risco",
          onChange: this.onChange("grupo"),
          value: this.props.equipamentos.grupo,
          list: DadosListas.grupo_lista
        },
        {
          label: "Categoria",
          onChange: this.onChange("categoria"),
          value: this.props.equipamentos.categoria,
          list: DadosListas.categoria_lista
        },
        {
          label: "Temperatura De Operação",
          onChange: this.onChange("temp_operacao"),
          value: this.props.equipamentos.temp_operacao
        },
        {
          label: "Regime de trabalho",
          onChange: this.onChange("regime_trabalho"),
          value: this.props.equipamentos.regime_trabalho,
          list: DadosListas.regime_trabalho_lista
        }
      ],
      2: [
        {
          label: "Categoria",
          onChange: this.onChange("categoria"),
          value: this.props.equipamentos.categoria,
          list: DadosListas.selectinput4_lista
        },
        {
          label: "Tipo",
          onChange: this.onChange("tipo"),
          value: this.props.equipamentos.tipo
        },
        {
          label: "Superfície de Aquecimento (m²)",
          onChange: this.onChange("fluido"),
          value: this.props.equipamentos.fluido
        },
        {
          label: "Produção de Vapor (kg/h)",
          onChange: this.onChange("pvapor"),
          value: this.props.equipamentos.pvapor
        }
      ],
      3: [
        {
          label: "Finalidade do Equipamento",
          onChange: this.onChange("finalidade"),
          value: this.props.equipamentos.finalidade
        },
        {
          label: "Fluido - Casco",
          onChange: this.onChange("fluido"),
          value: this.props.equipamentos.fluido
        },
        {
          label: "Fluido - Tubos",
          onChange: this.onChange("fluido2"),
          value: this.props.equipamentos.fluido2
        },
        {
          label: "Classe do Fluido",
          onChange: this.onChange("classe"),
          value: this.props.equipamentos.classe,
          list: DadosListas.selectinput5_lista
        },
        {
          label: "Grupo Potencial de Risco",
          onChange: this.onChange("grupo"),
          value: this.props.equipamentos.grupo,
          list: DadosListas.selectinput6_lista
        },
        {
          label: "Categoria",
          onChange: this.onChange("categoria"),
          value: this.props.equipamentos.categoria,
          list: DadosListas.selectinput7_lista
        },
        {
          label: "Temperatura de Operação - Casco",
          onChange: this.onChange("temp_operacao"),
          value: this.props.equipamentos.temp_operacao
        },
        {
          label: "Temperatura de Operação - Tubos",
          onChange: this.onChange("temp_operacao2"),
          value: this.props.equipamentos.temp_operacao2
        },
        {
          label: "Regime de trabalho",
          onChange: this.onChange("regime_trabalho"),
          value: this.props.equipamentos.regime_trabalho,
          list: DadosListas.selectinput8_lista
        }
      ],
      4: [
        {
          label: "Fluido",
          onChange: this.onChange("fluido"),
          value: this.props.equipamentos.fluido
        },
        {
          label: "Classe de Serviço",
          onChange: this.onChange("grupo"),
          value: this.props.equipamentos.grupo
        },
        {
          label: "Finalidade do Tubulação",
          value: this.props.equipamentos.finalidade,
          onChange: this.onChange("finalidade")
        },
        {
          label: "Categoria NR13",
          onChange: this.onChange("categoria"),
          value: this.props.equipamentos.categoria
        },
        {
          label: "Material",
          onChange: this.onChange("tipo"),
          value: this.props.equipamentos.tipo
        }
      ]
    };
    return (
      <View style={styles.options}>
        {inputs[this.props.equipamentos.equipamento || 1].map(
          (props, index) => {
            if (props.list)
              return (
                <SelectInput
                  key={index + "input"}
                  key_label={"text"}
                  key_value={"value"}
                  {...props}
                />
              );
            return <TextInput key={index + "input"} {...props} />;
          }
        )}
      </View>
    );
  }
}

class Etapa4 extends Component {
  state = {};
  deleteCom(item) {
    var lista = this.props.equipamentos.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      if (foco.objectId == item.objectId) {
        this.props.equipamentos.lista.splice(i, 1);
        break;
      }
    }
    this.setState({ alterado: true });
  }

  add() {
    try {
      if (!this.props.equipamentos.lista) {
        this.props.equipamentos.lista = [];
      }
      var novo = {};
      novo.objectId = new Date().getTime() + "key";
      this.props.equipamentos.lista.push(novo);
      this.setState({ novo: true });
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  caluclar(item, mpa) {
    mpa = Number(mpa);
    item.mpa = mpa;
    item.psi = mpa * 145.0377;
    item.bar = mpa * 10;
    // item.pa=mpa*1000000;
    item.kgf = mpa * 10.19716;
    item.mpa = item.mpa.toFixed(2);
    item.psi = item.psi.toFixed(2);
    item.bar = item.bar.toFixed(2);
    // item.pa=item.pa.toFixed(2);
    item.kgf = item.kgf.toFixed(2);
    // console.log(item.mpa.toFixed(2));
    this.setState({ alterado: true });
    // formulas
    // psi= Mpa x 145.0377
    // MPa ÷ 0.00689475729 = psi
    // bar=mpa x 10
    // kg/cm² = mpa x 10.19716
    // pa = mpa x 1000000
    // mpa = pa / 1000000
  }

  render() {
    let lista =
      DadosListas.descricao_lista[this.props.equipamentos.equipamento || 1];
    return (
      <View style={styles.options}>
        <Table
          tableHead={["Descrição", "kgf/cm²", "bar", "psi", "MPa", "Deletar"]}
          tableData={(this.props.equipamentos.lista || []).map(
            (item, index) => [
              <Select
                style={styles.selectinput}
                value={item.descricao}
                key_label={"text"}
                key_value={"value"}
                list={lista}
                onChange={(value, data, index) => {
                  item.descricao = value;
                  this.setState({
                    equipamentos: this.state.equipamentos
                  });
                }}
                ref={v => (this.selectinput = v)}
              />,
              <TextInput
                style={styles.input}
                placeholder={"Valor"}
                onChange={value => {
                  item.kgf = decimal(value);
                  this.setState({ value });
                }}
                onSubmitEditing={() => {
                  item.mpa = item.kgf / 10.19716;
                  this.caluclar(item, item.mpa);
                }}
                type="number"
                value={item.kgf}
                inputNative
              />,
              <TextInput
                style={styles.input}
                placeholder={"Valor"}
                onChange={value => {
                  item.bar = decimal(value);
                  this.setState({ value });
                }}
                value={item.bar}
                type="number"
                inputNative
                onSubmitEditing={() => {
                  item.mpa = item.bar / 10;
                  this.caluclar(item, item.mpa);
                }}
              />,
              <TextInput
                style={styles.input}
                placeholder={"Valor"}
                onChange={value => {
                  item.psi = decimal(value);
                  this.setState({ value });
                }}
                value={item.psi}
                type="number"
                inputNative
                onSubmitEditing={() => {
                  item.mpa = item.psi / 145.0377;
                  this.caluclar(item, item.mpa);
                }}
              />,
              <TextInput
                style={styles.input}
                placeholder={"Valor"}
                type="number"
                onChange={value => {
                  item.mpa = decimal(value);
                  this.setState({ value });
                }}
                value={item.mpa}
                inputNative
                onSubmitEditing={() => {
                  this.caluclar(item, item.mpa);
                }}
              />,
              <TouchableOpacity
                icon
                onPress={() => {
                  this.deleteCom(item);
                }}
              >
                <Icon name={"delete"} />
              </TouchableOpacity>
            ]
          )}
          title={"Lista de Pressões"}
          actionBar={
            <TouchableOpacity
              children={"Novo"}
              variant="outlined"
              onPress={() => {
                this.add();
              }}
            />
          }
        />
      </View>
    );
  }
}

function decimal(value) {
  return Number(value.replace(",", "."));
}

var styles = StyleSheet.create({
  input: {
    width: 100
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  options: {
    flexDirection: "column"
  },
  option: {
    height: 70,
    marginTop: 15
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 15,
    marginTop: 20
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
