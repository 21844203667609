import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Input from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import Ferrament from "./Ferrament";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: [],
      load: true,
      item: null
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("feramenta");

    query.addAscendingOrder("nome");
    query.select(lista => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }



  novo() {
    this.setState({
      item:new Dados("feramenta"),
      load: false
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          {!this.state.item ? (
            <View style={styles.tela} type={"Paper"}>
              <View>
                <Text style={styles.label}>{"Lista de Ferramentas"}</Text>
                <TouchableOpacity
                  style={{ marginLeft: "auto" }}
                  onPress={() => this.novo()}
                  icon
                >
                  <Icon name={"add"} />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.props.closeModal()}
                  style={{ marginLeft: 10 }}
                  children={"Fechar"}
                  color={"default"}
                  variant="outlined"
                ></TouchableOpacity>
              </View>
              <ListView
                renderItem={({ item, index }) => {
                  return (
                    <ListItem key={index} style={{ width: "100%" }}>
                      <Avatar>
                        <Icon
                          style={styles.icon2}
                          fromFontFamily={"Material Icons"}
                          icon={"format_list_numbered"}
                        />
                      </Avatar>
                      <ListItemText
                        primary={item.nome}
                        secondary={item.n_cert}
                      />
                      <TouchableOpacity
                        onClick={() => {
                          this.setState({ item });
                        }}
                        color={"default"}
                        icon
                      >
                        <Icon
                          fromFontFamily={"Material Design Icons"}
                          name={"chevron-right"}
                        />
                      </TouchableOpacity>
                    </ListItem>
                  );
                }}
                separator
                nativo
                data={this.state.list}
                load={this.state.load}
              />
            </View>
          ) : null}
          {this.state.item ? (
            <Ferrament
              {...this.props}
              feramenta={this.state.item}
              activity={this}
              closeModal={() => {
                this.setState({ item: null });
              }}
            />
          ) : null}
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
