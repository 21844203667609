"use strict";
import React, { Component } from "react";

import {
  Modal,
  MapView,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  TextInput,
  SelectInput,
  TitleBar,
  File,
  FotosUpload,
  Endereco
} from "react-1app";
import {
  Progresso,
  Imagem,
  Fotos,
  Image,
  Text,
  ListView,
  Alert,
  Navigator,
  Switch,
  View,
  moment,
  banco,
  ApiUteis
} from "react-1app";
import {
  TabView,
  DrawerLayout,
  ImageUpload,
  StyleSheet,
  GridView,
  Icon,
  Fragment,
  RefreshControl,
  PageView,
  DatePicker,
  QRCode
} from "react-1app";

import { Util, Dados, Query } from "../../infra";

//nao atualizar
export default class OpcSelet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {}
    };
    Dados.clonarHeranca(props, this.state);
    //{onConstructor}
  }

  deleteSelet(item) {
    var itens = this.state.item.itens;
    for (var i = 0; i < itens.length; i++) {
      var foco = itens[i];
      if (foco.objectId == item.objectId) {
        // console.log(i,to);
        this.state.item.itens.splice(i, 1);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.setState({ alterado: true });
  }

  salvarlist() {
    var item = {};
    if (!this.state.item.itens) {
      this.state.item.itens = [];
    }

    item.objectId = new Date().getTime() + "key";
    item.value = "";
    item.data = {};
    this.state.item.itens.push(item);

    // console.log(this.state.item.itens);
    this.setState({ alterado: true });
  }

  criarLista() {
    var itensList = [];
    if (this.state.item.itens) {
      for (var i = 0; i < this.state.item.itens.length; i++) {
        let item = this.state.item.itens[i];
        itensList.push(
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap"
            }}
          >
            <TextInput
              style={styles.itens}
              value={item.value}
              onChange={value => {
                item.value = value;
                item.text = value;
                this.setState({
                  item: this.state.item
                });
              }}
              ref={v => (this.itens = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Opções"}
            />

            <TouchableOpacity
              style={styles.bottom4}
              onPress={() => {
                this.deleteSelet(item);
              }}
            >
              <Icon
                style={styles.icon3}
                fromFontFamily={"Material Icons"}
                icon={"close"}
              />
            </TouchableOpacity>
          </View>
        );
      }
    }
    return itensList;
  }

  back_pg_bottom() {
    if (this.state.navigator) {
      this.state.navigator.closeModal();
    }
    if (this.state.superNavigator) {
      this.state.navigator.closeModal();
    }
  }
  componentDidMount() {
    // console.log(this.state.item.itens);
    //{onDidMount}
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }

  salvar() {
    this.back_pg_bottom();
    this.props.activity.salvar();
  }
  alterar() {
    if (this.state.item.lista) {
      this.state.item.lista = false;
      this.setState({
        item: this.state.item
      });
    } else {
      this.state.item.lista = true;
      this.setState({
        item: this.state.item
      });
    }
  }
  render() {
    return (
      <View style={styles.tela}>
        <TitleBar style={styles.titlebar}>
          <TouchableOpacity
            style={styles.bottom}
            onPress={() => {
              this.back_pg_bottom();
            }}
          >
            <Icon
              style={styles.icon}
              fromFontFamily={"Material Design Icons"}
              icon={"close"}
            />
          </TouchableOpacity>
          <Text style={styles.label} text={"Opções"} />
          <TouchableOpacity
            style={styles.bottom}
            onPress={() => {
              this.alterar();
            }}
          >
            {!this.state.item.lista ? (
              <Icon
                style={styles.icon}
                fromFontFamily={"Material Icons"}
                icon={"radio_button_unchecked"}
              />
            ) : null}
            {this.state.item.lista ? (
              <Icon
                style={styles.icon}
                fromFontFamily={"Material Icons"}
                icon={"check_circle"}
              />
            ) : null}
          </TouchableOpacity>
        </TitleBar>
        <ScrollView
          style={styles.scroll1}
          keyboardDismissMode={"interactive"}
          horizontal={false}
        >
          <View style={styles.view3}>
            {this.criarLista()}
            <View style={styles.view4}>
              <TouchableOpacity
                style={styles.bottom10}
                onPress={() => {
                  this.salvarlist();
                }}
              >
                <Icon
                  style={styles.icon10}
                  fromFontFamily={"Material Icons"}
                  icon={"add"}
                />
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <View style={styles.view}>
          <View style={styles.view1}>
            <TouchableOpacity
              style={styles.bottom1}
              onPress={() => {
                this.salvar();
              }}
            >
              <Icon
                style={styles.icon1}
                fromFontFamily={"Material Icons"}
                icon={"save"}
              />
              <Text style={styles.label1} text={"Salvar"} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    minWidth: 500,
    maxWidth: 500,
    minHeight: 500,
    maxHeight: 800
  },
  titlebar: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "stretch",
    height: 50,
    backgroundColor: "rgba(7,81,120,1)"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50,
    position: "relative"
  },
  bottom4: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50
  },
  icon3: {
    fontSize: 30,
    color: "rgba(0,0,0,1)"
  },
  icon: {
    fontSize: 30,
    color: "rgba(255,255,255,1)"
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  bottom10: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    flexDirection: "column",
    flexWrap: "nowrap",
    borderRadius: 18,
    width: 36,
    backgroundColor: "rgba(7,81,120,1)",
    margin: 5
  },
  icon10: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontSize: 20,
    flex: 1
  },
  lista: {
    marginTop: 17,

    maxHeight: 20
  },
  scroll1: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  view3: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 10
  },
  itens: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    textAlign: "left"
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    padding: 20
  },
  bottom1: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    width: 250,
    backgroundColor: "rgba(7,81,120,1)",
    marginBottom: 20,
    position: "relative"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    marginLeft: 5,
    fontSize: 14
  }
});
