
'use strict';
import React,{Component} from 'react';
import ChartistGraph from 'react-chartist';
import {Dados,Navigator,moment,View,Progress,Text } from "react-1app";
import ChartCard from '../dashboard/Cards/ChartCard'

var Chartist = require('chartist');
// let avatar = require('/img/prof.png')


//nao atualizar
export default class GraficoInspetor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista:[],
      loadGrafico:true,
      semDados:false
    };

  }

  getHeigh(list, name, add) {
    add = add ? add : 10;
    let init = 0
    if (list && list[0]) {
      let newL = list.map(d => ({ iTotal: d[name] }))
      for (var i = 0; i < newL.length; i++) {
        let t = Number(newL[i].iTotal)
        // console.log(newL[i]);
        if (t > init) init = t;
      }
    }
    return (init + add);
  }
  mormalizarValorList(list=[]) {

    let NewLabel = []
    let NewSerial = []
    if (list && list[0]) {
      for (var i = 0; i < list.length; i++) {
        let item = list[i]
        NewLabel.push(item.dia);
        NewSerial.push(item.total);
      }
    }
    // console.log({labels:NewLabel,series:[NewSerial]});
    return { labels: NewLabel, series: [NewSerial] };
  }

  render() {

    return (
      <ChartCard
        chart={
          <ChartistGraph
            className="ct-chart"
            data={this.mormalizarValorList(this.props.list)}
            type="Bar"
            options={Object.assign(dailySalesChart.options,{high: this.getHeigh(this.state.lista,"inspecao")})}
            listener={
              dailySalesChart.animation
            }
            >
            {(this.props.loadGrafico||this.props.semDados)&&
              <View style={{
                position:"absolute",
                top:0,
                right:-16,
                bottom:-5,
                alignItems: "center",
                justifyContent: "center",
                left:-16,
                zIndex:300,
                backgroundColor: "rgba(255,255,255,0.5)",
              }}>
                {this.state.loadGrafico&&  <Progress/>}
                {this.state.semDados&& <Text text={"Sem Dados para o gráfico"}></Text>}
              </View>}
            </ChartistGraph>

        }
        chartColor="mega"
        title="Produção"
        action={this.props.action}
        // statText={"Producao por mês - Período de 1 ano. Atualizado em " + moment().format("DD") + " de " + moment().format("MMMM") + " de " + moment().format("YYYY")+"."+this.total(this.state.lista)}
        />

    );
  }
}
var delays = 80,
  durations = 500;

const dailySalesChart = {
  data: {
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    series: [
      [12, 17, 7, 17, 23, 18, 38]
    ]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation : {
    "draw" : function(data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    }
  }
}
