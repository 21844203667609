import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import File from "../../components/File";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import TextInput from "react-1app/lib/TextInput";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query, Util } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import CadastorEquipamento from "../equipamento/Edit";
import MoverEquipamentoInspecao from "../equipamento/MoverEquipamentoInspecao";
import Input from "../../components/Input";

export default class UploadArquivos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes)
    };
  }

  componentDidMount() {
    if (this.state.inspecoes.id) this.buscarDados();
  }

  buscarDados(callback) {
    Cloud.get(
      "inspecoes",
      { id: this.state.inspecoes.id, objectId: this.state.inspecoes.objectId },
      (inspecoes = {}, error) => {
        this.setState({ inspecoes: new Dados("inspecoes").parse(inspecoes) });
      }
    );
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.inspecoes.setMetodoApi("salvarWeb", "POST");
    this.state.inspecoes.salvarNuven(re => {
      if (re.objectId || re.id) {
        OpenSnack({ message: "Salvo", type: "success" });
        this.props.closeModal();
        if(this.props.atualizar)this.props.atualizar();
      }
      this.setState({
        load: false
      });
    });
  }

  add() {
    try {
      if (!this.state.inspecoes.lista) {
        this.state.inspecoes.lista = [];
      }
      var novo = {};
      // novo = JSON.parse(novo);
      novo.objectId = new Date().getTime() + "key";
      novo.value = "";
      novo.data = {};

      // console.log(novo);
      this.state.inspecoes.lista.push(novo);
      this.setState({ novo: true });
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  deleteSelet(item) {
    Alert.alert("Deletar", "Deseja deletar este registro", [
      {
        text: "Não",
        onPress: null
      },
      {
        text: "Sim",
        onPress: () => {
          var lista = this.state.inspecoes.lista;
          for (var i = 0; i < lista.length; i++) {
            var foco = lista[i];
            if (foco.objectId == item.objectId) {
              // console.log(i,to);
              this.state.inspecoes.lista.splice(i, 1);
              this.setState({ novo: true });
              break;
            }
          }
        }
      }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Upload de Inspeção"}</Text>
            <TextInput
              style={styles.codigo}
              value={this.state.inspecoes.codigo}
              disabled={
                !this.state.usuario.user && !this.state.usuario.market
                  ? false
                  : true
              }
              onChange={value => {
                this.state.inspecoes.codigo = value;
                this.setState({ inspecoes: this.state.inspecoes });
              }}
              keyboardType={"default"}
              label={"Codigo (OS)"}
            />
            <ListView
              ListHeaderComponent={() => (
                <View>
                  <Text style={styles.label2} text={"Arquivos da inspeção"} />
                  {!this.state.usuario.user && !this.state.usuario.market ? (
                    <TouchableOpacity
                      style={styles.bottom3}
                      onPress={() => {
                        this.add();
                      }}
                      icon
                    >
                      <Icon
                        style={styles.icon}
                        fromFontFamily={"Material Icons"}
                        icon={"add"}
                      />
                    </TouchableOpacity>
                  ) : null}
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem key={index} style={{ width: "100%" }}>
                    <View style={{ flexDirection: "column" }}>
                      <Input
                        style={{ width: "100%" }}
                        value={item.descricao}
                        onChange={value => {
                          item.descricao = value;
                          this.setState({ item: {} });
                        }}
                        placeholder={"Descrição"}
                      />
                      {!this.state.usuario.user &&
                      !this.state.usuario.market ? (
                        <File
                          style={styles.arquivo}
                          value={item.arquivo}
                          onChange={value => {
                            item.arquivo = value;
                            this.setState({
                              item: this.state.item
                            });
                          }}
                          ref={v => (this.arquivo = v)}
                          label={"Upload do arquivo"}
                        />
                      ) : null}
                    </View>
                    <TouchableOpacity
                      onClick={() => {
                        Util.web(item.arquivo);
                      }}
                      icon
                      disabled={!item.arquivo}
                    >
                      <Icon
                        fromFontFamily={"Material Icons"}
                        name={"cloud_download"}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onClick={() => {
                        this.deleteSelet(item);
                      }}
                      color={"secondary"}
                      icon
                    >
                      <Icon fromFontFamily={"Material Icons"} name={"delete"} />
                    </TouchableOpacity>
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.inspecoes.lista}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                style={styles.delete}
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  arquivo:{
    backgroundColor:"none"
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  bottom3: {
    marginLeft: "auto"
  }
});
