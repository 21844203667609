import { Cloud } from "../infra";

export function gerarPdfAssinado({tag, assinado,key_inspecoes, key_user }, callback) {
  Cloud.post(
    "assinaweb",
    {
      key_inspecoes,
      key_user,
      assinado,
      tag
    },
    (data, error) => {
      callback(data, error);
    }
  );
}


export function gerarPdfInspesao({ key_inspecoes, key_user }, callback) {
  Cloud.post(
    "pdf/inspecao",
    {
      key_inspecoes,
      key_user
    },
    (data, error) => {
      callback(data, error);
    }
  );
}

export function gerarPdfInspesaoInp({ assinado,key_inspecoes, key_user }, callback) {

    Cloud.get("pdf/inspecao/impressao",
    {
      key_inspecoes,
      key_user,
      assinado
    },
    (data, error) => {
      callback(data, error);
    }
  );
}

export function pdfComerialNR13(input,callback) {
  Cloud.post("pdf/comercial/market",input,(res,error)=>{
    callback(res,error)
  });
}
export function pdfComerialREC(input,callback) {

  Cloud.post("pdf/comercial/recomendacoes/market",input,(res,error)=>{
    callback(res,error)
  });
}
export function excelComerial(input,callback) {

  Cloud.post("pdf/comercial/markexlsx",input,(res,error)=>{
    callback(res,error)
  });
}

export function pdfValvulas(input,callback) {
  Cloud.post("pdf/valvulas/instrumentos",input,(res,error)=>{
    callback(res,error)
  });
}

export function excelComerialVencimento(input,callback) {
  Cloud.post("pdf/comercial/markexlsx/vencimento",input,(res,error)=>{
    callback(res,error)
  });
}


export function protocoloEnvio(input,callback) {
  Cloud.post("envio/protocolo/os",input,(res,error)=>{
    callback(res,error)
  });
}

export function protocoloEnvioLista(input,callback) {
  Cloud.get("envio/protocolo/os",input,(res,error)=>{
    callback(res,error)
  });
}

export function montarProtocolo(input,callback) {
  Cloud.get("envio/protocolo/os/ver",input,(res,error)=>{
    callback(res,error)
  });
}
