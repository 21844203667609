import React, { Component } from "react";
import { StyleSheet, View } from "react-1app"; ///  <KeyboardAvoidingView behavior={"padding"}
import { TextInput, SelectInput } from "react-1app";
import { Imagem, Switch } from "react-1app";
import {
  TouchableOpacity,
  RefreshControl,
  Icon,
  ListView,
  Text,
  Image
} from "react-1app";
import Comp2 from "./Comp2";
import TextPadrao from "./TextPadrao";
import SugOcorrencias from "./SugOcorrencia";
import Cel from "react-1app/lib/View"; //"./Cel";
import * as ActionsComp from "./actions_comp";
import PhotosUpload from "react-1app/lib/PhotosUpload";
import { Dados } from "../../infra";
import moment from "moment";
import Select from "../../components/SelectInput";
import Input from "../../components/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "../../components/DatePicker";
import EdiOcorrencia from "./EdiOcorrencia";

export default class Comp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: new Dados("dados"),
      load_listview: false,
      ...this.props
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.onDidMount();
    }, 2000);
  }
  onDidMount() {
    if (this.props.item.code.test) {
      if (
        this.props.item.code.tag == "tbEQ" ||
        this.props.item.code.tag == "tb"
      ) {
        this.atualizar();
      }
      if (this.props.item.code.tag == "ocorrencias") {
        this.atualizar2();
      }
    }
  }

  deleteComIm(item, index) {
    var lista = this.props.ocorrencias.lista;
    if (item.objectId) {
      for (var i = 0; i < lista.length; i++) {
        var foco = lista[i];
        if (foco.objectId == item.objectId) {
          this.props.ocorrencias.lista.splice(i, 1);
          break;
        }
      }
    } else if (index) {
      this.props.ocorrencias.lista.splice(index, 1);
    }
    this.salvar_bottom2();
  }

  salvar_bottom2() {
    this.props.ocorrencias.key_inspecoes = this.props.inspecoes.objectId;
    this.setState({
      save: true
    });

    this.props.ocorrencias.setMetodoApi("salvar", "POST");
    this.props.ocorrencias.salvarNuven(re => {
      this.setState({
        save: false
      });
      // this.state.navigator.pop();
    });
  }

  //START CODE
  testarNet() {
    ActionsComp.saveOcorencias(this.props, () => {});
  }
  atualizar() {
    this.setState({
      dados: this.props.dados,
      load_listview: false,
      item: this.props.item
    });
  }
  atualizar2() {
    this.setState({
      ocorrencias: this.props.ocorrencias,
      load_listview: false
    });
  }
  // componentWillUnmount(){
  // }
  open_pg_bottom3() {
    this.props.navigator.openModal({
      component: SugOcorrencias,
      props: {
        novo: true,
        listaDados: {},
        oldState: this.props,
        activity: this,
        ocorrencias: this.props.ocorrencias,
        classe: this.props.item.code.lay.default
      }
    });
  }

  addIm(novo) {
    try {
      if (!this.props.ocorrencias.lista || !this.props.ocorrencias.lista[0]) {
        this.props.ocorrencias.lista = [];
      }
      novo.objectId = new Date().getTime() + "key";
      this.props.ocorrencias.lista.push(novo);
    } catch (e) {
      console.log(e);
    } finally {
    }
    this.salvar_bottom2();
  }
  deleteCom(item) {
    var lista = this.props.dados[this.props.item.objectId];
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      if (foco.objectId == item.objectId) {
        // console.log(i,to);
        this.props.dados[this.props.item.objectId].splice(i, 1);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.setState({ alterado: true });
    // this.setState({item:null})
  }

  usar(item) {
    // console.log(item.text);
    if (!this.props.dados[this.props.item.objectId]) {
      this.props.dados[this.props.item.objectId] = "";
    }
    this.props.dados[this.props.item.objectId] += item;
    this.setState({
      alterado: true
    });
  }
  open_modal_bottom2() {
    this.props.navigator.openModal({
      component: TextPadrao,
      props: {
        test: this.props.item.code.lay.textopadroes_id,
        oldState: this.props,
        activity: this
      }
    });
  }
  view1Column() {
    var listaC = this.props.item.code.lay.lista;
    if (listaC)
      return listaC.map((itemV, i) => {
        if (
          !(
            itemV.code.lay.culunm == 1 ||
            !itemV.code.lay.culunm ||
            itemV.code.lay.culunm > 2
          )
        )
          return null;
        if (itemV.code.lay.calculo) {
          ActionsComp.caluclar(itemV);
        }
        if (itemV.code.lay.test) {
          ActionsComp.test(itemV);
          // console.log(item.code.lay.test);
        } else {
          itemV.code.test = true;
        }
        return (
          <Comp2
            {...this.props}
            activity={this.props.activity}
            navigation={this.props.navigation}
            inspecoes={this.props.inspecoes}
            item={itemV}
            dados={this.props.dados}
            key={"item_3" + i + this.props.item.objectId}
          />
        );
      });
  }
  view2Column() {
    var listaC = this.props.item.code.lay.lista;
    if (listaC)
      return listaC.map((itemV, i) => {
        if (!(itemV.code.lay.culunm == 2)) return null;
        if (itemV.code.lay.calculo) {
          ActionsComp.caluclar(itemV);
        }
        if (itemV.code.lay.test) {
          ActionsComp.test(itemV);
          // console.log(item.code.lay.test);
        } else {
          itemV.code.test = true;
        }
        return (
          <Comp2
            {...this.props}
            activity={this.props.activity}
            navigation={this.props.navigation}
            inspecoes={this.props.inspecoes}
            item={itemV}
            dados={this.props.dados}
            key={"item_2" + i + this.props.item.objectId}
          />
        );
      });
  }

  open_modal_bottom4(item, index) {
    // console.log(this.state.navigator);
    this.state.navigator.openModal({
      component: EdiOcorrencia,
      props: {
        item: item,
        heranca: this.state,
        activity: this,
        index,
        lista: this.state.ocorrencias.lista
      }
    });
  }

  test2(item, foco) {
    ActionsComp.test2(item, foco, this.state);
  }
  criarTexttb() {
    var texttb = [];
    if (this.props.item.code.lay.lista) {
      for (var i = 0; i < this.props.item.code.lay.lista.length; i++) {
        let item = this.props.item.code.lay.lista[i];
        texttb.push(
          <Text
            key={"Tabtext_" + i}
            style={{
              textAlign: "center",
              flexWrap: "wrap",
              color: "rgba(0,0,0,1)",
              alignSelf: "stretch",
              flex: 1,
              fontSize: 12
            }}
            text={item.text ? item.text : "-"}
          />
        );
      }
    }
    return texttb;
  }
  add() {
    try {
      if (
        !this.props.dados[this.props.item.objectId] ||
        !this.props.dados[this.props.item.objectId][0]
      ) {
        this.props.dados[this.props.item.objectId] = [];
      }
      var novo = {};
      // novo = JSON.parse(novo);
      novo.objectId = new Date().getTime() + "key";
      this.props.dados[this.props.item.objectId].push(novo);
      this.setState({ novo: true });
    } catch (e) {
      console.log(e);
    } finally {
    }
  }
  render() {
    // console.log(this.props.ocorrencias);
    const { lay, test, tag } = this.props.item.code;
    // if(tag=="ocorrencias")console.log(this.props);
    if (!test) return null;
    switch (tag) {
      case "Text":
        return (
          <View style={styles2.view}>
            <Text
              style={[
                styles2.label1,
                {
                  textAlign: lay.position ? lay.position : "left",
                  fontSize: lay.font ? lay.font : 14,
                  fontWeight: lay.stilo ? lay.stilo : "400"
                }
              ]}
              text={lay.text}
            />
          </View>
        );
      case "fotosupload":
        return (
          <PhotosUpload
            style={styles2.objectId}
            description={true}
            value={this.props.dados[this.props.item.objectId]}
            onChange={value => {
              this.props.dados[this.props.item.objectId] = value;
              this.setState({
                dados: this.props.dados
              });
              // alert(JSON.stringify(value))
            }}
            directUpload
            ref={v => (this.objectId = v)}
          />
        );
      case "switch":
        return (
          <View style={styles2.view6}>
            <View style={styles2.view7}>
              <Text
                style={[
                  styles2.label1,
                  {
                    textAlign: lay.position ? lay.position : "left",
                    fontSize: lay.font ? lay.font : 14,
                    fontWeight: lay.stilo ? lay.stilo : "400"
                  }
                ]}
                text={lay.obrigatorio ? lay.text + " (Obrigatorio)" : lay.text}
              />
            </View>
            <Switch
              style={styles2.switch}
              value={this.props.dados[this.props.item.objectId]}
              onChange={value => {
                this.props.dados[this.props.item.objectId] = value;
                // this.setState({item:this.props.item});
              }}
              ref={v => (this.switch = v)}
            />
          </View>
        );
      case "prof":
        console.log(this.props.listaHabilitados);
        return (
          <SelectInput
            style={styles2.selectinput}
            value={
              this.props.dados[this.props.item.objectId] &&
              this.props.dados[this.props.item.objectId].objectId
                ? this.props.dados[this.props.item.objectId].objectId
                : ""
            }
            list={
              this.props.listaHabilitados ? this.props.listaHabilitados : []
            }
            key_value={"objectId"}
            onChange={(value, data, index) => {
              data = this.props.listaHabilitados.find(a => a.objectId == value);
              this.props.dados[this.props.item.objectId] = data;

              this.setState({ item: this.props.item });
            }}
            ref={v => (this.selectinput = v)}
            label={lay.obrigatorio ? lay.text + " (Obrigatorio)" : lay.text}
          />
        );
      case "TextInput":
        return (
          <TextInput
            style={styles2.textinput}
            label={
              lay.obrigatorio ? lay.descricao + " (Obrigatorio)" : lay.descricao
            }
            keyboardType={lay.tipo_text ? lay.tipo_text : "default"}
            multiline={lay.multiline ? lay.multiline : false}
            value={this.props.dados[this.props.item.objectId]}
            disabled={lay.calculo ? true : false}
            onChange={value => {
              console.log(value);
              this.props.dados[this.props.item.objectId] = value;
              // if (lay.tag) {
              this.props.activity.setState({
                alterado: true
              });
              // }
            }}
            ref={v => (this.textinput = v)}
            mask={lay.mascara ? lay.mascara : ""}
            InputProps={
              lay.textopadroes_id
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <TouchableOpacity
                          style={styles2.bottom21}
                          onPress={() => {
                            this.open_modal_bottom2();
                          }}
                          icon
                        >
                          <Icon
                            style={styles2.icon2}
                            fromFontFamily={"Material Icons"}
                            icon={"list"}
                          />
                        </TouchableOpacity>
                      </InputAdornment>
                    )
                  }
                : null
            }
          />
        );
      case "selectinput":
        return (
          <SelectInput
            style={styles2.selectinput}
            value={this.props.dados[this.props.item.objectId]}
            list={(lay.lista || []).map(a => ({
              ...a,
              text: a.text || a["nome"] || a,
              value: a.value || a["objectId"] || a
            }))}
            key_label={"text"}
            key_value={"value"}
            onChange={(value, data, index) => {
              this.props.dados[this.props.item.objectId] = value;
              this.setState({ item: this.props.item });
            }}
            ref={v => (this.selectinput = v)}
            label={
              lay.obrigatorio ? lay.descricao + " (Obrigatorio)" : lay.descricao
            }
          />
        );
      case "ViewEdit":
        return (
          <View
            style={[styles2.view13, { marginTop: 0, flexDirection: "row" }]}
          >
            <View
              style={[
                {
                  flex: 1,
                  alignSelf: "auto",
                  flexWrap: "nowrap",
                  position: "relative"
                },
                {
                  minWidth: lay.largura ? lay.largura : null,
                  maxWidth: lay.largura ? lay.largura : null,
                  minHeight: lay.altura ? lay.altura : null,
                  backgroundColor: lay.cor ? lay.cor : "rgba(251,251,251,0)",
                  maxHeight: lay.altura ? lay.altura : null,
                  flexDirection: lay.orientacao ? lay.orientacao : "column",
                  alignItems: lay.orientacao2 ? lay.orientacao2 : "flex-start",
                  justifyContent: lay.orientacao3
                    ? lay.orientacao3
                    : "flex-start"
                }
              ]}
            >
              {this.view1Column()}
            </View>
          </View>
        );
      case "View":
        return (
          <View style={styles2.view13}>
            <View style={styles2.view11}>
              <View style={styles2.view14}>{this.view1Column()}</View>
              <View style={styles2.view12}>{this.view2Column()}</View>
            </View>
          </View>
        );
      case "image":
        return (
          <View
            style={[
              styles2.view13,
              {
                flexDirection: "row",
                justifyContent: lay.orientacao3
                  ? lay.orientacao3
                  : "flex-start",
                alignItems: lay.orientacao2 ? lay.orientacao2 : "flex-start"
              }
            ]}
          >
            <Image
              style={[
                styles2.image,
                {
                  width: lay.largura ? lay.largura : 50,
                  height: lay.altura ? lay.altura : 50,
                  maxWidth: "100%"
                }
              ]}
              resizeMode={"contain"}
              source={{ uri: lay.link }}
            />
          </View>
        );
      case "tb":
        if (!lay.lista) return null;
        return (
          <View
            style={{
              display: null,
              alignSelf: "stretch",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 5
            }}
          >
            <View style={styles.view}>
              <Text
                style={{
                  textAlign: "center",
                  flexWrap: "wrap",
                  alignSelf: "stretch",
                  textAlign: "left",
                  fontSize: 14
                }}
                text={this.props.item.code.lay.descricao}
              />
            </View>
            <table style={{ width: "100%" }}>
              {this.criarTabellaCab()}
              {this.criarTabella()}
            </table>
            <View style={styles.view41}>
              <TouchableOpacity
                style={styles.bottom11}
                onPress={() => {
                  this.add();
                }}
                icon
              >
                <Icon
                  style={styles.icon11}
                  fromFontFamily={"Material Icons"}
                  icon={"add"}
                />
              </TouchableOpacity>
              {this.props.item.code.lay.obrigatorio ? (
                <Icon
                  style={styles.icon14}
                  fromFontFamily={"Material Icons"}
                  icon={"lock"}
                />
              ) : null}
            </View>
          </View>
        );
      case "tbEQ":
        return (
          <View
            style={{
              display: null,
              alignSelf: "stretch",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 5
            }}
          >
            <View style={styles.view}>
              <Text
                style={{
                  textAlign: "center",
                  flexWrap: "wrap",
                  alignSelf: "stretch",
                  textAlign: "left",
                  fontSize: 14
                }}
                text={this.props.item.code.lay.descricao}
              />
            </View>
            <table style={{ width: "100%" }}>
              {this.criarTabellaCab()}

              {this.criarTabella2()}
            </table>
            <View style={styles.view41}>
              <TouchableOpacity
                style={styles.bottom11}
                onPress={() => {
                  this.add();
                }}
                icon
              >
                <Icon
                  style={styles.icon11}
                  fromFontFamily={"Material Icons"}
                  icon={"add"}
                />
              </TouchableOpacity>
              {this.props.item.code.lay.obrigatorio ? (
                <Icon
                  style={styles.icon14}
                  fromFontFamily={"Material Icons"}
                  icon={"lock"}
                />
              ) : null}
            </View>
          </View>
        );
      case "ocorrencias":
        return (
          <View
            style={{
              display: null,
              alignSelf: "stretch",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 5
            }}
          >
            <View style={styles.view}>
              <Text
                style={{
                  textAlign: "center",
                  flexWrap: "wrap",
                  alignSelf: "stretch",
                  textAlign: "left",
                  fontSize: 14
                }}
              >
                {this.props.item.code.lay.descricao}
              </Text>
            </View>
            <table style={{ width: "100%" }}>
              {this.criarTabellaCab2()}

              {this.criarTabella3()}
            </table>
            <View style={styles.view41}>
              <TouchableOpacity
                style={styles.bottom11}
                onPress={() => {
                  this.open_pg_bottom3();
                }}
                icon
              >
                <Icon
                  style={styles.icon11}
                  fromFontFamily={"Material Icons"}
                  icon={"add"}
                />
              </TouchableOpacity>
              {this.props.item.code.lay.obrigatorio ? (
                <Icon
                  style={styles.icon14}
                  fromFontFamily={"Material Icons"}
                  icon={"lock"}
                />
              ) : null}
            </View>
          </View>
        );
      case "checkList":
        return (
          <View style={styles2.view13}>
            <Text
              style={[
                styles2.label1,
                {
                  textAlign: lay.position ? lay.position : "left",
                  fontSize: lay.font ? lay.font : 14,
                  fontWeight: lay.stilo ? lay.stilo : "400"
                }
              ]}
              text={lay.obrigatorio ? lay.text + " (Obrigatorio)" : lay.text}
            />
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                position: "relative"
              }}
            >
              {this.view1Column()}
            </View>
          </View>
        );
      case "son":
        if (this.props.dados[this.props.item.objectId] == undefined)
          this.props.dados[this.props.item.objectId] = true;
        return (
          <View style={styles2.view13} key={"key_" + this.props.item.objectId}>
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                position: "relative",
                margin: 5
              }}
            >
              <Text
                style={[
                  styles.label1,
                  {
                    textAlign: lay.position ? lay.position : "left",
                    fontSize: lay.font ? lay.font : 14,
                    fontWeight: lay.stilo ? lay.stilo : "400",
                    flex: 1
                  }
                ]}
                text={lay.obrigatorio ? lay.text + " (Obrigatorio)" : lay.text}
              />
              <View style={{ alignSelf: "auto", width: 100, margin: 5 }}>
                <TouchableOpacity
                  style={[styles2.bottom34, { margin: 5 }]}
                  key={"key_B" + this.props.item.objectId}
                  onPress={() => {
                    this.props.dados[this.props.item.objectId] = true;
                    this.setState({
                      alterado: true
                    });
                    // this.props.activity.setState({alterado:true});
                  }}
                  variant={
                    !this.props.dados[this.props.item.objectId]
                      ? "outlined"
                      : "contained"
                  }
                  children={"Sim"}
                ></TouchableOpacity>
              </View>
              <View style={{ alignSelf: "auto", width: 100, margin: 5 }}>
                <TouchableOpacity
                  style={[styles2.bottom34, { margin: 5 }]}
                  onPress={() => {
                    this.props.dados[this.props.item.objectId] = false;
                    this.setState({
                      alterado: true
                    });
                    // this.props.activity.setState({alterado:true});
                  }}
                  variant={
                    this.props.dados[this.props.item.objectId]
                      ? "outlined"
                      : "contained"
                  }
                  children={"Não"}
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        );
      case "data":
        return (
          <DatePicker
            style={styles2.datepicker}
            type={"date"}
            value={this.props.inspecoes[lay.value]}
            onChange={value => {
              this.props.inspecoes[lay.value] = value;
              this.props.inspecoes[lay.value + "_br"] = moment(value).format(
                "DD/MM/YYYY"
              );

              this.setState({
                item: this.props.item
              });
            }}
            ref={v => (this.datepicker = v)}
            label={
              lay.obrigatorio ? lay.descricao + " (Obrigatorio)" : lay.descricao
            }
          />
        );
      case "dataEdit":
        return (
          <DatePicker
            style={styles2.datepicker}
            type={"date"}
            value={this.props.dados[this.props.item.objectId]}
            onChange={value => {
              this.props.dados[this.props.item.objectId] = value;
              this.props.dados[this.props.item.objectId + "_br"] = moment(
                value
              ).format("DD/MM/YYYY");

              this.setState({
                item: this.props.item
              });
            }}
            ref={v => (this.datepicker = v)}
            label={
              lay.obrigatorio ? lay.descricao + " (Obrigatorio)" : lay.descricao
            }
          />
        );
      default:
        return <View />;
    }
  }
  criarTabellaCab() {
    var itens = [];
    if (this.props.item.code.lay.lista) {
      itens = this.props.item.code.lay.lista;
    }
    var pg = [];
    for (var i = 0; i < itens.length; i++) {
      let item = itens[i];

      try {
        pg.push(
          <td key={"cell" + i} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <View style={styles.view3}>
                <Text style={{}} text={item.text} />
              </View>
            </View>
          </td>
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    var LinhaTB = [];
    LinhaTB.push(
      <tr style={{ backgroundColor: "rgba(155,155,155,0.2)", height: 35 }}>
        {pg}

        <td style={{ maxWidth: 50 }}>
          <View style={{ maxWidth: 50 }}>
            <Icon
              style={styles.icon3}
              fromFontFamily={"Material Icons"}
              icon={"delete"}
            />
          </View>
        </td>
      </tr>
    );

    return LinhaTB;
  }
  criarTabella() {
    var LinhaTB = [];
    var dados = [];
    if (
      !this.props.dados[this.props.item.objectId] ||
      !this.props.dados[this.props.item.objectId][0]
    ) {
      this.props.dados[this.props.item.objectId] = [];
      dados = this.props.dados[this.props.item.objectId];
    } else {
      dados = this.props.dados[this.props.item.objectId];
    }
    var itens = [];
    if (this.props.item.code.lay.lista) {
      itens = this.props.item.code.lay.lista;
    }
    var pg = [];
    // console.log(dados);
    for (var a = 0; a < dados.length; a++) {
      let d = dados[a];
      // console.log(itens);
      for (var i = 0; i < itens.length; i++) {
        let item = itens[i];
        try {
          pg.push(
            <td
              key={"cell" + i + a}
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              {item.lista && item.itens ? (
                <Select
                  style={styles.selectinput}
                  value={d[item.objectId]}
                  lista={item.itens}
                  onChange={(value, data, index) => {
                    d[item.objectId] = value;
                    // console.log(data);
                    this.setState({ item: this.props.item });
                  }}
                  ref={v => (this.selectinput = v)}
                />
              ) : (
                <Input
                  style={styles.temp_operacao}
                  value={d[item.objectId]}
                  onChange={value => {
                    d[item.objectId] = value;
                    this.setState({ item: this.props.item });
                  }}
                  placeholder={"Digite..."}
                  ref={v => (this.temp_operacao = v)}
                  keyboardType={item.numero ? "numeric" : "default"}
                  secureTextEntry={false}
                  multiline={false}
                />
              )}
            </td>
          );
        } catch (e) {
          console.log(e);
        } finally {
        }
      }
      LinhaTB.push(
        <tr>
          {pg}
          <td style={styles.td}>
            <TouchableOpacity
              style={styles.td}
              onPress={() => {
                this.deleteCom(d);
              }}
              icon
            >
              <Icon
                style={styles.icon3}
                fromFontFamily={"Material Icons"}
                icon={"delete"}
              />
            </TouchableOpacity>
          </td>
        </tr>
      );
      pg = [];
    }
    return LinhaTB;
  }
  criarTabellaCab2() {
    var itens = [];
    if (this.props.item.code.lay.lista) {
      itens = this.props.item.code.lay.lista;
    }
    var pg = [];
    for (var i = 0; i < itens.length; i++) {
      let item = itens[i];

      try {
        pg.push(
          <td key={"cell" + i} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <View style={styles.view3}>
                <Text style={{}} text={item.text} />
              </View>
            </View>
          </td>
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    var LinhaTB = [];
    LinhaTB.push(
      <tr style={{ backgroundColor: "rgba(150,150,150,0.5)", height: 35 }}>
        {pg}

        <td style={{ maxWidth: 50 }}>
          <View style={{ maxWidth: 50 }}>
            <Icon
              style={styles.icon3}
              fromFontFamily={"Material Icons"}
              icon={"border_color"}
            />
          </View>
        </td>
      </tr>
    );

    return LinhaTB;
  }
  criarTabella2() {
    var LinhaTB = [];
    var dados = [];
    if (
      !this.props.dados[this.props.item.objectId] ||
      !this.props.dados[this.props.item.objectId][0]
    ) {
      this.props.dados[this.props.item.objectId] = [];
      dados = this.props.dados[this.props.item.objectId];
    } else {
      dados = this.props.dados[this.props.item.objectId];
    }
    var itens = [];
    if (this.props.item.code.lay.lista) {
      itens = this.props.item.code.lay.lista;
    }
    var pg = [];
    // console.log(dados);
    for (var a = 0; a < dados.length; a++) {
      let d = dados[a];
      // console.log(itens);

      pg.push(
        <tr>
          <td key={"cell" + 1 + a} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Select
              style={styles.selectinput}
              value={d.item}
              lista={this.props.itens_feramenta}
              onChange={(value, data, index) => {
                d.item = value;

                d.nome = data.nome;
                d.modelo = data.modelo;
                d.marca = data.marca;
                d.n_cert = data.n_cert;
                d.n_serie = data.n_serie;

                // console.log(data);
                this.setState({ item: this.props.item });
              }}
              ref={v => (this.selectinput = v)}
              label={this.props.item.code.lay.descricao}
            />
          </td>

          <td key={"cell" + 2 + a} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Text style={styles.label13} text={d.marca ? d.marca : "-"} />
          </td>
          <td key={"cell" + 5 + a} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Text style={styles.label13} text={d.modelo ? d.modelo : "-"} />
          </td>
          <td key={"cell" + 3 + a} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Text style={styles.label13} text={d.n_serie ? d.n_serie : "-"} />
          </td>
          <td key={"cell" + 4 + a} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <Text style={styles.label13} text={d.n_cert ? d.n_cert : "-"} />
          </td>
          <td style={{ maxWidth: 50 }}>
            <TouchableOpacity
              style={{ maxWidth: 50 }}
              onPress={() => {
                this.deleteCom(d);
              }}
            >
              <Icon
                style={styles.icon3}
                fromFontFamily={"Material Icons"}
                icon={"delete"}
              />
            </TouchableOpacity>
          </td>
        </tr>
      );
    }

    return pg;
  }

  criarTabella3() {
    var pg = [];
    if (this.props.ocorrencias) {
      var dados = [];
      if (!this.props.ocorrencias.lista) {
        this.props.ocorrencias.lista = [];
        dados = this.props.ocorrencias.lista;
      } else {
        dados = this.props.ocorrencias.lista;
      }

      // console.log(dados);
      for (var a = 0; a < dados.length; a++) {
        let d = dados[a] || {};
        let pos = a;
        pg.push(
          <tr>
            <td
              key={"cell" + 1 + a}
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              <Text style={styles.label13} text={d.titulo ? d.titulo : "-"} />
            </td>

            <td
              key={"cell" + 2 + a}
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              <Text style={styles.label13} text={d.tipo ? d.tipo : "-"} />
            </td>
            <td
              key={"cell" + 5 + a}
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              <Text
                style={styles.label13}
                text={d.descricao ? d.descricao : "-"}
              />
            </td>
            <td style={{ maxWidth: 50, flexDirection: "row" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  style={{ maxWidth: 50 }}
                  onPress={() => {
                    this.open_modal_bottom4(d, pos);
                  }}
                >
                  <Icon
                    style={styles.icon3}
                    fromFontFamily={"Material Icons"}
                    icon={"border_color"}
                  />
                </TouchableOpacity>
                {dados.length && (
                  <View style={{ flexDirection: "column" }}>
                    <TouchableOpacity
                      onPress={() => {
                        dados.move(pos, pos - 1);
                        this.salvar_bottom2();
                      }}
                    >
                      <Icon
                        style={styles.icon}
                        fromFontFamily={"Material Icons"}
                        icon={"expand_less"}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        dados.move(pos, pos + 1);
                        this.salvar_bottom2();
                      }}
                    >
                      <Icon
                        style={styles.icon}
                        fromFontFamily={"Material Icons"}
                        icon={"expand_more"}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </td>
          </tr>
        );
      }
    }

    return pg;
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    alignSelf: "stretch",
    backgroundColor: "rgba(255,255,255,1)",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {},
  td: {
    maxWidth: 50
  },
  view: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 7
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap"
  },
  label13: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 13
  },
  bottom34: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 100,
    padding: 10
  },
  datepicker: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch"
  },
  icon14: {
    color: "rgba(7,81,120,1)",
    fontSize: 30,
    margin: 10
  },
  textinput: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    marginTop: 7
  },
  view41: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  bottom11: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    flexDirection: "column",
    flexWrap: "nowrap",
    borderRadius: 18,
    width: 36,
    backgroundColor: "rgba(7,81,120,1)",
    margin: 5
  },
  icon11: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  icon12: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view6: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 25,
    marginTop: 7
  },
  icon2: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  image: {
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view3: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    margin: 7
  },
  fotosupload: {
    alignSelf: "auto",
    width: 520
  },
  view12: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view14: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    marginRight: 10,
    position: "relative"
  },
  view11: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",

    paddingTop: 10,
    paddingBottom: 10
  },
  view13: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5
  },
  view7: {
    alignSelf: "auto",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 20
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    fontSize: 14
  },
  switch: {},
  selectinput: {
    minHeight: 40,
    alignSelf: "stretch",
    marginTop: 7
  }
});
var styles2 = StyleSheet.create({
  tela: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 7,
    alignSelf: "stretch"
  },
  label1: {
    textAlign: "left",
    flex: 1,
    flexWrap: "wrap",
    alignSelf: "stretch"
  },
  objectId: {
    alignSelf: "stretch",
    minHeight: 500
  },
  bottom34: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 100,
    padding: 10
  },
  icon12: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },

  datepicker: {
    color: "rgba(0,0,0,1)",
    alignSelf: "auto"
  },
  bottom21: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 35,
    borderRadius: 18,
    // marginLeft: 5,
    // marginTop: 10,
    backgroundColor: "rgba(7,81,120,1)"
  },
  icon2: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  image: {
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  textinput: {
    minHeight: 35,
    flex: 1,
    color: "rgba(0,0,0,1)",

    marginTop: 7
  },
  view6: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 25,
    marginTop: 7
  },
  view3: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    margin: 7,
    flex: 1,
    backgroundColor: "rgba(150,150,150,0.2)",
    height: 250
  },
  fotosupload: {
    alignSelf: "auto",
    width: 350,
    height: 250
  },
  view12: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view14: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    marginRight: 10,
    position: "relative"
  },
  view11: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",

    paddingTop: 10,
    paddingBottom: 10
  },
  view13: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5
  },
  view7: {
    alignSelf: "auto",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 20
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    fontSize: 14
  },
  switch: {},
  selectinput: {
    flex: 1,
    minHeight: 40,
    alignSelf: "stretch",
    marginTop: 0
  },
  view10: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    backgroundColor: "rgba(218,218,218,1)"
  },
  text10: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 12
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 55
  },
  icon: {
    color: "rgba(0,0,0,1)",
    fontSize: 25
  },
  listview: {
    alignSelf: "stretch"
  },
  cell: {
    flexDirection: "row",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  label10: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 12
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 40
  },
  icon1: {
    color: "rgba(0,0,0,1)",
    fontSize: 25
  }
});
