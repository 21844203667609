"use strict";
import React, { Component } from "react";

import {
  MapView,
  TouchableHighlight,
  ScrollView,
  TextInput,
  SelectInput,
  TitleBar,
  File,
  FotosUpload,
  Endereco
} from "react-1app";
import {
  Progresso,
  Imagem,
  Fotos,
  Image,
  Text,
  ListView,
  Alert,
  Navigator,
  Switch,
  View,
  moment,
  banco,
  ApiUteis
} from "react-1app";
import {
  DrawerLayout,
  ImageUpload,
  StyleSheet,
  GridView,
  Icon,
  Fragment,
  RefreshControl,
  PageView,
  DatePicker
} from "react-1app";

import OpcSelet from "./opcSelet";
import TouchableOpacity from "../../components/TouchableOpacity";

import { Util, Dados, Query } from "../../infra";

//nao atualizar
export default class Selet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {}
    };
    Dados.clonarHeranca(props, this.state);
    //{onConstructor}
  }
  componentDidMount() {
    //{onDidMount}
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);

      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }

  open_modal_bottom1() {
    this.state.navigator.openModal({
      component: OpcSelet,
      props: { heranca: this.state, activity: this }
    });
  }
  alterarTeclado() {
    if (this.state.item.numero) {
      this.state.item.numero = false;
    } else {
      this.state.item.numero = true;
    }
    this.setState({ alterado: true });
  }
  salvar() {
    this.props.activity.salvar();
  }
  render() {
    return (
      <View style={styles.tela}>
        <TextInput
          style={styles.textinput}
          value={this.state.item.value}
          onChange={value => {
            this.state.item.value = value;
            this.state.item.text = value;
            this.props.activity.props.activity.setState({ alterar: true });
            // this.setState({item:this.state.item});
          }}
          ref={v => (this.textinput = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"texto"}
        />
        {this.state.conp.code.tag != "tbEQ" &&
        this.state.conp.code.tag != "ocorrencias" ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              width: 100,
              justifyContent: "flex-start",
              flexWrap: "nowrap"
            }}
          >
            <TouchableOpacity
              style={styles.bottom1}
              elevation={3}
              onPress={() => {
                this.alterarTeclado();
              }}
            >
              {!this.state.item.numero ? (
                <Icon
                  style={styles.icon2}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"radio_button_unchecked"}
                />
              ) : null}
              {this.state.item.numero ? (
                <Icon
                  style={styles.icon4}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"check_circle"}
                />
              ) : null}

              <Text style={styles.label1} text={"Nº"} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.bottom1}
              elevation={3}
              onPress={() => {
                this.open_modal_bottom1();
              }}
            >
              {!this.state.item.lista ? (
                <Icon
                  style={styles.icon2}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"radio_button_unchecked"}
                />
              ) : null}
              {this.state.item.lista ? (
                <Icon
                  style={styles.icon4}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"check_circle"}
                />
              ) : null}

              <Text style={styles.label1} text={"Lista"} />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.bottom}
              color={"primary"}
              onPress={() => {
                Alert.alert(
                  "Apagar",
                  "Voce realmente deseja apagar este item?",
                  [
                    {
                      text: "Ok",
                      onPress: () =>
                        this.props.activity.deleteSelet(this.state.item)
                    },
                    { text: "Cancelar", onPress: () => console.log() }
                  ]
                );
              }}
            >
              <Icon
                style={styles.icon3}
                fromFontFamily={"Material Icons"}
                icon={"close"}
              />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40
  },
  icon3: {
    fontSize: 30,
  },
  textinput: {
    alignSelf: "stretch",
    minHeight: 35,
    alignSelf: "stretch",
    flexWrap: "nowrap"
  },
  bottom1: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 40,
    padding: 10
  },
  icon2: {
    fontSize: 20
  },
  icon4: {
    fontSize: 20
  },
  label1: {
    marginTop: 5,
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 10
  }
});
