import Dados from "./Dados.js";
import { Cloud } from "../../src/infra";

var host = "";
var token_api = "";
function addSql(oldSql, sql) {
  return oldSql && oldSql != "" && oldSql != " " ? oldSql + " and " + sql : sql;
}
function Query(entidade) {
  this.entidade = entidade ? entidade : "dados";
  this.sql = { sql: "" };
  this.sql_direto = "";
  this.limit = 1000;
  this.off = 0;
  this.ordem = {};
  this.whereServer = null;

  Query.prototype.setWhereServer = function(value) {
    this.whereServer = value;
  };
  Query.prototype.setEntidade = function(value) {
    this.entidade = value;
  };

  Query.prototype.setSqlDireto = function(sql) {
    this.sql_direto = sql;
  };

  Query.prototype.whereNotEqualTo = function(key, value) {
    if (key) {
      this.sql.sql = addSql(
        this.sql.sql,
        ' "' + key + "\" != '" + value + "' "
      );
    }
  };

  Query.prototype.addSql = function(sql) {
    if (sql) {
      this.sql.sql = addSql(this.sql.sql, sql);
    }
  };
  Query.prototype.whereEqualTo = function(key, value) {
    if (key) {
      this.sql[key] = value;
    }
  };

  Query.prototype.whereGreaterThanOrEqualTo = function(key, value) {
    if (key) {
      this.sql[key] = { $gte: value };
      this.sql.sql = addSql(
        this.sql.sql,
        ' "' + key + "\" >= '" + value + "' "
      );
    }
  };

  Query.prototype.whereGreaterThan = function(key, value) {
    if (key) {
      // this.sql[key] = {"$gt": value};
      this.sql.sql = addSql(this.sql.sql, ' "' + key + "\" > '" + value + "' ");
    }
  };

  Query.prototype.whereLessThanOrEqualTo = function(key, value) {
    if (key) {
      this.sql.sql = addSql(this.sql.sql, ' "' + key + "\" < '" + value + "' ");
    }
  };

  Query.prototype.whereLessThan = function(key, value) {
    if (key) {
      this.sql[key] = { $lt: value };
      this.sql.sql = addSql(
        this.sql.sql,
        ' "' + key + "\"  <=  '" + value + "' "
      );
    }
  };

  Query.prototype.whereExists = function(key) {
    if (key) {
      this.sql.sql = addSql(this.sql.sql, ` "${key}" is NOT NULL `);
    }
  };
  Query.prototype.whereNotExists = function(key) {
    if (key) {
      // this.sql[key] =  {"$exists": false};
      this.sql.sql = addSql(
        this.sql.sql,
        ` ( ${key} is NULL  or ${key}=false ) `
      );
    }
  };

  Query.prototype.whereContains = function(key, value) {
    if (key) {
      this.sql.sql = addSql(
        this.sql.sql,
        `( replace(replace(replace(replace(replace(lower("${key}"),'ã','a'),'é','e'),'á','a'), 'ç','c'),'õ','o') LIKE '%${value}%'
      or lower("${key}") LIKE lower('${value}%') )  `
      );
    }
  }; //'$not'
  Query.prototype.whereNotContains = function(key, value) {
    if (key) {
      this.sql.sql = addSql(
        this.sql.sql,
        ' ( replace(replace(replace(replace(replace(lower("' +
          key +
          "\"),'ã','a'),'é','e'),'á','a'), 'ç','c'),'õ','o') NOT LIKE '%" +
          value +
          "%' &&  lower(\"" +
          key +
          "\") NOT LIKE '%" +
          value +
          "%' )  "
      );
    }
  };
  Query.prototype.addAscendingOrderNumerica = function(key) {
    if (key) {
      this.ordem[key] = 1;
    }
  };
  Query.prototype.addDescendingOrderNumerica = function(key) {
    if (key) {
      this.ordem[key] = -1;
    }
  };
  Query.prototype.addAscendingOrder = function(key) {
    if (key) {
      this.ordem[key] = 1;
    }
  };

  Query.prototype.addDescendingOrder = function(key) {
    if (key) {
      this.ordem[key] = -1;
    }
  };

  Query.prototype.setLimit = function(limit) {
    this.limit = limit;
  };
  Query.prototype.setOff = function(off) {
    this.off = off;
  };
  Query.prototype.whereNear = function(latitude, longitude, maxDistance) {
    try {
      if (!this.whereServer) {
        this.whereServer = {};
      }
      this.whereServer.point = {
        $nearSphere: {
          $geometry: {
            type: "Point",
            coordinates: [latitude, longitude]
          }
          // , $maxDistance : 1000
        }
      };
      if (maxDistance) {
        this.whereServer.point.$nearSphere.$maxDistance = maxDistance;
      }
    } catch (e) {
      console.log(e);
    }
  };

  Query.prototype.getSql = function() {
    return this.sql;
  };

  Query.prototype.setMetodoApi = function(metodo, tipo) {
    this.metodoApi = metodo ? metodo : "getAll";
    this.metodoTipo = tipo ? tipo : "POST";
  };

  Query.prototype.sinc = function(retorno) {
    var where = this.getSql();
    if (!where.status) {
      where.status = 1;
    }
    if (this.whereServer) {
      where = this.whereServer;
    }

    Cloud.post(
      this.metodoApi ? this.metodoApi : "getAll",
      {
        entidade: this.entidade,
        where: where,
        ordem: this.ordem,
        limit: this.limit,
        off: this.off
      },
      results => {
        retorno((results||[]).map(data => new Dados(this.entidade).parse(data)));
        return;
      }
    );
  };

  Query.prototype.select = function(retorno, direto) {
    this.sinc(retorno);
  };
  Query.prototype.cloud = function(retorno, direto) {
    this.sinc(retorno);
  };
  Query.prototype.first = function(retorno, direto) {
    this.sinc(lista => {
      if (retorno) {
        if (lista[0]) {
          retorno(lista[0]);
        } else {
          retorno(new Dados(this.entidade));
        }
      }
    });
  };
  Query.prototype.firstCloud = function(retorno, direto) {
    this.sinc(lista => {
      if (retorno) {
        if (lista[0]) {
          retorno(lista[0]);
        } else {
          retorno(new Dados(this.entidade));
        }
      }
    });
  };
}

export default Query;

Query.sincDireto = function sincronizar(entidade, retorno) {};
Query.setToken = function(token) {
  token_api = token;
};
Query.setHost = function(url) {
  host = url;
};
