import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {
  OpenSnack,
  Switch,
  ImageUpload,
  TextInput,
  SelectInput
} from "react-1app";
import moment from "moment";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: this.props.inspecoes,
      load: false,
      list: [],
      listCity: [
        "Camaçari – BA",
        "Porto Alegre – RS",
        "São José dos Campos – SP"
      ]
    };
  }

  salvar_bottom2() {
    this.props.salvarInspecao();
    this.props.closeModal();
  }

  componentDidMount() {}

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Informações da capa"}</Text>
            <TextInput
              style={styles.empresa}
              value={this.state.inspecoes.empresa.nome}
              onChange={value => {
                this.state.inspecoes.empresa.nome = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.empresa = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Nome da empresa"}
            />
            <TextInput
              style={styles.unidade}
              value={this.state.inspecoes.unidade.nome}
              onChange={value => {
                this.state.inspecoes.unidade.nome = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.unidade = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Nome da Unidade"}
            />
            <TextInput
              style={styles.relatorio}
              value={this.state.inspecoes.relatorio.texto}
              onChange={value => {
                this.state.inspecoes.relatorio.texto = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.relatorio = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Texto descritivo capa"}
            />
            <TextInput
              style={styles.numero}
              value={this.state.inspecoes.numero}
              onChange={value => {
                this.state.inspecoes.numero = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.numero = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Codigo da inspeção"}
            />
            <TextInput
              style={styles.equipamento}
              value={this.state.inspecoes.equipamento.denominacao}
              onChange={value => {
                this.state.inspecoes.equipamento.denominacao = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.equipamento = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Denominação"}
            />
            <TextInput
              style={styles.textinput5}
              value={this.state.inspecoes.equipamento.codigo}
              onChange={value => {
                this.state.inspecoes.equipamento.codigo = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.textinput5 = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"TAG"}
            />

            <View style={{ paddingLeft: 5 }}>
              <SelectInput
                label={"Cidade"}
                value={
                  this.state.inspecoes.cidade ? this.state.inspecoes.cidade : ""
                }
                onChange={value => {
                  this.state.inspecoes.cidade = value;
                  this.setState({ inspecoes: this.state.inspecoes });
                }}
                list={this.state.listCity}
              />
            </View>
            <Text style={styles.label2} text={"Imagem para Capa"} />
            <ImageUpload
              style={styles.imageupload}
              value={this.state.inspecoes.relatorio.image}
              onChange={value => {
                console.log(value);
                this.state.inspecoes.relatorio.image = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              aspectRatio={1}
              ref={v => (this.imageupload = v)}
              label={"Imagem para Capa"}
            />
            <View style={styles.action}>
              <Switch
                style={styles.boolTinbrada}
                value={this.state.inspecoes.boolTinbrada}
                onChange={value => {
                  this.state.inspecoes.boolTinbrada = value;
                  this.setState({
                    inspecoes: this.state.inspecoes
                  });
                }}
                label={"Timbre"}
              />
              <TouchableOpacity
                onPress={() => this.salvar_bottom2()}
                children={"Salvar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  imageupload: {
    width: 250,
    height: 250,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderColor: "rgba(150,150,145,1)",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 3,
    margin:"auto"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
