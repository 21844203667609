import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import TextInput from "react-1app/lib/TextInput";

const Input = props => (
  <TextInput {...props} inputNative={true} className={props.classes && props.classes.input} />
);
const styles = theme => ({
  input: {
    color: theme.typography.h1.color
  }
});

const InputTheme = withStyles(styles)(Input);
export default InputTheme;
