import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack, Switch, ImageUpload, TextInput } from "react-1app";
import SelectInput from "../../components/SelectInput";

import moment from "moment";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false,
      list: [],
      listoc: [
        {
          value: "Recomendação NR-13",
          text: "Recomendação NR-13",
          test: 1
        },
        {
          value: "Recomendação Diversa",
          text: "Recomendação Diversa",
          test: 2
        }
      ]
    };
  }

  salvar_bottom2() {
    if (this.props.novo) {
      this.props.activity.addIm(this.props.item);
    } else {
      this.props.activity.salvar_bottom2();
    }
    this.props.closeModal();
  }

  deletar_bottom1() {
    Alert.alert("Deletar", "Deseja deletar este registro", [
      {
        text: "Não",
        onPress: null
      },
      {
        text: "Sim",
        onPress: () => {
          this.props.activity.deleteComIm(this.props.item, this.props.index);
          this.props.closeModal();
        }
      }
    ]);
  }

  componentDidMount() {}

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Ocorrencia"}</Text>
            <TextInput
              style={styles.empresa}
              value={this.props.item.titulo}
              onChange={value => {
                this.props.item.titulo = value;
                this.setState({
                  inspecoes: this.state.inspecoes
                });
              }}
              ref={v => (this.empresa = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={false}
              label={"Titulo"}
            />

            <View style={{ paddingLeft: 5 }}>
              <SelectInput
                label={"Tipo da ocorrências"}
                value={this.props.item.tipo || ""}
                onChange={value => {
                  this.props.item.tipo = value;
                  this.setState({ item: this.state.item });
                }}
                list={this.state.listoc}
              />
            </View>
            <TextInput
              style={styles.empresa}
              value={this.props.item.descricao}
              onChange={value => {
                this.props.item.descricao = value;
                this.setState({
                  item: this.state.item
                });
              }}
              ref={v => (this.empresa = v)}
              keyboardType={"default"}
              secureTextEntry={false}
              multiline={true}
              label={"Descricão"}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.deletar_bottom1()}
                children={"Delete"}
                variant="outlined"
                color={"secondary"}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar_bottom2()}
                style={styles.delete}
                children={"Salvar"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  imageupload: {
    width: 250,
    height: 250,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderColor: "rgba(150,150,145,1)",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 3,
    margin: "auto"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
