import React, { Component } from "react";

import { Text ,StyleSheet,View} from "react-1app";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
export default class recebiveis extends Component {
  constructor(props) {
    super(props);
    this.state = { saldo: { bloqueado: 0, saldo: 0 } };
  }

  componentDidMount() {
  }

  componentWillUnmount() {}

  render() {
    return (
      <Grid container  spacing={16}>
        <Grid item xs={6} sm={12}>
          <Paper style={styles.card}>
            <View style={styles.view4}>
              <Text style={styles.text1}>{"Empresa: "}</Text>
              <Text style={styles.text2}>
                {this.props.nome||"-"}
              </Text>
            </View>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={12}>
          <Paper style={styles.card}>
            <View style={styles.view6}>
              <Text style={styles.text3}>{"Total de inspeções: "}</Text>
              <Text style={styles.text4}>
                {this.props.total||"-"}
              </Text>
            </View>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={12}>
          <Paper style={styles.card}>
            <View style={styles.view6}>
              <Text style={styles.text3}>{"Total de equipamento: "}</Text>
              <Text style={styles.text4}>
                {this.props.total_equipamento||"-"}
              </Text>
            </View>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={12}>
          <Paper style={styles.card}>
            <View style={styles.view6}>
              <Text style={styles.text3}>{"Total de tempo: "}</Text>
              <Text style={styles.text4}>
                {this.props.total_horas||"-"}
              </Text>
            </View>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginTop: -80
  },
  view2: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 20
  },
  card:{
    "alignSelf": "stretch",
  },
  view4: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    height: 75,
    borderRadius: 5,
    padding: 20
  },
  text1: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "600",
    fontSize: 17,
    flex: 1
  },
  text2: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "500",
    fontSize: 20
  },
  view5: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 20
  },
  view6: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    height: 75,
    borderRadius: 5,
    padding: 20
  },
  text3: {
    textAlign: "left",
    color: null,
    alignSelf: "stretch",
    fontWeight: "600",
    fontSize: 17,
    flex: 1
  },
  text4: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "500",
    fontSize: 20
  },
  button: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 10,
    backgroundColor: null
  },
  text5: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 12
  },
  view8: {
    alignSelf: "stretch",
    flexDirection: "row"
  },
  button3: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 20,
    marginTop: -30
  },
  text7: {
    textAlign: "left",
    alignSelf: "stretch",
    fontWeight: "normal"
  }
});
