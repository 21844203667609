import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Input from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import TextPadrao from "./TextPadrao";
import ClonarFormulario from './ClonarFormulario';

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: [],
      load: true,
      item: {}
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("relatorio");

    query.addAscendingOrder("nome");
    query.setLimit(1000);
    query.select(lista => {
      console.log(lista);
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  open_pg_dinamico_cell(relatorio) {
    this.props.navigator.openModal({
      component: ClonarFormulario,
      props: {
        heranca: this.state,
        activity: this.props.activity,
        relatorio,
        destinoRelatorio:this.props.destinoRelatorio
      }
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View>
              <Text style={styles.label}>{"Qual relatorio usar"}</Text>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={{ marginLeft: "auto" }}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem key={index} style={{ width: "100%" }}>
                    <Avatar style={{ marginRight: 20 }}>
                      <Icon
                        style={styles.icon2}
                        fromFontFamily={"Material Icons"}
                        icon={"format_list_numbered"}
                      />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={item.descricao}
                    />
                    <TouchableOpacity
                      onClick={() => {
                        this.open_pg_dinamico_cell( item );
                      }}
                      color={"default"}
                      icon
                      disabled={!item.objectId}
                    >
                      <Icon
                        fromFontFamily={"Material Design Icons"}
                        name={"chevron-right"}
                      />
                    </TouchableOpacity>
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
