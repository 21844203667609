"use strict";
import React, { Component } from "react";

import {
  MapView,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  TextInput,
  TitleBar,
  File,
  FotosUpload,
  Endereco,
} from "react-1app";
import {
  Progresso,
  Imagem,
  Fotos,
  Image,
  Text,
  ListView,
  Alert,
  Navigator,
  Switch,
  View,
  moment,
  banco,
  ApiUteis,
} from "react-1app";
import {
  DrawerLayout,
  ImageUpload,
  StyleSheet,
  GridView,
  Icon,
  Fragment,
  RefreshControl,
  PageView,
  DatePicker,
} from "react-1app";
import { Util, Dados, Query } from "../../infra";

import ListFerment from "./ListFerraments";
import Selet from "./selet";
import ListaPadro from "./ListTextPadrao";
import ListaSugOcorrencaias from "./ListSugOcorrencias";
import { SelectInput } from "../../components/SelectInput";

//nao atualizar
export default class ConfigComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      listaV: [],
      listClasse: [
        { text: "Vasos de Pressão", value: "vasos" },
        { text: "Caldeiras", value: "caldeiras" },
        { text: "Tubulação", value: "tubulacao" },
      ],
      ...this.props.oldState,
      ...this.props,
    };
    // Dados.clonarHeranca(props, this.state);
    //{onConstructor}
  }
  componentDidMount() {
    //{onDidMount}
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);
      //{onUpdate}
    }
    return true;
  }
  salvar() {
    this.props.activity.salvar_bottom2();
  }
  open_modal_bottom2() {
    this.state.navigator.openModal({
      component: ListaPadro,
      props: {
        heranca: this.state,
        activity: this,
      },
    });
  }
  // componentWillUnmount(){
  // }
  // salvarItem(){
  //   this.props.activity.salvar_bottom2();
  // }
  deleteCom() {
    var lista = this.props.activity.state.formularios.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      if (foco.objectId == this.state.item.objectId) {
        // console.log(i,to);
        this.props.activity.state.formularios.lista.splice(i, 1);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.props.activity.setState({ alterado: true });
    this.setState({ item: null });
  }
  deleteComV() {
    if (!this.state.listaV) {
      this.deleteCom();
    } else {
      var lista = this.state.listaV;

      for (var i = 0; i < lista.length; i++) {
        var foco = lista[i];
        if (foco.objectId == this.state.item.objectId) {
          // console.log(i,to);
          this.state.listaV.splice(i, 1);
          break;
        }
        // console.log(foco,to,anterior);
      }
      this.props.activity.setState({ alterado: true });
      this.setState({ item: null });
    }
  }
  selet() {
    var itens = [];
    if (this.state.item.code.lay.lista) {
      itens = this.state.item.code.lay.lista;
    }
    var pg = [];
    for (var i = 0; i < itens.length; i++) {
      let item = itens[i];
      try {
        pg.push(
          <Selet
            activity={this}
            navigator={this.state.navigator}
            item={item}
            conp={this.state.item}
            key={"item_" + i}
          />
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    return pg;
  }
  add() {
    try {
      if (!this.state.item.code.lay.lista) {
        this.state.item.code.lay.lista = [];
      }
      var novo = {};
      // novo = JSON.parse(novo);
      novo.objectId = new Date().getTime() + "key";
      novo.value = "";
      novo.data = {};

      // console.log(novo);
      this.state.item.code.lay.lista.push(novo);
      this.setState({ novo: true });
    } catch (e) {
      console.log(e);
    } finally {
    }
    this.props.activity.setState({ alterar: true });
  }
  deleteSelet(item) {
    var lista = this.state.item.code.lay.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      if (foco.objectId == item.objectId) {
        // console.log(i,to);
        this.state.item.code.lay.lista.splice(i, 1);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.setState({ alterado: true });
    // this.setState({item:null})
    this.props.activity.setState({ alterar: true });
  }
  open_modal_bottom3() {
    this.state.navigator.openModal({
      component: ListFerment,
      props: {
        heranca: this.state,
        activity: this,
      },
    });
  }
  open_modal_bottom4() {
    this.state.navigator.openModal({
      component: ListaSugOcorrencaias,
      props: {
        heranca: this.state,
        activity: this,
      },
    });
  }
  render() {
    if (
      !this.state.item ||
      !this.state.item.code ||
      !this.state.item.code.lay
    ) {
      return <View></View>;
    }

    return (
      <View style={styles.tela} type={"Paper"}>
        <View style={styles.view}>
          <Text style={styles.label} text={"Configurações do componente"} />
          <ScrollView
            style={styles.scroll}
            keyboardDismissMode={"interactive"}
            horizontal={false}
          >
            <View style={styles.view1}>
              {this.state.item.code.propr.descricao ? (
                <TextInput
                  style={styles.textinput}
                  value={this.state.item.code.lay.descricao}
                  onChange={(value) => {
                    this.state.item.code.lay.descricao = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.descricao = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={false}
                  label={"Descrição"}
                />
              ) : null}
              {this.state.item.code.propr.mascara ||
              this.state.item.code.tag == "TextInput" ? (
                <TextInput
                  style={styles.textinput}
                  value={this.state.item.code.lay.mascara}
                  onChange={(value) => {
                    this.state.item.code.lay.mascara = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.mascara = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={false}
                  label={"Mascara"}
                />
              ) : null}
              {this.state.item.code.propr.text ? (
                <TextInput
                  style={styles.textinput}
                  value={this.state.item.code.lay.text}
                  onChange={(value) => {
                    this.state.item.code.lay.text = value;
                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.text = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={true}
                  label={"Texto"}
                />
              ) : null}
              {this.state.item.code.propr.sv_tag ? (
                <TextInput
                  style={styles.textinput1}
                  value={this.state.item.code.lay.sv_tag}
                  onChange={(value) => {
                    this.state.item.code.lay.sv_tag = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.sv_tag = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  label={"tag"}
                />
              ) : null}
              {this.state.item.code.propr.altura ? (
                <TextInput
                  style={styles.textinput1}
                  value={this.state.item.code.lay.altura}
                  onChange={(value) => {
                    this.state.item.code.lay.altura = Number(value);
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.altura = v)}
                  keyboardType={"numeric"}
                  secureTextEntry={false}
                  label={"Altura"}
                />
              ) : null}
              {this.state.item.code.propr.largura ? (
                <TextInput
                  style={styles.textinput1}
                  value={this.state.item.code.lay.largura}
                  onChange={(value) => {
                    this.state.item.code.lay.largura = Number(value);
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.largura = v)}
                  keyboardType={"numeric"}
                  secureTextEntry={false}
                  label={"Largura"}
                />
              ) : null}
              {this.state.item.code.propr.cor ? (
                <TextInput
                  style={styles.textinput1}
                  value={this.state.item.code.lay.cor}
                  onChange={(value) => {
                    this.state.item.code.lay.cor = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.cor = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  label={"Cor (#ffffff)"}
                />
              ) : null}
              {this.state.item.code.propr.orientaçao ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.orientacao}
                  lista={[
                    { text: "Culuna", value: "column", data: {} },
                    { text: "Linha", value: "row", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.orientacao = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.orientaçao = v)}
                  label={"Orientação"}
                />
              ) : null}
              {this.state.item.code.propr.orientaçao2 ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.orientacao2}
                  lista={[
                    { text: "Inicio", value: "flex-start", data: {} },
                    { text: "Center", value: "center", data: {} },
                    { text: "Fim", value: "flex-end", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.orientacao2 = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.orientaçao2 = v)}
                  label={"alignItems"}
                />
              ) : null}
              {this.state.item.code.propr.orientaçao3 ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.orientacao3}
                  lista={[
                    { text: "Inicio", value: "flex-start", data: {} },
                    { text: "Center", value: "center", data: {} },
                    { text: "Fim", value: "flex-end", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.orientacao3 = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.orientaçao3 = v)}
                  label={"justifyContent"}
                />
              ) : null}
              {this.state.item.code.propr.culunm &&
              this.state.item.code.tag != "check" ? (
                <TextInput
                  style={styles.textinput3}
                  value={this.state.item.code.lay.culunm}
                  onChange={(value) => {
                    this.state.item.code.lay.culunm = Number(value);

                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.tag = v)}
                  keyboardType={"numeric"}
                  secureTextEntry={false}
                  multiline={false}
                  label={"Culunm"}
                />
              ) : null}
              {this.state.item.code.propr.font ? (
                <TextInput
                  style={styles.textinput3}
                  value={this.state.item.code.lay.font}
                  onChange={(value) => {
                    this.state.item.code.lay.font = Number(value);

                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.tag = v)}
                  keyboardType={"numeric"}
                  secureTextEntry={false}
                  multiline={false}
                  label={"Tamanho da fonte"}
                />
              ) : null}
              {this.state.item.code.propr.position ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.position}
                  lista={[
                    { text: "Inicio", value: "left", data: {} },
                    { text: "Centre", value: "center", data: {} },
                    { text: "Fim", value: "right", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.position = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.selectinput = v)}
                  label={"Posição do texto"}
                />
              ) : null}
              {this.state.item.code.propr.stilo ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.stilo}
                  lista={[
                    { text: "Nomal", value: "400", data: {} },
                    { text: "Negrito", value: "900", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.stilo = value;
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.selectinput = v)}
                  label={"Stilo do texto"}
                />
              ) : null}
              {this.state.item.code.propr.tipo_text ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.tipo_text}
                  lista={[
                    { text: "Default", value: "default", data: {} },
                    { text: "Numeric", value: "numeric", data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.tipo_text = value;
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.selectinput = v)}
                  label={"Tipo de texto"}
                />
              ) : null}
              {this.state.item.code.propr.lista ? (
                <SelectInput
                  style={styles.selectinput}
                  value={this.state.item.code.lay.value}
                  lista={this.state.item.code.lay.lista}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.descricao = data.text;
                    this.state.item.code.lay.value = value;
                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.selectinput = v)}
                  label={"Tipo da data"}
                />
              ) : null}
              {this.state.item.code.propr.multiline ? (
                <SelectInput
                  style={styles.selectinput1}
                  value={this.state.item.code.lay.multiline}
                  lista={[
                    { text: "Falso", value: false, data: {} },
                    { text: "Verdadeiro", value: true, data: {} },
                  ]}
                  onChange={(value, data, index) => {
                    if (value == "false") {
                      this.state.item.code.lay.multiline = false;
                    } else {
                      this.state.item.code.lay.multiline = true;
                    }
                    //                     console.log(true,value);
                    //
                    // console.log(this.state.item.code.lay,value);
                    this.props.activity.setState({ alterar: true });
                    // this.setState({item:this.state.item});
                  }}
                  ref={(v) => (this.multiline = v)}
                  label={"Multi linhas"}
                />
              ) : null}
              {this.state.item && !this.state.item.code.propr.culunm ? (
                <SelectInput
                  style={styles.lista}
                  value={this.state.item.code.lay.ordemPdf}
                  lista={this.state.relatorio.lista}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.ordemPdf = value;
                    this.state.item.code.lay.ordemPdf_id = data.id;
                    this.state.item.code.lay.ordemPdf_n = data.ordem;
                    // console.log(data.ordem);
                    this.setState({
                      relatorio: this.state.relatorio,
                    });
                  }}
                  ref={(v) => (this.lista = v)}
                  label={"Ordem para o pdf"}
                />
              ) : null}
              {this.state.item && this.state.item.code.tag == "ocorrencias" ? (
                <SelectInput
                  style={styles.lista}
                  value={this.state.item.code.lay.default}
                  lista={this.state.listClasse}
                  onChange={(value, data, index) => {
                    this.state.item.code.lay.default = value;
                    this.setState({
                      relatorio: this.state.relatorio,
                    });
                  }}
                  ref={(v) => (this.lista = v)}
                  label={"Tipo do equipamento default"}
                />
              ) : null}
              {this.state.item.code.propr.textopadroes ? (
                <View style={styles.view61}>
                  <SelectInput
                    style={styles.selectinput21}
                    value={this.state.item.code.lay.textopadroes}
                    lista={this.props.activity.state.itens_textopadroes}
                    onChange={(value, data, index) => {
                      // console.log(data.id);
                      this.state.item.code.lay.textopadroes = value;
                      this.state.item.code.lay.textopadroes_id = data.id;
                      this.setState({
                        item: this.state.item,
                      });
                    }}
                    ref={(v) => (this.selectinput21 = v)}
                    label={"Texto padron"}
                  />
                  <TouchableOpacity
                    style={styles.bottom21}
                    onPress={() => {
                      this.open_modal_bottom2();
                    }}
                    icon
                  >
                    <Icon
                      style={styles.icon2}
                      fromFontFamily={"Material Icons"}
                      icon={"list"}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
              {this.state.item.code.propr.selet ? (
                <View style={styles.view3}>
                  <Text style={styles.label2} text={"Lista de opções"} />
                  {this.selet()}
                  {this.state.item.code.tag != "tbEQ" ? (
                    <View style={styles.view4}>
                      <TouchableOpacity
                        style={styles.bottom1}
                        onPress={() => {
                          this.add();
                        }}
                        icon
                      >
                        <Icon
                          style={styles.icon1}
                          fromFontFamily={"Material Icons"}
                          icon={"add"}
                        />
                      </TouchableOpacity>
                    </View>
                  ) : null}
                </View>
              ) : null}
              {this.state.item.code.tag == "tbEQ" ? (
                <TouchableOpacity
                  style={styles.bottom3}
                  onPress={() => {
                    this.open_modal_bottom3();
                  }}
                >
                  <Icon
                    style={styles.icon3}
                    fromFontFamily={"Material Icons"}
                    icon={"view_list"}
                  />
                  <Text style={styles.label4} text={"Lista de Ferramentas"} />
                </TouchableOpacity>
              ) : null}
              {this.state.item.code.tag == "ocorrencias" ? (
                <TouchableOpacity
                  style={styles.bottom3}
                  onPress={() => {
                    this.open_modal_bottom4();
                  }}
                >
                  <Icon
                    style={styles.icon3}
                    fromFontFamily={"Material Icons"}
                    icon={"view_list"}
                  />
                  <Text style={styles.label4} text={"Lista de Sugestão"} />
                </TouchableOpacity>
              ) : null}
              {this.state.item.code.propr.tag ? (
                <TextInput
                  style={styles.textinput3}
                  value={this.state.item.code.lay.tag}
                  onChange={(value) => {
                    this.state.item.code.lay.tag = value;

                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.tag = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={false}
                  label={"tag para calculo ou Teste"}
                />
              ) : null}

              {this.state.item.code.propr.calculo ? (
                <TextInput
                  style={styles.textinput4}
                  value={this.state.item.code.lay.calculo}
                  onChange={(value) => {
                    this.state.item.code.lay.calculo = value;

                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.calculo = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={true}
                  label={"Calculo"}
                />
              ) : null}
              {this.state.item.code.propr.test ? (
                <TextInput
                  style={styles.textinput4}
                  value={this.state.item.code.lay.test}
                  onChange={(value) => {
                    this.state.item.code.lay.test = value;

                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.calculo = v)}
                  keyboardType={"default"}
                  secureTextEntry={false}
                  multiline={true}
                  label={"Teste"}
                />
              ) : null}
              {this.state.item.code.propr.link ? (
                <File
                  style={styles.link}
                  value={this.state.item.code.lay.link}
                  onChange={(value) => {
                    this.state.item.code.lay.link = value;
                    this.props.activity.setState({ alterar: true });
                  }}
                  ref={(v) => (this.link = v)}
                />
              ) : null}
              {this.state.item ? (
                <View style={styles.view5}>
                  <Text style={styles.label3} text={"Não Ir para o pdf"} />
                  <Switch
                    style={styles.switch}
                    value={this.state.item.code.lay.naoir}
                    onChange={(value) => {
                      this.state.item.code.lay.naoir = value;
                    }}
                    ref={(v) => (this.naoir = v)}
                  />
                </View>
              ) : null}
              {this.state.item ? (
                <View style={styles.view5}>
                  <Text
                    style={styles.label3}
                    text={"Não Ir para o formularios"}
                  />
                  <Switch
                    style={styles.switch}
                    value={this.state.item.code.lay.naoirF}
                    onChange={(value) => {
                      this.state.item.code.lay.naoirF = value;
                    }}
                    ref={(v) => (this.naoirF = v)}
                  />
                </View>
              ) : null}
              {this.state.item &&
              this.state.item.code.tag != "View" &&
              this.state.item.code.tag != "ViewEdit" &&
              this.state.item.code.tag != "Text" &&
              this.state.item.code.tag != "check" ? (
                <View style={styles.view5}>
                  <Text
                    style={styles.label3}
                    text={"Preenchimento obrigatorio"}
                  />
                  <Switch
                    style={styles.switch}
                    value={this.state.item.code.lay.obrigatorio}
                    onChange={(value) => {
                      this.state.item.code.lay.obrigatorio = value;
                    }}
                    ref={(v) => (this.obrigatorio = v)}
                  />
                </View>
              ) : null}

              {this.state.item ? (
                <View style={styles.view2}>
                  <TouchableOpacity
                    style={styles.bottom}
                    onPress={() => {
                      // console.log(this.state.item);
                      if (!this.state.item.code.propr.culunm) {
                        this.deleteCom();
                      } else {
                        this.deleteComV();
                      }
                    }}
                  >
                    <Text style={styles.label1} text={"Remover item"} />
                    <Icon
                      style={styles.icon}
                      fromFontFamily={"Material Icons"}
                      icon={"delete"}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
              <div style={{ fontSize: 10 }}>{this.state.item.objectId}</div>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 5,
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    marginTop: 10,
  },
  view1: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 15,
    display: null,
  },

  scroll: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  view61: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5,
    marginBottom: 5,
  },
  selectinput21: {
    color: "rgba(0,0,0,1)",
    minHeight: 40,
    alignSelf: "stretch",
    flexWrap: "nowrap",
    flex: 1,
  },
  bottom21: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 35,
    borderRadius: 18,
    marginLeft: 5,
    marginTop: 10,
    backgroundColor: "rgba(7,81,120,1)",
  },
  icon2: {
    color: "rgba(255,255,255,1)",
    fontSize: 25,
  },
  textinput: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
  },
  textinput1: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  selectinput: {
    minHeight: 40,
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  textinput3: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  bottom3: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    backgroundColor: "rgba(7,81,120,1)",
    marginTop: 10,
    marginBottom: 5,
  },
  icon3: {
    color: "rgba(255,255,255,1)",
    fontSize: 25,
    marginRight: 10,
  },
  label4: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontSize: 16,
  },
  textinput4: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  selectinput1: {
    minHeight: 40,
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  view2: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    marginTop: 20,
  },
  link: {
    alignSelf: "auto",
    maxWidth: 200,
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    width: 170,
    backgroundColor: "rgba(7,81,120,1)",
    padding: 5,
  },
  view5: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 10,
  },
  lista: {
    minHeight: 40,
    alignSelf: "stretch",
    flexWrap: "nowrap",
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 12,
  },
  switch: {
    alignSelf: "auto",
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "auto",
    flex: 1,
  },
  view3: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 10,
    padding: 5,
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    fontSize: 11,
  },
  textinput2: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 5,
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    flexDirection: "column",
    flexWrap: "nowrap",
    borderRadius: 18,
    width: 36,
    backgroundColor: "rgba(7,81,120,1)",
    margin: 5,
  },
  icon1: {
    fontSize: 25,
    color: "rgba(255,255,255,1)",
  },
  icon: {
    fontSize: 25,
    color: "rgba(255,255,255,1)",
  },
});
