import React from 'react';
import {
    withStyles, Card, CardHeader, CardContent, CardActions, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';




class ProfileCard extends React.Component{
    render(){
        const { classes, subtitle, title, description, footer, avatar } = this.props;
        return (
            <Card className={classes.card}>
                {avatar&&<CardHeader
                    classes={{
                        root: classes.cardHeader,
                        avatar: classes.cardAvatar,
                    }}
                    avatar={
                        <img src={avatar} alt="..." className={classes.img}/>
                    }
                />}
                <CardContent className={classes.textAlign}>
                    {subtitle !== undefined ? (<Typography component="h6" className={classes.cardSubtitle}>
                        {subtitle}
                    </Typography>):null}
                    {title !== undefined ? (<Typography component="h4" className={classes.cardTitle}>
                        {title}
                    </Typography>):null}
                    {description !== undefined ? (<Typography component="p" className={classes.cardDescription}>
                        {description}
                    </Typography>):null}
                </CardContent>
                <CardActions className={classes.textAlign + " " + classes.cardActions}>
                    {footer}
                </CardActions>
            </Card>
        );
    }
}

ProfileCard.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    description: PropTypes.node,
    footer: PropTypes.node,
    avatar: PropTypes.string
};

const profileCardStyle = {
    card: {
        marginTop: '30px',
        textAlign: 'center',
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        margin: '25px 0',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        borderRadius: '3px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    cardHeader: {
        display: 'inline-block',
        width: '100%',
        padding: '0px',
    },
    cardAvatar: {
        maxWidth: '130px',
        maxHeight: '130px',
        margin: '5px auto 0',
        borderRadius: '50%',
        overflow: 'hidden',
        boxShadow: '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    },
    img: {
        width: '100%',
        height: 'auto',
        verticalAlign: 'middle',
        border: '0',
    },
    textAlign: {
        textAlign: 'center'
    },
    cardSubtitle: {
        color: '#666',
        fontSize: '1em',
        textTransform: 'uppercase',
        marginTop: '10px',
        marginBottom: '10px',
    },
    cardTitle: {
        fontSize: '1.3em',
        marginTop: '10px',
        marginBottom: '10px',
    },
    cardDescription: {
        padding: '15px 20px',
        margin: '0 0 10px',
    },
    cardActions: {
        height: 'auto',
        display: 'inline'
    }
};


export default withStyles(profileCardStyle)(ProfileCard);
