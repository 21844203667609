import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Avatar from "@material-ui/core/Avatar";
import { SelectInput } from "../../components/SelectInput";
import TextInput from "react-1app/lib/TextInput";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: this.props.list,
      listClasse: [
        { text: "Vasos de Pressão", value: "vasos" },
        { text: "Caldeiras", value: "caldeiras" },
        { text: "Tubulação", value: "tubulacao" },
        { text: "Todos", value: "todos" }
      ]
    };
  }

  componentDidMount() {}

  salvar_bottom2() {
    this.setState({
      save: true
    });
    this.props.sug_ocorrencias.setMetodoApi("salvar", "POST");
    this.props.sug_ocorrencias.salvarNuven(re => {
      this.setState({
        save: false
      });
      this.props.activity.buscarDados()
      OpenSnack({ message: "Salvo", type: "success" });
      this.props.closeModal();
    });
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <View>
          <TouchableOpacity
            onPress={() => this.props.closeModal()}
            style={{ marginRight: 10 }}
            icon
            color={"default"}
            variant="outlined"
          >
            <Icon
              fromFontFamily={"Material Design Icons"}
              name={"chevron-left"}
            />
          </TouchableOpacity>
          <Text style={styles.label}>{"Sugestão"}</Text>
          <TouchableOpacity
            style={{ marginLeft: "auto" }}
            onPress={() => this.salvar_bottom2()}
            children={"Salvar"}
            variant="outlined"
            load={this.state.load}
          ></TouchableOpacity>
        </View>
        <TextInput
          style={styles.titulo}
          value={this.props.sug_ocorrencias.titulo}
          onChange={value => {
            this.props.sug_ocorrencias.titulo = value;
            this.setState({
              sug_ocorrencias: this.props.sug_ocorrencias
            });
          }}
          ref={v => (this.titulo = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Titulo"}
        />
        <SelectInput
          style={styles.selectinput}
          value={this.props.sug_ocorrencias.tipo}
          lista={[
            {
              value: "Recomendação NR-13",
              text: "Recomendação NR-13",
              test: 1
            },
            {
              value: "Recomendação Diversa",
              text: "Recomendação Diversa",
              test: 2
            }
          ]}
          onChange={(value, data, index) => {
            this.props.sug_ocorrencias.tipo = value;
            this.props.sug_ocorrencias.test = data.test;

            this.setState({
              sug_ocorrencias: this.props.sug_ocorrencias
            });
          }}
          ref={v => (this.selectinput = v)}
          label={"Tipo da ocorrências"}
        />
        <TextInput
          style={styles.descricao}
          value={this.props.sug_ocorrencias.descricao}
          onChange={value => {
            this.props.sug_ocorrencias.descricao = value;
            this.setState({
              sug_ocorrencias: this.props.sug_ocorrencias
            });
          }}
          ref={v => (this.descricao = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={true}
          label={"Descricão"}
        />
        <SelectInput
          style={styles.selectinput}
          value={this.props.sug_ocorrencias.classe}
          lista={this.state.listClasse}
          onChange={(value, data, index) => {
            this.props.sug_ocorrencias.classe = value;
            // this.props.sug_ocorrencias.test = data.test ;

            this.setState({
              sug_ocorrencias: this.props.sug_ocorrencias
            });
          }}
          ref={v => (this.selectinput = v)}
          label={"Tipo do equipamentos"}
        />
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    alignItems: "center"
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
