import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import View from "react-1app/lib/View";
import StyleSheet from "react-1app/lib/StyleSheet";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View style={styles.content}>
        <img
          src={"/img/logomegasteam25anos2.png"}
          alt="..."
          style={styles.image}
        />
        <View style={styles.view2}>
          <LinearProgress color="primary" style={styles.progress} />
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 200
  },
  progress: {
    width: 300
  },
  image: {
    width: 200,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  view2: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: 300,
    height: 10,
    marginTop: 20
  }
});
