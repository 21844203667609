import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Icon from "react-1app/lib/Icon";
import styleGlobal from "../../../styleGlobal";
import View from "react-1app/lib/View";
import Text from "react-1app/lib/Text";

class StatsCard extends React.Component {
  render() {
    const { classes, title, description, small, statText } = this.props;

    return (
      <View type={"Paper"} className={classes.card}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            avatar: classes.cardAvatar
          }}
          avatar={
            <Icon
              name={this.props.icon}
              style={{ fontSize: null }}
              className={classes.cardIcon}
            />
          }
        />
        <CardContent className={classes.cardContent}>
          <Typography component="p" className={classes.cardCategory}>
            {title}
          </Typography>
          {description || description === 0 ? (
            <Typography
              type="headline"
              component="h2"
              className={classes.cardTitle}
            >
              {description}{" "}
              {small !== undefined ? (
                <small className={classes.cardTitleSmall}>{small}</small>
              ) : null}
            </Typography>
          ) : null}
        </CardContent>
        {statText ? (
          <CardActions className={classes.cardActions}>
            <div className={classes.cardStats}>
              {this.props.statIcon && (
                <Icon
                  name={this.props.statIcon}
                  className={classes.cardStatsIcon}
                />
              )}{" "}
              {statText !== undefined ? (
                <Text className={classes.text} color="default">
                  {statText}
                </Text>
              ) : null}
            </div>
          </CardActions>
        ) : null}
      </View>
    );
  }
}

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont
};

let styles = tema => ({
  cardHeader: {
    margin: "-15px 15px 0",
    borderRadius: "3px",
    padding: "7px",
    float: "left",
    textAlign: "center",
    background: tema.palette.primary.main,
    boxShadow:
      "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
  },
  card: {
    ...styleGlobal.CardBody,
    marginTop: 50,
    padding: 0,
    paddingBottom: 5
  },
  cardContent: {
    textAlign: "right",
    paddingTop: "10px",
    padding: "15px 20px"
  },
  cardIcon: {
    width: "40px",
    height: "36px",
    fontSize: 36,
    fill: "#fff",
    color: "#fff"
  },
  cardAvatar: {
    margin: "8px"
  },
  cardCategory: {
    marginBottom: "0",
    margin: "0 0 10px",
    ...defaultFont
  },
  cardTitle: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.625em",
    fontWeight: "bold"
  },
  cardTitleSmall: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1"
  },
  cardActions: {
    ...cardActions,
    padding: "0!important"
  },
  cardStats: {
    lineHeight: "22px",
    fontSize: "12px",
    margin: "0!important",
    fontWeight: "bold",
    display: "flex"
  },
  cardStatsIcon: {
    position: "relative",
    top: "7px",
    width: "25px",
    height: "25px"
  },
  text: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: "12px"
  }
});
export default withStyles(styles)(StatsCard);
