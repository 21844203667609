import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import ImageUpload from "react-1app/lib/ImageUpload";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Formularios from "../formularios";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment";
import Icon from "react-1app/lib/Icon";
import EditOrdem from "./EditOrdem";
import * as Almoxarifado from "../../worker/almoxarifado";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produto:this.props.produto,
      load: false,
      value: 0
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.produto != this.props.produto) {
      // setTimeout( ()=> {
      this.setState({
        produto: nextProps.produto,
        value: 0
      });
      // }, 10);
    }
    return true;
  }

  salvar() {
    let { produto } = this.state;
    let res = (dados, error) => {
      this.setState({
        load: false,
        produto: dados
      });
      if (this.props.atualizar) this.props.atualizar(produto);
    };
    this.setState({
      load: true
    });
    if (produto.id) {
      Almoxarifado.atualizarProduto(produto, res);
    } else {
      Almoxarifado.salvarProduto(produto, res);
    }
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.setState({
            load2: true
          });
          Almoxarifado.deletarProduto(this.state.produto, (res,error)=>{
            if(error) alert(error.msg);
            if (this.props.atualizar) this.props.atualizar({});
            this.setState({
              load2: false
            });
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  open_ordem(item) {
    this.props.navigator.openModal({
      component: EditOrdem,
      props: {
        heranca: this.state,
        activity: this,
        item
      }
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container spacing={16}>
        <View style={styleGlobal.Grid} xs={12} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View style={{ marginBottom: 10, padding: 5 }}>
              <Text style={styles.label}>{"Produto"}</Text>
              <TouchableOpacity
                style={{ marginLeft: "auto" }}
                variant={this.state.value == 0 ? "contained" : "outlined"}
                children={"Info"}
                onPress={() => this.setState({ value: 0 })}
              />
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                variant={this.state.value == 1 ? "contained" : "outlined"}
                children={"Ordem"}
                onPress={() => this.setState({ value: 1 })}
              />
            </View>
            {this.state.value == 0 ? (
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 20
                }}
              >
                <TextInput
                  style={styles.nome}
                  value={this.state.produto.nome}
                  onChange={value => {
                    this.state.produto.nome = value;
                    this.setState({ produto: this.state.produto });
                  }}
                  ref={v => (this.nome = v)}
                  keyboardType={"default"}
                  label={"Nome"}
                />
                <TextInput
                  style={styles.cnpj}
                  value={this.state.produto.descricao}
                  onChange={value => {
                    this.state.produto.descricao = value;
                    this.setState({ produto: this.state.produto });
                  }}
                  ref={v => (this.cnpj = v)}
                  label={"Descrição"}
                  multiline={true}
                />
                <Text style={styles.label1} text={"Imagem para Capa"} />
                <ImageUpload
                  style={styles.image}
                  value={this.state.produto.image}
                  onChange={value => {
                    this.state.produto.image = value;
                    this.setState({
                      produto: this.state.produto
                    });
                  }}
                  label={"Imagem para Capa"}
                  resizeMode={"cover"}
                />
                <TextInput
                  style={styles.texto}
                  value={this.state.produto.texto}
                  onChange={value => {
                    this.state.produto.texto = value;
                    this.setState({
                      produto: this.state.produto
                    });
                  }}
                  keyboardType={"default"}
                  label={"Texto descritivo capa"}
                />
              </View>
            ) : null}
            {this.state.value == 1 ? (
              <ListView
                ListHeaderComponent={() => (
                  <View style={styles.head}>
                    <ListSubheader component="div">
                      {"Lista de ordem para o PDF"}
                    </ListSubheader>
                    <TouchableOpacity
                      children={"Novo"}
                      style={{ marginLeft: "auto" }}
                      variant="outlined"
                      onPress={() => {
                        this.open_modal_bottom1({});
                      }}
                    />
                  </View>
                )}
                renderItem={({ item, index }) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ width: "100%" }}
                      onClick={event => this.open_ordem(item)}
                    >
                      <Avatar src={item.logo}>
                        <Icon name={"sort"} />
                      </Avatar>
                      <ListItemText
                        primary={item.nome}
                        secondary={moment(item.createdAt).format("DD/MM/YY")}
                      />
                      <ListItemSecondaryAction>
                        <Icon
                          color={"primary"}
                          name={"created"}
                          style={styles.icon}
                          onClick={() => {
                            this.open_ordem(item);
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
                separator
                data={this.state.produto.lista}
              />
            ) : null}
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.deletar()}
                children={"Delete"}
                color={"secondary"}
                variant="outlined"
                disabled={!this.state.produto.objectId}
                load={this.state.load2}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styleGlobal.Grid} xs={12} lg={6} item>
          {this.props.produto && this.props.produto.id ? (
            <View {...this.props} produto={this.props.produto} />
          ) : null}
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
    // marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginTop: 10
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  },
  image: {
    width: 250,
    height: 250,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderColor: "rgba(150,150,145,1)",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 3
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    fontSize: 13,
    marginTop: 20
  }
});
