"use strict";
import React, { Component } from "react";

import { TextInput, Table, TouchableOpacity, Icon, View } from "react-1app";

import TableSelect from "../../components/TableSelect";

import { dadosRelatorio, gerarPdfInspetores } from "../../worker/dados";
import FilterList from "@material-ui/icons/FilterList";

import TextField from "@material-ui/core/TextField";
import { Dados, Util, Query } from "../../infra";
import moment from "moment";
import ModalIframe from "../../components/ModalIframe";

export default class RelatorioInspetor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      heranca: {},
      item: {},
      select: [],
      lista: [],
      listaData: [],
      head: [],
      inicio: moment()
        .subtract(12, "months")
        .format("MM/YY"),
      final: moment().format("MM/YY")
    };
  }

  componentDidMount() {
    this.get_data_user();
    // this.getDagos();
  }

  get_data_user() {
    var query = new Query("user_local");
    this.setState({ load_user: true });
    query.addAscendingOrder("nome");
    query.whereNotExists("programador");
    query.whereEqualTo("campo", true);
    if (this.state.text) query.whereContains("nome", this.state.text);
    query.setLimit(1000);
    query.select((lista = []) => {
      this.setState({
        lista,
        load_user: false
      });
      this.selectAll();
    });
  }
  getDagos() {
    let { select, inicio, final } = this.state;
    dadosRelatorio(
      {
        listUser: select,
        inicio: moment(inicio, "MM/YY").toJSON(),
        final: moment(final, "MM/YY").toJSON()
      },
      ({ list, head }, error) => {
        this.setState({ listaData: list, head });
        window.focusElementById("tabela");
      }
    );
  }
  isChecado(index) {
    let item = this.state.lista[index];
    return this.state.select.find(x => x === item.objectId);
  }
  selectItem(index) {
    let { lista } = this.state;
    let { select } = this.state;
    if (this.isChecado(index)) {
      select.splice(select.findIndex(e => e == lista[index].objectId), 1);
    } else {
      select.push(lista[index].objectId);
    }
    this.setState({ select });
  }
  selectAll() {
    // console.log(99999);
    let { lista } = this.state;
    let { select } = this.state;
    if (select.length == lista.length) {
      select = [];
    } else {
      lista.map(item => {
        select.push(item.objectId);
      });
      select = select.filter((este, i) => select.indexOf(este) == i);
    }
    this.setState({ select });
  }
  montarHead() {
    let { lista, listaData, head } = this.state;
    return head;
  }
  montarData() {
    let { lista, listaData } = this.state;
    return listaData;
    // let list=listaData.map((row)=>{
    //   let listRow=[];
    //   for (var prop in row) {
    //     listRow.push(row[prop])
    //   }
    //   return listRow;
    //   //       listRow.push(row.inspecao)
    // })
    //
    // return list;
  }
  gerarPdf() {
    let { select, inicio, final } = this.state;
    this.setState({ gerando: true });
    gerarPdfInspetores(
      {
        listUser: select,
        inicio: moment(inicio, "MM/YY").toJSON(),
        final: moment(final, "MM/YY").toJSON(),
        key_user: this.state.usuario.objectId,
        isBase64: true
      },
      (caminho, error) => {
        this.setState({ gerando: false });
        if (error) return alert("Erro ao gerar o pdf");

        // var wnd = window.open(caminho.nome);
        // wnd.print();
        // console.log(caminho);
        try {
          this.verPdf(caminho.base64);
        } catch (e) {
          console.log(e);
        }
      }
    );
  }

  verPdf(pdf) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src: pdf
      }
    });
  }

  render() {
    let { lista } = this.state;
    let { select } = this.state;
    // console.log(this.state);
    return (
      <View container spacing={16}>
        <View item xs={12} md={4}>
          <TableSelect
            tableHead={["Nome", "funcao"]}
            title="Inspetores"
            tableData={lista.map((item, index) => [item.nome, item.funcao])}
            valueSelect={this.state.select}
            selectItem={index => this.selectItem(index)}
            selectAll={() => this.selectAll()}
            isSelected={index => Boolean(this.isChecado(index))}
            actionBar={[
              <TextInput
                style={{ minHeight: 35, width: 70 }}
                value={this.state.inicio}
                onChange={inicio =>
                  this.setState({ inicio: Util.maskFormate(inicio, "99/99") })
                }
                label="Inicio"
                placeholder="MM/AA"
              />,
              <TextInput
                style={{ minHeight: 35, width: 70 }}
                value={this.state.final}
                onChange={final =>
                  this.setState({ final: Util.maskFormate(final, "99/99") })
                }
                label="Final"
                placeholder="MM/AA"
              />,
              <>
                <TouchableOpacity
                  icon
                  aria-label="Filter list"
                  onClick={() => {
                    this.getDagos();
                  }}
                >
                  <Icon name={"search"} />
                </TouchableOpacity>
                <TouchableOpacity
                  icon
                  aria-label="pdf"
                  onClick={() => this.gerarPdf()}
                >
                  {!this.state.gerando ? (
                    <Icon name={"picture_as_pdf"} />
                  ) : (
                    <Icon name={"loop"} />
                  )}
                </TouchableOpacity>
              </>
            ]}
          />
        </View>
        <View item id={"tabela"} xs={12} md={8}>
          <Table tableHead={this.montarHead()} tableData={this.montarData()} />
        </View>
      </View>
    );
  }
}
