import React, { Component, useState } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import ImageUpload from "react-1app/lib/ImageUpload";
import Progress from "react-1app/lib/Progress";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ListView from "react-1app/lib/ListView";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Icon from "react-1app/lib/Icon";
import ListSubheader from "@material-ui/core/ListSubheader";
import Edit from "../usuarios/Edit";
import { OpenDialog, Switch } from "react-1app";
import List from "../comercial/List";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresas: new Dados("empresas").parse(this.props.empresas),
      load: true,
      list: [],
      value: 0,
      usuario: null
    };
  }

  componentDidMount() {
    this.get_data_user();
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.empresas.salvarNuven(re => {
      this.setState({
        load: false
      });
    });
  }

  get_data_user() {
    var query = new Query("user_local");

    query.addAscendingOrder("nome");
    query.whereEqualTo("user", true);
    query.whereEqualTo("empresas", this.state.empresas.objectId);
    query.setLimit(100);
    query.select(lista => {
      console.log(lista);
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  get_data_unidades() {
    if (!this.state.usuario.lista) {
      this.state.usuario.lista = [];
      return this.setState({ load_unidades: false });
    }

    var query = new Query("unidades");
    query.setWhereServer({
      objectId: {
        $in: this.state.usuario.lista
      }
    });
    query.addAscendingOrder("nome");
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_unidades: lista,
        load_unidades: false
      });
    });
  }

  salvar_bottom() {
    console.log(2);
    this.setState({
      save: true
    });
    this.state.usuario.salvarNuven(re => {
      this.setState({
        save: false
      });
      console.log(this.state.usuario);
      this.get_data_unidades();
    });
  }

  vincular() {
    OpenDialog({
      title: "Vincular unidades",
      action: [
        {
          label: "Salvar",
          onClick: () => {
            this.salvar_bottom();
          }
        },
        { label: "Cancelar" }
      ],
      Component: () => (
        <View>
          <UnidadesSelect
            usuario={this.state.usuario}
            empresas={this.state.empresas}
          />
        </View>
      )
    });
  }

  vincularLocal(unidades) {
    OpenDialog({
      title: "Vincular Locais",
      action: [
        {
          label: "Salvar",
          onClick: () => {
            this.salvar_bottom();
          }
        },
        { label: "Cancelar" }
      ],
      Component: () => (
        <View>
          <LocaisSelect
            unidades={unidades}
            usuario={this.state.usuario}
            empresas={this.state.empresas}
          />
        </View>
      )
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container spacing={16}>
        <View style={styleGlobal.Grid} xs={6} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Usuários"}</ListSubheader>
                  <TouchableOpacity
                    style={{ marginLeft: "auto" }}
                    children={"Adicionar"}
                    onPress={() => {
                      this.setState({
                        usuario: {},
                        id: null,
                        load_unidades: false,
                        itens_unidades: []
                      });
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.id === item.id}
                    onClick={event => {
                      if (this.state.id !== item.id) {
                        this.setState(
                          {
                            usuario: item,
                            id: item.id,
                            load_unidades: true,
                            itens_unidades: []
                          },
                          () => {
                            this.get_data_unidades();
                          }
                        );
                      }
                    }}
                  >
                    <Avatar src={item.logo}>
                      <Icon
                        style={styles.icon}
                        fromFontFamily={"Material Design Icons"}
                        name={"account"}
                      />
                    </Avatar>
                    <ListItemText primary={item.nome} secondary={item.funcao} />
                  </ListItem>
                );
              }}
              separator
              data={this.state.list}
              load={this.state.load}
            />
          </View>
        </View>
        <View style={{ marginTop: 10 }} xs={6} item>
          {this.state.usuario && this.state.usuario.id ? (
            <View type={"Paper"}>
              <ListView
                ListHeaderComponent={() => (
                  <View style={styles.head}>
                    <ListSubheader component="div">{"Unidades"}</ListSubheader>
                    <TouchableOpacity
                      style={{ marginLeft: "auto" }}
                      children={"Adicionar"}
                      onPress={() => {
                        this.vincular();
                      }}
                    />
                  </View>
                )}
                renderItem={({ item, index }) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ width: "100%" }}
                      selected={this.state.id === item.id}
                      onClick={event =>
                        this.setState({ usuario: item, id: item.id })
                      }
                    >
                      <Avatar src={item.logo}>
                        <Icon style={styles.icon} name={"business"} />
                      </Avatar>
                      <ListItemText
                        primary={item.nome}
                        secondary={item.funcao}
                      />
                      <ListItemSecondaryAction>
                        <TouchableOpacity
                          children={"Locais"}
                          onPress={() => {
                            this.vincularLocal(item);
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
                separator
                data={this.state.itens_unidades}
                load={this.state.load_unidades}
              />
            </View>
          ) : null}
          {this.state.usuario && !this.state.usuario.id ? (
            <Edit
              user_local={{
                user: true,
                empresas: this.state.empresas.objectId
              }}
              activity={this}
              onChange={usuario => {
                this.setState(
                  {
                    usuario: usuario,
                    id: usuario.id,
                    load_unidades: false,
                    itens_unidades: []
                  },
                  () => {
                    this.get_data_user();
                  }
                );
              }}
            />
          ) : null}
        </View>
      </View>
    );
  }
}

class UnidadesSelect extends Component {
  state = {};
  render() {
    return (
      <List
        parametros={{
          entidade: "unidades",
          order: "nome",
          where: [["empresas", this.props.empresas.objectId]]
        }}
        key_label={"nome"}
        onChange={item => {
          // this.openIsLocal(item);
        }}
        // open={item => {
        //   // this.openIsLocal(item);
        // }}
        title={"Unidade"}
        renderAction={item => (
          <Switch
            style={styles.adm}
            value={(this.props.usuario.lista || []).find(
              a => item.objectId === a
            )}
            onChange={value => {
              if (!this.props.usuario.lista) this.props.usuario.lista = [];
              if (value) {
                this.props.usuario.lista.push(item.objectId);
              } else {
                this.props.usuario.lista = this.props.usuario.lista.filter(
                  a => item.objectId !== a
                );
              }
              console.log(this.props.usuario.lista);
              this.setState({ lista: this.props.usuario.lista });
            }}
          />
        )}
      />
    );
  }
}
class LocaisSelect extends Component {
  state = {};
  render() {
    console.log(this.props.usuario.lista_locais);
    return (
      <List
        parametros={{
          entidade: "locais",
          order: "nome",
          where: [
            ["empresas", this.props.empresas.objectId],
            ["unidades", this.props.unidades.objectId]
          ]
        }}
        key_label={"nome"}
        onChange={item => {
          // this.openIsLocal(item);
        }}
        // open={item => {
        //   // this.openIsLocal(item);
        // }}
        title={"Locais"}
        renderAction={item => (
          <Switch
            style={styles.adm}
            value={(this.props.usuario.lista_locais || []).find(
              a => item.objectId === a
            )}
            onChange={value => {
              if (!this.props.usuario.lista_locais)
                this.props.usuario.lista_locais = [];
              if (value) {
                this.props.usuario.lista_locais.push(item.objectId);
              } else {
                this.props.usuario.lista_locais = this.props.usuario.lista_locais.filter(
                  a => item.objectId !== a
                );
              }
              this.setState({ lista_locais: this.props.usuario.lista_locais });
            }}
          />
        )}
      />
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
