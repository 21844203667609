import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import Image from "react-1app/lib/Image";
import Progress from "react-1app/lib/Progress";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import { getGraficoAno } from "../../worker/dados";
import StatsCard from "../dashboard/Cards/StatsCard.js";
import ChartCard from "../dashboard/Cards/ChartCard.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../dashboard/Cards/charts";
import moment from "moment";
import ChartistGraph from "react-chartist";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      load: true,
      value: 0,
      empresa: {}
    };
  }

  componentDidMount() {
    this.buscar();
    this.getDados();
  }
  getHeight(list, name, add) {
    add = add ? add : 30;
    let init = 0;
    if (list && list[0]) {
      let newL = list.map(d => ({ iTotal: d[name] }));
      for (var i = 0; i < newL.length; i++) {
        let t = Number(newL[i].iTotal);
        // console.log(newL[i]);
        if (t > init) init = t;
      }
    }
    return init + init * 0.05;
  }

  tratarList(lista = []) {
    let data = { labels: [], series: [] };
    data.labels = lista.map(item => item.mes_ano);
    data.series = [lista.map(item => item.inspecao)];
    return data;
  }

  buscar() {
    var query = new Query("empresas");
    query.addAscendingOrder("nome");
    query.whereEqualTo("objectId", this.state.usuario.empresas);
    query.setLimit(1);
    query.first(data => {
      this.setState({
        empresa: data,
        load: false
      });
    });
  }

  getDados() {
    getGraficoAno(this.state.usuario.objectId, (list, error) => {
      this.setState({ lista: list, loadGrafico: false });
    });
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <Text style={styles.label}>{"Empresa"}</Text>
        {this.state.load ? (
          <Progress />
        ) : (
          [
            <Image style={styles.logo} resizeMode={"contain"} src={this.state.empresa.logo} />,
            <TextInput
              style={styles.nome}
              value={this.state.empresa.nome}
              disabled={true}
              label={"Razão Social"}
            />
          ]
        )}
        <ChartCard
          chart={
            <ChartistGraph
              className="ct-chart"
              data={this.tratarList(this.state.lista)}
              // data={emailsSubscriptionChart.data}
              type="Bar"
              options={{
                ...emailsSubscriptionChart.options,
                high: this.getHeight(this.state.lista, "inspecao")
              }}
              responsiveOptions={emailsSubscriptionChart.responsiveOptions}
              listener={emailsSubscriptionChart.animation}
            />
          }
          chartColor="darkgray"
          title={"Inspeções por mês"}
          text="Inspeções no período de 1 ano"
          statIcon={"access_time"}
          statIconColor="darkgray"
          statText={
            "Atualizado em " +
            moment().format("DD") +
            " de " +
            moment().format("MMMM") +
            " de " +
            moment().format("YYYY")
          }
        />
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    backgroundSize: "contain",
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 20,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
