import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Local from "../local";
import Edit from "./Edit";

// import styleGlobal from "../../styleGlobal";
import moment from "moment";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.empresas != this.props.empresas) {
      setTimeout(() => {
        this.buscar();
      }, 10);
      this.setState({ unidade: null });
    }
    return true;
  }

  buscar(pesquisa) {
    var query = new Query("unidades");
    if (pesquisa) query.whereContains("nome", pesquisa);
    query.addAscendingOrder("nome");
    query.whereEqualTo("empresas", this.props.empresas.objectId);
    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue+"^", "i");
    const regex2 = new RegExp(escapedValue, "g");

    return list.filter(item => item.nome&&(item.nome.toLowerCase().match(regex2)||item.nome.toLowerCase().match('^' + escapedValue) ||regex.test(item.nome.toLowerCase())));
  }

  componentWillUnmount() {}

  open_modal_bottom1(unidades) {
    this.props.navigator.openModal({
      component: Edit,
      props: {
        heranca: this.state,
        activity: this,
        unidades,
        new: !unidades.objectId,
        atualizar: unidade => {
          this.buscar();
          if (unidade) this.setState({ unidade });
        }
      }
    });
  }

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={6} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Unidades"}</ListSubheader>
                  <TextInput
                    label="Filtrar empresas"
                    style={styles.input}
                    placeholder="Pesquisa por nome."
                    onChange={pesquisa => {
                      this.state.parametros.page=0;
                      this.setState({ pesquisa });
                    }}
                    value={this.state.pesquisa}
                    inputNative
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    variant="outlined"
                    onPress={() => {
                      this.open_modal_bottom1({
                        empresas: this.props.empresas.objectId
                      });
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.id === item.id}
                    onClick={event =>{
                      this.setState({ unidade: item, id: item.id });
                      window.focusElementById("local");
                    }}
                  >
                    <Avatar src={item.logo}>
                      <Icon name={"business"} />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={moment(item.createdAt).format("DD/MM/YY")}
                    />
                    <ListItemSecondaryAction>
                      <Icon
                        color={"primary"}
                        name={"created"}
                        style={styles.icon}
                        onClick={() => {
                          this.open_modal_bottom1(item);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }}
              separator
              data={this.getList()}
              tablePagination
              count={Number(this.getSuggestions().length)}
              load={this.state.load}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, { page })
                });
              }}
              handleChangeRowsPerPage={event => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  })
                });
              }}
            />
          </View>
        </View>
        <View style={styles.view3} id={"local"} xs={12} md={6} item>
          {this.state.unidade ? (
            <Local {...this.props} unidades={this.state.unidade} />
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
