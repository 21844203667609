"use strict";
import React, { Component } from "react";
import { View, Dimensions, StyleSheet } from "react-1app"; ///  <KeyboardAvoidingView behavior={"padding"}
import { TextInput, SelectInput, Image } from "react-1app";
import { TouchableOpacity, Icon, Switch, Text } from "react-1app";
import TextPadrao from "./TextPadrao";
import { Dados } from "../../infra";
import moment from "moment";
import DatePicker from "../../components/DatePicker"
import InputAdornment from "@material-ui/core/InputAdornment";
export default class Comp2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldState: {},
      item: {},
      dados: new Dados("dados")
    };

    //{onConstructor}
  }

  atualizar() {
    this.setState({
      alterado: true
    });
  }
  //START CODE
  // usar(item) {
  //   // console.log(item.text);
  //   this.props.dados[item.objectId] = item;
  //   this.setState({
  //     alterado: true
  //   });
  // }
  usar(item) {
    // console.log(item.text);
    if (!this.props.dados[this.props.item.objectId]) {
      this.props.dados[this.props.item.objectId] = "";
    }
    this.props.dados[this.props.item.objectId] += item;
    this.setState({
      alterado: true
    });
  }
  open_modal_bottom2() {
    this.props.navigator.openModal({
      component: TextPadrao,
      props: {
        test: this.props.item.code.lay.textopadroes_id,
        oldState: this.props,
        activity: this
      }
    });
  }
  render() {
    const { code, ...item } = this.props.item;
    if (!code.test) return null;
    switch (code.tag) {
      case "Text":
        return (
          <View style={styles2.view}>
            <Text
              style={[
                styles2.label1,
                {
                  textAlign: code.lay.position ? code.lay.position : "left",
                  fontSize: code.lay.font ? code.lay.font : 14,
                  fontWeight: code.lay.stilo ? code.lay.stilo : "400"
                }
              ]}
              text={code.lay.text}
            />
          </View>
        );
      case "switch":
        return (
          <View style={styles2.view6}>
            <View style={styles2.view7}>
              <Text
                style={[
                  styles2.label1,
                  {
                    textAlign: code.lay.position ? code.lay.position : "left",
                    fontSize: code.lay.font ? code.lay.font : 14,
                    fontWeight: code.lay.stilo ? code.lay.stilo : "400"
                  }
                ]}
                text={
                  code.lay.obrigatorio
                    ? code.lay.text + " (Obrigatorio)"
                    : code.lay.text
                }
              />
            </View>
            <Switch
              style={styles2.switch}
              value={this.props.dados[item.objectId]}
              onChange={value => {
                this.props.dados[item.objectId] = value;
                this.setState({item:item});
              }}
              ref={v => (this.switch = v)}
            />
          </View>
        );
      case "TextInput":
        return (
          <View
            style={{
              alignSelf: "stretch",
              flex: 1,
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              margin: 2
            }}
          >
            <TextInput
              style={styles2.textinput}
              label={
                code.lay.obrigatorio
                  ? code.lay.descricao + " (Obrigatorio)"
                  : code.lay.descricao
              }
              keyboardType={code.lay.tipo_text ? code.lay.tipo_text : "default"}
              multiline={code.lay.multiline ? code.lay.multiline : false}
              value={this.props.dados[item.objectId]}
              disabled={code.lay.calculo ? true : false}
              onChange={value => {
                this.props.dados[item.objectId] = value;
                // if (code.lay.tag) {
                  this.props.activity.setState({
                    alterado: true
                  });
                // }
                // this.setState({item:item});
              }}
              ref={v => (this.textinput = v)}
              mask={code.lay.mascara ? code.lay.mascara : ""}
              InputProps={code.lay.textopadroes_id ?{
                endAdornment: (
                  <InputAdornment position="end">
                      <TouchableOpacity
                        style={styles2.bottom21}
                        onPress={() => {
                          this.open_modal_bottom2();
                        }}
                        icon
                      >
                        <Icon
                          style={styles2.icon21}
                          fromFontFamily={"Material Icons"}
                          icon={"list"}
                        />
                      </TouchableOpacity>
                  </InputAdornment>
                )
              }: null}
            />

          </View>
        );
      case "selectinput":
        return (
          <SelectInput
            style={styles2.selectinput}
            value={this.props.dados[item.objectId]}
            list={(code.lay.lista||[]).map(a=>({
              ...a,
              text:a.text||a["nome"]||a,
              value:a.value||a["objectId"]||a
            }))}
            key_label={"text"}
            key_value={"value"}
            onChange={(value, data, index) => {
              this.props.dados[item.objectId] = value;
              this.setState({alterado:true});
            }}
            ref={v => (this.selectinput = v)}
            label={
              code.lay.obrigatorio
                ? code.lay.descricao + " (Obrigatorio)"
                : code.lay.descricao
            }
          />
        );
      case "data":
      console.log(this.props.inspecoes[code.lay.value]);

        return (
          <DatePicker
            style={styles2.datepicker}
            value={this.props.inspecoes[code.lay.value]}
            type={"date"}
            onChange={value => {
              this.props.inspecoes[code.lay.value] = value;
              this.props.inspecoes[code.lay.value + "_br"] = moment(
                value
              ).format("DD/MM/YYYY");
              this.setState({alterado:true});
            }}
            ref={v => (this.datepicker = v)}
            label={
              code.lay.obrigatorio
                ? code.lay.descricao + " (Obrigatorio)"
                : code.lay.descricao
            }
          />
        );
      case "image":
        console.log(code.lay.link);
        return (
          <View
            style={[
              styles2.view13,
              {
                flexDirection: "row",
                justifyContent: code.lay.orientacao3
                  ? code.lay.orientacao3
                  : "flex-start",
                alignItems: code.lay.orientacao2
                  ? code.lay.orientacao2
                  : "flex-start"
                // "backgroundColor": "rgba(255,255,2,1)",
              }
            ]}
          >
            <Image
              style={[
                styles2.image,
                {
                  width: code.lay.largura ? code.lay.largura : 50,
                  height: code.lay.altura ? code.lay.altura : 50,
                  // maxWidth: Dimensions.get("window").width / 2 - 10
                }
              ]}
              resizeMode={"contain"}
              source={{ uri: code.lay.link }}
            />
          </View>
        );
      case "dataEdit":
        return (
          <DatePicker
            style={styles2.datepicker}
            type={"date"}
            value={this.props.dados[item.objectId]}
            onChange={value => {
              this.props.dados[item.objectId] = value;
              this.props.dados[item.objectId + "_br"] = moment(value).format(
                "DD/MM/YYYY"
              );

              this.setState({
                item: item
              });
            }}
            ref={v => (this.datepicker = v)}
            label={
              code.lay.obrigatorio
                ? code.lay.descricao + " (Obrigatorio)"
                : code.lay.descricao
            }
          />
        );
      case "check":
        return (
          <View style={{ alignSelf: "auto", width: 175, margin: 5 }}>
            <TouchableOpacity
              style={[styles2.bottom, { margin: 5 },{
                textAlign: code.lay.position ? code.lay.position : "left",
                fontSize: code.lay.font ? code.lay.font : 14,
                fontWeight: code.lay.stilo ? code.lay.stilo : "400"
              }]}
              elevation={3}
              onPress={() => {
                this.props.dados[item.objectId] = !this.props.dados[
                  item.objectId
                ];
                this.props.activity.setState({
                  alterado: true
                });
              }}
              children={code.lay.obrigatorio
                ? code.lay.text + " (Obrigatorio)"
                : code.lay.text}
              variant={!this.props.dados[item.objectId]?"outlined":"contained"}
            >

            </TouchableOpacity>
          </View>
        );
      default:
        return <View />;
    }
  }
}

var styles = StyleSheet.create({
  tela: {
    alignSelf: "stretch",
    backgroundColor: "rgba(255,255,255,1)",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  }
});

var styles2 = StyleSheet.create({
  tela: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 7
  },
  bottom21: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 35,
    borderRadius: 18,
    // marginLeft: 5,
    // marginTop: 10,
    backgroundColor: "rgba(7,81,120,1)"
  },
  image: {
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  icon21: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  icon2: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  icon1: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 175,
    padding: 10
  },
  label1: {
    flex: 1,
    textAlign: "left",
    flexWrap: "wrap",
    alignSelf: "stretch"
  },
  textinput: {
    flex: 1,
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    marginTop: 7
  },
  view6: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 25,
    marginTop: 7
  },
  view3: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    margin: 7
  },
  fotosupload: {
    alignSelf: "auto",
    width: 350,
    height: 250
  },
  view7: {
    alignSelf: "auto",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 20
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    fontSize: 14
  },
  switch: {},
  selectinput: {
    flex: 1,
    minHeight: 40,
    alignSelf: "stretch",
    marginTop: 7
  }
});
