import { StyleSheet } from "react-1app";
export default StyleSheet.create({
  Componente: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 20
  },
  Grid: {
    // alignSelf: "stretch",
    // flexDirection: "column",
  },
  textinput: {
    // alignSelf: "stretch",
    // flexDirection: "column",
  },
  SubHeader: {
    paddingLeft: 24,
    paddingRight: 24,
    height: 48,
    // alignSelf: "stretch",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center"
  },
  subTabLabel: {
    textAlign: "left",
    // color: "rgba(0,0,0,1)",
    // alignSelf: "stretch",
    fontWeight: "normal",
    fontSize: 20
  },
  subTabIcon: {
    // color: "rgba(0,0,0,1)",
    fontSize: 25
  },
  progress: {
    height: 25,
    width: 25
  },
  gridListDimacao: {
    xs: 12,
    md: 5,
    xl: 4
  },
  gridEditDimacao: {
    xs: 12,
    md: 7,
    xl: 5
  }
});
