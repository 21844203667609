"use strict";
import React, { Component } from "react";
import { Grid, IconButton } from "@material-ui/core";
import ChartistGraph from "react-chartist";
import {
  Dados,
  Navigator,
  View,
  Progresso,
  OpenSnack,
  Text,
  TextInput,
  TouchableOpacity,
  Progress
} from "react-1app";
import ChartCard from "../dashboard/Cards/ChartCard";
import Search from "@material-ui/icons/Search";
import Loop from "@material-ui/icons/Loop";
import ModalTabela from "../info/ModalTabela";
import { Util } from "../../infra";
import TableSelect from "../../components/TableSelect";
import {
  getGraficoAno,
  getDadosRelatorioUsuario,
  getDadosRelatorioUsuarioDetalhado
} from "../../worker/dados";
import moment from "moment";
var Chartist = require("chartist");

//nao atualizar
export default class GraficoInspetor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [
        { mes: 11, mes_ano: "Ago", inspecao: 25 },
        { mes: 10, mes_ano: "Set", inspecao: 50 },
        { mes: 9, mes_ano: "Out", inspecao: 45 },
        { mes: 8, mes_ano: "Nov", inspecao: 66 },
        { mes: 7, mes_ano: "Dez", inspecao: 57 },
        { mes: 6, mes_ano: "Jan", inspecao: 69 },
        { mes: 5, mes_ano: "Fev", inspecao: 45 },
        { mes: 4, mes_ano: "Mar", inspecao: 23 },
        { mes: 3, mes_ano: "Abr", inspecao: 78 },
        { mes: 2, mes_ano: "Mai", inspecao: 89 },
        { mes: 1, mes_ano: "Jun", inspecao: 91 },
        { mes: 0, mes_ano: "Jul", inspecao: 98 }
      ],
      loadGrafico: true,
      loadTipo: true,
      semDados: false,
      listTipo: [],
      inicio: moment()
        .subtract(12, "months")
        .format("MM/YY"),
      final: moment().format("MM/YY")
    };
    //{onConstructor}
  }
  getDados() {
    if (!this.props.user_local || !this.props.user_local.objectId) return;
    this.setState({ loadGrafico: true, loadTipo: true });
    let { inicio, final, user_local } = this.state;
    getGraficoAno(this.props.user_local.objectId, (list = [], error) => {
      // console.log(JSON.stringify(list));
      if (!list || !list[0])
        return this.setState({
          semDados: true,
          loadGrafico: false,
          loadTipo: false
        });
      this.setState({ lista: list, loadGrafico: false, semDados: false });
      getDadosRelatorioUsuario(
        {
          inicio: moment(inicio, "MM/YY").toJSON(),
          final: moment(final, "MM/YY").toJSON(),
          key_user: this.props.user_local.objectId
        },
        (listTipo = [], error) => {
          this.setState({ listTipo, loadTipo: false });
        }
      );
      // this.setState({lista:list,loadGrafico:false})
    });
  }

  componentDidMount() {
    this.getDados();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      if (nextProps.user_local != this.props.user_local)
        setTimeout(() => {
          this.getDados();
        }, 10);
    }
    return true;
  }
  // componentWillUnmount(){
  // }
  getHeigh(list, name, add) {
    add = add ? add : 10;
    let init = 0;
    if (list && list[0]) {
      let newL = list.map(d => ({ iTotal: d[name] }));
      for (var i = 0; i < newL.length; i++) {
        let t = Number(newL[i].iTotal);
        // console.log(newL[i]);
        if (t > init) init = t;
      }
    }
    return init + add;
  }
  mormalizarValorList(list) {
    let NewLabel = [];
    let NewSerial = [];
    if (list && list[0]) {
      for (var i = 0; i < list.length; i++) {
        let item = list[i];
        NewLabel.push(item.mes_ano);
        NewSerial.push(item.inspecao);
      }
    }
    // console.log({labels:NewLabel,series:[NewSerial]});
    return { labels: NewLabel, series: [NewSerial] };
  }
  total(list) {
    let total = 0;
    list.map(item => {
      total = total + item.inspecao;
    });
    return this.state.loadGrafico || this.state.semDados || !total
      ? ""
      : " Total: " + total;
  }

  openListaConpleta() {
    let { inicio, final, user_local } = this.state;
    OpenSnack({ message: "Buscando dados. Aguarde!" });
    getDadosRelatorioUsuarioDetalhado(
      {
        key_user: this.props.user_local.objectId,
        inicio: moment(inicio, "MM/YY").toJSON(),
        final: moment(final, "MM/YY").toJSON()
      },
      (list = [], error) => {
        this.props.navigator.openModal({
          component: ModalTabela,
          props: {
            listData: list.map(item => [
              item.nome + " - " + item.descricao,
              moment(item.dia, "YYYY-MM-DD").format("DD MMM YYYY"),
              moment.duration(item.total_horas, "seconds").humanize(),
              item.total_equipamento,
              item.total
            ]),
            listHead: ["Nome", "Dia", "Tempo", "T.EQ", "Total"],
            title: "Historico Inspeçãos",
            navigator: this.props.navigator
          }
        });
      }
    );
  }

  render() {
    return (
      <Grid container spacing={16} style={{marginTop:-35}}>
        <Grid item xs={12} md={6} style={{ marginTop: 28 }}>
          <TableSelect
            tableHead={["Nome", "Tempo", "T.EQ", "Total"]}
            tableData={this.state.listTipo.map(item => [
              item.nome + " - " + item.descricao,
              moment.duration(item.total_horas, "seconds").humanize(),
              item.total_equipamento,
              item.total
            ])}
            title={"Relatórios realizado"}
            actionBar={[
              <TextInput
                style={{ minHeight: 35, width: 70 }}
                value={this.state.inicio}
                onChange={inicio =>
                  this.setState({ inicio: Util.maskFormate(inicio, "99/99") })
                }
                label="Inicio"
                placeholder="MM/AA"
              />,
              <TextInput
                style={{ minHeight: 35, width: 70 }}
                value={this.state.final}
                onChange={final =>
                  this.setState({ final: Util.maskFormate(final, "99/99") })
                }
                label="Final"
                placeholder="MM/AA"
              />,
              <IconButton
                aria-label="Filter list"
                onClick={() => this.getDados()}
              >
                {(!this.state.loadTipo && <Search />) || <Progress />}
              </IconButton>
            ]}
            renderFooter={
              <TouchableOpacity
                style={styles.bottom3}
                onPress={() => {
                  this.openListaConpleta();
                }}
              >
                <Text style={styles.label3} text={"Ver lista completa"} />
              </TouchableOpacity>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} style={{marginTop:-20}}>
          <ChartCard
            chart={
              <ChartistGraph
                className="ct-chart"
                data={this.mormalizarValorList(this.state.lista)}
                type="Bar"
                options={Object.assign(dailySalesChart.options, {
                  high: this.getHeigh(this.state.lista, "inspecao")
                })}
                listener={dailySalesChart.animation}
              >
                {false&&(this.state.loadGrafico || this.state.semDados) && (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      right: -16,
                      bottom: -5,
                      alignItems: "center",
                      justifyContent: "center",
                      left: -16,
                      zIndex: 300,
                      backgroundColor: "rgba(255,255,255,0.5)"
                    }}
                  >
                    {this.state.loadGrafico && <Progress />}
                    {this.state.semDados && (
                      <Text text={"Sem Dados para o gráfico"}></Text>
                    )}
                  </View>
                )}
              </ChartistGraph>
            }
            chartColor="mega"
            title="Produção"
            statIcon={null}
            statText={
              "Producao por mês - Período de 1 ano. Atualizado em " +
              moment().format("DD") +
              " de " +
              moment().format("MMMM") +
              " de " +
              moment().format("YYYY") +
              "." +
              this.total(this.state.lista)
            }
          />
        </Grid>
      </Grid>
    );
  }
}

let styles = {
  bottom3: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "nowrap",
    padding: 10
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",
    fontSize: 13
  }
};
var delays = 80,
  durations = 500;
const dailySalesChart = {
  data: {
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    series: [[12, 17, 7, 17, 23, 18, 38]]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation: {
    draw: function(data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};
