"use strict";
import React,{Component} from "react";

import {
  MapView,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  TextInput,
  TitleBar,
  File,
  FotosUpload,
  Endereco
} from "react-1app";
import {
  Progresso,
  Imagem,
  Fotos,
  Image,
  Text,
  ListView,
  Alert,
  Navigator,
  Switch,
  moment,
  banco,
  ApiUteis,
} from "react-1app";
import {
  DrawerLayout,
  ImageUpload,
  StyleSheet,
  GridView,
  Icon,
  Fragment,
  RefreshControl,
  PageView,
  DatePicker
} from "react-1app";
import View from "../../components/View";
import {SelectInput} from "../../components/SelectInput";

import Inputs from "./Inputs2";
import { Util, Dados, Query } from "../../infra";

//nao atualizar
export default class compon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {}
    };
    Dados.clonarHeranca(props, this.state);
    //{onConstructor}
  }
  componentDidMount() {
    // console.log(this.state);
    //{onDidMount}
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);

      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }
  view1Column() {
    var lista = [];
    if (this.state.item.code.lay.lista) {
      var listaC = this.state.item.code.lay.lista;
      for (var i = 0; i < listaC.length; i++) {
        let itemV = listaC[i];
        itemV.code.test = true;
        // console.log(itemV);
        if (
          itemV.code.lay.culunm == 1 ||
          !itemV.code.lay.culunm ||
          itemV.code.lay.culunm > 2
        ) {
          lista.push(
            <Inputs
              activity={this.props.activity}
              selecionado={this.state.selecionado}
              item={itemV}
              key={"item_" + i}
            />
          );
        }
      }
    }
    return lista;
  }
  view2Column() {
    var lista = [];
    if (this.state.item.code.lay.lista) {
      var listaC = this.state.item.code.lay.lista;
      for (var i = 0; i < listaC.length; i++) {
        let itemV = listaC[i];
        itemV.code.test = true;
        if (itemV.code.lay.culunm == 2) {
          lista.push(
            <Inputs
              activity={this.props.activity}
              selecionado={this.state.selecionado}
              item={itemV}
              key={"item_" + i}
            />
          );
        }
      }
    }
    return lista;
  }
  criarTabella() {
    var itens = [];
    if (this.state.item.code.lay.lista) {
      itens = this.state.item.code.lay.lista;
    }
    var pg = [];
    for (var i = 0; i < itens.length; i++) {
      let item = itens[i];

      try {
        // pg.push(
        //
        //     <td key={"cell"+i} style={{"paddingTop":2,  "paddingBottom":2 }}>
        //       <TextInput style={ styles.temp_operacao } value={ item.descricao } onChange={ (value) => {
        //           item.descricao = value;
        //           // this.setState({equipamentos:this.state.equipamentos});
        //         } } ref={ (v) => this.temp_operacao = v } keyboardType={ "default" } secureTextEntry={ false }
        //         multiline={ false } disabled = {this.state.test}/>
        //
        //     </td>
        //
        //
        // );
        pg.push(
          <td key={"cell" + i} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <View
              style={{ alignItems: "flex-start", justifyContent: "flex-end" }}
            >
              <View style={styles.view3}>
                <Text style={{}} text={"value"} />
              </View>
              <View style={styles.view2} />
            </View>
          </td>
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    var LinhaTB = [];
    LinhaTB.push(
      <tr>
        {pg}

        <td style={{ maxWidth: 50 }}>
          <TouchableOpacity style={{ maxWidth: 50 }}>
            <Icon
              style={styles.icon3}
              fromFontFamily={"Material Icons"}
              icon={"delete"}
            />
          </TouchableOpacity>
        </td>
      </tr>
    );

    return LinhaTB;
  }
  criarTabellaCab() {
    var itens = [];
    if (this.state.item.code.lay.lista) {
      itens = this.state.item.code.lay.lista;
    }
    var pg = [];
    for (var i = 0; i < itens.length; i++) {
      let item = itens[i];

      try {
        pg.push(
          <td key={"cell" + i} style={{ paddingTop: 2, paddingBottom: 2 }}>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <View style={styles.view3}>
                <Text style={{}} text={item.text} />
              </View>
            </View>
          </td>
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    var LinhaTB = [];
    LinhaTB.push(
      <tr style={{ backgroundColor: "rgba(227,227,227,1)", height: 35 }}>
        {pg}

        <td style={{ maxWidth: 50 }}>
          <View style={{ maxWidth: 50 }}>
            <Icon
              style={styles.icon3}
              fromFontFamily={"Material Icons"}
              icon={"delete"}
            />
          </View>
        </td>
      </tr>
    );

    return LinhaTB;
  }

  render() {
    return (
      <View style={styles.tela}>
        {this.state.item.code.tag == "Text" ? (
          <View
            style={styles.view}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <Text
              style={{
                flexWrap: "wrap",
                color: "rgba(0,0,0,1)",
                alignSelf: "stretch",
                textAlign: this.state.item.code.lay.position
                  ? this.state.item.code.lay.position
                  : "left",
                fontSize: this.state.item.code.lay.font
                  ? this.state.item.code.lay.font
                  : 14,
                fontWeight: this.state.item.code.lay.stilo
                  ? this.state.item.code.lay.stilo
                  : 400
              }}
              text={this.state.item.code.lay.text}
            />
          </View>
        ) : null}
        {this.state.item.code.tag == "switch" ? (
          <View
            style={styles.view6}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view7}>
              <Text
                style={{
                  flexWrap: "wrap",
                  color: "rgba(0,0,0,1)",
                  alignSelf: "stretch",

                  textAlign: this.state.item.code.lay.position
                    ? this.state.item.code.lay.position
                    : "left",
                  fontSize: this.state.item.code.lay.font
                    ? this.state.item.code.lay.font
                    : 14,
                  fontWeight: this.state.item.code.lay.stilo
                    ? this.state.item.code.lay.stilo
                    : 400
                }}
                text={this.state.item.code.lay.text}
              />
            </View>
            <Switch
              style={styles.switch}
              value={this.state.item.switch}
              onChange={value => {
                this.state.item.switch = value;
                // this.setState({item:this.state.item});
              }}
              ref={v => (this.switch = v)}
            />
          </View>
        ) : null}
        {this.state.item.code.tag == "TextInput" ? (
          <View
            style={styles.view1}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view3}>
              <Text
                style={styles.label1}
                text={this.state.item.code.lay.descricao}
              />
            </View>
            <View style={styles.view2} />
          </View>
        ) : null}
        {this.state.item.code.tag == "data" ||
        this.state.item.code.tag == "dataEdit" ? (
          <View
            style={styles.view1}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view3}>
              <Text
                style={styles.label1}
                text={this.state.item.code.lay.descricao}
              />
            </View>
            <View style={styles.view2} />
          </View>
        ) : null}

        {this.state.item.code.tag == "View" ? (
          <View
            style={styles.view13}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view14}>{this.view1Column()}</View>
            <View style={styles.view12}>{this.view2Column()}</View>
          </View>
        ) : null}
        {this.state.item.code.tag == "ViewEdit" ? (
          <View
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 0
            }}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View
              style={{
                flex: 1,
                alignSelf: "auto",
                flexWrap: "nowrap",
                position: "relative",
                minWidth: this.state.item.code.lay.largura
                  ? this.state.item.code.lay.largura
                  : null,
                maxWidth: this.state.item.code.lay.largura
                  ? this.state.item.code.lay.largura
                  : null,
                minHeight: this.state.item.code.lay.altura
                  ? this.state.item.code.lay.altura
                  : null,
                backgroundColor: this.state.item.code.lay.cor
                  ? this.state.item.code.lay.cor
                  : "rgba(251,251,251,0)",
                maxHeight: this.state.item.code.lay.altura
                  ? this.state.item.code.lay.altura
                  : null,
                flexDirection: this.state.item.code.lay.orientacao
                  ? this.state.item.code.lay.orientacao
                  : "column",
                alignItems: this.state.item.code.lay.orientacao2
                  ? this.state.item.code.lay.orientacao2
                  : "flex-start",
                justifyContent: this.state.item.code.lay.orientacao3
                  ? this.state.item.code.lay.orientacao3
                  : "flex-start"
              }}
            >
              {this.view1Column()}
            </View>
          </View>
        ) : null}
        {this.state.item.code.tag == "image" ? (
          <View
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 5,
              justifyContent: this.state.item.code.lay.orientacao3
                ? this.state.item.code.lay.orientacao3
                : "flex-start",
              alignItems: this.state.item.code.lay.orientacao2
                ? this.state.item.code.lay.orientacao2
                : "flex-start"
            }}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <Image
              style={{
                flexDirection: "column",
                alignSelf: "auto",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexWrap: "nowrap",

                width: this.state.item.code.lay.largura
                  ? this.state.item.code.lay.largura
                  : 50,
                height: this.state.item.code.lay.altura
                  ? this.state.item.code.lay.altura
                  : 50
              }}
              resizeMode={Image.resizeMode.cover}
              source={{ uri: this.state.item.code.lay.link }}
            />
          </View>
        ) : null}
        {this.state.item.code.tag == "fotosupload" ? (
          <View
            style={styles.view4}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view5} />
          </View>
        ) : null}
        {this.state.item.code.tag == "checkList" ? (
          <View
            style={styles.view4}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <Text
              style={{
                flexWrap: "wrap",
                color: "rgba(0,0,0,1)",
                alignSelf: "stretch",

                textAlign: this.state.item.code.lay.position
                  ? this.state.item.code.lay.position
                  : "left",
                fontSize: this.state.item.code.lay.font
                  ? this.state.item.code.lay.font
                  : 14,
                fontWeight: this.state.item.code.lay.stilo
                  ? this.state.item.code.lay.stilo
                  : 400
              }}
              text={this.state.item.code.lay.text}
            />
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                position: "relative"
              }}
            >
              {this.view1Column()}
            </View>
          </View>
        ) : null}
        {this.state.item.code.tag == "selectinput" ? (
          <View
            style={styles.view8}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view9}>
              <Text
                style={styles.label3}
                text={this.state.item.code.lay.descricao}
              />
              <Icon
                style={styles.icon}
                fromFontFamily={"Material Design Icons"}
                icon={"chevron-down"}
              />
            </View>
            <View style={styles.view10} />
          </View>
        ) : null}
        {this.state.item.code.tag == "prof" ? (
          <View
            style={styles.view8}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View style={styles.view9}>
              <Text
                style={styles.label3}
                text={this.state.item.code.lay.text}
              />
              <Icon
                style={styles.icon}
                fromFontFamily={"Material Design Icons"}
                icon={"chevron-down"}
              />
            </View>
            <View style={styles.view10} />
          </View>
        ) : null}
        {this.state.item.code.tag == "tb" ||
        this.state.item.code.tag == "tbEQ" ||
        this.state.item.code.tag == "ocorrencias" ? (
          <View
            style={{
              display: null,
              alignSelf: "stretch",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              position: "relative",
              marginTop: 5
            }}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <table style={{width:"100%"}}>
              {this.criarTabellaCab()}

              {this.criarTabella()}
            </table>
            <View style={styles.view41}>
              <TouchableOpacity icon style={styles.bottom11}>
                <Icon
                  style={styles.icon11}
                  fromFontFamily={"Material Icons"}
                  icon={"add"}
                />
              </TouchableOpacity>
            </View>
          </View>
        ) : null}

        {this.state.item.code.tag == "son" ? (
          <View
            style={styles.view4}
            onClick={() => {
              this.props.activity.editComp(this.state.item);
            }}
            elevation={
              this.state.selecionado == this.state.item.objectId ? 2 : 0
            }
          >
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                position: "relative",
                margin: 5
              }}
            >
              <Text
                style={{
                  flexWrap: "wrap",
                  color: "rgba(0,0,0,1)",
                  alignSelf: "stretch",

                  textAlign: this.state.item.code.lay.position
                    ? this.state.item.code.lay.position
                    : "left",
                  fontSize: this.state.item.code.lay.font
                    ? this.state.item.code.lay.font
                    : 14,
                  fontWeight: this.state.item.code.lay.stilo
                    ? this.state.item.code.lay.stilo
                    : 400,
                  flex: 1
                }}
                text={this.state.item.code.lay.text}
              />
              <View style={{ alignSelf: "auto", width: 100 }}>
                <TouchableOpacity
                  style={{
                    alignSelf: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 50,
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    position: "relative",
                    width: 100,
                    backgroundColor: "rgba(227,227,227,1)",
                    padding: 10,
                    margin: 5
                  }}
                  elevation={3}
                  onPress={() => {}}
                >
                  <Text
                    style={{
                      flexWrap: "wrap",
                      color: "rgba(0,0,0,1)",
                      alignSelf: "stretch",

                      textAlign: "left",
                      fontSize: 17,
                      fontWeight: 400,
                      flex: 1
                    }}
                    text={"Sim"}
                  />

                  <Icon
                    style={styles.icon12}
                    fromFontFamily={"Material Icons"}
                    icon={"check_circle"}
                  />
                </TouchableOpacity>
              </View>
              <View style={{ alignSelf: "auto", width: 100 }}>
                <TouchableOpacity
                  style={{
                    alignSelf: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 50,
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    position: "relative",
                    width: 100,
                    backgroundColor: "rgba(227,227,227,1)",
                    padding: 10,
                    margin: 5
                  }}
                  elevation={3}
                  onPress={() => {}}
                >
                  <Text
                    style={{
                      flexWrap: "wrap",
                      color: "rgba(0,0,0,1)",
                      alignSelf: "stretch",

                      textAlign: "left",
                      fontSize: 17,
                      fontWeight: 400,
                      flex: 1
                    }}
                    text={"Não"}
                  />
                  <Icon
                    style={styles.icon12}
                    fromFontFamily={"Material Icons"}
                    icon={"radio_button_unchecked"}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5,
    minHeight: 20
  },
  view12: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view41: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  bottom11: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    flexDirection: "column",
    flexWrap: "nowrap",
    borderRadius: 18,
    width: 36,
    backgroundColor: "rgba(7,81,120,1)",
    margin: 5
  },
  bottom34: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 100,
    backgroundColor: "rgba(227,227,227,1)",
    padding: 10
  },
  icon12: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  icon11: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  view14: {
    flex: 1,
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    marginRight: 10,
    position: "relative"
  },
  view11: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    paddingTop: 10,
    paddingBottom: 10
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    alignSelf: "stretch",
    fontSize: 14
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    marginTop: 5
  },
  view3: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 3,
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(177,177,177,1)",
    alignSelf: "stretch",
    fontSize: 12
  },
  view2: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 2,
    backgroundColor: "rgba(87,146,221,1)"
  },
  image: {
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view4: {
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    padding: 20
  },
  view5: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    backgroundColor: "rgba(150,150,150,1)",
    width: 350,
    height: 250
  },
  view6: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 25,
    marginTop: 5
  },
  view7: {
    alignSelf: "auto",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 20
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 14
  },
  switch: {},
  view8: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    marginTop: 5
  },
  view13: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5
  },
  view9: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 3
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(177,177,177,1)",
    alignSelf: "auto",
    fontSize: 12,
    flex: 1
  },
  icon: {
    fontSize: 25
  },
  view10: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 2,
    backgroundColor: "rgba(87,146,221,1)"
  }
});
