
import Cloud from "./Cloud"

function Dados(ent){
  this.entidade = "dados";
  if(ent){
    this.entidade = ent;
  }
  this.status = 1;

  Dados.prototype.getString = function (key) {
    if((this)[key]){
      return (this)[key];
    }else{
      return "";
    }
  };

  Dados.prototype.setValue = function (key,value){
    (this)[key] = value;
  }

  Dados.prototype.set = function (key,value){
    (this)[key] = value;
  }
  Dados.prototype.get = function (key,value){
    return  (this)[key];
  }
  Dados.prototype.setEntidade = function (value){
    this.entidade = value;
  }

  Dados.prototype.setPosition= function (position){
    // console.log(position);
    if(position && position.coords){
      this.latitude =position.coords.latitude;
      this.longitude =position.coords.longitude;
      this.point= { "type": "Point", "coordinates": [  this.latitude ,   this.longitude ] };
    }
    // console.log(this);
    this.salvarLocal();
  }


  Dados.prototype.salvarLocal = function (retorno){

  }
  Dados.prototype.save = function (config,call){
    if(!this.status ){
      this.status =1;
    }
    this.salvarNuven((id)=>{
      if (call && call.success) {
        call.success(this);
      }
    });
  }

  Dados.prototype.setMetodoApi = function (metodo,tipo){
    this.metodoApi = metodo ? metodo : "salvar";
    this.metodoTipo = tipo? tipo : "POST";
  }

  Dados.prototype.salvarNuven = function (retorno,metodo_direto){

    var metodo = "salvar";
    if(metodo_direto){
      metodo=metodo_direto;
    }
    var saveObject = JSON.parse(JSON.stringify(this))
    delete saveObject.metodoApi;
    delete saveObject.entidade;
    delete saveObject.metodoTipo;


    var send= {entidade:this.entidade,data:JSON.stringify(saveObject)};

    Cloud.post((this.metodoApi?this.metodoApi:metodo),send,(json)=>{
      this.update(json);
      if(retorno){
        retorno(this);
      }
    })
  }

  Dados.prototype.update = function (obj,entidade){
    if (entidade) {
      this.entidade = entidade;
    }
    if (!obj) {
      return this;
    }
    if (obj.objectId) {
      this.objectId = obj.objectId;
    }
    if (obj._id) {
      this._id = obj._id;
    }

    var lista = Object.keys(obj);
    for (var i = 0; i < lista.length; i++) {
      var nome = lista[i];
      var valor =(this)[nome];
      if(!(this)[nome]){
        (this)[nome] = obj[nome] ;
      }else if(isArray(valor)){
        // (this)[nome] = obj[nome] ;
      }else if(isObject(valor)){
        // (this)[nome] = obj[nome] ;
      }else{
        (this)[nome] = obj[nome] ;
      }
    }
    if (entidade) {
      this.entidade = entidade;
    }
    return this;
  }

  Dados.prototype.parse = function (obj,entidade){
    if (entidade) {
      this.entidade = entidade;
    }
    if (!obj) {
      return this;
    }
    if (obj.objectId) {
      this.id = obj.objectId;
    }

    var lista = Object.keys(obj);
    for (var i = 0; i < lista.length; i++) {
      var nome = lista[i];
      (this)[nome] = obj[nome] ;
    }
    if (entidade) {
      this.entidade = entidade;
    }
    return this;
  }

};

export default Dados;



Dados.clonar=function( original, copia){
  if(copia && original){
    var lista = Object.keys(original);
    for (var i = 0; i < lista.length; i++) {
      var nome = lista[i];
      copia[nome] = original[nome] ;
    }
    if(copia.heranca){
      for (var i = 0; i < lista.length; i++) {
        var nome = lista[i];
        copia.heranca[nome] = original[nome] ;
      }
    }
  }
}
Dados.clonarHeranca=function( original, copia){
  if(original && copia ){
    var foco  = original;
    if(original.route && original.route.props){
      foco = original.route.props
    }
    if(foco && foco.heranca){
      var heranca = Object.keys(foco.heranca);
      for (var i = 0; i < heranca.length; i++) {
        var nome = heranca[i];
        copia[nome] = foco.heranca[nome] ;
      }
    }

    var lista = Object.keys(foco);
    for (var i = 0; i < lista.length; i++) {
      var nome = lista[i];
      if(nome=="heranca"){
        continue;
      }
      copia[nome] = foco[nome] ;
    }
  }
}
Dados.salvarLista=function(results, entidade, retorno){

}


function isObject(val) {
  return typeof val === "object";
}
function isArray(object) {
  if (object && JSON.stringify(object) == "[]") {
    return true;
  }
  if (object && object.constructor && object.constructor === Array) {
    return true;
  } else {
    return false;
  }
}
