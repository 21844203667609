import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Local from "react-1app/lib/View";
import { PgModal } from "react-1app/lib/PgModal";
import moment from "moment";
import Edit from "./Edit";
import FiltroRelatorio from "./FiltroRelatorio";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.relatorio != this.props.relatorio ||
      (nextProps.relatorio &&
        this.props.relatorio &&
        nextProps.relatorio.objectId != this.props.relatorio.objectId)
    ) {
      setTimeout(() => {
        this.buscar();
      }, 10);
      this.setState({ local: {}, load: true });
    }
    return true;
  }

  buscar() {
    var query = new Query("formularios");
    query.whereEqualTo("relatorio", this.props.relatorio.objectId);
    query.addAscendingOrder("ordem");
    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return list.filter(item => regex.test(item.nome));
  }

  open_modal_bottom13(formularios) {
    if (this.Modal && this.Modal.openModal)
      this.Modal.openModal(Edit, {
        ...this.props,
        heranca: this.state,
        activity: this,
        formularios
      });
  }

  open_modal_props_bottom2() {
    if (this.props.navigator) {
      this.props.navigator.openModal({
        component: FiltroRelatorio,
        props: {
          heranca: this.state,
          activity: this,
          navigator:this.props.navigator,
          destinoRelatorio:this.props.relatorio.objectId
        }
      });
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <View type={"Paper"}>
        <ListView
          ListHeaderComponent={() => (
            <View style={styles.head}>
              <ListSubheader component="div">{"Formulários"}</ListSubheader>
              <TextInput
                label="Filtrar empresas"
                style={styles.input}
                placeholder="Pesquisa por nome."
                onChange={pesquisa => this.setState({ pesquisa })}
                value={this.state.pesquisa}
                inputNative
              />
              <TouchableOpacity
                style={styles.copy}
                onPress={() => {
                  this.open_modal_props_bottom2();
                }}
                icon
              >
                <Icon
                  fromFontFamily={"Material Icons"}
                  icon={"content_copy"}
                />
              </TouchableOpacity>
              <TouchableOpacity
                children={"Novo"}
                variant="outlined"
                onPress={() => {
                  this.open_modal_bottom13(
                    new Dados("formularios").parse({
                      relatorio: this.props.relatorio.objectId,
                      lista: []
                    })
                  );
                }}
              />
            </View>
          )}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                key={index}
                style={{ width: "100%" }}
                selected={this.state.id === item.id}
                onClick={event => this.open_modal_bottom13(item)}
              >
                <Avatar src={item.logo}>
                  <Icon name={"format_align_center"} />
                </Avatar>
                <ListItemText
                  primary={item.nome}
                  secondary={moment(item.createdAt).format("DD/MM/YY")}
                />
                <ListItemSecondaryAction>
                  <Icon
                    color={"primary"}
                    name={"created"}
                    style={styles.icon}
                    onClick={() => {
                      this.open_modal_bottom13(item);
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }}
          separator
          data={this.getList()}
          tablePagination
          count={Number(this.getSuggestions().length)}
          load={this.state.load}
          page={this.state.parametros.page}
          rowsPerPage={this.state.parametros.limit}
          handleChangePage={(event, page) => {
            this.setState({
              parametros: Object.assign(this.state.parametros, { page })
            });
          }}
          handleChangeRowsPerPage={event => {
            this.setState({
              parametros: Object.assign(this.state.parametros, {
                limit: event.target.value,
                page: 0
              })
            });
          }}
        />
        <PgModal {...this.props} ref={f => (this.Modal = f)} />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 150
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  copy:{
    padding:5,
    marginRight:5
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
