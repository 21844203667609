import React from "react";
import StyleSheet from "react-1app/lib/StyleSheet";
import View from "react-1app/lib/View";

const TouchableOpacity = props => {
  return (
    <View
      {...props}
      style={StyleSheet.flatten([
        { cursor: "pointer", padding: 0, color: "#444" },
        (props.elevation?{boxShadow : "1px 2px "+(props.elevation*3)+"px 0px rgba(0,0,0,0.5)"}:{}),
        props.style
      ])}
      onClick={e => {
        e.stopPropagation();
        // console.log(ev);
        if (!props.disabled) {
          if (props.onClick) {
            props.onClick();
          }
          if (props.onPress) {
            props.onPress();
          }
        }
      }}
    />
  );
};

export default TouchableOpacity;
