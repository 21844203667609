import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { createStore } from "redux";
import reducers from "./redux/reducer";
import { Provider } from "react-redux";
import { pages } from "./index.pages.js";
import { Cloud } from "./infra";
import File from "./components/File";
import ImageUpload from "react-1app/lib/ImageUpload";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import createBrowserHistory from "history/createBrowserHistory";
import Load from "./views/Load";
import NotFound from "./views/NotFound";
import InitApp from "react-1app/lib/InitApp";
import Dash from "./views/dash";
import withStyles from "@material-ui/core/styles/withStyles";
import { OpenModal } from "react-1app/lib/PgModal";
import * as WorkerLogin from "./worker/login";
import * as Actions from "./redux/actions";

const customHistory = createBrowserHistory();
const store = createStore(reducers);

require("moment/locale/pt-br");
var moment = require("moment");
moment.locale("pt-br");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: store.getState().user,
      empresaConfig: store.getState().empresaConfig,
      load: true,
      theme: store.getState().theme
    };
    ImageUpload.setHost(Cloud.getHost());
    File.setHost(Cloud.getHost());
    ImageUpload.setToken(Cloud.getToken());
    File.setToken(Cloud.getToken());
  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      var { user, theme } = store.getState();
      if (user !== this.state.user || theme !== this.state.theme)
        this.setState({ user, theme });
    });
    this.loadUser();
  }

  loadUser() {
    if (!WorkerLogin.loadUser()) return this.setState({ load: false });
    WorkerLogin.loginToken({}, (user = {}, error) => {
      if (user && user.id && !error)
        store.dispatch(Actions.logar({ ...user, permissao: 2 }));
      this.setState({ load: false });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  rotas(privado) {
    let { user } = this.state;
    // console.log(user);
    let rotas = pages.filter(
      e =>
        (user &&
          e.permissao &&
          (user[e.permissao] || (user.adm && e.permissao != "user"))) ||
        (!e.permissao && !privado)
    );
    if (privado && rotas[0])
      rotas.push({ ...rotas[0], path: "/", menu: false });
    return rotas;
  }

  render() {
    return (
      <MuiThemeProvider theme={themes[this.state.theme]}>
        <Provider ref={"pro"} store={store}>
          <Router ref={v => (this.router = v)} history={customHistory}>
            <InitApp {...this.props} screenProps={{ store }} />
            <Switch>
              {this.state.load ? <Load /> : null}
              {this.rotas(false).map((rota, key) => (
                <PrivateRoute
                  exact
                  key={"key_" + key + "_rota"}
                  path={rota.path}
                  rotas={this.rotas()}
                  store={store}
                  aberto={!rota.permissao}
                  user={store.getState().user}
                  component={rota.component}
                />
              ))}

              {this.rotas(true).map((rota, key) => (
                <PrivateRoute
                  exact
                  key={"key_" + key + "_rota"}
                  path={rota.path}
                  rotas={this.rotas()}
                  store={store}
                  aberto={!rota.permissao}
                  user={store.getState().user}
                  component={rota.component}
                />
              ))}
              <Route component={NotFound} exact path="/not-found" />
              {this.state.user && this.state.user.id ? (
                <Redirect to="/not-found" />
              ) : null}
              <Redirect to="/login" />
            </Switch>
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

const themes = {
  light: createMuiTheme({
    spacing: 4,
    palette: {
      background: {
        // default: "rgb(235,235,235)",
        paper: "rgb(235,235,235)"
      },
      type: "light",
      primary: {
        main: "#001e60"
      }
    }
    // overrides: {
    //   MuiPaper: {
    //     root: {
    //       backgroundColor: "rgb(235,235,235)" // Some CSS
    //     }
    //   }
    // }
  }),
  dark: createMuiTheme({
    spacing: 4,
    palette: {
      type: "dark",
      primary: {
        main: "rgb(205,155,56)"
      },
      secondary: {
        main: "#ff6e40"
      }
    }
  })
};

const PrivateRout = ({ component: Component, classes, rotas, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const navigation = {
        navigate: (path, state = {}) =>
          props.history.push({ pathname: path, state }),
        goBack: () => props.history.goBack(),
        openModal: ({ component, props }) => OpenModal(component, props)
      };
      if (rest.aberto)
        return (
          <div className={classes.root}>
            <Component
              {...props}
              screenProps={{ store: rest.store }}
              navigation={navigation}
              navigator={navigation}
            />
          </div>
        );
      return (
        <ContentBody
          {...props}
          rotas={rotas}
          screenProps={{ store: rest.store }}
          navigation={navigation}
          navigator={navigation}
        >
          <Component
            {...props}
            screenProps={{ store: rest.store }}
            navigation={navigation}
            navigator={navigation}
          />
        </ContentBody>
      );
    }}
  />
);

function Body({ children, classes, rotas = [], ...props }) {
  return (
    <div className={classes.root}>
      <Dash {...props} rotas={rotas.filter(a => a.menu)} />
      <main id="main" className={classes.content}>
        {children}
      </main>
    </div>
  );
}

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "-webkit-fill-available",
    backgroundColor: theme.palette.background.default
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    marginTop: 65,
    paddingTop: 30
  }
});

const PrivateRoute = withStyles(styles)(PrivateRout);
const ContentBody = withStyles(styles)(Body);
export default App;
