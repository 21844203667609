import React from 'react';
import TextField from '@material-ui/core/TextField';

import Autosuggest from 'react-autosuggest';
let contador=1;
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}


function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
// console.log(888);
    this.state = {
      value: '',
      suggestions: []
    };
    this.contador=(contador++)
  }
  getSuggestions(value) {
    const { list=[],keyLabel="nome" } = this.props;
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return list.filter(item => regex.test(item[keyLabel]));
  }

  onChange  (event, { newValue, method })  {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested({ value }){
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const { list=[],keyLabel="nome",label="",placeholder="Selecione",onChange,onChangeText } = this.props;

    const inputProps = {
      placeholder,
      value,
      label,
      onChange: (event, { newValue, method })=>  {
        this.setState({
          value: newValue
        });
        if(onChangeText)onChangeText(newValue);
      }
    };

    return (
      <Autosuggest
      id={(this.contador)+"key"}
        suggestions={suggestions}
        style={{    width: "100%"}}
        onSuggestionsFetchRequested={({ value })=>{
          this.setState({
            suggestions: this.getSuggestions(value)
          });
        }}
        onSuggestionsClearRequested={()=> {
          this.setState({
            suggestions: []
          });
        }}
        renderInputComponent={renderInputComponent}
        getSuggestionValue={(suggestion)=> (suggestion[keyLabel])}
        renderSuggestion={(suggestion)=>(
          <span>{suggestion[keyLabel]}</span>
        )}
        onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>{
          onChange(suggestion)
          // console.log( suggestion, suggestionValue, suggestionIndex, sectionIndex, method );
        }}
        // getSectionSuggestions={(section)=>(section[keyLabel]=="BRF S.A.")}
        inputProps={inputProps} />
    );
  }
}
