"use strict";
import React, { Component } from "react";

import moment from "moment";
import {
  Table,
  TextInput,
  Text,
  OpenSnack,
  StyleSheet,
  View,
  Icon,
  TouchableOpacity,
  ScrollView,
  Progresso,
  TitleBar
} from "react-1app";
import { Grid, InputAdornment, Button } from "@material-ui/core";
// import{Add} from "@material-ui/icons";
import { validarPdfAssinado } from "../../worker/inspecoes";
import styleGlobal from "../../styleGlobal";

//nao atualizar
export default class ModalTabela extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cod: "",
      dados: []
    };
  }

  componentDidMount() {
    //{onDidMount}
  }

  buscar() {
    this.setState({ load: true });
    validarPdfAssinado({ cod: this.state.cod }, (dados = {}, error) => {
      if (error || !dados.id) {
        OpenSnack({ message: "Dados não encontrados" });
        return this.setState({ load: false });
      }
      this.setState({
        dados: [
          [
            dados.codigo,
            dados.nome_inspetor,
            dados.nome_engenheiro,
            moment(dados.createdAt).format("DD/MM/YYYY"),
            <Button
              onClick={() => {
                window.open(dados.link_rel || dados.link_rel_impressao);
              }}
            >
              Visualizar
            </Button>
          ]
        ],
        load: false
      });
      OpenSnack({ message: "PDF Valido!", type: "success" });
    });
  }

  render() {
    let { listData = [], listHead = [], title = "" } = this.props;
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label} text={"Validar PDF Assinado"} />
            <TextInput
              style={styles.textinput}
              value={this.state.cod}
              onChange={value => {
                this.setState({ cod: value });
              }}
              keyboardType={"default"}
              label={"Codigo do pdf"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() => {
                        this.buscar();
                      }}
                    >
                      <Icon
                        style={styles.icon2}
                        fromFontFamily={"Material Icons"}
                        icon={"search"}
                      />
                      Buscar
                    </Button>
                  </InputAdornment>
                )
              }}
              inputProps={{
                style: {
                  borderBottom: "none",
                  marginBottom: 0,
                  boxShadow: "none",
                  margin: 5,
                  marginLeft: 10,
                  fontSize: 18
                }
              }}
            />
            <Table
              tableHead={["OS", "Inspetor", "Engenheiro", "Inicio", "PDF"]}
              tableData={this.state.dados}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  textinput:{
    marginBottom:20
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50,
    position: "relative"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
  },
  icon2: {
    color: "rgba(66,66,66,1)",
    fontSize: 30
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontSize: 20,
    flex: 1
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50,
    position: "relative"
  },

  view4: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: 50,
    height: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  view2: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: 200,
    height: 200
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    padding: 20
  },
  bottom2: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    width: 250,
    backgroundColor: "rgba(7,81,120,1)",
    marginBottom: 20,
    position: "relative"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    marginLeft: 5,
    fontSize: 14
  },
  view3: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    width: 250,
    height: 50,
    backgroundColor: "rgba(7,81,120,1)"
  },
  progresso: {
    width: 35,
    height: 35
  }
});
