import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false,
      ocorrencias: new Dados("ocorrencias"),
      list: [],
      load: true,
      usar: []
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("textopadroes");

    query.whereEqualTo("objectId", this.props.test);
    query.setLimit(1000);
    query.first(data => {
      if (data.objectId) {
        this.setState({
          list: data.lista,
          load: false
        });
      }
    });
  }

  usar() {
    var texto = "";
    for (var i = 0; i < this.state.usar.length; i++) {
      texto += this.state.usar[i].text;
    }
    this.props.activity.usar(texto);
    this.props.closeModal();
  }

  usarItem(item) {
    let i = this.state.usar.findIndex(a => a.objectId == item.objectId);
    console.log(i);
    if (i > -1) {
      console.log(this.state.usar.splice(i, 1), "remover");
    } else {
      this.state.usar.push(item);
    }
    this.setState({ usar: [...this.state.usar] });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Ocorrencias /Não conformidades"}</Text>
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    button
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.usarItem(item, index);
                    }}
                  >
                    <ListItemText secondary={Util.subString(item.text, 250)} />
                    <Icon
                      color={"default"}
                      name={
                        this.state.usar.find(a => a.objectId == item.objectId)
                          ? "check_circle"
                          : "radio_button_unchecked"
                      }
                    />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.usar()}
                children={"Usar"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
