import React, { Component } from "react";

import { OpenDialogOption } from "react-1app";
import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Text from "react-1app/lib/Text";
import Inspecoes from "../inspecoes";
import Edit from "./Edit";
import InfoInspecao from "../inspecoes_edit/InfoInspecao";
import Tooltip from "@material-ui/core/Tooltip";
import UploadArquivos from "./UploadArquivos";
import Mover from "./Mover";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      load: true,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
    console.log(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.locais != this.props.locais) {
      setTimeout(() => {
        this.buscar();
      }, 10);
      this.setState({ unidade: null });
    }
    return true;
  }

  buscar(pesquisa) {
    var query = new Query("equipamentos");
    if (pesquisa) query.whereContains("denominacao", pesquisa);
    query.whereEqualTo("key_local", this.props.locais.objectId);
    query.addAscendingOrder("createdAt");
    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue+"^", "i");
    const regex2 = new RegExp(escapedValue, "g");

    return list.filter(item => item.denominacao&&(item.denominacao.toLowerCase().match(regex2)||item.denominacao.toLowerCase().match('^' + escapedValue) ||regex.test(item.denominacao.toLowerCase())));
    // return list.filter(item => regex.test(item.denominacao));
  }

  componentWillUnmount() {}

  open_modal_bottom1(equipamentos) {
    this.props.navigator.openModal({
      component: Edit,
      props: {
        heranca: this.state,
        activity: this,
        equipamentos,
        new: !equipamentos.objectId,
        atualizar: equipamento => {
          this.buscar();
          this.setState(equipamento);
        }
      }
    });
  }

mover(){
  this.props.navigator.openModal({
    component: Mover,
    props: {
      ...this.props,
      equipamento:this.state.equipamento,
      parametros:{
      key_empresa: this.props.empresas.objectId,
      where:'',
      limit:20
    }
    }
  });
}

  novaInspecao() {
    var tempnumero = new Date().getTime() - 1495800000000;
    let inspecoes = {};
    inspecoes.numero = "R/" + tempnumero;
    inspecoes.key_unidade = this.props.unidades.objectId;
    inspecoes.key_empresa = this.props.empresas.objectId;
    inspecoes.key_user = this.state.usuario.objectId;
    inspecoes.key_local = this.props.locais.objectId;
    inspecoes.local = this.props.locais;
    inspecoes.empresa = this.props.empresas;
    inspecoes.unidade = this.props.unidades;
    inspecoes.user = this.state.usuario;
    inspecoes.key_equipamento = this.state.equipamento.objectId;
    inspecoes.equipamento = this.state.equipamento;
    inspecoes.dados = {};
    inspecoes.listaFormulario = [];
    this.props.navigator.openModal({
      component: InfoInspecao,
      props: {
        navigator: this.props.navigator,
        listaInsp: this,
        activity: this,
        inspecoes,
        // atualizar: inp => this.salvar_bottom(inp)
      }
    });
  }

  novaUpInspecao() {
    var tempnumero = new Date().getTime() - 1495800000000;
    let inspecoes = {};
    inspecoes.numero = "R/" + tempnumero;
    inspecoes.key_unidade = this.props.unidades.objectId;
    inspecoes.key_empresa = this.props.empresas.objectId;
    inspecoes.key_user = this.state.usuario.objectId;
    inspecoes.key_local = this.props.locais.objectId;
    inspecoes.local = this.props.locais;
    inspecoes.empresa = this.props.empresas;
    inspecoes.unidade = this.props.unidades;
    inspecoes.user = this.state.usuario;
    inspecoes.key_equipamento = this.state.equipamento.objectId;
    inspecoes.equipamento = this.state.equipamento;
    inspecoes.dados = {};
    inspecoes.listaFormulario = [];
    inspecoes.naoedit=true;
    inspecoes.sPendecias=true;
    inspecoes.concluido=true;
    this.props.navigator.openModal({
      component: UploadArquivos,
      props: {
        navigator: this.props.navigator,
        listaInsp: this,
        activity: this,
        inspecoes,
        // atualizar: inp => this.salvar_bottom(inp)
      }
    });
  }

  openPlaca(item) {
    OpenDialogOption({
      title: "Placa",
      description: `Qual placa do equipamento ${item.codigo} você quer?`,
      action: [
        {
          label: "Placa completa",
          onPress: () =>
            Util.web(
              `https://megasteam.1app.com.br/placa/equipamento/${item.id}/placa.pdf`
            )
        },
        {
          label: "Placa limpa",
          onPress: () =>
            Util.web(
              `https://megasteam.1app.com.br/placa/equipamento/${item.id}/placa-limpa.pdf`
            )
        },
        {
          label: "Cancelar",
          onPress: () => console.log(),
          separa: "Cancelar"
        }
      ]
    });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <View style={styles.view2} container spacing={16}>
          <AppBar>
            <Toolbar>
              <Text variant="h6" color="inherit">
                {`Equipamento / Inspeções | ${(this.props.empresas||{}).nome||""}`}
              </Text>
              <TouchableOpacity
                style={styles.add}
                icon
                color="inherit"
                variant="outlined"
                disabled={
                  !(this.state.equipamento && this.state.equipamento.objectId)
                }
                onPress={() => {
                  this.novaUpInspecao();
                }}>
                <Icon fromFontFamily={"Material Icons"}  name={"backup"} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 20 }}
                color="inherit"
                variant="outlined"
                children={"Mover"}
                disabled={
                  !(this.state.equipamento && this.state.equipamento.objectId)
                }
                onPress={() => {
                  this.mover();
                }}
                />
              <TouchableOpacity
                style={{ marginLeft: 20 }}
                color="inherit"
                variant="outlined"
                children={"Nova Inspeção"}
                disabled={
                  !(this.state.equipamento && this.state.equipamento.objectId)
                }
                onPress={() => {
                  this.novaInspecao();
                }}
              />

              <TouchableOpacity
                icon
                style={{ marginLeft: 20 }}
                color="inherit"
                aria-label="Close"
                onPress={() => {
                  this.props.closeModal();
                }}
              >
                <Icon name={"close"} />
              </TouchableOpacity>
            </Toolbar>
          </AppBar>
          <View style={styles.view3} xs={12} md={4} item>
            <View type={"Paper"}>
              <ListView
                ListHeaderComponent={() => (
                  <View style={styles.head}>
                    <ListSubheader component="div">
                      {"Equipamento"}
                    </ListSubheader>
                    <TextInput
                      label="Filtrar empresas"
                      style={styles.input}
                      placeholder="Pesquisa por nome."
                      onChange={pesquisa => {
                        this.state.parametros.page=0;
                        this.setState({ pesquisa })}}
                      value={this.state.pesquisa}
                      inputNative
                    />
                    <TouchableOpacity
                      children={"Novo"}
                      variant="outlined"
                      onPress={() => {
                        this.open_modal_bottom1({
                          empresas: this.props.empresas.objectId,
                          unidades: this.props.unidades.objectId,
                          key_local: this.props.locais.objectId
                        });
                      }}
                    />
                  </View>
                )}
                renderItem={({ item, index }) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ width: "100%" }}
                      selected={this.state.id === item.id}
                      onClick={event => {
                        this.setState({ equipamento: item, id: item.id });
                        window.focusElementById("inspecoes");
                      }}
                    >
                      <Avatar src={item.logo}>
                        <Icon name={"settings_applications"} />
                      </Avatar>
                      <ListItemText
                        primary={item.denominacao}
                        secondary={item.codigo}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Placa do equipamento">
                          <Icon
                            color={"primary"}
                            name={"check_box_outline_blank"}
                            style={styles.icon}
                            onClick={() => {
                              this.openPlaca(item);
                            }}
                          />
                        </Tooltip>
                        <Icon
                          color={"primary"}
                          name={"created"}
                          style={styles.icon}
                          onClick={() => {
                            this.open_modal_bottom1(item);
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
                separator
                data={this.getList()}
                tablePagination
                count={Number(this.getSuggestions().length)}
                load={this.state.load}
                page={this.state.parametros.page}
                rowsPerPage={this.state.parametros.limit}
                handleChangePage={(event, page) => {
                  this.setState({
                    parametros: Object.assign(this.state.parametros, { page })
                  });
                }}
                handleChangeRowsPerPage={event => {
                  this.setState({
                    parametros: Object.assign(this.state.parametros, {
                      limit: event.target.value,
                      page: 0
                    })
                  });
                }}
              />
            </View>
          </View>
          <View style={styles.view3} id={"inspecoes"} xs={12} md={8} item>
            {this.state.equipamento ? (
              <Inspecoes
                {...this.props}
                parametros={{
                  key_equipamento: this.state.equipamento.objectId,
                  key_empresa: this.props.empresas.objectId,
                  where:''
                }}
                norCEmpresa
              />
            ) : null}
          </View>
        </View>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer",
    margin: 10
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 70
  },
  view3: {

  }
});

const styles2 = theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    // backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 2
  }
});
export default withStyles(styles2)(Home);
