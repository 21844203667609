import { Cloud } from "../infra";

export function salvarProduto(input, callback) {
  Cloud.post("almoxarifado/produto", input, (dados, error) => {
    callback(dados, error);
  });
}
export function atualizarProduto(input, callback) {
  Cloud.put("almoxarifado/produto", input, (dados, error) => {
    callback(dados, error);
  });
}
export function listarProduto(input, callback) {
  Cloud.get("almoxarifado/produto", input, (dados, error) => {
    callback(dados, error);
  });
}
export function deletarProduto(input, callback) {
  Cloud.delete("almoxarifado/produto", input, (dados, error) => {
    callback(dados, error);
  });
}
