import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import TextInput from "../../components/Input";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false,
      ocorrencias: new Dados("ocorrencias"),
      list: [],
      load: true,
      usar: []
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("sug_ocorrencias");
    if (this.state.text) {
      query.whereContains("titulo", this.state.text);
    }
    if (this.props.classe) {
      query.addSql(`(classe='${this.props.classe}' or classe='todos')`);
    }
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  usar() {
    if (this.state.usar.objectId) {
      this.props.activity.addIm(this.state.usar);
      this.props.closeModal();
    } else {
      alert("Deve ser escolhido uma opção");
    }
  }
  usarItem(item) {
    this.setState({ usar: item });
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue+"^", "i");
    const regex2 = new RegExp(escapedValue, "g");

    return list.filter(item => item.titulo&&(item.titulo.toLowerCase().match(regex2)||item.titulo.toLowerCase().match('^' + escapedValue) ||regex.test(item.titulo.toLowerCase())));
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View style={styles.head}>
              <Text style={styles.label}>{"Sugestões"}</Text>
                <TextInput
                  label="Filtrar empresas"
                  style={styles.input}
                  placeholder="Pesquisa por titulo."
                  onChange={pesquisa => {
                    this.setState({ pesquisa });
                  }}
                  value={this.state.pesquisa}
                  inputNative
                />
              </View>
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    button
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.usarItem(item, index);
                    }}
                  >
                    <ListItemText
                    primary={`${item.titulo} - ${item.tipo}`}
                    secondary={item.descricao} />
                    <Icon
                      color={"default"}
                      name={
                        this.state.usar.objectId == item.objectId
                          ? "check_circle"
                          : "radio_button_unchecked"
                      }
                    />
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.getSuggestions()}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.usar()}
                children={"Usar"}
                variant="outlined"
                load={this.state.load}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
});
