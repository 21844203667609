import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locais: new Dados("locais").parse(this.props.locais),
      load: false,
      value: 0
    };
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.locais.salvarNuven(re => {
      this.setState({
        load: false
      });
      if (this.props.atualizar)
        this.props.atualizar(this.state.locais);
      this.props.closeModal();
    });
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.locais.status = 3;
          this.setState({
            load2: true
          });
          this.state.locais.salvarNuven(re => {
            this.setState({
              load2: false
            });
            this.props.closeModal();
            if (this.props.atualizar) this.props.atualizar();
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Local"}</Text>
            <TextInput
              style={styles.nome}
              value={this.state.locais.nome}
              onChange={value => {
                this.state.locais.nome = value;
                this.setState({ locais: this.state.locais });
              }}
              ref={v => (this.nome = v)}
              keyboardType={"default"}
              label={"Nome"}
            />
            <TextInput
              style={styles.cnpj}
              value={this.state.locais.descricao}
              onChange={value => {
                this.state.locais.descricao = value;
                this.setState({ locais: this.state.locais });
              }}
              ref={v => (this.cnpj = v)}
              label={"Descrição"}
              multiline={ true }
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.deletar()}
                children={"Delete"}
                color={"secondary"}
                variant="outlined"
                style={styles.delete}
                disabled={!this.state.locais.objectId}
                load={this.state.load2}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
