import React, { Component } from "react";

import View from "react-1app/lib/View";
import Alert from "react-1app/lib/Alert";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Icon from "react-1app/lib/Icon";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Util } from "../../infra";
import Comp from "./Comp";
export default class Home extends Component {
  criar() {
    if (this.props.formularios.lista) {
      var componentes = this.props.formularios.lista;
      var pg = [];
      for (var i = 0; i < componentes.length; i++) {
        let item = componentes[i];
        try {
          if (item.code.lay.calculo) {
            this.caluclar(item);
          }
          if (!item.code.lay) {
            // console.log(foco.code);
          }
          // console.log(item.code.lay);
          if (item.code.lay.test) {
            this.test(item);
            // console.log(item.code.lay.test);
          } else {
            item.code.test = true;
          }
          pg.push(
            <Comp
              activity={this}
              item={item}
              navigator={this.props.navigator}
              itens_feramenta={this.props.itens_feramenta}
              listaHabilitados={this.props.listaHabilitados}
              inspecoes={this.props.inspecoes}
              ocorrencias={this.props.ocorrencias}
              dados={this.props.inspecoes.dados}
              key={"item_" + i}
            />
          );
        } catch (e) {
          console.log(e);
        } finally {
        }
      }
      return pg;
    } else {
      return [];
    }
  }

  caluclar(item) {
    try {
      var formula = item.code.lay.calculo; //  {a}+{b}
      for (var i = 0; i < this.props.formularios.lista.length; i++) {
        var foco = this.props.formularios.lista[i];
        if (foco.code.lay.tag && this.props.inspecoes.dados[foco.objectId]) {
          formula = Util.replaceAll(
            formula,
            "{" + foco.code.lay.tag + "}",
            this.props.inspecoes.dados[foco.objectId]
          ); // {a} por 10
        }
      }
      this.props.inspecoes.dados[item.objectId] = eval(formula);
    } catch (e) {
    } finally {
    }
  }
  test(item) {
    var test = item.code.lay.test; //  {a}+{b}
    for (var i = 0; i < this.props.formularios.lista.length; i++) {
      var foco = this.props.formularios.lista[i];

      // console.log(foco);
      // console.log(foco.code.lay.tag , this.props.inspecoes.dados[foco.objectId]);
      if (
        (foco.code.lay &&
          foco.code.lay.tag &&
          this.props.inspecoes.dados[foco.objectId]) ||
        (foco.code.lay &&
          foco.code.lay.tag &&
          !this.props.inspecoes.dados[foco.objectId])
      ) {
        test = Util.replaceAll(
          test,
          "{" + foco.code.lay.tag + "}",
          this.props.inspecoes.dados[foco.objectId]
        ); // {a} por 10
      }
      // console.log(foco.code.tag);
      if (
        foco.code.lay.lista &&
        foco.code.tag != "tbEQ" &&
        foco.code.tag != "tb" &&
        foco.code.tag != "selectinput" &&
        foco.code.tag != "data" &&
        foco.code.tag != "data" &&
        foco.code.tag != "ocorrencias"
      ) {
        this.test2(item, foco);
      }
    }
    try {
      item.code.test = eval(test);
    } catch (e) {
    } finally {
    }
  }
  test2(item, foco) {
    var test = item.code.lay.test; //  {a}+{b}
    for (var i = 0; i < foco.code.lay.lista.length; i++) {
      var foco2 = foco.code.lay.lista[i];
      if (!foco2.code.lay) {
        // console.log(foco2.code);
      }
      // console.log(foco2);
      if (
        (foco2.code.lay &&
          foco2.code.lay.tag &&
          this.props.inspecoes.dados[foco2.objectId]) ||
        (foco2.code.lay &&
          foco2.code.lay.tag &&
          !this.props.inspecoes.dados[foco2.objectId])
      ) {
        // console.log(this.props.inspecoes.dados[foco2.objectId]);
        test = Util.replaceAll(
          test,
          "{" + foco2.code.lay.tag + "}",
          this.props.inspecoes.dados[foco2.objectId]
        ); // {a} por 10
      }
      // console.log(test,2);
    }
    try {
      // console.log(test,2);

      item.code.test = eval(test);
    } catch (e) {
    } finally {
    }
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <View style={styles.view1}>
          <TouchableOpacity
            style={styles.bottom}
            onPress={() => {
              this.props.salvar();
            }}
          >
            <Icon
              style={styles.icon}
              fromFontFamily={"Material Icons"}
              icon={"save"}
            />
          </TouchableOpacity>
        </View>
        {this.criar()}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 20,
    paddingTop: 50,
    paddingBottom: 50,
    width: "auto",
    minWidth: 610,
    minHeight: 790,
    display: null
  },
  view1: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "fixed",
    width: 50,
    top: 60,
    right: 10
  },
  bottom: {
    margin: 10,
    borderRadius: 5,
    width: 50,
    right: 20,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    backgroundColor: "rgba(150,150,145,1)"
  },
  icon: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  }
});
