import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import moment from "moment";
import List from "./List";
import Select from "../../components/SelectInput";
import ModalInspecoes from "./Inspecoes";
import { PgModal } from "react-1app/lib/PgModal";
import {OpenDialog} from "react-1app";
// import DatePicker from "react-1app/lib/DatePicker";
import DatePicker from "../../components/DatePicker";

import * as RelatorioPdf from "../../worker/relatorio_pdf";
import { Util } from "../../infra";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      empresaP: {
        entidade: "empresas",
        order: "nome",
        where: []
      },
      select: "locais",
      inicio: moment().toJSON(),
      termino: moment()
        .add(12, "months")
        .toJSON()
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  openGerar() {
    OpenDialog({
      title: "Gerar Excel de vencimentos",
      description: "Defina a data de início e fim período desejado",
      action: [
        {
          label: "Gerar",
          onClick: () => {
            this.excelComerialVencimento();
          }
        },
        { label: "Cancelar" }
      ],
      Component: ()=>(
        <ModalExelVencimendoDatas
          onSetState={({ termino, inicio }) => {
            this.setState({ termino, inicio });
          }}
        />
      )
    });
  }

  excelComerialVencimento() {
    this.setState({
      gerar4: true
    });

    RelatorioPdf.excelComerialVencimento(
      {
        key_user: this.state.usuario.objectId,
        inicio: this.state.inicio,
        termino: this.state.termino
      },
      (data, error) => {
        this.setState({
          gerar4: false,
          inicio: moment().toJSON(),
          termino: moment()
            .add(12, "months")
            .toJSON()
        });
        if (error) return alert("Erro ao gerar excel");

        Util.web("https://megasteam.1app.com.br/files/" + data.nome);
      }
    );
  }

  head() {
    return (
      <Select
        list={[
          { label: "Local", value: "locais" },
          { label: "Equipamneto", value: "equipamento" }
        ]}
        value={this.state.select}
        onChange={value => {
          if (value == "locais") {
            this.setState({
              localP: {
                entidade: "locais",
                order: "nome",
                where: [
                  ["empresas", this.state.unidade.empresas],
                  ["unidades", this.state.unidade.objectId]
                ]
              },
              equipamentoP: null,
              select: value
            });
          } else {
            this.setState({
              equipamentoP: {
                entidade: "equipamentos",
                order: "createdAt",
                where: [
                  ["key_empresa", this.state.unidade.empresas],
                  ["key_unidade", this.state.unidade.objectId]
                ]
              },
              localP: null,
              select: value
            });
          }
        }}
        key_label={"label"}
        key_value={"value"}
      />
    );
  }

  openInspecoes(props) {
    if (this.Modal && this.Modal.openModal)
      this.Modal.openModal(ModalInspecoes, {
        ...this.props,
        heranca: this.state,
        activity: this,
        ...props
      });
  }

  openIsUnidade() {
    this.openInspecoes({
      parametros: {
        key_empresa: this.state.unidade.empresas,
        key_unidade: this.state.unidade.objectId
      }
    });
  }

  openIsLocal(item) {
    this.openInspecoes({
      parametros: {
        key_empresa: item.empresas,
        key_unidade: item.unidades,
        key_local: item.objectId
      }
    });
  }
  openIsEquipamento(item) {
    this.openInspecoes({
      parametros: {
        key_empresa: item.key_empresa,
        key_unidades: item.key_unidade,
        key_equipamento: item.objectId
      }
    });
  }

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={4} item>
          <List
            parametros={this.state.empresaP}
            key_label={"nome"}
            action={() => (
              <TouchableOpacity
                icon
                onClick={() => {
                  this.openGerar();
                }}
              >
                <Icon name={"insert_drive_file"} />
              </TouchableOpacity>
            )}
            onChange={item => {
              if (this.state.idEmpresa != item.id)
                this.setState({
                  unidadeP: {
                    entidade: "unidades",
                    order: "nome",
                    where: [["empresas", item.objectId]]
                  },
                  idEmpresa: item.id,
                  localP: null,
                  equipamentoP: null
                },()=>{
                  window.focusElementById("unidade");
                });
            }}
            id={this.state.idEmpresa}
            title={"Empresa"}
          />
        </View>
        <View style={styles.view3} id={"unidade"} xs={12} md={4} item>
          {this.state.unidadeP ? (
            <List
              parametros={this.state.unidadeP}
              key_label={"nome"}
              onChange={item => {
                if (this.state.idUnidade != item.id)
                  this.setState({
                    localP: {
                      entidade: "locais",
                      order: "nome",
                      where: [
                        ["empresas", item.empresas],
                        ["unidades", item.objectId]
                      ]
                    },
                    unidade: item,
                    idUnidade: item.id,
                    select: "locais",
                    equipamentoP: null
                  },()=>{
                    window.focusElementById("l_e");
                  });
              }}
              id={this.state.idUnidade}
              title={"Unidade"}
            />
          ) : null}
        </View>
        <View style={styles.view3} id={"l_e"} xs={12} md={4} item>
          {this.state.localP ? (
            <List
              parametros={this.state.localP}
              key_label={"nome"}
              onChange={item => {
                this.openIsLocal(item);
              }}
              open={item => {
                this.openIsLocal(item);
              }}
              title={"Locais"}
              head={() => this.head()}
              action={() => (
                <TouchableOpacity
                  icon
                  onClick={() => {
                    this.openIsUnidade();
                  }}
                >
                  <Icon name={"insert_drive_file"} />
                </TouchableOpacity>
              )}
            />
          ) : null}
          {this.state.equipamentoP ? (
            <List
              parametros={this.state.equipamentoP}
              key_label={"denominacao"}
              onChange={item => {
                this.openIsEquipamento(item);
              }}
              open={item => {
                this.openIsEquipamento(item);
              }}
              title={"Equipamento"}
              head={() => this.head()}
              action={() => (
                <TouchableOpacity
                  icon
                  onClick={() => {
                    this.openIsUnidade();
                  }}
                >
                  <Icon name={"insert_drive_file"} />
                </TouchableOpacity>
              )}
            />
          ) : null}
        </View>
        <PgModal {...this.props} ref={f => (this.Modal = f)} />
      </View>
    );
  }
}

class ModalExelVencimendoDatas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inicio: moment().toJSON(),
      termino: moment()
        .add(12, "months")
        .toJSON()
    };
  }

  render() {
    return (
      <View style={styles.view8}>
        <DatePicker
          style={styles.datepicker}
          value={this.state.inicio}
          type={"date"}
          onChange={value => {
            this.state.inicio = value;
            this.props.onSetState(this.state);
            this.setState({
              item: this.state.item
            });
          }}
          ref={v => (this.datepicker = v)}
          label={"Data inicio"}
        />
        <DatePicker
          style={styles.datepicker1}
          value={this.state.termino}
          type={"date"}
          onChange={value => {
            this.props.onSetState(this.state);
            this.state.termino = value;
            this.setState({
              item: this.state.item
            });
          }}
          ref={v => (this.datepicker1 = v)}
          label={"Data de fim"}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  image: {
    height: 30,
    width: 30
  },
  icon: {
    cursor: "pointer"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
