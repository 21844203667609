import React, { Component } from "react";
import Progress from "react-1app/lib/Progress";

import View from "react-1app/lib/View";
import Alert from "react-1app/lib/Alert";
import Text from "react-1app/lib/Text";
import DatePicker from "react-1app/lib/DatePicker";
import Icon from "react-1app/lib/Icon";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import { Dados, Query, Cloud } from "../../infra";
import * as Inspecoes from "../../worker/inspecoes";
import * as RelatorioPdf from "../../worker/relatorio_pdf";
import { OpenSnack } from "react-1app/lib/SnackBar";
import { OpenDialogOption } from "react-1app";
import PgQrcode from "../equipamento/PgQrCode";
import ListaOcorrencias from "../inspecoes/PgOcorrencias";

import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import FolhaFormulario from "./Folha";
import ListaAlteracoes from "./ListaAlteracoes";
import InfoInspecao from "./InfoInspecao";
import InfoCapa from "./InfoCapa";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      load: true,
      totalLista: 0,
      inspecoes: new Dados("inspecoes").parse({
        id: this.props.match.params.id_inspecao
      }),
      usuario: this.props.screenProps.store.getState().user,
      buscando: true,
      init: false,
      formulario: null,
      listaHabilitados: [],
      ocorrencias: new Dados("ocorrencias"),
      load_formularios:true
    };
  }

  componentDidMount() {
    Inspecoes.buscarInspecao(this.state.inspecoes, inspecoes => {
      this.setState({
        inspecoes: new Dados("inspecoes").parse(inspecoes),
        buscando: false
      });
      this.get_data_tela();
      this.get_data_formularios();
      this.get_data_feramenta();
      this.buscar_ocorrecias();
    });
  }

  verPdf() {
    var wnd = window.open(this.state.inspecoes.link_rel_impressao);
    wnd.print();
  }
  imprimir() {
    var wnd = window.open(this.state.inspecoes.impressao);
    wnd.print();
  }

  get_data_formularios() {
    if (
      this.state.inspecoes.listaFormulario &&
      this.state.inspecoes.listaFormulario[0]
    ) {
      this.setState({
        load_formularios: false
      });
    } else {
      this.atualizarFormulario();
    }
  }

  gerarpdf2(assinado) {
    this.setState({ gerando: true });
    RelatorioPdf.gerarPdfInspesaoInp(
      {
        key_inspecoes: this.state.inspecoes.objectId,
        key_user: this.state.usuario.objectId,
        assinado
      },
      (data, error) => {
        if (data) {
          if (data.erro) {
            alert("Pdf em manutenção");
            this.setState({ gerando: false });
          } else {
            this.state.inspecoes.impressao = data.impressao;
            this.setState({ inspecoes: this.state.inspecoes, gerando: false });
            var wnd = window.open(data.impressao).print();
          }
        } else {
          this.setState({ gerando: false });
        }
      }
    );
  }

  actionPdf() {
    // return this.setState({open:true})
    let { inspecoes } = this.state;
    if (inspecoes.impressao) {
      let listOpcoes = [
        {
          label: "Gerar com assinatura",
          onPress: () => this.gerarpdf2(true),
          separa: "Opções PDF"
        },
        { label: "Gerar sem assinatura", onPress: () => this.gerarpdf2(false) },
        { label: "Ver PDF atual", onPress: () => this.imprimir() }
      ];
      if (this.state.usuario.adm && inspecoes.impressao) {
        listOpcoes.push({
          label: inspecoes.link_rel_impressao
            ? "Assinar Eletronicamente Novamente"
            : "Assinar Eletronicamente",
          onPress: () => this.gerarPdfAssinado(inspecoes),
          separa: "Opções PDF assinado ( AssinaWeb )"
        });
      }
      if (inspecoes.link_rel_impressao)
        listOpcoes.push({
          label: "Ver PDF assinado Eletronicamente",
          onPress: () => this.verPdf()
        });
      listOpcoes.push({
        label: "Cancelar",
        onPress: () => console.log(),
        separa: "Cancelar"
      });
      OpenDialogOption({
        title: "Gerar PDF",
        description: "Você realmente deseja gerar um novo PDF para impressão?",
        action: listOpcoes
      });
    } else {
      this.gerarpdf2();
    }
  }

  gerarPdfAssinado(inspecoes) {
    this.setState({ gerando: inspecoes.objectId });
    RelatorioPdf.gerarPdfAssinado(
      {
        key_inspecoes: inspecoes.objectId,
        key_user: this.state.usuario.objectId,
        tag: "impressao"
      },
      (data, error) => {
        if (error) {
          OpenSnack({ message: error.msg, type: "error" });
          this.setState({ gerando: false });
        } else {
          OpenSnack({ message: "PDF gerando", type: "success" });
          inspecoes.link_rel = data.link_rel;
          this.setState({ gerando: false });
          var wnd = window.open(data.link_rel);
          wnd.print();
        }
        this.get_data_inspecoes();
      }
    );
  }

  open_modal_bottom7() {
    this.props.navigator.openModal({
      pageName: "PgQrcode",
      component: PgQrcode,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes: this.state.inspecoes
      }
    });
  }

  clonar(item) {
    Alert.alert(
      "Criar uma nova Inspeção",
      `Você criara um clone desta inspecao`,
      [
        {
          text: "Criar",
          onPress: () => {
            this.setState({
              new: true
            });
            item.key_user = this.state.usuario.objectId;
            item.user = this.state.usuario;
            Inspecoes.clonarInspeacao(item, re => {
              window.location.assign(
                window.location.origin + "/editar/" + re.id
              );
              this.setState({
                new: false
              });
            });
          }
        },
        { text: "Cancelar", onPress: () => console.log("ok") }
      ]
    );
  }

  atualizarFormulario() {
    this.setState({
      load_formularios: true
    });
    var query = new Query("formularios");
    query.whereEqualTo("relatorio", this.state.inspecoes.relatorio.objectId);
    query.addAscendingOrder("ordem");
    query.setLimit(1000);
    query.select(lista => {
      if (lista[0]) {
        this.atualizarRelatorio(relatorio => {
          this.state.inspecoes.listaFormulario = lista;
          this.state.inspecoes.relatorio = relatorio;
          this.setState({
            inspecoes: this.state.inspecoes,
            load_formularios: false,
            formulario: null,
            id: null
          });
          this.salvar_bottom();
          OpenSnack({ message: "Formularios Atualizados", type: "success" });
        });
      } else {
        this.setState({
          load_formularios: false
        });
        OpenSnack({ message: "Lista de Formularios vazia.", type: "error" });
      }
    });
  }

  open_modal_bottom5() {
    this.props.navigator.openModal({
      component: ListaAlteracoes,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes: this.state.inspecoes
      }
    });
  }

  get_data_tela() {
    var query = new Query("user_local");
    query.addAscendingOrder("nome");
    query.whereEqualTo("habilitado", true);
    query.setLimit(1000);
    query.select(lista => {
      if (lista[0]) {
        this.setState({
          listaHabilitados: lista,
          load_tela: false
        });
      }
    });
  }

  pendencias() {
    if (this.state.inspecoes.sPendecias) {
      this.state.inspecoes.sPendecias = false;
      this.setState({ alterado: true });
    } else {
      this.state.inspecoes.sPendecias = true;
      this.setState({ alterado: true });
    }
  }

  atualizarRelatorio(callback) {
    var query = new Query("relatorio");
    query.whereEqualTo("objectId", this.state.inspecoes.relatorio.objectId);
    query.first(dados => {
      if (!dados || !dados.objectId) {
        this.setState({
          load_formularios: false
        });
        return OpenSnack({ message: "Erro relatorio vazia.", type: "error" });
      }
      callback(dados);
    });
  }

  open_modal_bottom() {
    this.props.navigator.openModal({
      component: InfoInspecao,
      props: {
        navigator: this.props.navigator,
        equipamentos: this.state.inspecoes.equipamento,
        heranca: this.state,
        activity: this,
        inspecoes: this.state.inspecoes,
        salvarInspecao: inp => this.salvar_bottom(inp)
      }
    });
  }

  get_data_feramenta() {
    var query = new Query("feramenta");

    query.addAscendingOrder("nome");
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_feramenta: lista,
        load_feramenta: false
      });
    });
  }

  open_modal_bottom4() {
    this.props.navigator.openModal({
      component: ListaOcorrencias,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes: this.state.inspecoes
      }
    });
  }

  buscar_ocorrecias() {
    var query = new Query("ocorrencias");
    query.whereEqualTo("key_inspecoes", this.state.inspecoes.objectId);
    query.setLimit(1000);
    query.first(data => {
      if (data && data.objectId) {
        this.setState({ ocorrencias: data, load_tela: false });
      }
    });
  }

  open_modal_bottom6() {
    this.props.navigator.openModal({
      component: InfoCapa,
      props: {
        heranca: this.state,
        activity: this,
        inspecoes: this.state.inspecoes,
        salvarInspecao: inp => this.salvar_bottom(inp)
      }
    });
  }

  salvar_bottom(inp) {
    // console.log(this.state.inspecoes,inp);
    this.setState({
      save: true
    });
    this.state.inspecoes.setMetodoApi("salvarWeb", "POST");
    this.state.inspecoes.salvarNuven(re => {
      this.setState({
        save: false
      });
      OpenSnack({ message: "Salvo", type: "success" });
      this.salvarGeo();
    });
  }

  salvarGeo() {
    if (this.state.usuario.adm) return;
    Cloud.post(
      "geolocalizacao",
      {
        latitude: 0.1,
        longitude: 0.1,
        key_inspecao_local: this.state.inspecoes._idLocal,
        tag: !this.state.init ? "inicio" : "progresso",
        key_inspecao: this.state.inspecoes.objectId,
        origin: "dash"
      },
      (dados, error) => {
        if (!this.state.init) this.onRotina();
        this.setState({ init: true });
        // console.log(dados,error);
      }
    );
  }
  pronto(quatidade, texto) {
    if (quatidade == 0) {
      if (this.state.inspecoes.concluido) {
        this.state.inspecoes.concluido = false;
        this.setState({ alterado: true });
      } else {
        this.state.inspecoes.concluido = true;
        this.setState({ alterado: true });
      }
    } else {
      Alert.alert(
        "Existe " + quatidade + " items obrigatorios não preenchido ",
        "Entre eles: " + texto,
        [{ text: "Ok", onPress: () => console.log("ok") }]
      );
      this.state.inspecoes.concluido = false;
      this.setState({ alterado: true });
    }
  }
  verificar() {
    var texto = "";
    var quatidade = 0;
    for (var i = 0; i < this.state.inspecoes.listaFormulario.length; i++) {
      let item = this.state.inspecoes.listaFormulario[i];
      for (var a = 0; a < item.lista.length; a++) {
        let item1 = item.lista[a];
        let test = false;
        if (
          item1.code.lay.lista &&
          item1.code.test &&
          item1.code.tag != "tbEQ" &&
          item1.code.tag != "checkList" &&
          item1.code.tag != "tb" &&
          item1.code.tag != "selectinput" &&
          item1.code.tag != "data" &&
          item1.code.tag != "ocorrencias"
        ) {
          for (var c = 0; c < item1.code.lay.lista.length; c++) {
            let item2 = item1.code.lay.lista[c];
            // if (!item2 || !item2.code) {
            //   // console.log(item1,c);
            // }
            if (
              item2.code.lay.obrigatorio &&
              item2.code.test &&
              !this.state.inspecoes.dados[item2.objectId]
            ) {
              quatidade = quatidade + 1;
              if (item2.code.lay.text) {
                texto += item2.code.lay.text + ", ";
              }
              if (item2.code.lay.descricao) {
                texto += item2.code.lay.descricao + ", ";
              }
            }
          }
        } else if (
          item1.code.lay.obrigatorio &&
          item1.code.test &&
          item1.code.tag == "checkList"
        ) {
          quatidade = quatidade + 1;
          for (var d = 0; d < item1.code.lay.lista.length; d++) {
            let item2 = item1.code.lay.lista[d];
            if (
              !test &&
              item2.code.test &&
              this.state.inspecoes.dados[item2.objectId]
            ) {
              quatidade = quatidade - 1;
              test = true;
            }
            if (item1.code.lay.lista.length == d + 1 && !test) {
              texto += item1.code.lay.text + ", ";
            }
          }
        } else if (
          (item1.code.lay.obrigatorio &&
            item1.code.tag == "tbEQ" &&
            item1.code.test) ||
          (item1.code.lay.obrigatorio &&
            item1.code.tag == "tb" &&
            item1.code.test)
        ) {
          if (
            !this.state.inspecoes.dados[item1.objectId] ||
            !this.state.inspecoes.dados[item1.objectId][0]
          ) {
            quatidade = quatidade + 1;
            texto += "Tabela, ";
          }
        } else if (item1.code.lay.obrigatorio && item1.code.tag == "son") {
          if (
            !this.state.inspecoes.dados[item1.objectId] == true &&
            !this.state.inspecoes.dados[item1.objectId] == false
          ) {
            quatidade = quatidade + 1;

            texto += item1.code.lay.text + ", ";
          }
        } else if (item1.code.lay.obrigatorio && item1.code.tag == "data") {
          if (!this.state.inspecoes[item1.code.lay.value]) {
            quatidade = quatidade + 1;
            if (item1.code.lay.descricao) {
              texto += item1.code.lay.descricao + ", ";
            }
          }
        } else {
          if (
            item1.code.lay.obrigatorio &&
            item1.code.test &&
            !this.state.inspecoes.dados[item1.objectId]
          ) {
            quatidade = quatidade + 1;
            if (item1.code.lay.text) {
              texto += item1.code.lay.text + ", ";
            }
            if (item1.code.lay.descricao) {
              texto += item1.code.lay.descricao + ", ";
            }
          }
        }
      }
    }
    this.pronto(quatidade, texto);
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        {this.state.min ? (
          <TouchableOpacity
            style={styles.bottom44}
            onPress={() => {
              this.setState({ min: false });
            }}
          >
            <Icon
              style={styles.icon}
              fromFontFamily={"Material Icons"}
              icon={"menu"}
            />
          </TouchableOpacity>
        ) : null}
        {!this.state.min ? (
          <View style={styles.view3} xs={12} md={4} item>
            <View type={"Paper"} style={styles.menu}>
              <View style={styles.head}>
                <Text style={styles.label} color={"primary"}>
                  {"Formularios"}
                </Text>
                {this.state.usuario.adm && false ? (
                  <DatePicker
                    style={styles.data_contato}
                    value={this.state.inspecoes.data_contato}
                    onChange={value => {
                      this.state.inspecoes.data_contato = value;
                      this.salvar_bottom();
                    }}
                    ref={v => (this.data_contato = v)}
                    label={"Data para contato"}
                  />
                ) : null}
                {!this.state.new ? (
                  <TouchableOpacity
                    style={styles.bottom5}
                    onPress={() => {
                      this.clonar(this.state.inspecoes);
                    }}
                    icon
                    tooltip={"Clonar"}
                  >
                    <Icon
                      style={styles.icon9}
                      fromFontFamily={"Material Icons"}
                      icon={"add"}
                    />
                  </TouchableOpacity>
                ) : null}
                {this.state.gerando || this.state.new ? (
                  <View style={styles.view7}>
                    <Progress style={styles.progresso} />
                  </View>
                ) : null}
                {(this.state.inspecoes.impressao || true) &&
                !this.state.gerando ? (
                  <TouchableOpacity
                    style={styles.bottom6}
                    onPress={() => {
                      this.actionPdf();
                    }}
                    icon
                    tooltip={"PDF"}
                  >
                    <Icon
                      style={styles.icon10}
                      fromFontFamily={"Material Icons"}
                      icon={"print"}
                    />
                  </TouchableOpacity>
                ) : null}
                <TouchableOpacity
                  style={styles.bottom6}
                  onPress={() => {
                    this.open_modal_bottom7();
                  }}
                  icon
                >
                  {this.state.inspecoes.qrcode ? (
                    <Icon
                      fromFontFamily={"Material Design Icons"}
                      icon={"qrcode"}
                    />
                  ) : null}
                  {!this.state.inspecoes.qrcode ? (
                    <Icon
                      style={styles.icon10}
                      fromFontFamily={"Material Design Icons"}
                      icon={"qrcode"}
                    />
                  ) : null}
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.bottom5}
                  onPress={() => {
                    this.setState({ min: true });
                  }}
                  icon
                  tooltip={"Fechar aba"}
                >
                  <Icon
                    style={styles.icon9}
                    fromFontFamily={"Material Icons"}
                    icon={"close"}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.view4}>
                <TouchableOpacity
                  style={styles.bottom2}
                  onPress={() => {
                    this.verificar();
                  }}
                >
                  <Text style={styles.label2} text={"Relatório Concluído "} />
                  <Icon
                    style={styles.icon4}
                    fromFontFamily={"Material Icons"}
                    icon={
                      !this.state.inspecoes.concluido
                        ? "radio_button_unchecked"
                        : "check_circle"
                    }
                  />
                </TouchableOpacity>
                {this.state.usuario.adm ? (
                  <TouchableOpacity
                    style={styles.bottom2}
                    onPress={() => {
                      this.pendencias();
                    }}
                  >
                    <Text
                      style={styles.label2}
                      text={"Relatório sem Pendências"}
                    />
                    <Icon
                      style={styles.icon6}
                      fromFontFamily={"Material Icons"}
                      icon={
                        !this.state.inspecoes.sPendecias
                          ? "radio_button_unchecked"
                          : "check_circle"
                      }
                    />
                  </TouchableOpacity>
                ) : null}
                <TouchableOpacity
                  style={styles.bottom2}
                  onPress={() => {
                    this.open_modal_bottom4();
                  }}
                >
                  <Text style={styles.label2} text={"Lista de Pendencias"} />
                  <Icon
                    style={styles.icon7}
                    fromFontFamily={"Material Icons"}
                    icon={"view_list"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.bottom2}
                  onPress={() => {
                    this.open_modal_bottom5();
                  }}
                >
                  <Text style={styles.label2} text={"Lista de Alterações"} />
                  <Icon
                    style={styles.icon7}
                    fromFontFamily={"Material Icons"}
                    icon={"view_list"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.bottom2}
                  onPress={() => {
                    this.open_modal_bottom6();
                  }}
                >
                  <Text style={styles.label2} text={"Informações da Capa"} />
                  <Icon
                    style={styles.icon7}
                    fromFontFamily={"Material Icons"}
                    icon={"closed_caption"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.bottom2}
                  onPress={() => {
                    this.open_modal_bottom();
                  }}
                >
                  <Icon
                    style={styles.icon1}
                    fromFontFamily={"Material Design Icons"}
                    icon={"engine"}
                  />
                  <Text
                    style={styles.tipo}
                    text={this.state.inspecoes.codigo}
                    color={"primary"}
                  />
                  <Icon
                    style={styles.icon2}
                    fromFontFamily={"Material Design Icons"}
                    icon={"pencil"}
                  />
                </TouchableOpacity>
              </View>
              <View type={"Paper"}>
                <ListView
                  renderItem={({ item, index }) => {
                    return (
                      <ListItem
                        key={index}
                        button
                        style={{ minWidth: "100%" }}
                        selected={item.id === this.state.id}
                        onClick={() => {
                          this.setState({ formulario: item, id: item.id });
                          window.focusElementById("edit");
                        }}
                      >
                        <Icon
                          color={"primary"}
                          fromFontFamily={"Material Design Icons"}
                          name={"file-document"}
                        />
                        <ListItemText
                          primary={item.nome}
                          secondary={item.descricao}
                        />
                      </ListItem>
                    );
                  }}
                  separator
                  nativo
                  data={this.state.inspecoes.listaFormulario}
                  load={this.state.load_formularios}
                />
              </View>
              <View style={styles.view4}>
                <TouchableOpacity
                  style={styles.bottom3}
                  onPress={() => {
                    this.atualizarFormulario();
                  }}
                >
                  <Icon
                    style={styles.icon3}
                    fromFontFamily={"Material Design Icons"}
                    icon={"update"}
                  />
                  <Text style={styles.label3} text={"Atualizar Formulários"} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        <View
          style={styles.view3}
          id={"edit"}
          xs={"12"}
          md={this.state.min ? 12 : 8}
          item
        >
          {this.state.formulario ? (
            <FolhaFormulario
              activity={this.props.activity}
              salvar={() => {
                this.salvar_bottom();
              }}
              dados={this.state.inspecoes.dados}
              navigator={this.props.navigator}
              inspecoes={this.state.inspecoes}
              itens_feramenta={this.state.itens_feramenta}
              listaHabilitados={this.state.listaHabilitados}
              heranca={this.state}
              formularios={this.state.formulario}
              ocorrencias={this.state.ocorrencias}
            />
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  bottom44: {
    margin: 10,
    borderRadius: 5,
    width: 50,
    top: 60,
    left: 70,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    backgroundColor: "rgba(150,150,145,1)",
    position: "fixed"
  },
  bottom3: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    backgroundColor: "rgba(109,107,107,1)",
    marginTop: 10
  },
  icon3: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    margin: 10
  },
  menu: {
    minHeight: "100%",
    width: "100%",
    padding: 5
  },
  head: {
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    fontSize: 20,
    marginLeft: 10,
    flex: 1
  },
  image: {
    height: 30,
    width: 30
  },
  bottom2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgba(219,219,229,1)",
    marginTop: 5
  },
  label2: {
    flex: 1
  },
  tipo: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    flex: 1,
    // marginLeft: 5,
    marginTop: 3
  },
  view4: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view3: {
    height: "100%"
  },
  icon10: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view2: {},
  numero: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,150,1)",
    alignSelf: "stretch",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10
  }
});
