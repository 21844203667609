"use strict";
import React from "react";
import { Util } from "../../infra";

import {
  Text,
  Component,
  StyleSheet,
  View,
  Icon,
  TouchableOpacity,
  ScrollView,
  Progress,
  Table,
  OpenSnack
} from "react-1app";
import {
  buscarInspecoesRelatorio,
  buscarInspecoesRelatorioGrafico,
  buscarInspecoesRelatorioGraficoTipo,
  buscarInspecoesRelatorioGraficoUser,
  buscarOsUserType
} from "../../worker/inspecoes";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Cards from "./Cards";
import GraficoRelatorio from "./GraficoRelatorio";
import ModalTabela from "./ModalTabela";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DatePicker from "../../components/DatePicker";
import TextInput from "../../components/Input";
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itens_inspecoes: [],
      listTipo: [],
      listUser: [],
      total_equipamento: 0,
      totalLista: 0,
      total_horas: 0,
      text: "",
      value: 0,
      fim: null,
      inicio: null,
      listOsUser: [],
      parametros: {
        limit: 5,
        index: 0,
        page: 0,
        sortName: "nome",
        sortOrder: "asc",
        listGrafico: [],
        loadGrafico: true
      }
    };
  }

  get_data_inspecoes() {
    if (!this.state.text && this.state.value == 0)
      return OpenSnack({ message: "Adicione um OS" });
    if ((!this.state.inicio || !this.state.fim) && this.state.value == 1)
      return OpenSnack({ message: "Adicione um inicio e um fim" });
    if (this.state.value == 0) {
      this.atualizar_inspecoes();
    } else {
      this.buscarOsUserType();
    }
    this.buscarDadosGrafico();
    this.buscarDadosGraficoTipo();
    this.buscarDadosGraficoUser();
  }

  atualizar_inspecoes() {
    // if (!this.state.text) return OpenSnack({ message: "Adicione um OS" });
    // return ;
    this.setState({
      load_inspecoes: true,
      loadGrafico: true
    });
    let { limit, page } = this.state.parametros;
    buscarInspecoesRelatorio(
      {
        limit,
        page,
        codigo: this.state.text,
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false )",
        // sql_ordem:'  "sPendecias" ASC nulls last, "createdAt" DESC nulls last '
        sql_ordem: '"createdAt" DESC nulls last  '
      },
      (
        { lista = [], total = 0, total_equipamento = 0, total_horas = 0 },
        error
      ) => {
        this.setState({
          total_horas,
          totalLista: total,
          itens_inspecoes: lista,
          total_equipamento,
          load_inspecoes: false
        });
        if (!total) {
          this.setState({ loadGrafico: false });
          return OpenSnack({ message: "OS não encontrada" });
        }
      }
    );
  }

  buscarOsUserType() {
    buscarOsUserType(
      {
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false )  and campo=true"
      },
      (list = [], error) => {
        this.setState({ listOsUser: list, loadGrafico: false });
      }
    );
  }

  buscarDadosGrafico() {
    buscarInspecoesRelatorioGrafico(
      {
        codigo: this.state.text,
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false )  and campo=true",
        limit: 20
      },
      (list = [], error) => {
        this.setState({ listGrafico: list, loadGrafico: false });
      }
    );
  }
  buscarDadosGraficoTipo() {
    buscarInspecoesRelatorioGraficoTipo(
      {
        codigo: this.state.text,
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false ) and campo=true"
      },
      (list = [], error) => {
        this.setState({ listTipo: list });
      }
    );
  }

  buscarDadosGraficoUser() {
    buscarInspecoesRelatorioGraficoUser(
      {
        codigo: this.state.text,
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false )  and campo=true"
      },
      (list = [], error) => {
        this.setState({ listUser: list });
      }
    );
  }

  openListaConpleta() {
    OpenSnack({ message: "Buscando dados. Aguarde!" });
    buscarInspecoesRelatorioGrafico(
      {
        codigo: this.state.text,
        inicio: this.state.inicio,
        fim: this.state.fim,
        where: "( naoedit is NULL  or naoedit=false )  and campo=true",
        limit: 1000
      },
      (list = [], error) => {
        console.log(list);
        this.props.navigator.openModal({
          component: ModalTabela,
          props: {
            listData: list.map(item => [
              moment(item.dia, "YY/MM/DD").format("DD MMM YYYY"),
              item.total
            ]),
            listHead: ["Dia", "Total"],
            title: "Historico Inspeçãos",
            navigator: this.props.navigator
          }
        });
      }
    );
  }

  render() {
    return (
      <View style={styles.tela}>
        <View style={styles.view0}>
          <View style={styles.view1}>
            <Tabs
              value={this.state.value}
              onChange={(event, value) => {
                this.setState(
                  value == 0
                    ? { inicio: null, fim: null, value }
                    : { text: "", value }
                );
              }}
            >
              <Tab label="Relatorio OS" />
              <Tab label="Relatorio Período" />
            </Tabs>
            <View style={styles.view2}>
              {this.state.value == 0 ? (
                <TextInput
                  style={styles.textinput1}
                  value={this.state.text}
                  inputNative
                  onChange={value => {
                    this.state.text = value;
                    this.setState({
                      item: this.state.item
                    });
                  }}
                  onSubmitEditing={() => {
                    this.get_data_inspecoes();
                  }}
                  placeholder={"Buscar por OS"}
                />
              ) : null}
              {this.state.value == 1 ? (
                <DatePicker
                  value={this.state.inicio}
                  type={"date"}
                  onChange={value => {
                    console.log(value);
                    this.state.inicio = value;
                    this.setState({
                      inicio: value
                    });
                  }}
                  label={"Data Inicio"}
                />
              ) : null}
              {this.state.value == 1 ? (
                <DatePicker
                  type={"date"}
                  value={this.state.fim}
                  onChange={value => {
                    this.state.fim = value;
                    this.setState({
                      item: this.state.item
                    });
                  }}
                  label={"Data Fim"}
                />
              ) : null}
              <TouchableOpacity
                style={styles.bottom0}
                onPress={() => {
                  this.get_data_inspecoes();
                }}
              >
                {(!this.state.load_inspecoes && (
                  <Icon
                    fromFontFamily={"Material Design Icons"}
                    icon={"magnify"}
                  />
                )) || <Progress />}
              </TouchableOpacity>
            </View>
            <View style={styles.view11}>
              <TouchableOpacity
                style={styles.bottom7}
                onPress={() => {
                  this.get_data_inspecoes();
                }}
              >
                <Icon
                  style={styles.icon12}
                  fromFontFamily={"Material Icons"}
                  icon={"loop"}
                />
              </TouchableOpacity>
            </View>
          </View>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <GraficoRelatorio
                list={this.state.listGrafico}
                loadGrafico={this.state.loadGrafico}
                action={
                  <TouchableOpacity
                    style={styles.bottom3}
                    onPress={() => {
                      this.openListaConpleta();
                    }}
                  >
                    <Text style={styles.label3} text={"Ver lista completa"} />
                  </TouchableOpacity>
                }
              />
            </Grid>
            {this.state.value == 0 ? (
              <Grid item xs={12} md={4}>
                <Cards
                  nome={
                    this.state.itens_inspecoes[0] &&
                    this.state.itens_inspecoes[0].empresa_nome
                  }
                  total={Number(this.state.totalLista)}
                  total_equipamento={Number(this.state.total_equipamento)}
                  total_horas={
                    this.state.total_horas
                      ? moment
                          .duration(this.state.total_horas, "seconds")
                          .humanize()
                      : "Sem dados para cálculo"
                  }
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={this.state.value == 0 ? 4 : 6}>
              <Table
                tableHead={["Nome", "Total", "Tempo"]}
                tableData={this.state.listUser.map(item => [
                  item.nome,
                  item.total,
                  item.total_horas
                    ? moment.duration(item.total_horas, "seconds").humanize()
                    : "Sem dados para cálculo"
                ])}
                title={"Inspetores"}
              />
            </Grid>
            <Grid item xs={12} md={this.state.value == 0 ? 4 : 6}>
              <Table
                tableHead={["Nome", "Total"]}
                tableData={this.state.listTipo.map(item => [
                  item.nome + " - " + item.descricao,
                  item.total
                ])}
                title={"Tipos de relatórios realizado"}
              />
            </Grid>
            <Grid item xs={12}>
              {this.state.value == 1 ? (
                <Table
                  title={"Inspeções"}
                  tableHead={["OS", "Usuario", "Tipo de inspeção", "Total"]}
                  reduzido={true}
                  tableData={this.state.listOsUser.map(item => [
                    item.codigo,
                    item.nome,
                    item.relatorio_nome,
                    item.total
                  ])}
                />
              ) : null}
              {this.state.value == 0 ? (
                <Table
                  tablePagination
                  title={"Inspeções"}
                  tableHeaderColor="primary"
                  tableHead={[
                    "Unidade",
                    "Locais",
                    "TAG",
                    "OS",
                    "Inspetor",
                    "Inicio",
                    "Fim",
                    "Tempo",
                    "Tipo",
                    " Concl. e S/Pendên."
                  ]}
                  reduzido={true}
                  tableData={this.state.itens_inspecoes.map(
                    (inspecoes, index) => [
                      inspecoes.unidade_nome,
                      Util.subString(inspecoes.local_nome, 15),
                      inspecoes.codigo_equipamento,
                      inspecoes.codigo,
                      inspecoes.user_nome.split(" ")[0],
                      (inspecoes.inicio &&
                        moment(inspecoes.inicio)
                          .locale("pt-br")
                          .format("DD/MM/YY")) ||
                        "Em Branco",
                      (inspecoes.termino &&
                        moment(inspecoes.termino)
                          .locale("pt-br")
                          .format("DD/MM/YY")) ||
                        "Em Branco",
                      inspecoes.total_horas
                        ? moment
                            .duration(inspecoes.total_horas, "seconds")
                            .humanize()
                        : "Sem dados para cálculo",
                      inspecoes.nome_relatorio,
                      [
                        <Icon
                          style={[styles.icon10, { marginLeft: 10 }]}
                          fromFontFamily={"Material Icons"}
                          icon={
                            !inspecoes.concluido
                              ? "radio_button_unchecked"
                              : "check_circle"
                          }
                        />,
                        <Icon
                          style={[styles.icon10, { marginLeft: 10 }]}
                          fromFontFamily={"Material Icons"}
                          icon={
                            !inspecoes.sPendecias
                              ? "radio_button_unchecked"
                              : "check_circle"
                          }
                        />
                      ]
                    ]
                  )}
                  count={Number(this.state.totalLista)}
                  page={this.state.parametros.page}
                  rowsPerPage={this.state.parametros.limit}
                  handleChangePage={(event, page) => {
                    this.setState(
                      {
                        parametros: Object.assign(this.state.parametros, {
                          page
                        })
                      },
                      this.atualizar_inspecoes()
                    );
                  }}
                  handleChangeRowsPerPage={event => {
                    this.setState(
                      {
                        parametros: Object.assign(this.state.parametros, {
                          limit: event.target.value,
                          page: 0
                        })
                      },
                      this.atualizar_inspecoes()
                    );
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 2
  },

  view17: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 0
  },
  action: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around"
  },
  view0: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },

  view11: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  bottom7: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    position: "relative"
  },
  icon12: {
    color: "rgba(7,81,120,1)",
    fontSize: 30
  },
  view12: {
    alignSelf: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(227,227,227,1)"
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    width: 40
  },
  icon23: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    width: 70
  },
  numero: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(150,150,150,1)",
    alignSelf: "stretch",
    fontSize: 12,
    marginLeft: 10,
    marginRight: 10
  },
  bottom81: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    width: 40
  },
  icon13: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },

  view1: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    paddingLeft: 15,
    paddingRight: 10,
  },
  label0: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",
    fontSize: 22
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",
    fontSize: 13
  },
  icon8: {
    color: "rgba(7,81,120,1)",
    fontSize: 30
  },
  view2: {
    alignSelf: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: 400,
    paddingLeft: 10,
    // "paddingRight": 10,
    paddingBottom: 2,
    paddingTop: 2,
    height: 45,
    borderColor: "rgba(251,251,251,1)",
    borderWidth: 1,
    borderStyle: "solid"
  },
  textinput1: {
    minHeight: 35,
    alignSelf: "stretch",
    flexWrap: "nowrap",
    flex: 1,
    width: 280,
    borderColor: "rgba(0,0,0,1)",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid"
  },
  textinput2: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    flex: 1,
    marginLeft: 5
  },
  bottom0: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    // "marginRight": 10,
    width: 50,
    position: "relative"
  },
  bottom3: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "nowrap",
    padding: 10
  },
  icon0: {
    fontSize: 25,
    color: "rgba(7,81,120,1)"
  },

  inspecoes: {
    alignSelf: "stretch",
    flex: 0
  },

  view: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },

  icon: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },

  label: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 10
  },

  icon10: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  }
});
