import { Cloud } from "../infra";
import cookie from "react-cookies";

export function totalizadores(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("dashboard/dados/perido",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}
export function totalizadoresDiasSemana(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("dashboard/dados/semana",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}
export function totalizadoresMes(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("dashboard/dados/mes",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function totalizadoresPorMesUser(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("dashboard/user/mes",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function totalizadoresEmpresas(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("dashboard/empresa/totalizador",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}
