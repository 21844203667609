import { Cloud } from "../infra";

 export function getGrafico(key_user,callback){
   Cloud.get('grafico/inspecao/ano/vencimento',{limit:30,key_user},(list,error)=>{
     callback(list,error)
   })
 }

 export function getGraficoAno(key_user,callback){
   Cloud.get('grafico/inspecao/ano',{limit:30,key_user},(list,error)=>{
     callback(list,error)
   })
 }


 export function gerarPdfComercial({key_user,key_unidade},callback){
   Cloud.get('pdf/comercial',{limit:100,key_user,key_unidade},(caminho,error)=>{
     callback(caminho,error)
   })
 }
 export function gerarPdfInspetores({listUser,inicio,final,key_user,isBase64},callback){
   Cloud.get('pdf/inspetores',{listUser,inicio,final,key_user,isBase64},(caminho,error)=>{
     callback(caminho,error)
   })
 }
 export function dadosRelatorio({listUser,inicio,final},callback){
   Cloud.get('grafico/relatorio/inspetor',{listUser,inicio,final},(caminho,error)=>{
     callback(caminho,error)
   })
 }


 export function getDadosRelatorioUsuario({key_user,inicio,final},callback){
   Cloud.get('usuario/relatorio/user',{limit:30,key_user,inicio,final},(list,error)=>{
     callback(list,error)
   })
 }
 export function getDadosRelatorioUsuarioDetalhado({key_user,inicio,final},callback){
   Cloud.get('usuario/relatorio/user/detalhado',{limit:30,key_user,inicio,final},(list,error)=>{
     callback(list,error)
   })
 }


 export function buscarUsuarios({limit,page,pesquisa},callback){
   Cloud.get('usuario',{limit,page,pesquisa},(list,error)=>{
     callback(list,error)
   })
 }
