import React from "react";

import StyleSheet from "react-1app/lib/StyleSheet";
import SelectI from "react-1app/lib/SelectInput";

const Select = props => {
  let list = props.list || props.lista || [];
  return (
    <select
      id={"select-"}
      onChange={e => {
        var value = e.target.value;
        let item = list.find(
          a =>
            (a[props.key_value] || a["value"]|| a["objectId"]  || a) === value
        );
        if (props.onChange) props.onChange(value, item || {}, 0);
      }}
      value={props.value || ""}
      style={StyleSheet.flatten([
        { width: "100%", height: "100%", fontSize: 16, outline: "none" },
        props.style
      ])}
    >
      <option style={{ fontSize: 12 }} key={""}>
        {props.label}
      </option>
      {(list || []).map((item, index) => {
        let name =
          item[props.key_label]  || item["text"]|| item["nome"] || item["value"]|| item;
        let value =
          item[props.key_value] || item["value"]|| item["objectId"]  || item;
        return (
          <option
            key={index + "_sel"}
            style={{ color: "#777" }}
            value={value + ""}
          >
            {name + ""}
          </option>
        );
      })}
    </select>
  );
};

export const SelectInput = props => {
  let list=(props.lista || []).map(a => ({
    ...a,
    text: a.text || a["nome"] || a,
    value: a.value || a["objectId"] || a
  }));
  return (
    <SelectI
      {...props}
      onChange={(value)=>{
        let item = list.find(
          a =>
            (a[props.key_value] || a["value"]|| a["objectId"]  || a) === value
        );
        props.onChange(value,item||[],0)
      }}
      list={list}
    />
  );
};

export default Select;
