import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Maps from "../../components/Maps";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false,
      geo: {
        latitude: -30.002865,
        longitude: -51.2053106,
        lista_geo: [{ latitude: -30.002865, longitude: -51.2053106 }]
      },
      list: [],
      load: true
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    Cloud.get(
      "geolocalizacao",
      { key_inspecao: this.state.inspecoes.objectId },
      (dados, error) => {
        if (!dados.latitude && dados.lista_geo[0]) {
          dados.latitude = dados.lista_geo[0].latitude;
          dados.longitude = dados.lista_geo[0].longitude;
        }
        if (dados.latitude) {
          this.setState({ geo: dados, load: false });
        } else {
        }
        console.log(dados, error);
      }
    );
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.ocorrencias.setMetodoApi("salvar", "POST");
    this.state.ocorrencias.salvarNuven(re => {
      if (re.objectId || re.id)
        OpenSnack({ message: "Salvo", type: "success" });
      this.setState({
        load: false
      });
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Localização da Inspeção"}</Text>
            <Maps
              height={200}
              zoom={16}
              lista={this.state.geo.lista_geo}
              latitude={this.state.geo.latitude}
              longitude={this.state.geo.longitude}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
