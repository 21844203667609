import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import ImageUpload from "react-1app/lib/ImageUpload";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados } from "../../infra";
import styleGlobal from "../../styleGlobal";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Usuario from "./Usuario";
import TabelaPreco from "./TabelaPreco";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresas: new Dados("empresas").parse(this.props.empresas),
      load: false,
      value: 0
    };
  }

  salvar() {
    this.setState({
      load: true
    });
    this.state.empresas.salvarNuven(re => {
      this.setState({
        load: false
      });
    });
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar esta empresa?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.empresas.status = 3;
          this.setState({
            load2: true
          });
          this.state.empresas.salvarNuven(re => {
            this.setState({
              load2: false
            });
            this.props.closeModal();
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View>
              <Text style={styles.label}>{"Empresa"}</Text>
              <Tabs
                value={this.state.value}
                onChange={(ii, value) => this.setState({ value })}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Info" />
                <Tab label="Usuário" disabled={!this.state.empresas.objectId} />
                  <Tab label="Tabela Preço" disabled={!this.state.empresas.objectId} />
              </Tabs>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
            {this.state.value == 0 ? (
              <ImageUpload
                style={styles.logo}
                value={this.state.empresas.logo}
                onChange={value => {
                  this.state.empresas.logo = value;
                  this.setState({ empresas: this.state.empresas });
                }}
              />
            ) : null}

            {this.state.value == 0 ? (
              <TextInput
                style={styles.nome}
                value={this.state.empresas.nome}
                onChange={value => {
                  this.state.empresas.nome = value;
                  this.setState({ empresas: this.state.empresas });
                }}
                keyboardType={"default"}
                label={"Razão Social"}
              />
            ) : null}
            {this.state.value == 0 ? (
              <View style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.deletar()}
                  children={"Delete"}
                  color={"secondary"}
                  variant="outlined"
                  style={styles.delete}
                  disabled={!this.state.empresas.objectId}
                  load={this.state.load2}
                ></TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                  style={styles.delete}
                  load={this.state.load}
                ></TouchableOpacity>
              </View>
            ) : null}
            {this.state.value == 1 ? (
              <Usuario {...this.props} empresas={this.state.empresas}/>
            ):null}
            {this.state.value == 2 ? (
              <TabelaPreco {...this.props} empresas={this.state.empresas}/>
            ):null}
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
