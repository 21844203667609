import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import ListView from "react-1app/lib/ListView";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Unidade from "../unidade";
import Edit from "./Edit";
import PerfilUser from "./PerfilUser";

// import styleGlobal from "../../styleGlobal";
import moment from "moment";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [{ nome: "Teste", email: "teste@t.com" }],
      load: true,
      totalLista: 0,
      parametros: {
        limit: 10,
        page: 0,
        paginacao: true
      },
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar(pesquisa) {
    var query = new Query("user_local");
    if (pesquisa) query.whereContains("nome", pesquisa);
    query.addAscendingOrder("nome");
    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  getList() {
    let { limit, page } = this.state.parametros;
    let off = page * limit;
    off = off < 0 ? 0 : off;
    return [...this.getSuggestions()].splice(off, limit);
  }

  getSuggestions() {
    const { list = [], pesquisa = "" } = this.state;
    let escapedValue = pesquisa.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    if (escapedValue === "" || !escapedValue) {
      return list;
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return list.filter(item => regex.test(item.nome));
  }

  componentWillUnmount() {}

  open_modal_bottom1(empresas) {
    this.props.navigator.openModal({
      component: props=>(
        <View style={{}} container justify="center" spacing={16}>
          <View style={{marginTop: 50}} xs={12} ms={10} md={8} lg={6} item>
            <Edit {...props}/>
          </View>
        </View>
      ),
      props: {
        heranca: this.state,
        activity: this,
        empresas
      }
    });
  }

  renderIcon(user_local) {
    if (user_local.user) {
      return (
        <Icon
          style={styles.icon}
          fromFontFamily={"Material Design Icons"}
          name={"account"}
        />
      );
    }
    if (user_local.campo) {
      return (
        <Icon
          style={styles.icon1}
          fromFontFamily={"Material Design Icons"}
          name={"account-settings"}
        />
      );
    }
    if (user_local.market) {
      return (
        <Icon
          style={styles.icon2}
          fromFontFamily={"Material Design Icons"}
          name={"account-switch"}
        />
      );
    }
    if (user_local.adm) {
      return (
        <Icon
          style={styles.icon3}
          fromFontFamily={"Material Design Icons"}
          name={"account-star"}
        />
      );
    }
  }

  render() {
    return (
      <View style={styles.view2} container spacing={16}>
        <View style={styles.view3} xs={12} md={4} item>
          <View type={"Paper"}>
            <ListView
              ListHeaderComponent={() => (
                <View style={styles.head}>
                  <ListSubheader component="div">{"Usuários"}</ListSubheader>
                  <TextInput
                    label="Filtrar"
                    style={styles.input}
                    placeholder="Pesquisa por nome."
                    onChange={pesquisa => this.setState({ pesquisa })}
                    value={this.state.pesquisa}
                    inputNative
                  />
                  <TouchableOpacity
                    children={"Novo"}
                    variant="outlined"
                    onPress={() => {
                      this.open_modal_bottom1({});
                    }}
                  />
                </View>
              )}
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                    selected={this.state.id === item.id}
                    onClick={event =>{
                      this.setState({ usuario: item, id: item.id });
                      window.focusElementById("edit");
                    }}
                  >
                    <Avatar src={item.logo}>{this.renderIcon(item)}</Avatar>
                    <ListItemText primary={item.nome} secondary={item.funcao} />
                    <Icon
                      color="default"
                      name={"created"}
                    />
                  </ListItem>
                );
              }}
              separator
              data={this.getList()}
              tablePagination
              count={Number(this.getSuggestions().length)}
              load={this.state.load}
              page={this.state.parametros.page}
              rowsPerPage={this.state.parametros.limit}
              handleChangePage={(event, page) => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, { page })
                });
              }}
              handleChangeRowsPerPage={event => {
                this.setState({
                  parametros: Object.assign(this.state.parametros, {
                    limit: event.target.value,
                    page: 0
                  })
                });
              }}
            />
          </View>
        </View>
        <View style={styles.view3} id={"edit"} xs={12} md={8} item>
          {this.state.usuario ? (
            <PerfilUser {...this.props} user_local={this.state.usuario} />
          ) : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  icon: {
    fontSize: 25,
    color: "rgba(87,146,221,1)"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(75,38,136,1)"
  },
  icon2: {
    fontSize: 25,
    color: "rgba(244,77,113,1)"
  },
  icon3: {
    fontSize: 25,
    color: "rgba(80,195,87,1)"
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {}
});
