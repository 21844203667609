import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import TextInput from "react-1app/lib/TextInput";
import Grid from "react-1app/lib/View";
import { OpenSnack } from "react-1app/lib/SnackBar";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import * as WorkerLogin from "../../worker/login";
import * as Actions from "../../redux/actions";

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { email: "", senha: "" },
      load: false,
      empresa: this.props.screenProps.store.getState().empresa
    };
  }

  componentDidMount() {
    if (
      this.props.screenProps.store.getState().user &&
      this.props.screenProps.store.getState().user.id
    )
      this.props.navigation.navigate("/");
  }

  logar() {
    this.setState({ load: true });
    WorkerLogin.login(this.state.user, (user = {}, error) => {
      if (error) {
        OpenSnack({ message: error.msg || "Erro ao logar", type: "error" });
        return this.setState({ load: false });
      }
      this.setUser(user);
    });
  }

  setUser(user) {
    this.props.screenProps.store.dispatch(
      Actions.logar({ ...user, permissao: 2 })
    );
    setTimeout(() => {
      this.props.navigation.navigate("/");
    }, 10);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container id="main" component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} lg={8} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          lg={4}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar
              className={classes.avatar}
              src={"/img/logomegasteam25anos2.png"}
            ></Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form className={classes.form} noValidate>
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Login"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.user.email}
                onChange={value => {
                  this.setState({
                    user: { ...this.state.user, email: value }
                  });
                }}
              />
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.user.senha}
                onChange={value => {
                  this.setState({
                    user: { ...this.state.user, senha: value }
                  });
                }}
              />

              <TouchableOpacity
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onPress={() => {
                  this.logar();
                }}
              >
                Login
              </TouchableOpacity>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const useStyles = theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(/img/banner-1024x448.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    width: 200,
    height: 100,
    borderRadius: 0
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1
  },
  submit: {
    margin: "auto",
    marginTop: 15
  },
});

export default withStyles(useStyles, { withTheme: true })(MiniDrawer);
