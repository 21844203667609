import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Util, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Avatar from "@material-ui/core/Avatar";
import { SelectInput } from "../../components/SelectInput";
import TextInput from "react-1app/lib/TextInput";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: this.props.list
    };
  }

  componentDidMount() {}

  salvar_bottom2() {
    this.setState({
      save: true
    });
    this.props.feramenta.setMetodoApi("salvar", "POST");
    this.props.feramenta.salvarNuven(re => {
      this.setState({
        save: false
      });
      this.props.activity.buscarDados();
      OpenSnack({ message: "Salvo", type: "success" });
      this.props.closeModal();
    });
  }

  render() {
    return (
      <View style={styles.tela} type={"Paper"}>
        <View>
          <TouchableOpacity
            onPress={() => this.props.closeModal()}
            style={{ marginRight: 10 }}
            icon
            color={"default"}
            variant="outlined"
          >
            <Icon
              fromFontFamily={"Material Design Icons"}
              name={"chevron-left"}
            />
          </TouchableOpacity>
          <Text style={styles.label}>{"Sugestão"}</Text>
          <TouchableOpacity
            style={{ marginLeft: "auto" }}
            onPress={() => this.salvar_bottom2()}
            children={"Salvar"}
            variant="outlined"
            load={this.state.load}
          ></TouchableOpacity>
        </View>
        <TextInput
          style={styles.nom}
          value={this.props.feramenta.nome}
          onChange={value => {
            this.props.feramenta.nome = value;
            this.setState({
              feramenta: this.props.feramenta
            });
          }}
          ref={v => (this.nom = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"TAG"}
        />
        <TextInput
          style={styles.marca}
          value={this.props.feramenta.marca}
          onChange={value => {
            this.props.feramenta.marca = value;
            this.setState({
              feramenta: this.props.feramenta
            });
          }}
          ref={v => (this.marca = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Marca"}
        />
        <TextInput
          style={styles.modelo}
          value={this.props.feramenta.modelo}
          onChange={value => {
            this.props.feramenta.modelo = value;
            this.setState({
              feramenta: this.props.feramenta
            });
          }}
          ref={v => (this.modelo = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Modelo"}
        />
        <TextInput
          style={styles.n_serie}
          value={this.props.feramenta.n_serie}
          onChange={value => {
            this.props.feramenta.n_serie = value;
            this.setState({
              feramenta: this.props.feramenta
            });
          }}
          ref={v => (this.n_serie = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Nº Série"}
        />
        <TextInput
          style={styles.n_cert}
          value={this.props.feramenta.n_cert}
          onChange={value => {
            this.props.feramenta.n_cert = value;
            this.setState({
              feramenta: this.props.feramenta
            });
          }}
          ref={v => (this.n_cert = v)}
          keyboardType={"default"}
          secureTextEntry={false}
          multiline={false}
          label={"Certificado"}
        />
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    alignItems: "center"
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
