import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import Progress from "react-1app/lib/Progress";
import QRCode from "react-1app/lib/QRCode";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Cloud, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { OpenSnack } from "react-1app/lib/SnackBar";
import moment from "moment";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspecoes: new Dados("inspecoes").parse(this.props.inspecoes),
      load: false,
      list: [],
      load: true
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  buscarDados() {
    var query = new Query("alteracoes");

    query.whereEqualTo("inspecao", this.state.inspecoes.objectId);
    query.setLimit(1000);
    query.select((lista) => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  ajusteText(text){
    var array = (text+"").split("\n");
    var views = [];
    for (var i = 0; i < array.length; i++) {
      var item =   array[i];

      if(i>0){
        views.push(<br/>)
      }
      views.push(item)
    }
    return views
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Alterações do Relatorio"}</Text>
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem
                    key={index}
                    style={{ width: "100%" }}
                  >
                    <ListItemText
                      primary={`${item.nome} | ${moment(item.createdAt).locale('pt-br').format("DD/MMM/YY  HH:mm")}`}
                      secondary={this.ajusteText(item.alteracoes)}
                    />

                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={styles.delete}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
