import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import styleGlobal from "../../styleGlobal";
import Switch from "react-1app/lib/Switch";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      value: 0
    };
  }

  salvar() {
    if(!this.props.relatorio.lista)this.props.relatorio.lista=[]
    if(this.props.novo){
      this.props.item.objectId=new Date().getTime() + "key";
      this.props.relatorio.lista.push(this.props.item);
    }
    this.props.closeModal();
    this.props.activity.setState({alterado:true})
  }

  deletar() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este item?", [
      {
        text: "Ok",
        onPress: () => {
          var lista = this.props.relatorio.lista;
          for (var i = 0; i < lista.length; i++) {
            var foco = lista[i];
            if (foco.objectId == this.props.item.objectId) {
              this.props.relatorio.lista.splice(i, 1);
              break;
            }
          }
          this.props.closeModal();
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <Text style={styles.label}>{"Cadastro Setores o PDF"}</Text>
            <TextInput
              style={styles.nome}
              value={this.props.item.nome}
              onChange={value => {
                this.props.item.nome = value;
                this.setState({ locais: this.props.item });
              }}
              keyboardType={"default"}
              label={"Nome"}
            />
            <TextInput
              style={styles.cnpj}
              value={this.props.item.ordem}
              onChange={value => {
                this.props.item.ordem = value;
                this.setState({ locais: this.props.item });
              }}
              label={"Ordem"}
              type={"numeric"}
            />
            <View style={styles.action}>
              <Switch
                value={this.props.item.nPaguina}
                label={'Nova pagina'}
                onChange={value => {
                  this.props.item.nPaguina = value;
                  this.setState({
                    item: this.props.item
                  });
                }}
              />
              <Switch
                value={this.props.item.boolTinbrada}
                label={'Timbrada'}
                onChange={value => {
                  this.props.item.boolTinbrada = value;
                  this.setState({
                    item: this.props.item
                  });
                }}
              />
              <Switch
                label={'Informações'}
                value={this.props.item.dado_do_relatorio}
                onChange={value => {
                  this.props.item.dado_do_relatorio = value;
                  this.setState({
                    item: this.props.item
                  });
                }}
              />
            </View>
            <View style={styles.action}>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.deletar()}
                children={"Delete"}
                color={"secondary"}
                variant="outlined"
                style={styles.delete}
                disabled={!this.props.item.objectId}
                load={this.state.load2}
              ></TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.salvar()}
                children={"Salvar"}
                variant="outlined"
                style={styles.delete}
                load={this.state.load}
              ></TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
