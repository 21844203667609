import React, { Component } from "react";
import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import TextInput from "react-1app/lib/TextInput";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import styleGlobal from "../../styleGlobal";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.screenProps.store.getState().user,
      load: true,
      value: 0
    };
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styles.tela} type={"Paper"}>
          <View>
            <Text style={styles.label}>{"Usuario"}</Text>
            <TouchableOpacity onPress={() => this.props.closeModal()} icon>
              <Icon name={"close"} />
            </TouchableOpacity>
          </View>
          <Image
            style={styles.logo}
            resizeMode={"contain"}
            src={"./img/prof.png"}
          />
          ,
          <TextInput
            style={styles.nome}
            value={this.state.usuario.nome}
            disabled={true}
            label={"Nome"}
          />
          <TextInput
            style={styles.nome}
            value={this.state.usuario.login}
            disabled={true}
            label={"Login"}
          />
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    margin: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    backgroundSize: "contain",
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 30,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
