import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Alert from "react-1app/lib/Alert";
import TextInput from "react-1app/lib/TextInput";
import StyleSheet from "react-1app/lib/StyleSheet";
import styleGlobal from "../../styleGlobal";
import Switch from "react-1app/lib/Switch";
import AutoComplete from "../../components/Auto";
import * as Producao from "../../worker/producao";

export default class PgQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      value: 0
    };
  }

  salvar() {
    let { item } = this.props;
    let res = (dados, error) => {
      this.setState({
        load: false,
        item: dados
      });
      if (this.props.atualizar) this.props.atualizar(dados);
      this.props.closeModal();
    };
    this.setState({
      load: true
    });
    if (item.id) {
      Producao.atualizarRelacao(item, res);
    } else {
      Producao.salvarRelacao(item, res);
    }
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View container spacing={16}>
              <View xs={12} item>
                <Text style={styles.label}>{"RELAÇÃO"}</Text>
              </View>
              <View xs={12} item>
                <TextInput
                  style={styles.nome}
                  value={this.props.item.tag}
                  multiline
                  onChange={value => {
                    this.props.item.tag = value;
                    this.setState({ locais: this.props.item });
                  }}
                  keyboardType={"default"}
                  label={"IDENTIFICAÇÃO (TAG)"}
                />
              </View>

              <View xs={12} md={6} item>
                <AutoComplete
                  list={this.props.sugest}
                  keyLabel={"nome"}
                  keyValue={"id"}
                  label="Tipo"
                  value={this.props.item.id_tipo}
                  onChange={value => {
                    this.props.item.id_tipo = value.id;
                    this.props.item.valor = value.valor;
                    this.setState({ item: this.props.item });
                  }}
                />
              </View>
              <View xs={12} md={6} item>
                <TextInput
                  style={styles.cnpj}
                  value={this.props.item.tamanho}
                  onChange={value => {
                    this.props.item.tamanho = value;
                    this.setState({ locais: this.props.item });
                  }}
                  label={"Tamanho"}
                />
              </View>
              <View xs={12} item style={styles.action}>
                <Switch
                  value={this.props.item.rbc}
                  label={"RBC"}
                  onChange={value => {
                    this.props.item.rbc = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
                <Switch
                  value={this.props.item.rble}
                  label={"RBLE"}
                  onChange={value => {
                    this.props.item.rble = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
                <Switch
                  label={"RASTREÁVEL"}
                  value={this.props.item.rastreavel}
                  onChange={value => {
                    this.props.item.rastreavel = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
                <Switch
                  label={"MANUTENÇÃO"}
                  value={this.props.item.manutencao}
                  onChange={value => {
                    this.props.item.manutencao = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
              </View>
              <View xs={12} item>
                <TextInput
                  style={styles.nome}
                  value={this.props.item.outros}
                  multiline
                  onChange={value => {
                    this.props.item.outros = value;
                    this.setState({ locais: this.props.item });
                  }}
                  keyboardType={"default"}
                  label={"OUTRO (S)"}
                />
              </View>
              <View xs={12} item style={styles.action}>
                <Switch
                  value={this.props.item.ee}
                  label={"EE"}
                  onChange={value => {
                    this.props.item.ee = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
                <Switch
                  value={this.props.item.ei}
                  label={"EI"}
                  onChange={value => {
                    this.props.item.ei = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
                <Switch
                  value={this.props.item.me}
                  label={"ME"}
                  onChange={value => {
                    this.props.item.me = value;
                    this.setState({
                      item: this.props.item
                    });
                  }}
                />
              </View>
              <View xs={12} item>
                <TextInput
                  style={styles.nome}
                  value={this.props.item.obs}
                  multiline
                  onChange={value => {
                    this.props.item.obs = value;
                    this.setState({ locais: this.props.item });
                  }}
                  keyboardType={"default"}
                  label={"OBSERVAÇÃO"}
                />
              </View>
              <View xs={12} item style={styles.action}>
                <TouchableOpacity
                  onPress={() => this.props.closeModal()}
                  children={"Fechar"}
                  color={"default"}
                  variant="outlined"
                ></TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.salvar()}
                  children={"Salvar"}
                  variant="outlined"
                  style={styles.delete}
                  load={this.state.load}
                ></TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  },
  logo: {
    width: 200,
    height: 200,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    borderRadius: 25,
    margin: "auto",
    marginTop: 50,
    borderColor: "rgba(7,81,120,1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginBottom: 50,
    backgroundColor: "rgba(251,251,251,1)"
  }
});
