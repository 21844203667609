import { Cloud, Dados } from "../infra";


export function salvar(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.post("log",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.put("log/"+input.id,  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.get("log",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function enviarEngenharia(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.post("log/enviar/engenharia",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}

export function devolucaoRevisao(input, callback) {
  // login.senha = md5(login.senha);
  Cloud.post("log/devolucao/revisao",  input , (res, error) => {
    if (callback) callback(res, error);
  });
}
