"use strict";
import React from "react";

import {
  Table,
  Text,
  StyleSheet,
  TextInput,
  View,
  Icon,
  TouchableOpacity,
  ScrollView,
  Progress,
  TitleBar
} from "react-1app";
import styleGlobal from "../../styleGlobal";

//nao atualizar
export default class ModalTabela extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  close_modal_bottom() {
    this.props.closeModal();
  }

  componentDidMount() {
    //{onDidMount}
  }

  render() {
    let { listData = [], listHead = [], title = "" } = this.props;
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
            <Table
              tableHead={listHead}
              tableData={listData}
              title={title}
              actionBar={
                <TouchableOpacity
                  variant="outlined"
                  onPress={() => {
                    this.close_modal_bottom();
                  }}
                  children={"Fechar"}
                />
              }
            />
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    padding: 20,
    marginTop: 50
  },
  titlebar: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "stretch",
    minHeight: 50,
    backgroundColor: "rgba(7,81,120,1)"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50,
    position: "relative"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontSize: 20,
    flex: 1
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 50,
    position: "relative"
  },
  icon2: {
    color: "rgba(255,255,255,1)",
    fontSize: 25
  },
  view4: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: 50,
    height: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  view2: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: 200,
    height: 200
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    padding: 20
  },
  bottom2: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    width: 250,
    backgroundColor: "rgba(7,81,120,1)",
    marginBottom: 20,
    position: "relative"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(255,255,255,1)"
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    marginLeft: 5,
    fontSize: 14
  },
  view3: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    width: 250,
    height: 50,
    backgroundColor: "rgba(7,81,120,1)"
  },
  progresso: {
    width: 35,
    height: 35
  }
});
