import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import ListView from "react-1app/lib/ListView";
import TextInput from "react-1app/lib/TextInput";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Util, Dados, Query } from "../../infra";
import ListSubheader from "@material-ui/core/ListSubheader";
import Local from "react-1app/lib/View";
import { PgModal } from "react-1app/lib/PgModal";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Text from "react-1app/lib/Text";
import ConfigComp from "./configComp";
import ScrollView from "react-1app/lib/ScrollView";
import Inputs from "./Inputs";
import GridView from "react-1app/lib/GridView";
import TouchableOpacity from "../../components/TouchableOpacity";
import TouchableOpacity2 from "react-1app/lib/TouchableOpacity";
import { OpenSnack } from "react-1app/lib/SnackBar";
import Alert from "react-1app/lib/Alert";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      componentes: [],
      formularios: this.props.formularios,
      itens_formulario: this.props.formularios.lista || [],
      itens_gridview0: [],
      itens_textopadroes: [],
      item: {}
    };
  }

  componentDidMount() {
    this.get_data_gridview0();
    this.get_data_textopadroes();
  }

  onChange(item) {
    this.setState({
      formularios: item,
      itens_formulario: item.lista
    });
  }

  renderTela() {
    var componentes = this.state.itens_formulario;
    var pg = [];
    for (var i = 0; i < componentes.length; i++) {
      let item = componentes[i];
      item.code.test = true;
      try {
        pg.push(
          <Inputs
            activity={this}
            selecionado={this.state.selecionado}
            item={item}
            key={"item_" + i}
          />
        );
      } catch (e) {
        console.log(e);
      } finally {
      }
    }
    return pg;
  }

  get_data_gridview0() {
    var query = new Query("componentes");

    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        itens_gridview0: lista,
        load_gridview0: false
      });
    });
  }
  get_data_textopadroes() {
    var query = new Query("textopadroes");

    query.addAscendingOrder("createdAt");
    query.setLimit(1000);
    query.select(lista => {
      // console.log(lista);
      this.setState({
        itens_textopadroes: lista,
        load_textopadroes: false
      });
    });
  }

  delete() {
    Alert.alert("Apagar", "Voce realmente deseja apagar este formulário?", [
      {
        text: "Ok",
        onPress: () => {
          this.state.formularios.status = 3;
          this.setState({
            save: true
          });
          this.state.formularios.salvarNuven(re => {
            this.setState({
              save: false
            });
            this.props.activity.buscar();
            OpenSnack({ message: "Formularios deletado" });
            this.props.closeModal();
          });
        }
      },
      { text: "Cancelar", onPress: () => console.log() }
    ]);
  }

  salvar_bottom2() {
    this.state.formularios.relatorio = this.props.relatorio.objectId;
    this.setState({
      save: true
    });
    this.state.formularios.salvarNuven(re => {
      this.setState({
        save: false
      });
      this.props.activity.buscar();
      OpenSnack({ message: "Salvo", type: "success" });
    });
  }

  componenteView(componente) {
    componente.code.propr.culunm = true;
    try {
      if (!this.state.item.code.lay.lista) {
        this.state.item.code.lay.lista = [];
      }
      var copia = JSON.stringify(componente);
      var clone = JSON.parse(copia);
      clone.objectId =
        new Date().getTime() +"_" +
        this.state.item.code.lay.lista.length +
        "_" +
        clone.code.tag +
        "key";
      this.state.item.code.lay.lista.push(clone);
    } catch (e) {
      // console.log(e);
    } finally {
    }

    this.get_data_formularios();
    // this.salvar_bottom2();
  }

  get_data_formularios() {
    this.setState({
      itens_formulario: this.state.formularios.lista,
      load_formularios: false
    });
  }

  trocarF(formularios) {
    this.state.formularios = formularios;
    this.get_data_formularios();
    this.editComp({}, []);
  }

  addComponente(componente) {
    if (
      (this.state.item.code && this.state.item.code.tag == "View") ||
      (this.state.item.code && this.state.item.code.tag == "ViewEdit") ||
      (this.state.item.code && this.state.item.code.tag == "checkList")
    ) {
      this.componenteView(componente);
    } else if (!this.state.formularios.lista) {
      this.state.formularios.lista = [];
      this.componente(componente);
    } else {
      this.componente(componente);
    }
  }

  componente(componente) {
    try {
      var copia = JSON.stringify(componente);
      var clone = JSON.parse(copia);
      clone.objectId = new Date().getTime() + "key";
      clone.objectId =
        new Date().getTime() +"_" +
        this.state.formularios.lista.length +
        "_" +
        clone.code.tag +
        "key";
      this.state.formularios.lista.push(clone);
    } catch (e) {
      // console.log(e);
    } finally {
    }

    this.get_data_formularios();
    // this.salvar_bottom2();
  }

  editComp(item, listaV) {
    this.config.setState({ item: item, listaV: listaV });
    this.setState({ selecionado: item.objectId, item: item });
    // this.formularios.select(item.objectId);
    this.get_data_gridview0();
  }

  componentWillUnmount() {}

  render() {
    return (
      <View
        style={[styles.view2, { marginTop: 70, maxWidth: "100%" }]}
        container
        spacing={16}
      >
        <AppBar>
          <Toolbar>
            <Text variant="h6" color="inherit">
              {"Criação de formulario - " +
                (this.state.formularios.nome || "Novo")}
            </Text>
            <TouchableOpacity2
              style={{ marginLeft: "auto" }}
              color="secondary"
              variant="outlined"
              children={"Apagar"}
              disabled={!this.state.formularios.objectId}
              onPress={() => {
                this.delete();
              }}
            />
            <TouchableOpacity2
              style={{ marginLeft: 10 }}
              color="inherit"
              variant="outlined"
              children={"Salvar"}
              onPress={() => {
                this.salvar_bottom2();
              }}
            />
            <TouchableOpacity2
              icon
              style={{ marginLeft: 10 }}
              color="inherit"
              aria-label="Close"
              onPress={() => {
                this.props.closeModal();
              }}
            >
              <Icon name={"close"} />
            </TouchableOpacity2>
          </Toolbar>
        </AppBar>
        {false && (
          <View style={styles.card3} xs={1} item>
            <ListFormulario
              {...this.props}
              activity={this}
              ref={v => (this.Listformulario = v)}
              onChange={item => this.onChange(item)}
            />
          </View>
        )}
        <View style={styles.card3} xs={3} item>
          <ConfigComp
            {...this.props}
            activity={this}
            oldState={this.state}
            ref={v => (this.config = v)}
          />
        </View>
        <View style={styles.card3} xs={6} item>
          <ScrollView
            style={styles.scroll}
            keyboardDismissMode={"interactive"}
            horizontal={false}
          >
            <View style={styles.view5} type={"Paper"}>
              <View style={{ flexDirection: "column", display: null }}>
                {this.renderTela()}
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={styles.card3} xs={3} item>
          <View type={"Paper"} style={{ marginBottom: 20, padding: 5 }}>
            <TextInput
              style={styles.nome}
              value={this.state.formularios.nome}
              onChange={value => {
                this.state.formularios.nome = value;
                this.setState({ formularios: this.state.formularios });
              }}
              label={"Nome"}
            />

            <TextInput
              style={styles.nome}
              value={this.state.formularios.ordem}
              onChange={value => {
                this.state.formularios.ordem = Number(value);
                this.setState({ formularios: this.state.formularios });
              }}
              label={"Ordem"}
            />
          </View>
          <View type={"Paper"} style={{ marginBottom: 20 }}>
            <ListView
              style={styles.formularios}
              ref={v => (this.formularios = v)}
              dataSource={this.state.itens_formulario}
              renderRow={(rowData, sectionID, rowID) => {
                return (
                  <Cell1
                    formularios={rowData}
                    heranca={this.state}
                    activity={this}
                    navigator={this.state.navigator}
                    rowID={rowID}
                  />
                );
              }}
              nativo
              colorSeparator={"rgba(227,227,227,1)"}
            />
          </View>
          <View type={"Paper"} style={{ marginBottom: 20, padding: 5 }}>
            <GridView
              style={styles.gridview0}
              ref={v => (this.gridview0 = v)}
              dataSource={this.state.itens_gridview0}
              renderRow={(rowData, sectionID, rowID) => {
                // return <div/>;
                if (
                  (this.state.item.code &&
                    this.state.item.code.tag == "View") ||
                  (this.state.item.code &&
                    this.state.item.code.tag == "ViewEdit")
                ) {
                  if (rowData.code.propr.view) {
                    return (
                      <Cell
                        componentes={rowData}
                        heranca={this.state}
                        activity={this}
                        navigator={this.state.navigator}
                        rowID={rowID}
                      />
                    );
                  } else {
                    return null;
                  }
                } else if (
                  this.state.item.code &&
                  this.state.item.code.tag == "checkList"
                ) {
                  if (rowData.code.propr.check) {
                    return (
                      <Cell
                        componentes={rowData}
                        heranca={this.state}
                        activity={this}
                        navigator={this.state.navigator}
                        rowID={rowID}
                      />
                    );
                  } else {
                    return null;
                  }
                } else if (rowData.code.tag == "check") {
                  return null;
                } else {
                  return (
                    <Cell
                      componentes={rowData}
                      heranca={this.state}
                      activity={this}
                      navigator={this.state.navigator}
                      rowID={rowID}
                    />
                  );
                }
              }}
              column={3}
            />
          </View>
        </View>
      </View>
    );
  }
}

class ListFormulario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      list: []
    };
  }

  componentDidMount() {
    this.buscar();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.relatorio != this.props.relatorio) {
      setTimeout(() => {
        this.buscar();
      }, 10);
      this.setState({ local: {}, load: true });
    }
    return true;
  }

  buscar() {
    var query = new Query("formularios");
    query.whereEqualTo("relatorio", this.props.relatorio.objectId);
    query.addAscendingOrder("ordem");
    query.setLimit(4000);
    query.select(lista => {
      this.setState({
        list: lista,
        totalLista: lista.length,
        load: false
      });
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <ListView
        renderItem={({ item, index }) => {
          return (
            <View
              type={"Paper"}
              style={styles.cell12}
              onClick={() => {
                this.props.onChange(item);
              }}
            >
              <Avatar src={item.logo}>
                <Icon name={"format_align_center"} />
              </Avatar>
              <Text
                style={{ fontSize: 10, marginTop: 5 }}
                children={item.nome}
              />
            </View>
          );
        }}
        separator
        nativo
        data={this.state.list}
      />
    );
  }
}

class Cell1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {},
      componentes: new Dados("componentes")
    };
    Dados.clonarHeranca(props, this.state);
  }
  componentDidMount() {}
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);
    }
    return true;
  }
  muveUp() {
    var lista = this.props.activity.state.formularios.lista;
    var pos = 0;
    for (var i = lista.length - 1; i >= 0; i--) {
      var foco = lista[i];
      var to = i + -1;
      // var anterior = lista[to];
      if (foco.objectId == this.state.formularios.objectId && to >= 0) {
        // console.log(i,to);
        this.props.activity.state.formularios.lista.move(i, to);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.props.activity.setState({ alterado: true });
  }
  muveDown() {
    var lista = this.props.activity.state.formularios.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      var to = i + 1;
      var proximo = lista[to];
      // console.log(i,to,lista.length);
      if (
        foco.objectId == this.state.formularios.objectId &&
        to < lista.length
      ) {
        // console.log(i,to);
        this.props.activity.state.formularios.lista.move(i, to);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.props.activity.setState({ alterado: true });
  }
  muveUpV(itemV) {
    var lista = this.state.formularios.code.lay.lista;
    var pos = 0;
    for (var i = lista.length - 1; i >= 0; i--) {
      var foco = lista[i];
      var to = i + -1;
      // var anterior = lista[to];
      if (foco.objectId == itemV.objectId && to >= 0) {
        // console.log(i,to);
        this.state.formularios.code.lay.lista.move(i, to);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.props.activity.setState({ alterado: true });
  }
  muveDownV(itemV) {
    var lista = this.state.formularios.code.lay.lista;
    for (var i = 0; i < lista.length; i++) {
      var foco = lista[i];
      var to = i + 1;
      var proximo = lista[to];
      // console.log(i,to,lista.length);
      if (foco.objectId == itemV.objectId && to < lista.length) {
        // console.log(i,to);
        this.state.formularios.code.lay.lista.move(i, to);
        break;
      }
      // console.log(foco,to,anterior);
    }
    this.props.activity.setState({ alterado: true });
  }
  viewColumn() {
    var lista = [];
    if (this.state.formularios.code.lay.lista) {
      var listaC = this.state.formularios.code.lay.lista;
      for (var i = 0; i < listaC.length; i++) {
        let itemV = listaC[i];
        // console.log(itemV);
        lista.push(
          <TouchableOpacity
            fullWidth
            style={{
              backgroundColor:
                this.state.item.objectId == itemV.objectId
                  ? "rgba(221,221,221,1)"
                  : "rgba(251,251,251,0)"
            }}
            onPress={() => {
              this.props.activity.editComp(itemV, listaC);
            }}
          >
            <View style={styles.view4}>
              <Icon
                style={styles.icon1}
                fromFontFamily={"Material Icons"}
                color={"primary"}
                icon={itemV.code.icone}
              />
              <Text style={styles.label1} text={itemV.nome} />
              <TouchableOpacity
                style={styles.bottom3}
                onPress={() => {
                  this.muveUpV(itemV);
                }}
                icon
              >
                <Icon
                  style={styles.icon5}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_less"}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.bottom4}
                onPress={() => {
                  this.muveDownV(itemV);
                }}
                icon
              >
                <Icon
                  style={styles.icon6}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_more"}
                />
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        );
      }
    }
    return lista;
  }

  render() {
    if (
      this.state.formularios.code.tag != "View" &&
      this.state.formularios.code.tag != "ViewEdit" &&
      this.state.formularios.code.tag != "checkList"
    ) {
      return (
        <View style={[styles.cell1, styles.cell2]}>
          <TouchableOpacity
            fullWidth
            style={[
              {
                backgroundColor:
                  this.state.item.objectId == this.state.formularios.objectId
                    ? "rgba(221,221,221,1)"
                    : "rgba(251,251,251,0)"
              }
            ]}
            onPress={() => {
              this.props.activity.editComp(this.state.formularios);
            }}
          >
            <View style={styles.view4}>
              <Icon
                style={styles.icon1}
                color={"primary"}
                fromFontFamily={"Material Icons"}
                icon={this.state.formularios.code.icone}
              />
              <Text style={styles.label1} text={this.state.formularios.nome} />
              <TouchableOpacity
                style={styles.bottom3}
                onPress={() => {
                  this.muveUp();
                }}
                color={"primary"}
                icon
              >
                <Icon
                  style={styles.icon5}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_less"}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.bottom4}
                onPress={() => {
                  this.muveDown();
                }}
                color={"primary"}
                icon
              >
                <Icon
                  style={styles.icon6}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_more"}
                />
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={[styles.cell1, styles.cell2]}>
          <TouchableOpacity
            fullWidth
            style={[
              {
                backgroundColor:
                  this.state.item.objectId == this.state.formularios.objectId
                    ? "rgba(221,221,221,1)"
                    : "rgba(251,251,251,0)"
              }
            ]}
            onPress={() => {
              this.props.activity.editComp(this.state.formularios);
            }}
          >
            <View style={styles.view4}>
              <Icon
                style={styles.icon1}
                fromFontFamily={"Material Icons"}
                color={"primary"}
                icon={this.state.formularios.code.icone}
              />
              <Text style={styles.label1} text={this.state.formularios.nome} />
              <TouchableOpacity
                style={styles.bottom3}
                onPress={() => {
                  this.muveUp();
                }}
                icon
              >
                <Icon
                  style={styles.icon5}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_less"}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.bottom4}
                onPress={() => {
                  this.muveDown();
                }}
                icon
              >
                <Icon
                  style={styles.icon6}
                  color={"primary"}
                  fromFontFamily={"Material Icons"}
                  icon={"expand_more"}
                />
              </TouchableOpacity>
            </View>
          </TouchableOpacity>
          <View style={styles.cell2}>{this.viewColumn()}</View>
        </View>
      );
    }
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {},
      componentes: new Dados("componentes")
    };
    Dados.clonarHeranca(props, this.state);
  }
  componentDidMount() {}
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);
    }
    return true;
  }

  render() {
    return (
      <TouchableOpacity
        style={styles.cell}
        onPress={() => {
          this.props.activity.addComponente(this.state.componentes);
        }}
      >
        <View style={styles.view3}>
          <Icon
            color={"primary"}
            style={styles.icon}
            fromFontFamily={"Material Icons"}
            icon={this.state.componentes.code.icone}
          />
          <Text style={styles.label} text={this.state.componentes.nome} />
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%"
  },
  card3: {
    width: 150
  },
  cell12: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    margin: 5,
    padding: 5
  },
  view5: {
    margin: 5,
    padding: 20,
    paddingTop: 50,
    paddingBottom: 50,
    maxWidth: 710,
    minWidth: 590,
    minHeight: 790,
    display: null,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  view6: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: "rgba(237,239,245,1)"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40
  },
  icon2: {
    fontSize: 30
  },
  view7: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    marginRight: 10
  },
  icon3: {
    fontSize: 25
  },
  bottom2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40
  },
  view8: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 5,
    borderBottomColor: "rgba(169,169,169,1)",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderTopColor: "rgba(0,0,0,0)",
    borderLeftColor: "rgba(0,0,0,0)",
    borderRightColor: "rgba(0,0,0,0)"
  },
  boolTinbrada: {
    alignSelf: "auto",
    width: 55
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 12
  },
  dado_do_relatorio: {
    alignSelf: "auto",
    width: 55
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flex: 1,
    fontSize: 12
  },
  icon4: {
    fontSize: 25
  },
  bottom3: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  icon5: {
    fontSize: 15
  },
  bottom4: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "nowrap",
    marginLeft: 5,
    marginRight: 5
  },
  icon6: {
    fontSize: 15
  },
  fragment: {
    alignSelf: "stretch",
    flex: 1
  },
  nome: {
    minHeight: 35,
    alignSelf: "stretch",
    flexWrap: "nowrap"
  },
  formularios: {
    height: 400,
    alignSelf: "stretch",
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    overflow: "auto"
  },
  cell1: {
    alignSelf: "stretch",
    minHeight: 25,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },

  view9: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative"
  },
  fragment1: {
    alignSelf: "stretch",
    flex: 1
  },
  fragment2: {
    alignSelf: "stretch",
    width: 105
  },
  cell2: {
    marginLeft: 10,
    alignSelf: "stretch",
    minHeight: 25,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    width: "100%",
    padding: 5
  },
  icon1: {
    fontSize: 15
  },
  label1: {
    flex: 1,
    textAlign: "left",
    flexWrap: "wrap",
    alignSelf: "auto",
    marginLeft: 5,
    marginRight: "auto",
    fontSize: 12,
    width: "100%"
  },
  gridview0: {
    alignSelf: "stretch",
    flex: 1
  },
  cell: {
    alignSelf: "stretch",
    minHeight: 60,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view3: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  icon: {
    fontSize: 25
  },
  label: {
    textAlign: "center",
    flexWrap: "wrap",
    alignSelf: "stretch",
    fontSize: 13
  }
});
