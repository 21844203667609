import React from "react";
import StyleSheet from "react-1app/lib/StyleSheet";
import View from "react-1app/lib/View";

const ViewEdit = props => {
  return (
    <View
      {...props}
      style={StyleSheet.flatten([
        (props.elevation?{boxShadow : "1px 2px "+(props.elevation*3)+"px 0px rgba(0,0,0,0.5)"}:{}),
        props.style
      ])}
    />
  );
};

export default ViewEdit;
