"use strict";
import React,{Component} from "react";

import {
  MapView,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  TextInput,
  TitleBar,
  File,
  FotosUpload,
  Endereco
} from "react-1app";
import {
  Progresso,
  Imagem,
  Fotos,
  Image,
  Text,
  ListView,
  Alert,
  Navigator,
  Switch,
  moment,
  banco,
  ApiUteis,
} from "react-1app";
import {
  DrawerLayout,
  ImageUpload,
  StyleSheet,
  GridView,
  Icon,
  Fragment,
  RefreshControl,
  PageView,
  DatePicker
} from "react-1app";
import { Util, Dados, Query } from "../../infra";
import View from "../../components/View";
import {SelectInput} from "../../components/SelectInput";

//nao atualizar
export default class compon2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {}
    };
    Dados.clonarHeranca(props, this.state);
    //{onConstructor}
  }
  componentDidMount() {
    // console.log(this.state);
    //{onDidMount}
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props) {
      Dados.clonarHeranca(nextProps, nextState);

      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }
  //   view1Column(){
  //     var lista=[];
  //     for (var i = 0; i < this.state.item.code.lay.lista.length; i++) {
  //       let itemV = lista[i];
  //       lista.push(
  //         <View>
  //         <Text style={ styles.label2 } text={ i } />
  //         <compon activity={ this } item={itemV} dados={this.state.dados} key={"item_"+i} />
  //         </View>
  //       );
  //     }
  //     return lista;
  //   }
  //   view2Column(){
  //     var lista=[];
  //     for (var i = 0; i < this.state.item.code.lay.lista.length; i++) {
  //       let itemV = lista[i];
  //       lista.push(
  //           <View>
  //         <Text style={ styles.label2 } text={ i,+"2" } />
  //           <compon activity={ this } item={itemV} dados={this.state.dados} key={"item_"+i} />
  //             </View>
  //       );
  //     }
  // return lista;
  //   }

  render() {
    if (this.state.item.code.tag != "check") {
      return (
        <View style={styles.tela}>
          {this.state.item.code.tag == "Text" ? (
            <View
              style={styles.view}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <Text
                style={{
                  textAlign: this.state.item.code.lay.position
                    ? this.state.item.code.lay.position
                    : "left",
                  fontSize: this.state.item.code.lay.font
                    ? this.state.item.code.lay.font
                    : 14,
                  fontWeight: this.state.item.code.lay.stilo
                    ? this.state.item.code.lay.stilo
                    : 400
                }}
                text={this.state.item.code.lay.text}
              />
            </View>
          ) : null}
          {this.state.item.code.tag == "switch" ? (
            <View
              style={styles.view6}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <View style={styles.view7}>
                <Text
                  style={{
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,1)",
                    alignSelf: "stretch",

                    textAlign: this.state.item.code.lay.position
                      ? this.state.item.code.lay.position
                      : "left",
                    fontSize: this.state.item.code.lay.font
                      ? this.state.item.code.lay.font
                      : 14,
                    fontWeight: this.state.item.code.lay.stilo
                      ? this.state.item.code.lay.stilo
                      : 400
                  }}
                  text={this.state.item.code.lay.text}
                />
              </View>
              <Switch
                style={styles.switch}
                value={this.state.item.switch}
                onChange={value => {
                  this.state.item.switch = value;
                  // this.setState({item:this.state.item});
                }}
                ref={v => (this.switch = v)}
              />
            </View>
          ) : null}
          {this.state.item.code.tag == "check" ? (
            <View
              style={{ alignSelf: "auto", width: 200 }}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <TouchableOpacity
                style={{
                  alignSelf: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 50,
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  position: "relative",
                  width: 175,
                  backgroundColor: "rgba(227,227,227,1)",
                  padding: 10,
                  margin: 5
                }}
                elevation={3}
              >
                <Text
                  style={{
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,1)",
                    alignSelf: "stretch",

                    textAlign: this.state.item.code.lay.position
                      ? this.state.item.code.lay.position
                      : "left",
                    fontSize: this.state.item.code.lay.font
                      ? this.state.item.code.lay.font
                      : 14,
                    fontWeight: this.state.item.code.lay.stilo
                      ? this.state.item.code.lay.stilo
                      : 400,
                    flex: 1
                  }}
                  text={this.state.item.code.lay.text}
                />

                {!this.state.test ? (
                  <Icon
                    style={styles.icon2}
                    fromFontFamily={"Material Icons"}
                    icon={"radio_button_unchecked"}
                  />
                ) : null}

                {this.state.test ? (
                  <Icon
                    style={styles.icon1}
                    fromFontFamily={"Material Icons"}
                    icon={"check_circle"}
                  />
                ) : null}
              </TouchableOpacity>
            </View>
          ) : null}
          {this.state.item.code.tag == "TextInput" ? (
            <View
              style={styles.view1}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <View style={styles.view3}>
                <Text
                  style={styles.label1}
                  text={this.state.item.code.lay.descricao}
                />
              </View>
              <View style={styles.view2} />
            </View>
          ) : null}

          {this.state.item.code.tag == "data" ||
          this.state.item.code.tag == "dataEdit" ? (
            <View
              style={styles.view1}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <View style={styles.view3}>
                <Text
                  style={styles.label1}
                  text={this.state.item.code.lay.descricao}
                />
              </View>
              <View style={styles.view2} />
            </View>
          ) : null}

          {this.state.item.code.tag == "image" ? (
            <View
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                flexWrap: "nowrap",
                position: "relative",
                marginTop: 5,

                justifyContent: this.state.item.code.lay.orientacao3
                  ? this.state.item.code.lay.orientacao3
                  : "flex-start",
                alignItems: this.state.item.code.lay.orientacao2
                  ? this.state.item.code.lay.orientacao2
                  : "flex-start"
              }}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <Image
                style={{
                  flexDirection: "column",
                  alignSelf: "auto",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexWrap: "nowrap",

                  width: this.state.item.code.lay.largura
                    ? this.state.item.code.lay.largura
                    : 50,
                  height: this.state.item.code.lay.altura
                    ? this.state.item.code.lay.altura
                    : 50
                }}
                resizeMode={Image.resizeMode.cover}
                source={{ uri: this.state.item.code.lay.link }}
              />
            </View>
          ) : null}

          {this.state.item.code.tag == "fotosupload" ? (
            <View
              style={styles.view4}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <View style={styles.view5} />
            </View>
          ) : null}
          {this.state.item.code.tag == "selectinput" ? (
            <View
              style={styles.view8}
              onClick={() => {
                this.props.activity.editComp(this.state.item);
              }}
              elevation={
                this.state.selecionado == this.state.item.objectId ? 2 : 0
              }
            >
              <View style={styles.view9}>
                <Text
                  style={styles.label3}
                  text={this.state.item.code.lay.descricao}
                />
                <Icon
                  style={styles.icon}
                  fromFontFamily={"Material Design Icons"}
                  icon={"chevron-down"}
                />
              </View>
              <View style={styles.view10} />
            </View>
          ) : null}
        </View>
      );
    } else {
      return (
        <View
          style={{ alignSelf: "auto", width: 175 }}
          onClick={() => {
            this.props.activity.editComp(this.state.item);
          }}
          elevation={this.state.selecionado == this.state.item.objectId ? 2 : 0}
        >
          <TouchableOpacity
            style={{
              alignSelf: "auto",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 50,
              flexDirection: "row",
              flexWrap: "nowrap",
              position: "relative",
              width: 175,
              backgroundColor: "rgba(227,227,227,1)",
              padding: 10,
              margin: 5
            }}
            elevation={3}
          >
            <Text
              style={{
                flexWrap: "wrap",
                color: "rgba(0,0,0,1)",
                alignSelf: "stretch",

                textAlign: this.state.item.code.lay.position
                  ? this.state.item.code.lay.position
                  : "left",
                fontSize: this.state.item.code.lay.font
                  ? this.state.item.code.lay.font
                  : 14,
                fontWeight: this.state.item.code.lay.stilo
                  ? this.state.item.code.lay.stilo
                  : 400,
                flex: 1
              }}
              text={this.state.item.code.lay.text}
            />

            {!this.state.test ? (
              <Icon
                style={styles.icon2}
                fromFontFamily={"Material Icons"}
                icon={"radio_button_unchecked"}
              />
            ) : null}

            {this.state.test ? (
              <Icon
                style={styles.icon1}
                fromFontFamily={"Material Icons"}
                icon={"check_circle"}
              />
            ) : null}
          </TouchableOpacity>
        </View>
      );
    }
  }
}

var styles = StyleSheet.create({
  tela: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5,
    padding: 2
  },
  view13: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    marginTop: 5
  },

  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 14
  },
  image: {
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap"
  },
  view1: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    flex: 1,
    height: 40,
    marginTop: 5
  },
  bottom: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    width: 175,
    backgroundColor: "rgba(227,227,227,1)",
    padding: 10
  },
  icon2: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  icon1: {
    color: "rgba(150,150,145,1)",
    fontSize: 30
  },
  view3: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 3
  },
  label1: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(177,177,177,1)",
    alignSelf: "stretch",
    fontSize: 12
  },
  view2: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 2,
    backgroundColor: "rgba(87,146,221,1)"
  },
  view4: {
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    padding: 20
  },
  view5: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    backgroundColor: "rgba(237,239,245,1)",
    width: 350,
    height: 250
  },
  view6: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 25,
    marginTop: 5
  },
  view7: {
    alignSelf: "auto",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    minHeight: 20
  },
  label2: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 14
  },
  switch: {},
  view8: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 40,
    marginTop: 5
  },
  view9: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    padding: 3
  },
  label3: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(177,177,177,1)",
    alignSelf: "auto",
    fontSize: 12,
    flex: 1
  },
  icon: {
    fontSize: 25
  },
  view10: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 2,
    backgroundColor: "rgba(87,146,221,1)"
  }
});
