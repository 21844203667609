import React, { Component } from "react";
import View from "react-1app/lib/View";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Text from "react-1app/lib/Text";
import Icon from "react-1app/lib/Icon";
import Alert from "react-1app/lib/Alert";
import StyleSheet from "react-1app/lib/StyleSheet";
import { Dados, Query } from "../../infra";
import styleGlobal from "../../styleGlobal";
import ListView from "react-1app/lib/ListView";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";

export default class ClonarFormulario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      list: [],
      load: true,
      item: {}
    };
  }

  componentDidMount() {
    this.buscarDados();
  }

  isClonar(item) {
    Alert.alert(
      "Copiar",
      "Voce realmente deseja copiar o ( " + item.nome + " ) para o reletorio?",
      [
        { text: "Ok", onPress: () => this.clonar(item) },
        { text: "Cancelar", onPress: () => console.log() }
      ]
    );
  }

  clonar(item) {
    item.load = true;
    this.setState({
      save: true
    });
    item.origem = item.objectId;
    item.origemRelatorio = item.relatorio;
    delete item.createdAt;
    delete item.updatedAt;
    delete item.objectId;
    delete item._id;
    delete item.id;
    delete item.relatorio;
    this.state.formularios = new Dados("formularios").parse(item);
    this.state.formularios.relatorio = this.props.destinoRelatorio;
    for (var i = 0; i < this.state.formularios.lista.length; i++) {
      let item = this.state.formularios.lista[i];
      item.objectId = new Date().getTime() +item.objectId + ((item.code||{}).tag||"not_tag") + i + "key_cl";
      if (item.code.lay.lista && item.code.lay.lista[0]) {
        for (var a = 0; a < item.code.lay.lista.length; a++) {
          let item2 = item.code.lay.lista[a];
          item2.objectId =
            new Date().getTime() +item2.objectId +"_"+
            item.code.tag +
            i +
            ((item2.code||{}).tag||"not_tag") +
            a +
            "key_clo";
        }
      }
    }
    // return console.log(this.state.formularios);
    this.state.formularios.salvarNuven(re => {
      item.load = false;
      this.setState({
        save: false
      });
      this.props.activity.buscar();
      this.props.closeModal();
    });
  }

  buscarDados() {
    var query = new Query("formularios");

    query.addAscendingOrder("ordem");
    query.whereEqualTo("relatorio", this.props.relatorio.objectId);
    query.setLimit(1000);
    query.select(lista => {
      this.setState({
        list: lista,
        load: false
      });
    });
  }

  render() {
    return (
      <View style={styleGlobal.Grid} container justify="center" spacing={16}>
        <View style={styleGlobal.Grid} xs={12} ms={10} md={8} lg={6} item>
          <View style={styles.tela} type={"Paper"}>
            <View>
              <Text style={styles.label}>{"Copiar o Formulario"}</Text>
              <TouchableOpacity
                onPress={() => this.props.closeModal()}
                style={{ marginLeft: "auto" }}
                children={"Fechar"}
                color={"default"}
                variant="outlined"
              ></TouchableOpacity>
            </View>
            <ListView
              renderItem={({ item, index }) => {
                return (
                  <ListItem key={index} style={{ width: "100%" }}>
                    <Avatar style={{ marginRight: 20 }}>
                      <Icon
                        style={styles.icon2}
                        fromFontFamily={"Material Icons"}
                        icon={"format_list_numbered"}
                      />
                    </Avatar>
                    <ListItemText
                      primary={item.nome}
                      secondary={item.descricao}
                    />
                    <TouchableOpacity
                      onClick={() => {
                        this.isClonar(item);
                      }}
                      color={"default"}
                      icon
                      load={item.load}
                    >
                      <Icon
                        fromFontFamily={"Material Icons"}
                        name={"content_copy"}
                      />
                    </TouchableOpacity>
                  </ListItem>
                );
              }}
              separator
              nativo
              data={this.state.list}
              load={this.state.load}
            />
          </View>
        </View>
      </View>
    );
  }
}
var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  tela: {
    padding: 20,
    marginTop: 50
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 40,
    borderRadius: 10,
    backgroundColor: "#fff"
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(150,150,145,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    fontWeight: "normal",
    flex: 1,
    marginBottom: 30
  },
  action: {
    padding: 10,
    justifyContent: "center"
  },
  delete: {
    marginLeft: 20
  }
});
