import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Icon from "react-1app/lib/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Header from "./Header";
import MenuDash from "./Menu";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

// import {Util} from "../../infra"
const drawerWidth = 240;

class Navegation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var { user } = this.props.screenProps.store.getState();
      if (user != this.state.user) this.setState({ user });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleTheme = () => {
    this.props.screenProps.store.dispatch({
      type: "SET_THEMA",
      theme:
        this.props.screenProps.store.getState().theme == "light"
          ? "dark"
          : "light"
    });
  };

  render() {
    let { classes } = this.props;
    return [
      <CssBaseline />,

      <Hidden smDown key={"key_maior"}>
        <AppBar
          position="absolute"
          color="primary"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <TouchableOpacity
              icon
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}
            >
              <Icon name={"menu"} />
            </TouchableOpacity>
            <Header {...this.props} />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            )
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <TouchableOpacity icon onClick={this.handleDrawerClose}>
              <Icon name={"chevron_left"} />
            </TouchableOpacity>
          </div>
          <Divider />
          <MenuDash {...this.props} rotas={this.props.rotas || []} />
          <Divider />
          <TouchableOpacity
            className={classes.themeButton}
            variant="outlined"
            onClick={this.handleTheme}
            tooltip={"Tema"}
          >
            <Icon name={"palette"} />
          </TouchableOpacity>
        </Drawer>
      </Hidden>,
      <Hidden mdUp key={"key_menor"}>
        <AppBar
          position="absolute"
          color="primary"
          className={classNames(classes.appBar)}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <TouchableOpacity
              icon
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton)}
            >
              <Icon name={"menu"} />
            </TouchableOpacity>
            <Header {...this.props} />
          </Toolbar>
        </AppBar>
        <Drawer
          open={this.state.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          // disableSwipeToOpen
          onClose={() => this.setState({ open: false })}
        >
          <div
            onClick={() => this.setState({ open: false })}
            className={classes.toolbar}
          >
            <MenuDash {...this.props} rotas={this.props.rotas || []} />
            <Divider />
            <TouchableOpacity
              className={classes.themeButton2}
              variant="outlined"
              onClick={this.handleTheme}
            >
              <Icon name={"palette"} />
            </TouchableOpacity>
          </div>
        </Drawer>
      </Hidden>
    ];
  }
}

const styles = theme => {
  return {
    toolbar: {
      paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    themeButton: { marginTop: "auto", marginBottom: 10 },
    themeButton2: { width: "100%", marginTop: 10, marginBottom: 10 },
    menuButton: {
      marginLeft: 12,
      marginRight: 12
    },
    menuButtonHidden: {
      display: "none"
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9
      }
    },
    toolbar: {
      width: "100%"
    }
  };
};
export default withStyles(styles)(Navegation);
