import React, { Component } from "react";
import ListaInspecoes from "../inspecoes";

export default class Home extends Component {
  render() {
    return (
      <ListaInspecoes
        {...this.props}
        parametros={{
          where:
            "(concluido=false or concluido is NULL) and ( naoedit is NULL  or naoedit=false )"
        }}
        title={"Relatórios em elaboração"}
      />
    );
  }
}
