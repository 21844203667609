"use strict";
import React, { Component } from "react";

import {
  Image,
  Text,
  Alert,
  Switch,
  View,
  OpenSnack,
  ImageUpload,
  TouchableOpacity,
  TextInput,
  Icon,
  StyleSheet
} from "react-1app";

import { Grid, Paper, ListSubheader } from "@material-ui/core";

import ProfileCard from "../../components/ProfileCard";
import GraficoInspetor from "./GraficoInspetor";
import { Dados } from "../../infra";
//nao atualizar
export default class PerfilUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heranca: {},
      item: {},
      user: new Dados("user"),
      load_tela: true,
      user_local: new Dados("user_local").parse(this.props.user_local)
    };
    //{onConstructor}
  }

  alterar(test) {
    if (test == 1) {
      this.state.user_local.adm = true;
    } else {
      this.state.user_local.adm = false;
    }
    if (test == 2) {
      this.state.user_local.market = true;
      this.state.user_local.habilitado = false;
    } else {
      this.state.user_local.market = false;
    }
    if (test == 3) {
      this.state.user_local.campo = true;
    } else {
      this.state.user_local.campo = false;
    }
    this.setState({
      aterado: true
    });
  }

  deletar_bottom1() {
    Alert.alert("Deletar", "Deseja deletar este registro", [
      {
        text: "Não",
        onPress: null
      },
      {
        text: "Sim",
        onPress: () => {
          this.setState({
            save: true
          });
          this.state.user_local.status = 3;
          this.state.user_local.setMetodoApi("salvar", "POST");
          this.state.user_local.salvarNuven(re => {
            this.setState({
              save: false
            });
            OpenSnack({ message: "Deletado", type: "success" });
            if (this.props.activity.get_data_user)
              this.props.activity.get_data_user();
            // if (this.state.navigator.pop) this.state.navigator.pop();
          });
        }
      }
    ]);
  }
  salvar_bottom() {
    this.setState({
      save: true
    });
    this.state.user_local.setMetodoApi("salvar", "POST");
    this.state.user_local.salvarNuven(re => {
      this.props.activity.setState({ user_local: this.state.user_local });
      this.setState({
        save: false
      });
      OpenSnack({ message: "Salvo", type: "success" });
      if (this.props.activity.get_data_user)
        this.props.activity.get_data_user();
    });
  }
  componentDidMount() {
    //{onDidMount}
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user_local != this.props.user_local) {
      this.setState({
        user_local: new Dados("user_local").parse(nextProps.user_local)
      });
      //{onUpdate}
    }
    return true;
  }
  // componentWillUnmount(){
  // }

  render() {
    return (
      <View style={styles.tela}>
        { this.state.user_local.campo && (
          <GraficoInspetor
            user_local={this.state.user_local}
            navigator={this.props.navigator}
          />
        )}
        <View style={styles.view}>
          <Text style={styles.label} text={"Cadastro Usuario"} />
          {this.state.user_local.campo || this.state.user_local.adm ? (
            <View style={styles.view6}>
              <Switch
                label={"P. habilitado"}
                style={styles.habilitado}
                value={this.state.user_local.habilitado}
                onChange={value => {
                  this.state.user_local.habilitado = value;
                  this.setState({
                    user_local: this.state.user_local
                  });
                }}
                ref={v => (this.habilitado = v)}
              />
            </View>
          ) : null}
          <TouchableOpacity
            style={styles.bottom1}
            onPress={() => {
              this.deletar_bottom1();
            }}
          >
            <Icon
              style={styles.icon1}
              fromFontFamily={"Material Icons"}
              icon={"delete"}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.bottom}
            onPress={() => {
              this.salvar_bottom();
            }}
          >
            <Icon
              style={styles.icon}
              fromFontFamily={"Material Icons"}
              icon={"save"}
            />
          </TouchableOpacity>
        </View>
        <Grid container style={styles.grid} spacing={16}>
          <Grid item xs={12} md={6}>
            <ProfileCard
              // avatar={"/img/prof.png"}
              title={this.state.user_local.nome}
              subtitle={this.state.user_local.crea}
              description={this.state.user_local.funcao}
              footer={
                <Grid container style={{ padding: 20 }} spacing={16}>
                  <Grid item xs={6}>
                    <TextInput
                      style={styles.nome}
                      value={this.state.user_local.nome}
                      onChange={value => {
                        this.state.user_local.nome = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.nome = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Nome"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      style={styles.funcao}
                      value={this.state.user_local.funcao}
                      onChange={value => {
                        this.state.user_local.funcao = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.funcao = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Função"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      style={styles.login}
                      value={this.state.user_local.login}
                      onChange={value => {
                        this.state.user_local.login = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.login = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Login"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      style={styles.senha}
                      value={this.state.user_local.senha}
                      onChange={value => {
                        this.state.user_local.senha = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.senha = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Senha"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextInput
                      style={styles.telefone}
                      value={this.state.user_local.telefone}
                      onChange={value => {
                        this.state.user_local.telefone = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.telefone = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Telefone"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      style={styles.celular}
                      value={this.state.user_local.celular}
                      onChange={value => {
                        this.state.user_local.celular = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.celular = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"Celular"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      style={styles.crea}
                      value={this.state.user_local.crea}
                      onChange={value => {
                        this.state.user_local.crea = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.crea = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"CREA"}
                    />
                  </Grid>
                  {!this.state.user_local.user && (
                    <Grid item xs={12}>
                      <View style={styles.view2}>
                        <View style={styles.view3}>
                          <Switch
                            style={styles.adm}
                            value={this.state.user_local.adm}
                            onChange={value => {
                              this.state.user_local.adm = value;
                              this.setState({
                                user_local: this.state.user_local
                              });
                              this.alterar(1);
                            }}
                            ref={v => (this.adm = v)}
                          />
                          <Text style={styles.label1} text={"Administrador"} />
                        </View>
                        <View style={styles.view4}>
                          <Switch
                            style={styles.market}
                            value={this.state.user_local.market}
                            onChange={value => {
                              this.state.user_local.market = value;
                              this.setState({
                                user_local: this.state.user_local
                              });
                              this.alterar(2);
                            }}
                            ref={v => (this.market = v)}
                          />
                          <Text style={styles.label2} text={"Market"} />
                        </View>
                        <View style={styles.view5}>
                          <Switch
                            style={styles.campo}
                            value={this.state.user_local.campo}
                            onChange={value => {
                              this.state.user_local.campo = value;
                              this.setState({
                                user_local: this.state.user_local
                              });
                              this.alterar(3);
                            }}
                            ref={v => (this.campo = v)}
                          />
                          <Text style={styles.label3} text={"Inspetor"} />
                        </View>
                      </View>
                    </Grid>
                  )}
                </Grid>
              }
            />
          </Grid>
          {(this.state.user_local.campo || this.state.user_local.adm) && (
            <Grid item xs={12} md={6}>
              <Paper
                style={{ marginTop: 30, marginBottom: 30, paddingTop: 40 }}
              >
                <Grid
                  container
                  style={{ padding: 20, paddingTop: 0 }}
                  spacing={24}
                >
                  <Grid item xs={12}>
                    <ListSubheader component="div">Assinatura</ListSubheader>
                  </Grid>
                  <Grid item xs={9}>
                    <TextInput
                      style={styles.crea}
                      value={this.state.user_local.doc}
                      onChange={value => {
                        this.state.user_local.doc = value;
                        this.setState({
                          user_local: this.state.user_local
                        });
                      }}
                      ref={v => (this.crea = v)}
                      keyboardType={"default"}
                      secureTextEntry={false}
                      multiline={false}
                      label={"CPF/CNPJ"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <View style={[styles.view6, { marginTop: 20 }]}>
                      <Text style={styles.label3} text={"ICP"} />
                      <Switch
                        style={styles.campo}
                        value={!this.state.user_local.noICP}
                        onChange={value => {
                          this.state.user_local.noICP = !value;
                          this.setState({
                            user_local: this.state.user_local
                          });
                          this.alterar(3);
                        }}
                        ref={v => (this.campo = v)}
                      />
                    </View>
                  </Grid>
                  {this.state.user_local.noICP && (
                    <Grid item xs={6}>
                      <TextInput
                        style={styles.nome}
                        value={this.state.user_local.email}
                        onChange={value => {
                          this.state.user_local.email = value;
                          this.setState({
                            user_local: this.state.user_local
                          });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        secureTextEntry={false}
                        multiline={false}
                        label={"Email"}
                      />
                    </Grid>
                  )}
                  {this.state.user_local.noICP && (
                    <Grid item xs={6}>
                      <TextInput
                        style={styles.nome}
                        value={this.state.user_local.senha_ass}
                        onChange={value => {
                          this.state.user_local.senha_ass = value;
                          this.setState({
                            user_local: this.state.user_local
                          });
                        }}
                        ref={v => (this.nome = v)}
                        keyboardType={"default"}
                        secureTextEntry={false}
                        multiline={false}
                        label={"Senha da assinatura"}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <View style={styles.view7}>
                      <Text
                        style={styles.label5}
                        text={"Assinatura para pdf"}
                      />
                      <ImageUpload
                        aspectRatio={20 / 9}
                        style={styles.image}
                        value={this.state.user_local.image}
                        viewMode={0}
                        onChange={value => {
                          this.state.user_local.image = value;
                          this.setState({
                            user_local: this.state.user_local
                          });
                        }}
                        ref={v => (this.image = v)}
                        resizeMode={Image.resizeMode.cover}
                      />
                    </View>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  tela: {
    flex: 1,
    display: null,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: 10,
    paddingTop: 0
  },
  view: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    height: 40
    // "width": 480
  },
  label: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",
    marginLeft: 10,
    flex: 1,
    fontSize: 20
  },
  labelTitulo: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(7,81,120,1)",
    alignSelf: "stretch",

    flex: 1,
    fontSize: 24
  },
  view6: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
  },
  label4: {
    textAlign: "left",
    flexWrap: "wrap",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontSize: 14
  },
  habilitado: {
    alignSelf: "auto"
  },
  bottom1: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    marginRight: 10,
    marginLeft: 10,
    position: "relative"
  },
  icon1: {
    fontSize: 25,
    color: "rgba(7,81,120,1)"
  },
  bottom: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 40,
    position: "relative"
  },
  icon: {
    fontSize: 25,
    color: "rgba(7,81,120,1)"
  },
  grid: {},
  nome: {
    minHeight: 35,
    marginTop: 10,
    alignSelf: "stretch",
    flexWrap: "nowrap",
    borderRadius: 5,
    textAlign: "left"
  },
  login: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  senha: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  telefone: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  celular: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  funcao: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  crea: {
    minHeight: 35,
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    flexWrap: "nowrap",
    marginTop: 10,
    borderRadius: 5,
    textAlign: "left"
  },
  view2: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    marginTop: 20
  },
  view3: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  adm: {
    alignSelf: "auto"
  },
  label1: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view4: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  market: {
    alignSelf: "auto"
  },
  label2: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view5: {
    alignSelf: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative",
    height: 50,
    width: 90
  },
  campo: {
    alignSelf: "auto"
  },
  label3: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    marginTop: 5,
    fontSize: 13
  },
  view7: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    position: "relative"
    // "height": 75
  },
  label5: {
    textAlign: "center",
    flexWrap: "wrap",
    color: "rgba(109,107,107,1)",
    alignSelf: "stretch",
    fontSize: 13,
    marginBottom: 5
  },
  image: {
    width: 350,
    height: 170,
    flexDirection: "column",
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    backgroundColor: "rgba(237,239,245,1)",
    border: "solid"
  }
});
