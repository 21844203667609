import { Cloud, Dados } from "../infra";

export function buscarInspecoesDash(
  {
    limit,
    local,
    key_user,
    pesquisa,
    pesquisa2,
    objectId,
    page,
    key_equipamento,
    sql_ordem,
    where,
    key_unidade,
    key_empresa,
    key_local,
    codigo,
    isNotEnviado,isNotRevizado,enviado,revizado
  },
  callback
) {
  Cloud.get(
    "inspecoes/cliente",
    {
      limit,
      local,
      key_user,
      pesquisa,
      pesquisa2,
      objectId,
      page,
      status: 1,
      key_equipamento,
      sql_ordem,
      count: true,
      where,
      key_unidade,
      key_empresa,
      key_local,
      codigo,
      isNotEnviado,isNotRevizado,enviado,revizado
    },
    (list, error) => {
      callback(list, error);
      // console.log([...list,...[{test:true}]]);
      // console.log(Object.assign(list,[{test:true}]));
    }
  );
}
export function buscarInspecao({ id, objectId }, callback) {
  Cloud.get("inspecoes", { id, objectId }, (inspecoes = {}, error) => {
    console.log(inspecoes, error);
    // this.setState({inspecoes});
    callback(inspecoes);
  });
}

export function buscarInspecoesRelatorio(input = {}, callback) {
  input.status = 1;
  input.count = true;
  Cloud.get("inspecoes/relatorio", input, (list, error) => {
    callback(list, error);
    // console.log([...list,...[{test:true}]]);
    // console.log(Object.assign(list,[{test:true}]));
  });
}

export function buscarOsUserType(input = {}, callback) {
  input.status = 1;
  Cloud.get("inspecoes/relatorio/os/user", input, (list, error) => {
    callback(list, error);
    // console.log([...list,...[{test:true}]]);
    // console.log(Object.assign(list,[{test:true}]));
  });
}

export function buscarInspecoesRelatorioGrafico(input = {}, callback) {
  input.status = 1;
  input.count = true;
  Cloud.get("inspecoes/relatorio/grafico", input, (list, error) => {
    callback(list, error);
    // console.log([...list,...[{test:true}]]);
    // console.log(Object.assign(list,[{test:true}]));
  });
}
export function buscarInspecoesRelatorioGraficoTipo(input = {}, callback) {
  input.status = 1;
  input.count = true;
  Cloud.get("inspecoes/relatorio/grafico/tipo", input, (list, error) => {
    callback(list, error);
    // console.log([...list,...[{test:true}]]);
    // console.log(Object.assign(list,[{test:true}]));
  });
}
export function buscarInspecoesRelatorioGraficoUser(input = {}, callback) {
  input.status = 1;
  input.count = true;
  Cloud.get(
    "inspecoes/relatorio/grafico/inspecao/usuario",
    input,
    (list, error) => {
      callback(list, error);
      // console.log([...list,...[{test:true}]]);
      // console.log(Object.assign(list,[{test:true}]));
    }
  );
}

export function validarPdfAssinado(input = {}, callback) {
  Cloud.get("assinaweb/code/validar", input, (list, error) => {
    callback(list, error);
    // console.log([...list,...[{test:true}]]);
    // console.log(Object.assign(list,[{test:true}]));
  });
}

export function statusOSAberto(input = {}, callback) {
  Cloud.get("inspecoes/status/os/aberto", input, (list, error) => {
    callback(list, error);
  });
}

export function basic(input = {}, callback) {
  Cloud.get("inspecoes/dados/basico", input, (list, error) => {
    callback(list, error);
  });
}


export function valvulasInstrumentos(input = {}, callback) {
  Cloud.get("valvulas/instrumentos/dados", input, (list, error) => {
    callback(list, error);
  });
}



export function clonarInspeacao(item, callback) {
  var tempnumero = new Date().getTime() - 1495800000000;
  item.numero = "R/" + tempnumero;
  item.origem = item.objectId;
  delete item.createdAt;
  delete item.updatedAt;
  delete item.objectId;
  delete item._id;
  delete item.id;
  delete item.url;
  delete item.impressao;
  delete item.link_rel;
  delete item.link_rel_impressao;
  item.verificar = null;
  item.concluido = null;
  item.sPendecias = null;
  item.id_local = item.id_local + "_CP";
  item._id_local = item._id_local + "_CP";
  item._idLocal = item._idLocal + "_CP";
  let inspecoes = new Dados("inspecoes").parse(item);

  inspecoes.salvarNuven(re => {
    callback(re);
  });
}
