import * as types from "./actionTypes";
import { loadUser } from "../worker/login";
import cookie from "react-cookies";

export default function app(state = initState, action = {}) {
  console.log(action.type);
  switch (action.type) {
    case types.LOGOUT:
      delete state.user;
      return state;

    case types.LOGIN:
      state.user = action.user;
      return state;

    case types.SET_THEMA:
      cookie.save("theme", action.theme);
      return Object.assign(state, { theme: action.theme });

    default:
      return state;
  }
}

const initState = {
  user: null,
  empresaConfig: {},
  theme: cookie.load("theme") || "light"
};
