import React, { Component } from "react";

import View from "react-1app/lib/View";
import Icon from "react-1app/lib/Icon";
import Image from "react-1app/lib/Image";
import StyleSheet from "react-1app/lib/StyleSheet";
import TouchableOpacity from "react-1app/lib/TouchableOpacity";
import TextInput from "../../components/Input";
import Avatar from "@material-ui/core/Avatar";
import { Util, Dados, Query, Cloud } from "../../infra";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import Text from "react-1app/lib/Text";
import * as Inspecoes from "../../worker/inspecoes";

// import Table from "react-1app/lib/Table";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ModalIframe from "../../components/ModalIframe";
import { OpenSnack, OpenDialogOption, Switch } from "react-1app";
import Select from "../../components/SelectInput";
import DatePicker from "../../components/DatePicker";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import ReactDataSheet from "react-datasheet";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
// import "react-datasheet/lib/react-datasheet.css";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { json2excel } from "js2excel";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      usuario: this.props.screenProps.store.getState().user,
      totalLista: 0,
      inicio: null,
      termino: null,
      order: {},
      parametros: {
        limit: 1000,
        page: 0,
        paginacao: true,
        sql_ordem: '  "createdAt" DESC nulls last',
        key_user: this.props.screenProps.store.getState().user.objectId
      },
      denominacao: [],
      list: [],
      list2: [],
      listaColunas: [
        { value: true, label: "Item" },
        { value: true, label: "Denominação", filtro: "denominacao" },
        { value: true, label: "TAG", filtro: "tag" },
        { value: true, label: "Apto a operação?", filtro: "apto" },
        { value: true, label: "Área", filtro: "local" },
        { value: true, label: "PMTA(kgf/cm²)" },
        { value: true, label: "Cat", filtro: "cat" },
        { value: true, label: "Relatório" },
        { value: true, label: "Próxima Externa", filtro: "ve" },
        { value: true, label: "Próxima Interna", filtro: "vi" },
        { value: true, label: "Adequações NR-13", filtro: "nr13" },
        { value: true, label: "TAG (PSV)", filtro: "tag2" },
        { value: true, label: "Diâmetro. (PSV)", filtro: "diametro" },
        { value: true, label: "Pressão de Abertura (PSV)", filtro: "pa" },
        { value: true, label: "Data de Calibração (PSV)" },
        { value: true, label: "Data de Vencimento (PSV)", filtro: "vc" },
        { value: true, label: "Nº Certif. de Calibração (PSV)" },
        { value: true, label: "TAG (PI)", filtro: "tag3" },
        { value: true, label: "Escala (PI)", filtro: "escala" },
        { value: true, label: "Diâmetro. (PI)", filtro: "diametro" },
        { value: true, label: "Data de calibração (PI)" },
        { value: true, label: "Data de Vencimento (PI)", filtro: "vc2" },
        { value: true, label: "Nº Certif. de Calibração (PI)" }
      ]
    };
  }

  componentDidMount() {
    this.buscar();
  }

  buscar() {
    let { parametros, text } = this.state;
    this.setState({ load: true });
    Inspecoes.valvulasInstrumentos(
      { ...parametros, ...this.props.parametros, pesquisa2: text },
      (dados, error) => {
        console.log(dados, error, this.montarList(dados));
        this.setState({
          totalLista: 10,
          list: dados,
          load: false,
          list2: this.montarList(dados)
        });
      }
    );
  }
  montarList(list) {
    return (list||[]).map((inspecoes, index) => [
      index + 1 + "",
      inspecoes.equipamento.denominacao || "-",
      inspecoes.equipamento.codigo || "-",
      inspecoes.apto == "true"
        ? "SIM"
        : inspecoes.apto == "false"
        ? "NÃO"
        : "-",
      inspecoes.local.nome || "-",
      ((inspecoes.equipamento.lista||[]).filter(a => (a.descricao||'').includes("PMTA") &&a.kgf) || []).map(a=>(`${a.descricao} - ${a.kgf}`))|| "-",
      inspecoes.equipamento.categoria || "-",
      inspecoes.numero || "-",
      (inspecoes.ven_externo &&
        moment(inspecoes.ven_externo).format("DD/MM/YYYY")) ||
        "-",
      (inspecoes.ven_interno &&
        moment(inspecoes.ven_interno).format("DD/MM/YYYY")) ||
        "-",
      ((inspecoes.ocorrencias || {}).lista || [])
        .filter(a => (a.tipo||'').includes("NR-13"))
        .map((a, i) => a.descricao),
      ...this.montarDispSeguranca(inspecoes),
      ...this.montarIndicador(inspecoes),
      inspecoes.url,
      inspecoes.arquivos
    ]);
  }
  montarDispSeguranca(inspecoes) {
    if (!inspecoes.list_components) inspecoes.list_components = [];
    let dados = inspecoes.d_seguranca;
    let list = (dados || []).map(d => [
      d[
        (inspecoes.list_components.find(a => a.value == "TAG") || {}).objectId
      ] || "-",
      d[
        (
          inspecoes.list_components.find(
            a => a.value == "Diâmetro (Entrada)"
          ) || {}
        ).objectId
      ] || "-",
      d[
        (
          inspecoes.list_components.find(
            a => a.value == "Pressão de Abertura"
          ) || {}
        ).objectId
      ] || "-",
      d[
        (
          inspecoes.list_components.find(
            a => a.value == "Data de Calibração"|| a.value == "Data do Ensaio"
          ) || {}
        ).objectId
      ] || "-",
      this.getPeridicidade(
        d[
          (
            inspecoes.list_components.find(a => a.value == "Periodicidade") ||
            {}
          ).objectId
        ],
        d[
          (
            inspecoes.list_components.find(
              a => a.value == "Data de Calibração"|| a.value == "Data do Ensaio"
            ) || {}
          ).objectId
        ]
      ) || "-",
      d[
        (
          inspecoes.list_components.find(
            a => a.value == "N° Certif. de Calibração"||a.value =="Certificado"
          ) || {}
        ).objectId
      ] || "-"
    ]);
    let l = [
      list.map(a => a[0].trim()),
      list.map(a => a[1].trim()),
      list.map(a => a[2].trim()),
      list.map(a => a[3].trim()),
      list.map(a => a[4].trim()),
      list.map(a => a[5].trim())
    ];
    return l;
  }
  montarIndicador(inspecoes) {

    let dados = inspecoes.manometro;
    if (!inspecoes.list_manometros) inspecoes.list_manometros = [];
    let list = (dados || []).map(d => [
      d[
        (inspecoes.list_manometros.find(a => a.value == "TAG") || {}).objectId
      ] || "-",
      d[
        (inspecoes.list_manometros.find(a => a.value == "Escala") || {})
          .objectId
      ] || "-",
      d[
        (
          inspecoes.list_manometros.find(
            a => a.value == "Diâmetro (Entrada)"||a.value=="Diâmetro"
          ) || {}
        ).objectId
      ] || "-",
      d[
        (
          inspecoes.list_manometros.find(
            a => a.value == "Data de Calibração"||a.value == "Ano de Calibração"
          ) || {}
        ).objectId
      ] || "-",
      this.getPeridicidade(
        d[
          (
            inspecoes.list_manometros.find(a => a.value == "Periodicidade") ||
            {}
          ).objectId
        ],
        d[
          (
            inspecoes.list_manometros.find(
              a => a.value == "Data de Calibração"||a.value == "Ano de Calibração"
            ) || {}
          ).objectId
        ]
      ) || "-",
      d[
        (
          inspecoes.list_manometros.find(
            a => a.value == "N° Certif. de Calibração"||a.value == "Está Calibrado?"
          ) || {}
        ).objectId
      ] || "-"
    ]);
    let l = [
      list.map(a => a[0].trim()),
      list.map(a => a[1].trim()),
      list.map(a => a[2].trim()),
      list.map(a => a[3].trim()),
      list.map(a => a[4].trim()),
      list.map(a => a[5].trim())
    ];

    return l;
  }
  getPeridicidade(periodicidade, data) {
    if (!periodicidade || !data) return "";
    return moment(data, "DD/MM/YYYY")
      .add(periodicidade, "years")
      .format("DD/MM/YYYY");
  }

  getList() {
    let {
      list2,
      denominacao,
      tag,
      apto,
      local,
      cat,
      ve,
      vi,
      tag2,
      diametro,
      pa,
      tag3,
      escala,
      diametro2,
      vc,
      vc2,
      nr13,
      order
    } = this.state;
    let list = [...list2];
    if (denominacao && denominacao[0])
      list = list.filter(a => denominacao.find(e => a[1] == e));
    if (tag && tag[0]) list = list.filter(a => tag.find(e => a[2] == e));
    if (apto && apto[0]) list = list.filter(a => apto.find(e => a[3] == e));
    if (local && local[0]) list = list.filter(a => local.find(e => a[4] == e));
    if (cat && cat[0]) list = list.filter(a => cat.find(e => a[6] == e));
    if (ve && ve[0])
      list = list.filter(a =>
        ve.find(
          e =>
            (a[8]
              .split("/")
              .splice(1, 2)
              .join("/") || "SEM DATA") == e
        )
      );
    if (vi && vi[0])
      list = list.filter(a =>
        vi.find(
          e =>
            (a[9]
              .split("/")
              .splice(1, 2)
              .join("/") || "SEM DATA") == e
        )
      );
    if (nr13 && nr13[0])
      list = list.filter(a =>
        nr13.find(
          e => (a[10][0] ? "COM Adequações NR13" : "SEM Adequações NR13") == e
        )
      );

    if (tag2 && tag2[0])
      list = list.filter(a => tag2.find(e => a[11].join(", ") == e));
    if (diametro && diametro[0])
      list = list.filter(a => diametro.find(e => a[12].join(", ") == e));
    if (pa && pa[0])
      list = list.filter(a => pa.find(e => a[13].join(", ") == e));
    if (tag3 && tag3[0])
      list = list.filter(a => tag3.find(e => a[17].join(", ") == e));
    if (escala && escala[0])
      list = list.filter(a => escala.find(e => a[18].join(", ") == e));
    if (diametro2 && diametro2[0])
      list = list.filter(a => diametro2.find(e => a[18].join(", ") == e));

    if (vc && vc[0])
      list = list.filter(a =>
        vc.find(
          e =>
            (a[15]
              .map(
                w =>
                  w
                    .split("/")
                    .splice(1, 2)
                    .join("/") || "SEM DATA"
              )
              .join(", ")) == e
        )
      );
    if (vc2 && vc2[0])
      list = list.filter(a =>
        vc2.find(
          e =>
            (a[21]
              .map(
                w =>
                  w
                    .split("/")
                    .splice(1, 2)
                    .join("/") || "SEM DATA"
              )
              .join(", ")) == e
        )
      );
    if (order.pos) {
      list = list.sort((a, b) => {
        let aa = a[order.pos];
        let bb = b[order.pos];
        aa = aa.constructor && aa.constructor === Array ? aa.join(",") : aa;
        bb = bb.constructor && bb.constructor === Array ? bb.join(",") : bb;
        if (aa > bb) {
          return 1;
        }
        if (aa < bb) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      if (order.order == "asc") {
        return list;
      } else {
        return list.reverse();
      }
    }
    return list;
  }

  openModalColunas() {
    this.setState({ dialog: true });
  }

  exportToExcel(dados) {
    var data = this.getList().map(item => {
      let obj = {};
      this.state.listaColunas.forEach((a,i)=>{
        if(i&&a.value)
        obj[a.label]=item[i].constructor &&item[i].constructor === Array?item[i].join(' \n'):item[i];
      })
      return obj;
    });
    // console.log(data);
    try {
      json2excel({
        data,
        name: "Lista de equipamento",
        formateDate: "yyyy/mm/dd"
      });
    } catch (e) {
      console.log(e);
      console.error("export error");
      alert("Erro ao criar");
    }
  }
  totalizadore() {
    let list = this.state.list2;
    let aptos = list.filter(a => a[3] == "SIM").length;
    let naoApto = list.filter(a => a[3] == "NÃO").length;
    return [
      <View
        style={{ width: 1.5, margin: 10, backgroundColor: "white", height: 30 }}
      />,
      <Text color="inherit">{`${list.length}  equipamentos nessa unidade, ${aptos} aptos e ${naoApto} não aptos`}</Text>,
      <TouchableOpacity
        style={styles.action}
        children={"Gerar excel"}
        color="inherit"
        variant={"outlined"}
        onPress={() => {
          this.exportToExcel();
        }}
      />
    ];
  }

  actionInspecaoArquivos(inspecoes) {
    if (!inspecoes||!inspecoes.lista || !inspecoes.lista[0])
      return alert("Ainda nao tem arquivos");
    let listOpcoes = inspecoes.lista.map(item => ({
      label: item.descricao||item.arquivo,
      onPress: () => Util.web(item.arquivo)
    }));
    listOpcoes.push({
      label: "Fechar",
      onPress: () => console.log(),
      separa: "-"
    });
    OpenDialogOption({
      title: "Arquivos da inspeção",
      description: "Clique para visualizar",
      action: listOpcoes
    });
  }

  getSubHead(init, fim, label, color) {
    let total = this.state.listaColunas.filter(
      (e, x) => e.value && x >= init && x <= fim
    ).length;
    if (total)
      return (
        <TableCell
          align="center"
          style={{ backgroundColor: color }}
          colSpan={total}
        >
          {label}
        </TableCell>
      );
  }

  verPdf(pdf) {
    this.props.navigator.openModal({
      component: ModalIframe,
      props: {
        heranca: this.state,
        activity: this,
        title: "PDF",
        src: pdf
      }
    });
  }
  render() {
    return (
      <div className={this.props.classes.root}>
        <Dialog
          open={this.state.dialog}
          onClose={() => this.setState({ dialog: false })}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
        >
          <DialogTitle id="confirmation-dialog-title">{"Culunas"}</DialogTitle>
          <DialogContent>
            <View style={{ flexDirection: "column" }}>
              {this.state.listaColunas.map((coluna, i) => {
                return (
                  <TouchableOpacity
                    style={{ justifyContent: "flex-start", padding: 5 }}
                    onPress={() => {
                      coluna.value = !coluna.value;
                      this.setState({ listaColunas: this.state.listaColunas });
                    }}
                  >
                    <Icon
                      name={
                        coluna.value
                          ? "radio_button_checked"
                          : "radio_button_unchecked"
                      }
                    />
                    <Text style={{ marginLeft: 15 }}>{coluna.label}</Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialog: false })}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <View style={styles.view2} container spacing={16}>
          <AppBar>
            <Toolbar>
              <Text variant="h6" color="inherit">
                Tabela de equipamentos
              </Text>
              {!this.state.load ? this.totalizadore() : null}

              <TouchableOpacity
                icon
                style={styles.add}
                color="inherit"
                aria-label="Close"
                onPress={() => {
                  this.props.closeModal();
                }}
              >
                <Icon name={"close"} />
              </TouchableOpacity>
            </Toolbar>
          </AppBar>
          <View style={styles.view3} xs={12} item>
            {this.state.load ? <LinearProgress /> : null}
            {true && (
              <Table>
                <TableHead
                  onContextMenu={e => {
                    e.preventDefault();
                    this.openModalColunas();
                  }}
                >
                  <TableRow>
                    {this.getSubHead(0, 10, "Equipamento")}
                    {this.getSubHead(
                      11,
                      16,
                      "Dispositivos de Segurança",
                      "aliceblue"
                    )}
                    {this.getSubHead(17, 28, "Indicadores de Pressão")}
                  </TableRow>
                  <TableRow>
                    {this.state.listaColunas.map((item, index) => {
                      if (item.value)
                        return (
                          <TableCell align="center">
                            {item.filtro ? (
                              <SelectMultiplo
                                value={this.state[item.filtro] || []}
                                lista={this.state.list2}
                                pos={index}
                                onChange={value => {
                                  this.setState({ [item.filtro]: value });
                                }}
                                order={this.state.order}
                                setOrder={order => {
                                  this.setState({ order });
                                }}
                              >
                                {item.label}
                              </SelectMultiplo>
                            ) : (
                              item.label
                            )}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getList().map((inspecoes, index) => {
                    return (
                      <TableRow
                        key={"inspecoes_" + index}
                        // onClick={() => {
                        //   if (inspecoes[23]) this.verPdf(inspecoes[23]);
                        // }}
                      >
                        {inspecoes.map((a, i) => {
                          if (i < 23 && this.state.listaColunas[i].value)
                            return (
                              <TableCell
                                align="center"
                                style={{...(i==5?{minWidth:220}:null),
                                  ...(i == 10
                                    ? { minWidth: 600 }
                                    : i == 3 && a == "NÃO"
                                    ? { backgroundColor: "red" }
                                    : i >= 11 && i <= 16
                                    ? { backgroundColor: "aliceblue" }
                                    : null),
                                  ...(inspecoes[24]&&i==17||inspecoes[23]&&i==2
                                    ? { cursor: "pointer",color:'blue' }
                                    : null)
                                }}
                                disabled={i!=17&&i!=2}
                                onClick={()=>{
                                  if(inspecoes[24]&&i==17)this.actionInspecaoArquivos(inspecoes[24]);
                                  if(inspecoes[23]&&i==2) this.verPdf(inspecoes[23]);
                                  // alert(888888)
                                }}
                              >
                                {a.constructor && a.constructor === Array
                                  ? a.map((d, i) => (
                                      <>
                                        {i ? (
                                          <>
                                            <br />
                                            <br />
                                          </>
                                        ) : null}
                                        {d}
                                      </>
                                    ))
                                  : a}
                              </TableCell>
                            );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </View>
        </View>
      </div>
    );
  }
}
class SelectMultiplo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getValue() {}
  render() {
    if (!this.props) return null;
    return [
      <View style={{ display: "block" }}>
        <TableSortLabel
          active={this.props.order.pos == this.props.pos}
          direction={this.props.order.order}
          onClick={event => {
            let value = "";
            if (this.props.order.pos == this.props.pos) {
              value = this.props.order.order == "asc" ? "desc" : "asc";
            } else {
              value = "asc";
            }
            this.props.setOrder({ pos: this.props.pos, order: value });
          }}
        >
          {this.props.children}
        </TableSortLabel>

        <Icon
          style={{ cursor: "pointer", marginRight: -35, marginBottom: -10 }}
          onClick={() => {
            this.setState({ open: true });
          }}
          name={"filter_list"}
        ></Icon>
      </View>,
      <ConfirmationDialogRaw
        {...this.props}
        open={this.state.open}
        title={this.props.children}
        lista={this.props.lista}
        onClose={() => {
          this.setState({ open: false });
        }}
        onChange={value => {
          this.setState({ open: false });
          this.props.onChange(value);
        }}
      />
    ];
  }
}

class ConfirmationDialogRaw extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value2: props.value || [],
      options: this.options(props.lista)
    };
  }

  // TODO
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== this.props.value ||
      nextProps.lista !== this.props.lista ||
      nextProps.pos !== this.props.pos
    ) {
      this.setState({
        value: nextProps.value,
        options: this.options(nextProps.lista)
      });
    }
  }

  handleEntering = () => {
    this.radioGroupRef.focus();
  };
  //
  handleCancel = () => {
    this.props.onClose(this.props.value);
  };
  //
  handleOk = () => {
    this.props.onClose(this.state.value);
    this.props.onChange(this.state.value2);
  };

  handleChange = (event, value) => {
    let index = this.state.value2.findIndex(q => q == value);
    // console.log(index, value);
    if (index > -1) {
      this.state.value2.splice(index, 1);
    } else {
      this.state.value2.push(value);
    }
    // console.log(this.state.value2);
    this.setState({ value2: this.state.value2 });
    // this.props.onChange(this.state.value2)
  };
  options(lista) {
    let l = [];
    if (!this.props) return l;
    (lista || []).forEach(i => {
      let item = i[this.props.pos];
      if ((this.props.pos == 8 || this.props.pos == 9) && item)
        item =
          item
            .split("/")
            .splice(1, 2)
            .join("/") || "SEM DATA";
      if ((this.props.pos == 15 || this.props.pos == 21) && item)
        item = item.map(
          a =>
            a
              .split("/")
              .splice(1, 2)
              .join("/") || "SEM DATA"
        );
      // if(item=='NÃO')item='NÃO';
      if (this.props.pos == 10) {
        if (item[0]) {
          item = "COM Adequações NR13";
        } else {
          item = "SEM Adequações NR13";
        }
      }
      if (!item && item != 0) item = "-";
      item =
        item.constructor && item.constructor === Array ? item.join(", ") : item;
      item = item.trim();
      if (item && !l.find(a => a == item)) l.push(item);
    });

    return l.sort((aa, bb) => {
      if (aa > bb)   return 1;
      if (aa < bb)   return -1;
      return 0;
    });
  }

  render() {
    const { value, title, onChange, ...other } = this.props;
    if (!this.props.open) return null;
    return (
      <Dialog
        // disableBackdropClick
        // disableEscapeKeyDown
        maxWidth="xs"
        // onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <RadioGroup
            ref={ref => {
              this.radioGroupRef = ref;
            }}
            aria-label="Ringtone"
            name="ringtone"
          >
            {this.state.options.map((option, i) => {
              // if (this.state.listaColunas[i].value)
              return (
                <Switch
                  value={Boolean(
                    !this.state.value2[0] ||
                      this.state.value2.find(q => q == option)
                  )}
                  type={"checkbox"}
                  onChange={() => this.handleChange(null, option)}
                  label={option}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    cursor: "pointer"
  },
  border: {
    borderColor: "rgba(0,0,0,0.1)",
    borderWidth: 1,
    borderStyle: "solid",
    marginRight: 5,
    padding: 4
  },
  datepicker: {
    marginLeft: 3
  },
  action: {
    marginLeft: 20
  },
  selectinput: {
    marginRight: 4,
    width: 200
  },
  input: {
    marginRight: 10,
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: 10,
    paddingLeft: 10,
    width: 200
  },
  image: {
    height: 30,
    width: 30
  },
  head: {
    height: 50,
    width: "100%",
    padding: 5
  },
  add: {
    marginLeft: "auto"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view3: {
    paddingTop: 70,
    height: "100%"
  }
});

const styles2 = theme => ({
  root: {
    display: "flex",
    minWidth: "100%",
    overflow: "auto",
    height: "100%",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 2
  }
});
export default withStyles(styles2)(Home);
